import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Form, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import DropDown from "../../components/common/DropDown";
import MultiSelDropDown from "../../components/common/MultiSelDropDown";
import InputText from "../../components/form/inputText";
// import DropDown from "../../components/common/DropDown";
// import TextInput from "../../components/common/TextInput";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { updateLoading } from "../../store/actions";

function Create() {
  const [validated, setValidated] = useState(false);
  const [categoryType, setCategoryType] = useState("");
  // const [propertyType, setPropertyType] = useState("");

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const navigate = useNavigate();
  let auth_user = commonService.getLoginUserData();

  useEffect(() => {
    getPropertyList();
    getQuotationDuration();
    getQuotationServiceHours();
    getCompanyList();
  }, []);

  const [isCompanyFormClick, setIsCompanyFormClick] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [serviceHoursList, setServiceHoursList] = useState([]);
  const dispatch = useDispatch();

  const [formInputs, setInput] = useState({
    property_id: "",
    duration_term: "",
    service_hours_term: "",
    no_of_guards: "",
    no_of_checkpoints: "",
    no_of_patrolling_in_day: "",
    company_list: [],
  });
  const [formInputsErrors, setInputErrors] = useState({
    property_id: "",
    duration_term: "",
    service_hours_term: "",
    no_of_guards: "",
    no_of_checkpoints: "",
    no_of_patrolling_in_day: "",
    company_list: "",
  });

  const validation_msg = {
    property_id: "This is a required field",
    duration_term: "This is a required field",
    service_hours_term: "This is a required field",
    no_of_guards: "This is a required field",
    no_of_checkpoints: "This is a required field",
    no_of_patrolling_in_day: "This is a required field",
    company_list: "This is a required field",
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  async function submitCompanyForm(event) {
    event.preventDefault();
    if (validateCompanyForm()) {
      dispatch(updateLoading(true));
      setIsCompanyFormClick(true);
      const requestMetadata = await APICall(
        ApiRoutes.QuotationSave,
        formInputs
      );
      setIsCompanyFormClick(false);
      dispatch(updateLoading(false));
      if (requestMetadata) {
        navigate("/myquotation");
      }
    }
  }
  const getCompanyList = async () => {
    const res = await APICall(ApiRoutes.CompanyDropdownList);
    if (res) {
      setCompanyList(res.data.list);
    }
  };

  const getQuotationDuration = async () => {
    const requestMetadata = await APICall(ApiRoutes.QuotationDuration);
    if (requestMetadata) {
      setDurationList(requestMetadata.data.list);
    }
  };

  const getQuotationServiceHours = async () => {
    const requestMetadata = await APICall(ApiRoutes.QuotationServiceHours);
    if (requestMetadata) {
      setServiceHoursList(requestMetadata.data.list);
    }
  };

  const getPropertyList = async () => {
    const requestMetadata = await APICall(ApiRoutes.PropertyList, {
      is_filter: 1,
      filter: {
        status: 1,
        search_text: "",
      },
    });
    if (requestMetadata) {
      setPropertyList(
        requestMetadata.data.list.map((mapitem) => ({
          value: mapitem.property_id,
          label: mapitem.property_name,
          category_type: mapitem.property_category_type_title,
          property_type: mapitem.property_development_type_title,
        }))
      );
    }
  };

  function validateCompanyForm() {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors);
    const arrkeys = ["company_list"];
    errorKeys.forEach((item) => {
      if (arrkeys.includes(item)) {
        if (formInputs[item].length === 0) {
          errors[item] = validation_msg[item];
          isValid = false;
        } else {
          errors[item] = "";
        }
      } else if (formInputs[item]?.trim() === "") {
        errors[item] = validation_msg[item];
        isValid = false;
      } else {
        if (item === "email" && !pattern.test(formInputs.email)) {
          errors[item] = validation_msg.valid_email;
          isValid = false;
        } else {
          errors[item] = "";
        }
      }
    });

    setInputErrors(errors);
    return isValid;
  }

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              Get Quotation
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              {/* <Breadcrumb.Item href="/myquotation">Setup</Breadcrumb.Item> */}
              <Breadcrumb.Item href="/myquotation">Quotation</Breadcrumb.Item>
              <Breadcrumb.Item active>Request</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Card className="pt-4">
        <Card.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row>
              <DropDown
                data={propertyList}
                title={"Property"}
                onChange={(value) => {
                  setInput({
                    ...formInputs,
                    property_id: value,
                  });
                  if (value) {
                    propertyList.forEach((property) => {
                      if (property.value == value) {
                        setCategoryType(property.category_type);
                        // setPropertyType(property.property_type)
                      }
                    });
                  } else {
                    setCategoryType("");
                    // setPropertyType("")
                  }
                }}
                required
                validationText={formInputsErrors.property_id}
              />
              <InputText
                type={"text"}
                title={"Category Type"}
                value={categoryType}
                disabled
                // onChange={(value) =>
                //   setInput({
                //     ...formInputs,
                //     no_of_guards: value,
                //   })
                // }
                // required
                // validationText={formInputsErrors.no_of_guards}
              />
              {/* <InputText
                disabled
                type={"text"}
                title={"Property Type"}
                value={propertyType}
              // onChange={(value) =>
              //   setInput({
              //     ...formInputs,
              //     no_of_guards: value,
              //   })
              // }
              // required
              // validationText={formInputsErrors.no_of_guards}
              /> */}
              <DropDown
                data={durationList}
                title={"Duration"}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    duration_term: value,
                  })
                }
                required
                validationText={formInputsErrors.duration_term}
              />
              <DropDown
                data={serviceHoursList}
                title={"Service Hours"}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    service_hours_term: value,
                  })
                }
                required
                validationText={formInputsErrors.service_hours_term}
              />
            </Row>
            <Row>
              <InputText
                type={"text"}
                title={"Number of Guards"}
                value={formInputs.no_of_guards}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    no_of_guards: value,
                  })
                }
                required
                validationText={formInputsErrors.no_of_guards}
              />
              <InputText
                type={"text"}
                title={"Number of checkpoints"}
                value={formInputs.no_of_checkpoints}
                onChange={(value) => {
                  if (value && value !== "" && parseInt(value) < 51) {
                    setInput({
                      ...formInputs,
                      no_of_checkpoints: value,
                    });
                  } else {
                    setInput({
                      ...formInputs,
                      no_of_checkpoints: "",
                    });
                  }
                }}
                required
                validationText={formInputsErrors.no_of_checkpoints}
              />
            </Row>
            <Row>
              <InputText
                type={"number"}
                title={"Number of patrolling in a day"}
                value={formInputs.no_of_patrolling_in_day}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    no_of_patrolling_in_day: value,
                  })
                }
                required
                validationText={formInputsErrors.no_of_patrolling_in_day}
              />
            </Row>
            <Row>
              <MultiSelDropDown
                data={companyList}
                onChangeValue={(value) => {
                  setInput({
                    ...formInputs,
                    company_list: value,
                  });
                }}
                disabled={false}
                colDiv={"col-md-12 col-12"}
                title={"Select Company"}
                inputClassName={"class-custom"}
                inputLable={"Select Company"}
                validationText={formInputsErrors.company_list}
              />

              {/* <DropDown
                data={companyList}
                title={"Select Company / Branch"}
                isMulti={true}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    company_list: value,
                  })
                }
                required
                validationText={formInputsErrors.company_list}
              /> */}
            </Row>
            <div className="col-12 d-flex justify-content-end">
              <Button
                className="btn-15"
                variant="outline-primary"
                onClick={() => {
                  navigate("/myquotation");
                }}
              >
                Cancel
              </Button>

              {isCompanyFormClick === false ? (
                <>
                  <Button
                    variant="primary"
                    className="btn-15"
                    onClick={submitCompanyForm}
                  >
                    Send
                  </Button>
                </>
              ) : (
                <Button variant="primary" className="btn-15">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default Create;
