import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Images/logo/logo.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { AppConfigData, commonService } from "../../services/common.service";
import { alertService, AlertType } from "../../services/alert.service";
import InputText from "../../components/form/inputText";
import Spinner from "react-bootstrap/Spinner";
import { APICall } from "../../services/axiosService";
import { ApiRoutes } from "../../constants/apiroutes";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndCondition from "./TermsAndCondition";
import { ReactComponent as PageLoading } from "../../assets/Icons/page-loading.svg";

function Signup() {
  const [validated, setValidated] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [agreementEr, setAgreementEr] = useState("");
  const [agreementDiv, setAgreementDiv] = useState(false);
  const [policy, setPolicy] = useState(null);
  const [term, setTerm] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const [showTermsCon, setShowTermsCon] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  // document.getElementById("main").classList.add("login");
  // document.getElementById("main").classList.remove("menu-open");
  // document.getElementById("main").style.marginLeft = null;

  const history = useNavigate();

  const [input, setInput] = useState({
    client_name: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    position: "",
  });

  const [formInputsErrors, setFormInputsErrors] = useState({
    client_name: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    position: "",
  });

  const validation_msg = {
    client_name: "This field is required",
    email: "This field is required",
    valid_email: "Enter valid email address",
    first_name: "This field is required",
    last_name: "This field is required",
    phone: "This field is required",
    position: "This field is required",
  };

  const [IsSignupClick, setSignupClick] = useState(false);

  async function signupClick() {
    if (validateSignup()) {
      setSignupClick(true);
      const requestMetadata = await APICall(ApiRoutes.SignUp, input);

      if (requestMetadata) {
        history("/");
      }
      setSignupClick(false);
    }
  }

  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  function validateSignup() {
    let isValid = true;
    let errors = {};
    if (agreementDiv) {
      if (!agreement) {
        isValid = false;
        setAgreementEr("You must accept terms and conditions");
      } else {
        setAgreementEr("")
      }
    }
    const errorKeys = Object.keys(formInputsErrors);
    errorKeys.forEach((item) => {
      if (input[item].trim() === "") {
        errors[item] = validation_msg[item];
        isValid = false;
      } else {
        if (item === "email" && !pattern.test(input.email)) {
          errors[item] = validation_msg.valid_email;
          isValid = false;
        } else {
          errors[item] = "";
        }
      }
    });
    setFormInputsErrors(errors);
    return isValid;
  }

  const openTerms = () => {
    setShowTermsCon(true)
  }

  const openPrivacyPolicy = () => {
    setShowPrivacyPolicy(true)
  }

  const fetchPolicy = async () => {
    let res = await APICall(ApiRoutes.fetchPolicy)
    if (res.status == 0) {
      setAgreementDiv(false)
    } else if (res.status == 1) {
      setAgreementDiv(true)
      setPolicy(res.data.policy)
      setTerm(res.data.term_and_condition)
    }
    setPageLoading(false)
  }
  useEffect(() => {
    fetchPolicy()

  }, []);

  if (!pageLoading) {
    return (
      <>
        {/* <PrivacyPolicy policy={policy} showPrivacyPolicy={showPrivacyPolicy} setShowPrivacyPolicy={setShowPrivacyPolicy}/> */}
        <TermsAndCondition term={term} showTermsCon={showTermsCon} setShowTermsCon={setShowTermsCon}/>
        <div className="blank-page">
          <div className="content-body">
            <div className="auth-wrapper auth-basic px-4">
              <div className="auth-inner my-4">
                <div className="mb-0 card">
                  <div className="card-body p-4">
                    <Link to="/" className="login-logo">
                      <img src={logo} alt="logo" width="160" height="48" />
                    </Link>
                    <h4 className="mb-4 card-title text-center text-dark">
                      Welcome to Monitor & Manage
                    </h4>
                    <Form validated={validated} onSubmit={handleSubmit}>
                      <InputText
                        type={"text"}
                        size={12}
                        title={"Property / Committee Name"}
                        value={input.client_name}
                        onChange={(value) =>
                          setInput({
                            ...input,
                            client_name: value,
                          })
                        }
                        required
                        validationText={formInputsErrors.client_name}
                      />

                      <InputText
                        type={"text"}
                        size={12}
                        title={"First Name"}
                        value={input.first_name}
                        onChange={(value) =>
                          setInput({
                            ...input,
                            first_name: value,
                          })
                        }
                        required
                        validationText={formInputsErrors.first_name}
                      />

                      <InputText
                        type={"text"}
                        size={12}
                        title={"Last Name"}
                        value={input.last_name}
                        onChange={(value) =>
                          setInput({
                            ...input,
                            last_name: value,
                          })
                        }
                        required
                        validationText={formInputsErrors.last_name}
                      />



                      <InputText
                        type={"text"}
                        size={12}
                        title={"Position"}
                        value={input.position}
                        onChange={(value) =>
                          setInput({
                            ...input,
                            position: value,
                          })
                        }
                        required
                        validationText={formInputsErrors.position}
                      />
                      <InputText
                        type={"email"}
                        size={12}
                        title={"Corporate Email"}
                        value={input.email}
                        onChange={(value) =>
                          setInput({
                            ...input,
                            email: value,
                          })
                        }
                        required
                        validationText={formInputsErrors.email}
                      />

                      <InputText
                        type={"number"}
                        size={12}
                        title={"Contact Number"}
                        value={input.phone}
                        onChange={(value) =>
                          setInput({
                            ...input,
                            phone: value,
                          })
                        }
                        required
                        validationText={formInputsErrors.phone}
                      />
                      {agreementDiv && <><div className="my-2">
                        <span>
                          <input checked={agreement} onChange={() => {
                            setAgreement(!agreement)
                          }} className="cursor-pointer" type="checkbox"></input>  I agree to the <span className="cursor-pointer text-primary" onClick={() => openTerms()}>Terms and Conditions</span> & <a href='/privacy' className="cursor-pointer text-primary" target="_blank" >Privacy Policy</a>
                        </span>
                      </div>
                        <div className="my-2">
                          {agreementEr != "" ? <span className="text-danger">
                            {agreementEr}
                          </span> : ""}
                        </div></>}
                      {IsSignupClick === false ? (
                        <Button variant="primary w-100" onClick={signupClick}>
                          Sign Up
                        </Button>
                      ) : (
                        <Button variant="primary w-100">
                          <Spinner animation="border" size="sm" /> Signup...
                        </Button>
                      )}


                    </Form>
                    <p className="create-login-account-bottom text-center mt-3">
                      <span>Already have account?</span>
                      <Link to="/" className="text-primary h6">
                        <span>Login</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div className="page-loading">
    <PageLoading />
  </div>
  }

}

export default Signup;
