import { Enums } from "../constants/enums";
import { commonService } from "../services/common.service";

function getUserRights() {
  const userType = JSON.parse(commonService.getItem("user")).association_type_term;
  const userRights = JSON.parse(commonService.getItem("userRights"));
  switch (userType) {
    case Enums.UserRole.admin:
      return userRights;
    case Enums.UserRole.company:
      return userRights;
    case Enums.UserRole.branch_manager:
      return userRights;
    case Enums.UserRole.incharge:
      return userRights;
    case Enums.UserRole.guard:
      return userRights;

    default:
      return userRights;
  }
}

export default function getUserAccess(ServiceType) {
  switch (ServiceType) {
    case Enums.UserRights.company:
      return getUserRights().find(
        (item) => item.name === Enums.UserRights.company
      ).rights;
    case Enums.UserRights.branch:
      return getUserRights().find(
        (item) => item.name === Enums.UserRights.branch
      ).rights;
    case Enums.UserRights.incharge:
      return getUserRights().find(
        (item) => item.name === Enums.UserRights.incharge
      ).rights;
    case Enums.UserRights.guard:
      return getUserRights().find(
        (item) => item.name === Enums.UserRights.guard
      ).rights;
    case Enums.UserRights.properties:
      return getUserRights().find(
        (item) => item.name === Enums.UserRights.properties
      ).rights;
    case Enums.UserRights.users:
      return getUserRights().find(
        (item) => item.name === Enums.UserRights.users
      ).rights;
    default:
      return getUserRights();
  }
}
