
import { useEffect, useState } from "react";
import UnderDev from "../../components/underdev/UnderDev";
import { ApiRoutes } from "../../constants/apiroutes";
import { Enums } from "../../constants/enums";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import AdminDashboard from "./AdminDashboard";
import BranchDashboard from "./BranchDashboard";
import ClientDashboard from "./ClientDashboard";
import CompanyDashboard from "./CompanyDashboard";
function Dashboard() {
  const userType = JSON.parse(commonService.getItem("user")).association_type_term;
  const [dashboardCounts, setDashboardCounts] = useState([]);

  const getDashboardDetails = async () => {
    let res = await APICall(ApiRoutes.dashboardDetails)
    if (res.status === 1) {
      setDashboardCounts(res.data.dash_counts);
    }
  }

  useEffect(() => {
    getDashboardDetails()
  }, []);

  if (userType == Enums.UserRole.admin) {
    return <AdminDashboard dashboardCounts={dashboardCounts}/>
  } 
  else if(userType == Enums.UserRole.company){
    return <CompanyDashboard dashboardCounts={dashboardCounts}/>
  }
  else if(userType == Enums.UserRole.branch_manager){
    return <BranchDashboard dashboardCounts={dashboardCounts}/>
  }
  else if(userType == Enums.UserRole.client){
    return <ClientDashboard dashboardCounts={dashboardCounts}/>
  }
  else {
    return <UnderDev />
  }

}

export default Dashboard;
