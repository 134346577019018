import Lottie from "lottie-react";
import { Card } from "react-bootstrap";
import UnderDevelopment from "../../assets/Icons/animation/underDevelopment.json";

function UnderDev() {
  return (
    <>
      <Card className="pt-4">
        <Card.Body className="align-items-center">
          <Lottie
            animationData={UnderDevelopment}
            loop
            style={{ width: "50vw", height: "30vh", alignSelf: "center" }}
            className="m-auto"
          />
          <h4 className="text-dark text-center">Coming Soon!</h4>
          <h5 className="text-dark text-center">
            This feature is under development
          </h5>
        </Card.Body>
      </Card>
    </>
  );
}

export default UnderDev;
