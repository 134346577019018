import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, Collapse, Accordion } from "react-bootstrap";
import { Enums } from "../../../constants/enums";
import { commonService } from "../../../services/common.service";
import { alertService, AlertType } from "../../../services/alert.service";
import { toast } from "react-toastify";

export default function DayScheduleCom({
  validatePatrollingSchedule,
  daysTime,
  patrollingSchedulesErrors,
  term_schedule,
  checkPoinSchedules,
  setCheckPointSchedules,
}) {
  const handleAddInput = (e) => {
    let newJson = {
      ...checkPoinSchedules,
      [term_schedule]: [
        ...checkPoinSchedules[term_schedule],
        {
          sc_start_time: "",
          sc_end_time: "",
        },
      ],
    };
    setCheckPointSchedules(newJson);
  };

  const handleRemoveInput = (schedule_item_index) => {
    if (checkPoinSchedules[term_schedule].length > 1) {
      let newJsonToAppend = {};
      Object.keys(checkPoinSchedules).forEach((term_got_inner) => {
        if (term_got_inner == term_schedule) {
          let indexToRemove =
            checkPoinSchedules[term_schedule].indexOf(schedule_item_index);
          if (indexToRemove > -1) {
            checkPoinSchedules[term_schedule].splice(indexToRemove, 1);
          }
        }
        newJsonToAppend = {
          ...newJsonToAppend,
          [term_got_inner]: checkPoinSchedules[term_got_inner],
        };
      });
      setCheckPointSchedules(newJsonToAppend);
    }
    validatePatrollingSchedule();
  };

  


  function getMinutes(value) {
    const [hours, minutes] = value.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes; // 420
    return totalMinutes;
  }
  const handleStartTime = (indexOf, value, start_time, end_time) => {
    // const totalMinutes = getMinutes(value);
    // const startMinutes = getMinutes(start_time);
    // const todayEndMinutes = 24 * 60; // Midnight // 1440

    // const startDay = 0; // Midnight // 0
    // const endMinutes = getMinutes(end_time);

    // const inRange1 =
    //   totalMinutes >= startMinutes && totalMinutes < todayEndMinutes;

    // const inRange2 = totalMinutes >= startDay && totalMinutes <= endMinutes;    
    // if (inRange1 || inRange2) {     
    //   let newtermSc = [];
    //   checkPoinSchedules[term_schedule].forEach((item_check, index) => {
    //     if (index == indexOf) {
    //       let newJson = {
    //         sc_start_time: value,
    //         sc_end_time: checkPoinSchedules[term_schedule][index].sc_end_time,
    //       };
    //       newtermSc.push(newJson);
    //     } else {
    //       newtermSc.push(checkPoinSchedules[term_schedule][index]);
    //     }
    //   });
    //   let newData = {
    //     ...checkPoinSchedules,
    //     [term_schedule]: newtermSc,
    //   };
    //   setCheckPointSchedules(newData);
    // } else {      
    //   alertService.alert({
    //     type: AlertType.Error,
    //     message: `please check time in between ${start_time} and ${end_time}`,
    //   });
    // }
    let newtermSc = [];
    checkPoinSchedules[term_schedule].forEach((item_check, index) => {
      if (index == indexOf) {
        let newJson = {
          sc_start_time: value,
          sc_end_time: checkPoinSchedules[term_schedule][index].sc_end_time,
        };
        newtermSc.push(newJson);
      } else {
        newtermSc.push(checkPoinSchedules[term_schedule][index]);
      }
    });
    let newData = {
      ...checkPoinSchedules,
      [term_schedule]: newtermSc,
    };
    setCheckPointSchedules(newData);
  };
  const handleEndTime = (indexOf, value, start_time, end_time) => {
    // const totalMinutes = getMinutes(value);
    // const startMinutes = getMinutes(start_time);
    // const todayEndMinutes = 24 * 60; // Midnight // 1440

    // const startDay = 0; // Midnight // 0
    // const endMinutes = getMinutes(end_time);        

    // const inRange1 =
    //   totalMinutes >= startMinutes && totalMinutes < todayEndMinutes;
    // const inRange2 = totalMinutes >= startDay && totalMinutes <= endMinutes;    
    // if (inRange1 || inRange2) {
    //   let newtermSc = [];
    //   checkPoinSchedules[term_schedule].forEach((item_check, index) => {
    //     if (index == indexOf) {
    //       let newJson = {
    //         sc_start_time:
    //           checkPoinSchedules[term_schedule][index].sc_start_time,
    //         sc_end_time: value,
    //       };
    //       newtermSc.push(newJson);
    //     } else {
    //       newtermSc.push(checkPoinSchedules[term_schedule][index]);
    //     }
    //   });
    //   let newData = {
    //     ...checkPoinSchedules,
    //     [term_schedule]: newtermSc,
    //   };
    //   setCheckPointSchedules(newData);
    // } else {     
    //   alertService.alert({
    //     type: AlertType.Error,
    //     message: `please check time in between ${start_time} and ${end_time}`,
    //   });
      
    // }
    let newtermSc = [];
    checkPoinSchedules[term_schedule].forEach((item_check, index) => {
      if (index == indexOf) {
        let newJson = {
          sc_start_time: checkPoinSchedules[term_schedule][index].sc_start_time,
          sc_end_time: value,
        };
        newtermSc.push(newJson);
      } else {
        newtermSc.push(checkPoinSchedules[term_schedule][index]);
      }
    });
    let newData = {
      ...checkPoinSchedules,
      [term_schedule]: newtermSc,
    };
    setCheckPointSchedules(newData);
  };

  const handleClearInput = (indexOf) => {
    let newtermSc = [];
    checkPoinSchedules[term_schedule].forEach((item_check, index) => {
      if (index == indexOf) {
        let newJson = {
          sc_start_time: "",
          sc_end_time: "",
        };
        newtermSc.push(newJson);
      } else {
        newtermSc.push(checkPoinSchedules[term_schedule][index]);
      }
    });
    let newData = {
      ...checkPoinSchedules,
      [term_schedule]: newtermSc,
    };
    setCheckPointSchedules(newData);
  };

  const ErrorFormPatrolling = ({ indexOf, type }) => {
    if (patrollingSchedulesErrors[term_schedule]) {
      if (Object.keys(patrollingSchedulesErrors[term_schedule]).length !== 0) {
        if (indexOf in patrollingSchedulesErrors[term_schedule]) {
          if (patrollingSchedulesErrors[term_schedule][indexOf] != "") {
            if (type == "start") {
              return (
                <span className="text-danger">
                  {
                    patrollingSchedulesErrors[term_schedule][indexOf]
                      .sc_start_time
                  }
                </span>
              );
            }
            if (type == "end") {
              return (
                <span className="text-danger">
                  {
                    patrollingSchedulesErrors[term_schedule][indexOf]
                      .sc_end_time
                  }
                </span>
              );
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const applyToAll = () => {
    let newCheckpointJson = {};
    Object.keys(checkPoinSchedules).forEach((term_sc) => {
      newCheckpointJson = {
        ...newCheckpointJson,
        [term_sc]: checkPoinSchedules[term_schedule],
      };
    });
    setCheckPointSchedules(newCheckpointJson);
  };

  return (
    <>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0" className="mb-3">
          <Accordion.Header>
            <div className="row w-100">
              <div className="col-12 h6 d-flex cursor-pointer justify-content-between m-0 align-items-center ">
                <div className="col-3 justify-content-center d-flex flex-column ">
                  <span style={{ width: "150px" }} className="p-2">
                    {Enums.WeekDays[term_schedule].charAt(0).toUpperCase() +
                      Enums.WeekDays[term_schedule].slice(1)}
                  </span>
                </div>
                <div>
                  {/* <span>
                    {" "}
                    {commonService.changeTimeformate(
                      daysTime[term_schedule].start_time,
                      "MM-DD-YYYY HH:mm"
                    )}{" "}
                    -{" "}
                    {commonService.changeTimeformate(
                      daysTime[term_schedule].end_time,
                      "MM-DD-YYYY HH:mm"
                    )}
                  </span> */}
                </div>
                <div className="align-items-center h6 m-0">
                  {checkPoinSchedules[term_schedule].length
                    ? `Total Patrolling Schedules : ${checkPoinSchedules[term_schedule].length}`
                    : ""}
                </div>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <table className="w-100">
              <thead>
                <tr className="col-12 d-flex justify-content-between mb-2">
                  <th></th>
                  <th></th>
                  <th>
                    <Button
                      onClick={(e) => {
                        handleAddInput();
                      }}
                      className="me-2"
                      variant="primary"
                    >
                      <i className="fa fa-plus text-secondory sm me-2"></i>Add
                      Schedule
                    </Button>
                    <Button
                      onClick={(e) => {
                        applyToAll(e.target.value);
                      }}
                      variant="primary"
                    >
                      Apply To All
                    </Button>
                  </th>
                </tr>
                <tr className="col-12 gap-5 d-flex justify-content-between mb-2">
                  <th className="col-4">Patrolling Start Time</th>
                  <th className="col-4">Patrolling End Time</th>
                  <th className="col-4 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {checkPoinSchedules[term_schedule].length &&
                  checkPoinSchedules[term_schedule].map(
                    (schedule_item, indexOf) => {
                      return (
                        <tr
                          className="mb-3 col-12 gap-5 d-flex justify-content-between"
                          key={indexOf}
                          id={`schedule-time-indexOf`}
                        >
                          <td className="col-4">
                            <Form.Control
                              onChange={(e) => {
                                handleStartTime(
                                  indexOf,
                                  e.target.value,
                                  daysTime[term_schedule].start_time,
                                  daysTime[term_schedule].end_time
                                );
                              }}
                              value={schedule_item.sc_start_time}
                              type="time"
                            />
                            <ErrorFormPatrolling
                              indexOf={indexOf}
                              type="start"
                            />
                          </td>
                          <td className="col-4">
                            <Form.Control
                              onChange={(e) => {
                                handleEndTime(
                                  indexOf,
                                  e.target.value,
                                  daysTime[term_schedule].start_time,
                                  daysTime[term_schedule].end_time
                                );
                              }}
                              value={schedule_item.sc_end_time}
                              type="time"
                            />
                            <ErrorFormPatrolling indexOf={indexOf} type="end" />
                          </td>
                          <td className="col-4 text-center action-button-icon d-flex align-items-start justify-content-center">
                            {/* <i onClick={() => { handleAddInput() }} className="cursor-pointer fa fa-2x fa-plus text-danger sm me-4"></i> */}
                            <i
                              onClick={() => {
                                handleClearInput(indexOf);
                              }}
                              className="cursor-pointer fa fa-2x fa-times text-danger sm me-2"
                            ></i>
                            <i
                              onClick={() => {
                                handleRemoveInput(schedule_item);
                              }}
                              className="cursor-pointer fa fa-2x fa-trash text-danger sm"
                              aria-hidden="true"
                            ></i>
                            {/* <i className="cursor-pointer fa fa-2x fa-times text-danger sm"></i> */}
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
