import React, { useState } from 'react'
import { Breadcrumb, Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { commonHelper } from '../../helpers/commonHelper';
import { getBase64 } from "../../helpers/imageHelper";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { useRef } from 'react';
import { APICall } from '../../services/axiosService';
import { ApiRoutes } from '../../constants';

export default function InvoiceModal({ showInvoiceModel, contractId, setShowInvoiceModel, getDetails }) {

    const [isLoading, setIsLoading] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [error, setError] = useState("");

    const fileRef = useRef(null)

    const closeInvoiceModal = () => {

        setShowInvoiceModel(false)
        setSelectedDocuments([])
        setError('')
        fileRef.current.value = null;
    }

    const handleSendInvoice = async (e) => {
        e.preventDefault();

        if (selectedDocuments.length) {
            setError('')
            setIsLoading(true)
        }
        else {
            setError('Select atleast one document')
        }
        let res = await APICall(ApiRoutes.addInvoice , {
            contract_id : contractId,
            invoice_list : selectedDocuments
        })

        if(res.status === 1){
            closeInvoiceModal()
            getDetails()
        }

    }

    const renderImagePreview = (item, index) => (

        <div className={"col-md-2 col-12 mb-3"} key={index}>
            <div className="card border shadow-none mb-3 app-file-info h-100">
                <div className="p-3 text-center">
                    <div id="profile_pic_1_preview" className="image-fixed">
                        <img
                            className="obj_fit_img rounded w-100"
                            src={commonHelper.getExtBaseFile(item.base64, item.ext)}
                            alt=""
                            height="130"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DefaultImg;
                            }}
                        />
                    </div>
                    <div className="form-group add-new-file text-center mb-0  w-100 text-truncate">
                        <label className="form-label-input mt-3 text-break">{item.name}</label>
                    </div>
                    <div className="btn-fixed">
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() => {
                                setSelectedDocuments([]);
                                fileRef.current.value = null;
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return (<Modal className="modal-popup" dialogClassName="modal-dialog modal-xl modal-dialog-centered" show={showInvoiceModel} onHide={closeInvoiceModal}>
        <Modal.Header closeButton>
            <Modal.Title>Select Invoice Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <Form.Label>Invoice Documents</Form.Label>
                <Form.Control
                    ref={fileRef}
                    type="file"
                    accept=".pdf"
                    onChange={async (e) => {
                        let base64Arr = [];
                        const files = Array.from(e.target.files);
                        for (let index = 0; index < files.length; index++) {
                            try {
                                let ext = files[index].name.split(".").pop();
                                if(ext === 'pdf'){
                                    setError("")
                                    const result = await getBase64(files[index]);
                                    base64Arr.push({
                                        name: files[index].name,
                                        base64: result,
                                        ext: ext,
                                    });
                                }else{
                                    setError("Only pdf files are supported.")
                                }
                                
                            } catch (err) {
                                console.log(err);
                            }
                        }
                        setSelectedDocuments(base64Arr);
                    }}
                />
                {error !== '' && (
                    <span className="error">{error}</span>
                )}
            </div>
            <div
                className="scrollbar-container media-list scrollable-container ps ps--active-y"
                style={{ maxHeight: "30rem" }}
            >
                <div className="row pt-4 me-2">
                    {selectedDocuments.length > 0 &&
                        selectedDocuments.map(renderImagePreview)}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button type="button" variant="outline-primary" onClick={closeInvoiceModal}>
                Close
            </Button>
            {isLoading === false ? (
                <>
                    <Button type="button" variant="primary"
                        onClick={(e) => handleSendInvoice(e)}
                    >
                        Save
                    </Button>
                </>
            ) : (
                <Button type="button" variant="primary">
                    <Spinner animation="border" size="sm" /> Loading...
                </Button>
            )}
        </Modal.Footer>
    </Modal>)
}
