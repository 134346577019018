import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import ImgInput from "../../components/common/ImgInput";
import TextInput from "../../components/form/inputText";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { updateLoading } from "../../store/actions";
import { Breadcrumb } from "react-bootstrap";
import InputSearchLocation from "../../components/form/InputSearchLocation";
import { commonService } from "../../services/common.service";

const genderDropDownData = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

function BranchProfile() {
  const [isCompanyFormClick, setIsCompanyFormClick] = useState(false);
  const dispatch = useDispatch();

  const [formInputs, setInput] = useState({
    branch_name: "",
    branch_phone: "",
    branch_email: "",
    branch_contact_person_phone: "",
    branch_contact_person_email: "",
    branch_contact_person_name: "",
    branch_register_no: "",
    branch_website_url: "",
    id_number :"",
    qualification :"",
    total_experience:"",
    contact_by_reference:"",
    branch_summary: "",
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        longitude: "",
        latitude: ""
      },
    ],
  });
  const [formInputsErrors, setInputErrors] = useState({
    company_id: "",
    branch_name: "",
    branch_register_no: "",
    branch_email: "",
    branch_phone: "",
    branch_contact_person_email: "",
    branch_contact_person_name: "",
    branch_contact_person_phone: "",
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
    ],
  });

  const getRequiredFields = (bank_details, locations) => {
    setInputErrors({
      ...formInputsErrors,
      bank_details: bank_details.map(() => ({
        bank_name: "",
        account_no: "",
      })),
      locations:
        locations.length > 0
          ? locations.map(() => ({
            address1: "",
            city: "",
            state: "",
            postal_code: "",
            country: "",
          }))
          : [
            {
              address1: "",
              city: "",
              state: "",
              postal_code: "",
              country: "",
            },
          ],
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const validation_msg = {
    branch_name: "This field is required",
    branch_register_no: "This field is required",
    valid_email: "This field is required",
    branch_email: "This field is required",
    branch_phone: "This field is required",
    branch_contact_person_email: "This field is required",
    branch_contact_person_name: "This field is required",
    branch_contact_person_phone: "This field is required",
    payment_duration: "This field is required",
    company_id: "This field is required",
    address1: "This field is required",
    city: "This field is required",
    state: "This field is required",
    postal_code: "This field is required",
    country: "This field is required",
    invalid_email :"This email is invalid"
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  async function submitGuardsForm(event) {
    event.preventDefault();
    if (validateCompanyForm()) {
      setIsCompanyFormClick(true);
      dispatch(updateLoading(true));

      const requestMetadata = await APICall(ApiRoutes.UpdateProfileDetails, {
        ...formInputs,
        branch_profile_img: formInputs.branch_profile_img?.includes(
          process.env.REACT_APP_ASSETS_URL
        )
          ? null
          : formInputs.branch_profile_img,
      });
      getDetails(requestMetadata);
      setIsCompanyFormClick(false);
      dispatch(updateLoading(false));
      // if (requestMetadata) {
      //   history.push("/incharge");
      // }
    }
  }

  function validateCompanyForm() {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors);
    const requiredArr = ["bank_details", "locations"];
    errorKeys.forEach((item) => {
      if (requiredArr.includes(item)) {
        let newArr = [];
        formInputsErrors[item].forEach((childLoop, childIndex) => {
          let newObj = {};
          const childErrorsArr = Object.keys(childLoop);
          childErrorsArr.forEach((grandChildLoop) => {
            if (formInputs[item][childIndex][grandChildLoop]?.trim() === "") {
              newObj[grandChildLoop] = validation_msg[grandChildLoop];
              isValid = false;
            } else {
              newObj[grandChildLoop] = "";
            }
          });
          newArr.push(newObj);
        });
        errors[item] = newArr;
      } else {
        if (
          formInputs[item] === null ||
          formInputs[item]?.toString()?.trim() === ""
        ) {
          errors[item] = validation_msg[item];
          isValid = false;
        } else {
          if (
            item === "branch_email" &&
            !pattern.test(formInputs.branch_email)
          ) {
            errors[item] = validation_msg.invalid_email;
            isValid = false;
          } else {
            errors[item] = "";
          }
        }
      }
    });

    setInputErrors(errors);
    return isValid;
  }

  const getDetails = async (submitedData = null) => {
    const res = await APICall(ApiRoutes.ProfileDetails);
    if (res) {
      if (submitedData) {
        submitedData.data.profile['association_details'] = res.data;
        commonService.setItem("user", JSON.stringify(submitedData.data.profile));
      }
      setInput({
        ...res.data,
        branch_profile_img: res.data.branch_profile_img
          ? process.env.REACT_APP_ASSETS_URL + res.data.branch_profile_img
          : null,
        locations:
          res.data.locations.length > 0
            ? res.data.locations
            : [
              {
                address1: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
              },
            ],
      });
      getRequiredFields(res.data.bank_details, res.data.locations);
    }
  };
  const handleLocation = (addressInfoArray) => {
    setInput({
      ...formInputs,
      locations: [{
        ...formInputs.locations[0],
        address1: addressInfoArray.street_address,
        city: addressInfoArray.city,
        state: addressInfoArray.state,
        postal_code: addressInfoArray.postal_code,
        country: addressInfoArray.country,
        longitude: addressInfoArray.lng,
        latitude: addressInfoArray.lat,
      }]
    })
  }

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">Edit Profile</h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Proifle</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="card pt-4">
        <div className="card-body">
          <form onSubmit={submitGuardsForm} className="form" method="POST" action="#">
            <div className="col-12 row">
              <blockquote className="blockquote border-left-3 border-left-primary float-left pl-1">
                <h4 className="mb-0 shop-title float-left">Basic Info</h4>
              </blockquote>
            </div>
            <div className="form-body">
              <div className="row">
                <TextInput
                  type={"text"}
                  title={"Name"}
                  value={formInputs.branch_name}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      branch_name: value,
                    })
                  }
                  validationText={formInputsErrors.branch_name}
                />
                <TextInput
                  type={"email"}
                  title={"Email"}
                  value={formInputs.branch_email}
                  validationText={formInputsErrors.branch_email}
                  disabled={true}
                />
                <TextInput
                  type={"number"}
                  title={"Phone"}
                  value={formInputs.branch_phone}
                  validationText={formInputsErrors.branch_phone}
                  disabled={true}
                />
 <TextInput
                  type={"text"}
                  title={"Contact Person Name"}
                  value={formInputs.branch_contact_person_name}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      branch_contact_person_name: value,
                    })
                  }
                  validationText={formInputsErrors.branch_contact_person_name}
                />
                <TextInput
                  type={"text"}
                  title={"Contact Person Email"}
                  value={formInputs.branch_contact_person_email}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      branch_contact_person_email: value,
                    })
                  }
                  validationText={formInputsErrors.branch_contact_person_email}
                />
                <TextInput
                  type={"number"}
                  title={"Contact Person Phone Number"}
                  value={formInputs.branch_contact_person_phone}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      branch_contact_person_phone: value,
                    })
                  }
                  validationText={formInputsErrors.branch_contact_person_phone}
                />
                

               
                <TextInput
                  type={"text"}
                  title={"Registration Number"}
                  value={formInputs.branch_register_no}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      branch_register_no: value,
                    })
                  }
                  validationText={formInputsErrors.branch_register_no}
                />

                <TextInput
                  type={"text"}
                  title={"Website URL"}
                  value={formInputs.branch_website_url}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      branch_website_url: value,
                    })
                  }
                  validationText={formInputsErrors.branch_website_url}
                />
                 <TextInput
                  type={"text"}
                  title={"Id Number"}
                  value={formInputs.id_number}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      id_number: value,
                    })
                  }
                  validationText={formInputsErrors.id_number}
                />
                 <TextInput
                  type={"text"}
                  title={"Highest Level Education"}
                  value={formInputs.qualification}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      qualification: value,
                    })
                  }
                  validationText={formInputsErrors.qualification}
                />
                 <TextInput
                  type={"number"}
                  title={"Years of Experience"}
                  value={formInputs.total_experience}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      total_experience: value,
                    })
                  }
                  validationText={formInputsErrors.total_experience}
                />
                 <TextInput
                  type={"text"}
                  size={'12'}
                  title={"How did you hear about us ?"}
                  multiLine={true}
                  value={formInputs.contact_by_reference}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      contact_by_reference: value,
                    })
                  }
                  validationText={formInputsErrors.contact_by_reference}
                />
                <TextInput
                size={'12'}
                  type={"text"}
                  title={"Summary"}
                  multiLine={true}
                  value={formInputs.branch_summary}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      branch_summary: value,
                    })
                  }
                />

                <div className="col-12 row">
                  <blockquote className="blockquote border-left-3 border-left-primary float-left pl-1 mt-3">
                    <h4 className="mb-0 shop-title float-left">
                      Location Details
                    </h4>
                  </blockquote>
                </div>
                <InputSearchLocation size={"12"} placeholder="Search location" onPlaceChange={handleLocation} />
                {formInputs?.locations?.map((mapItem, mapIndex) => (
                  <>
                    <TextInput
                      type={"text"}
                      title={"Address"}
                      value={mapItem.address1}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  address1: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={
                        formInputsErrors?.locations[mapIndex]?.address1
                      }
                    />
                    <TextInput
                      type={"text"}
                      title={"City"}
                      value={mapItem.city}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  city: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={formInputsErrors?.locations[mapIndex]?.city}
                    />
                    <TextInput
                      type={"text"}
                      title={"State"}
                      value={mapItem.state}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  state: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={formInputsErrors?.locations[mapIndex]?.state}
                    />
                    <TextInput
                      type={"text"}
                      title={"Postal code"}
                      value={mapItem.postal_code}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  postal_code: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={
                        formInputsErrors?.locations[mapIndex]?.postal_code
                      }
                    />
                    <TextInput
                      type={"text"}
                      title={"Country"}
                      value={mapItem.country}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  country: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={
                        formInputsErrors?.locations[mapIndex]?.country
                      }
                    />
                  </>
                ))}

                <div className="col-12 row">
                  <ImgInput
                    title={"Profile Picture"}
                    img_src={formInputs.branch_profile_img}
                    input_ref_id="branch_profile_img"
                    onChange={(value) => {
                      setInput({
                        ...formInputs,
                        branch_profile_img: value,
                      });
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-end">
                  {isCompanyFormClick === false ? (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <button className="btn btn-primary mr-1">
                      <Spinner animation="border" size="sm" /> Loading...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BranchProfile;