import { Breadcrumb } from "react-bootstrap";

function Breadcrumbs() {
  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top">
            <h2 className="content-header-title float-start mb-0">
              Breadcrumbs
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                Library
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Data</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Default</h4>
            </div>
            <div className="card-body">
              <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                  Library
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Data</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Styles</h4>
            </div>
            <div className="card-body">
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">
                  Library
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Data</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Breadcrumbs;
