import React from "react";
import { Button, Form } from "react-bootstrap";

const FirstStep = ({
  clearAllDaysInput,
  allDaysTime,
  setAllDaysTime,
  validateError,
  applyToALl,
  weekDaysArray,
  handleDayTime,
  daysTime,
  weekTimeValidateErrors,
  removeTime,
}) => {
  return (
    <>
      <div className="row mt-3">
        <div className="d-flex col-12">
          <div className="col-3 me-4">
            <div>
              <div className="text-dark">START TIME</div>
              <Form.Control
                className="mt-1"
                value={allDaysTime.start_time}
                onChange={(e) => {
                  setAllDaysTime({
                    ...allDaysTime,
                    start_time: e.target.value,
                  });
                }}
                type="time"
              />
            </div>
            <div>
              {validateError.start_time != "" ? (
                <span className="text-danger">{validateError.start_time}</span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-3 me-4">
            <div className="text-dark">END TIME</div>
            <Form.Control
              className="mt-1"
              onChange={(e) => {
                setAllDaysTime({
                  ...allDaysTime,
                  end_time: e.target.value,
                });
              }}
              value={allDaysTime.end_time}
              type="time"
            />
            {validateError.end_time != "" ? (
              <span className="text-danger">{validateError.end_time}</span>
            ) : (
              ""
            )}
          </div>

          <div className="col-6 d-flex">
            <div className="me-4 mt-4">
              <Button className="btn btn-secondary" onClick={clearAllDaysInput}>
                Remove From All
              </Button>
            </div>
            <div className="mt-4">
              <Button onClick={applyToALl}>Apply To All</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row row mt-5">
        <div className="col-12 h6 d-flex mb-2 text-dark">
          <div className="col-3 justify-content-center d-flex flex-column">
            Day
          </div>
          <div className="col-6 d-flex w-100">
            <div className="col-3 me-2">
              <div> START TIME</div>
            </div>
            <div className="col-3 me-4">
              <div> END TIME</div>
            </div>
          </div>
        </div>
        <Form className="mt-2">
          {weekDaysArray.length
            ? weekDaysArray.map((weekDay, index) => {
                return (
                  <div
                    key={index}
                    className="col-12 d-flex mb-2 align-items-center"
                  >
                    <div className="col-6 d-flex">
                      <div className="col-3 justify-content-center d-flex flex-column">
                        {weekDay.label}
                      </div>
                      <div className="col-6 me-2">
                        {/* <div> START TIME</div> */}
                        <Form.Control
                          onChange={(e) => {
                            handleDayTime(
                              e.target.value,
                              weekDay.term,
                              "START"
                            );
                          }}
                          value={daysTime[weekDay.term].start_time}
                          type="time"
                        />
                        {weekTimeValidateErrors[weekDay.term].start_time !=
                        "" ? (
                          <span className="text-danger">
                            {weekTimeValidateErrors[weekDay.term].start_time}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-6 me-4">
                        <Form.Control
                          onChange={(e) => {
                            handleDayTime(e.target.value, weekDay.term, "END");
                          }}
                          value={daysTime[weekDay.term].end_time}
                          type="time"
                        />
                        {weekTimeValidateErrors[weekDay.term].end_time != "" ? (
                          <span className="text-danger">
                            {weekTimeValidateErrors[weekDay.term].end_time}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-3 ms-3 action-button-icon">
                        <i
                          onClick={() => {
                            removeTime(weekDay.term);
                          }}
                          className="cursor-pointer fa fa-2x fa-times text-danger sm"
                        ></i>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </Form>
      </div>
    </>
  );
};

export default FirstStep;
