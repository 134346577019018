import React, { useEffect, useState } from 'react'
import { ApiRoutes, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';
import { commonService } from '../../services/common.service'
import { useNavigate } from "react-router-dom";
import { Breadcrumb, Button, InputGroup } from "react-bootstrap";
import { Form, Card, Col, Tab, Nav } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import Switch from "react-switch";
import DropDown from '../../components/common/DropDown';
import GuardReviewList from './GuardReviewList';
import ReviewListByClient from './ReviewListByClient';
import NewLoader from '../../components/loader/NewLoader';

export default function ReviewAll() {
    const userData = JSON.parse(commonService.getItem("user"));
    const userType = userData.association_type_term;
    const navigate = useNavigate();
    const [txtSearch, setTxtSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [reviewList, setReviewList] = useState({
        isData: false,
        data: [],
    });
    const [dataTableLoading, setDataTableLoading] = useState(false);

    const [contractSearchInput, setContractSearchInput] = useState("");
    const [contractList, setContractList] = useState([]);
    const [selectedPropertyList, setSelectedPropertyList] = useState([]);

    React.useEffect(() => {
        fetchReviews("", "", 1);
    }, [pageNo, perPageRecords]);


    // const fetchReviews = async () => {
    //   let res = await APICall(ApiRoutes.fetchReviews, { contract_id: contractId })
    // }
    // useEffect(() => {
    //   fetchReviews()
    // }, []);

    async function fetchReviews(
        column = "",
        sortDirection = "",
        isFilter = 0
    ) {
        setDataTableLoading(true);


        let newSelectedArray = [];
        selectedPropertyList.forEach((property_item) => {
            newSelectedArray = [...newSelectedArray, property_item.value]
        })

        const requestMetadata = await APICall(ApiRoutes.fetchReviews, {
            is_all: false,
            page: pageNo,
            limit: perPageRecords,
            order: { column: column, dir: sortDirection },
            is_filter: isFilter,
            filter: {
                search_text: txtSearch,
                start_date:
                    from != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(from) + " 00:00:00"
                        )
                        : null,
                end_date:
                    to != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(to) + " 23:59:59"
                        )
                        : null,
                contract_list: newSelectedArray
            },
        });

        if (requestMetadata) {
            setReviewList({ isData: true, data: requestMetadata.data.list });

            setTotalRows(requestMetadata.data.total);
            setDataTableLoading(false);
        }
       
    }

    const handleSort = async (column, sortDirection) => {
        fetchReviews(column.sortField, sortDirection, 1);
    };

    const searchDateWise = (e) => {
        e.preventDefault();
        let isValidate = true;
        if (from || to) {
            if (from && to) {
                const dateFrom = new Date(from);
                const dateTo = new Date(to);
                if (dateFrom > dateTo) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: "End date must be after start date",
                    });
                    isValidate = false;
                }
            }
        }
        if (isValidate) {
            fetchReviews("", "", 1);
        }
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPageRecords(newPerPage);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const resetFilter = () => {
        setSelectedPropertyList([])
        setTo(null);
        setFrom(null);
        setTxtSearch("")
        fetchReviews("", "", 0);
    };

    React.useEffect(() => {
        fetchReviews("", "", 1);
    }, [txtSearch]);


    const fetchReviewContractList = async () => {
        let res = await APICall(ApiRoutes.fetchReviewContractList)
        if (res.status === 1) {
            setContractList(res.data.map((mapitem) => ({
                value: mapitem.contract_id,
                label: mapitem.property_name,
            })))
        }
    }

    useEffect(() => {
        fetchReviewContractList()
    }, []);

    const columns = [
        {
            name: "Contract/Property",
            selector: (row) => <span className='cursor-pointer nav-link p-0' onClick={() => {
                if (userType === Enums.UserRole.client) {
                    navigate("/client_contracts/view", {
                        state: {
                            id: row.contract_id,
                        },
                    })
                } else {
                    navigate("/contracts/view", {
                        state: {
                            id: row.contract_id,
                        },
                    })
                }

            }}>{row.property_name}</span>,
            sortable: true,
            sortField: "property_name",
        },
        {
            name: "Property Manager",
            selector: (row) => row.client_name,
            sortable: true,
            omit: userType === Enums.UserRole.client ? true : false,
            sortField: "client_name",
        },
        {
            name: "From User",
            omit: userType === Enums.UserRole.client ? true : false,
            selector: (row) => <span onClick={() => {

                // navigate(`/clients/view/${row.from_association_id}`)
            }} className="p-0">{Enums.UserRoleDetail[row.from_association_type_term]}</span>,
            sortable: true,
            sortField: "from_association_name",
        },
        {
            name: "From User Title",
            omit: userType === Enums.UserRole.client ? true : false,
            selector: (row) => <span onClick={() => {
                if (row.from_association_type_term === Enums.UserRole.client) {
                    navigate(`/clients/view/${row.from_association_id}`)
                }
                // 
            }} className={`p-0`}>{row.from_association_name}</span>,
            sortable: true,
            sortField: "from_association_name",
        },
        {
            name: "To User",
            omit: userType === Enums.UserRole.client ? true : false,
            selector: (row) => <span onClick={() => {
                if (row.to_association_type_term == Enums.UserRole.company) {
                    // navigate('/contract/company/profile', { state: { comapny_id: row.to_association_id } })
                } else if (row.to_association_type_term == Enums.UserRole.branch_manager) {
                    // navigate('/contract/branch/profile', { state: { branch_id: row.to_association_id } })
                }
            }} className="p-0">{Enums.UserRoleDetail[row.to_association_type_term]}</span>,
            sortable: true,
            sortField: "to_association_name",
        },
        {
            name: "To User Title",
            omit: userType === Enums.UserRole.client ? true : false,
            selector: (row) => <span onClick={() => {
                if (row.to_association_type_term == Enums.UserRole.company) {
                    navigate('/contract/company/profile', { state: { comapny_id: row.to_association_id } })
                } else if (row.to_association_type_term == Enums.UserRole.branch_manager) {
                    navigate('/contract/branch/profile', { state: { branch_id: row.to_association_id } })
                }
            }} className={`p-0`}>{row.to_association_name}</span>,
            sortable: true,
            sortField: "to_association_name",
        },
        {
            name: "Company/Branch",
            omit: userType === Enums.UserRole.client ? false : true,
            selector: (row) => <span onClick={() => {
                if (row.to_association_type_term == Enums.UserRole.company) {
                    navigate('/contract/company/profile', { state: { comapny_id: row.to_association_id } })
                } else if (row.to_association_type_term == Enums.UserRole.branch_manager) {
                    navigate('/contract/branch/profile', { state: { branch_id: row.to_association_id } })
                }
            }} className="cursor-pointer nav-link p-0">{row.to_association_name}</span>,
            sortable: true,
            sortField: "to_association_name",
        },
        {
            name: "Review",
            selector: (row) => <span className="cursor-pointer" title={row.review}>{row.review}</span>,
            sortable: true,
            sortField: "review",
        },
        {
            name: "Ratting",
            selector: (row) => <>{row.rating} <i className="fa fa-star"></i></>,
            sortable: true,
            sortField: "rating",
        },
        {
            name: "Reviewed At",
            sortable: false,
            sortField: "created_at",
            cell: (row, index, column, id) =>
                commonService.convertUTCToLocal(row.created_at),
        }
    ];

    const TableList = () => {
        return (<>
        <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
                <div className="breadcrumbs-top d-flex align-items-center">
                    <h2 className="content-header-title float-start mb-0">
                        Reviews & Ratings
                    </h2>
                    <Breadcrumb className="breadcrumb-chevron">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Reviews & Ratings</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>
            <div className="row">
                <div>
                    <Card>
                        <Card.Body className="p-3">
                            <div className="col-12 row mb-3 mt-2">
                                <div className="col-3">
                                    <InputGroup>
                                        <DatePicker
                                            className="datepicker form-control"
                                            selected={from ? from : ""}
                                            dateFormat="MM-dd-yyyy"
                                            placeholderText="Start Date"
                                            onChange={(date) => {
                                                if (date) {
                                                    setFrom(date);
                                                } else {
                                                    setFrom(null);
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                                <div className="col-3">
                                    <div>
                                        <InputGroup>
                                            <DatePicker
                                                className="datepicker form-control"
                                                selected={to ? to : ""}
                                                dateFormat="MM-dd-yyyy"
                                                placeholderText="End Date"
                                                onChange={(date) => {
                                                    if (date) {
                                                        setTo(date);
                                                    } else {
                                                        setTo(null);
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <DropDown
                                        value={selectedPropertyList}
                                        data={contractList}
                                        style={{ div: "col-12" }}
                                        isMulti={true}
                                        onChange={(value) =>
                                            setSelectedPropertyList(value)
                                        }
                                        placeholder="Select Contract/Property"
                                        required
                                    // validationText={formInputsErrors.duration_term}
                                    />
                                </div>
                                <div className="col-3 d-flex">
                                    <div className="ms-2 me-2">
                                        <Button
                                            onClick={searchDateWise}
                                            variant="outline-primary"
                                            type="button"
                                        >
                                            <i className="fa fa-search"></i>
                                        </Button>
                                    </div>
                                    <div className="ms-2 me-2">
                                        <Button
                                            onClick={resetFilter}
                                            variant="outline-primary"
                                            type="button"
                                        >
                                            <i className="fa fa-refresh"></i>
                                        </Button>
                                    </div>
                                </div>
                                {/* <div className="col-3">
                                    <Form.Group>
                                        <Form.Control
                                            list="list-contract"
                                            onChange={(e) => {
                                                setContractSearchInput(e.target.value ? e.target.value : "")
                                            }}
                                            value={contractSearchInput}
                                            type={"text"}
                                            placeholder="Select Contract/Property"
                                        />
                                    </Form.Group>
                                    <datalist id="list-contract">
                                      {contractList.map((contract_item )=>{
                                        <option onClick={()=>{
                                            
                                        }} value={contract_item.property_id}>{contract_item.property_name}</option>
                                      })}
                                    </datalist>
                                </div> */}

                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <DataTable
            paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
                columns={columns}
                data={reviewList.data}
                progressPending={dataTableLoading}
                progressComponent={<NewLoader />}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                paginationPerPage={perPageRecords}
                actions={<>
                    <div className="col-4">
                        <InputText
                            formGroupClass={"mb-0"}
                            size={'12'}
                            type={"text"}
                            Label={false}
                            placeholder={"Search..."}
                            value={txtSearch}
                            onChange={(value) => {
                                if (value) {
                                    setTxtSearch(value)
                                } else {
                                    setTxtSearch(value)
                                }
                            }}
                        />
                    </div>
                </>}
            />
        </>)
    }

    return (userType === Enums.UserRole.branch_manager || userType === Enums.UserRole.company || userType === Enums.UserRole.admin) ? <>
        <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
                <div className="breadcrumbs-top d-flex align-items-center">
                    <h2 className="content-header-title float-start mb-0">
                        Reviews & Ratings
                    </h2>
                    <Breadcrumb className="breadcrumb-chevron">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Reviews & Ratings</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>
        <Col className="order-0  col-12 order-lg-1 col-lg-12 col-xl-12 col">
           
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="d-flex">
                <Nav variant="pills" className="mb-3">
                    <Nav.Item className=" cursor-pointer">
                        <Nav.Link eventKey="first">
                            <i className="fa-regular fa-star"></i>
                            <span className="pills-tab-text">Guard Ratings</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Nav variant="pills" className="mb-3">
                    <Nav.Item className=" cursor-pointer">
                        <Nav.Link eventKey="sec">
                            <i className="fa-regular fa-star"></i>
                            <span className="pills-tab-text">Contract Ratings</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                </div>
                
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <GuardReviewList />
                    </Tab.Pane>
                    <Tab.Pane eventKey="sec">
                        <ReviewListByClient />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </Col></>
        : <TableList />

}
