import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { badgesEnum } from "../../constants";
import { ApiRoutes } from "../../constants/apiroutes";
import { commonHelper } from "../../helpers/commonHelper";
import { getBase64 } from "../../helpers/imageHelper";
import { APICall } from "../../services/axiosService";
import NewLoader from "../../components/loader/NewLoader";

const activate_doc_arr = ["accepted", "in_progress"];
const activate_doc_view_arr = ["pending"];

function Create() {
  const fileRef = useRef(null);
  const [show, setShow] = useState(false);
  const [getDetailsLoading, setgetDetailsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [validated, setValidated] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [formInputs, setInput] = useState({
    // amount: "",
    // new_amount: "",
    duration_title: "",
    service_hours_title: "",
    no_of_guards: "",
    no_of_checkpoints: "",
    no_of_patrolling_in_day: "",
    company_list: [],
    my_documents: [],
    quote_company_id: "",
  });
  const [error, setError] = useState({
    // amount: "",
    document: "",
  });
  const navigate = useNavigate();

  const location = useLocation();

  const routeParams = location.state;

  useEffect(() => {
    getDetails();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const onSaveCompanies = async () => {
    if (selectedDocuments.length > 0) {
      setIsLoading(true);
      const response = await APICall(ApiRoutes.QuotationUploadDocs, {
        quote_company_id: formInputs.quote_company_id,
        documents: selectedDocuments,
      });
      await getDetails();
      handleClose();
      setIsLoading(false);
    }
    setError({
      document:
        selectedDocuments.length > 0
          ? ""
          : "Please select at least one Document",
    });
  };

  const renderDocumentCard = (item, index) => (
    <div className="added-cards col-lg-2">
      <div className="cardMaster rounded border p-3 mb-3">
        <div className="card-information col-12">
          <div className="align-items-center mb-2">
            <div id="profile_pic_1_preview" className="image-fixed">
              <img
                className="obj_fit_img rounded w-100"
                src={commonHelper.getExtBaseFile(
                  process.env.REACT_APP_ASSETS_URL + item.doc_url,
                  item.doc_title.split(".").pop()
                )}
                alt=""
                height="90"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = DefaultImg;
                }}
              />
            </div>
            <h6 className="text-center text-truncate mt-2 col-12">
              {item.doc_title}
            </h6>
            <a
              href={process.env.REACT_APP_ASSETS_URL + item.doc_url}
              target="_blank"
              className=" d-flex justify-content-center mt-2 col-12 text-primary h6"
            >
              Preview
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  const renderNoData = () => (
    <div className="added-cards">
      <div className="cardMaster rounded border p-3 mb-3">
        <div className="d-flex justify-content-center flex-sm-row flex-column">
          <div className="card-information">
            <div className="d-flex align-items-center mb-2">
              <h4 className="card-title text-center my-2">
                Sorry, No documents uploaded.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const getDetails = async () => {
    setgetDetailsLoading(true);
    const responseData = await APICall(ApiRoutes.QuotationDetails, {
      id: routeParams.id,
    });
    if (responseData) {
      setInput((prevState) => ({
        ...prevState,
        ...responseData.data,
        company_list: responseData.data.companies_list,
      }));
      setgetDetailsLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    setError({ document: "" });
    setSelectedDocuments([]);
  };
  const handleShow = () => setShow(true);

  // const getExtBaseFile = (url, ext) => {
  //   console.log(ext);
  //   let result = "";
  //   const imgExtensions = ["jpg", "jpeg", "png", "gif", "JPG", "svg"];
  //   const pdfExtensions = ["pdf"];
  //   const xlsExtensions = ["xlsx", "xls"];
  //   const docExtensions = ["doc", "docx", "ppt", "pptx", "txt"];

  //   console.log("docExtensions ==> ", docExtensions.includes(ext));

  //   if (imgExtensions.includes(ext)) {
  //     result = url;
  //   } else if (docExtensions.includes(ext)) {
  //     result = DocImg;
  //   } else if (pdfExtensions.includes(ext)) {
  //     result = PdfImg;
  //   } else if (xlsExtensions.includes(ext)) {
  //     result = XlsImg;
  //   } else {
  //     result = DefaultImg;
  //   }
  //   return result;
  // };

  const renderImagePreview = (item, index) => (
    <div className={"col-md-2 col-12 mb-3"} key={index}>
      <div className="card border shadow-none mb-3 app-file-info h-100">
        <div className="p-3 text-center">
          <div id="profile_pic_1_preview" className="image-fixed">
            <img
              className="obj_fit_img rounded w-100"
              src={commonHelper.getExtBaseFile(item.base64, item.ext)}
              alt=""
              height="130"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = DefaultImg;
              }}
            />
          </div>
          <div className="form-group add-new-file text-center mb-0  w-100 text-truncate">
            <label className="form-label-input mt-3 text-break">
              {item.name}
            </label>
          </div>
          <div className="btn-fixed">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                const jsonPrevious = fileRef.current.files;
                const { [index]: removedProperty, ...json } = jsonPrevious;
                const dataTransfer = new DataTransfer();
                Object.keys(json).forEach((json_key) => {
                  selectedDocuments.forEach((selectedDoc) => {
                    if (selectedDoc.name === json[json_key].name) {
                      var arr = selectedDoc.base64.split(","),
                        mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]),
                        n = bstr.length,
                        u8arr = new Uint8Array(n);

                      while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                      }
                      let file = new File([u8arr], selectedDoc.name, {
                        type: mime,
                      });
                      dataTransfer.items.add(file);
                    }
                  });
                });
                fileRef.current.files = dataTransfer.files;
                setSelectedDocuments((prevState) =>
                  prevState.filter(
                    (filterItem, filterIndex) => index !== filterIndex
                  )
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const getLocation = () => {
    if (formInputs?.property_location) {
      const value = formInputs.property_location;
      let str =
        (value.address1 ? value.address1 + ", " : "") +
        (value.address2 ? value.address2 + ", " : "") +
        (value.city ? value.city + ", " : "") +
        (value.state ? value.state + ", " : "") +
        (value.country ? value.country + ", " : "") +
        (value.postal_code ? value.postal_code + ", " : "");
      str = str.trim();
      str = str.slice(0, -1);
      if (str.length && str && str !== "null") {
        return str;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const acceptRejectCall = async (is_accept) => {
    setDisabledBtn(is_accept);
    is_accept ? setIsAcceptLoading(true) : setIsRejectLoading(true);
    const response = await APICall(ApiRoutes.QuotationAcceptReject, {
      quotation_id: formInputs.quotation_id,
      is_accept: is_accept,
    });
    if (response) {
      await getDetails();
    }
    is_accept ? setIsAcceptLoading(false) : setIsRejectLoading(false);
    setDisabledBtn(null);
  };

  const renderQuotationDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Quotation Details</h4>
        {formInputs?.quote_company_status === "pending" &&
          formInputs?.show_accept_confirm_option === 1 && (
            <div>
              {isAcceptLoading === false ? (
                <Button
                  variant="success me-3"
                  disabled={disabledBtn === 0}
                  onClick={() => {
                    acceptRejectCall(1);
                  }}
                >
                  Accept
                </Button>
              ) : (
                <Button variant="success me-3">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
              {isRejectLoading === false ? (
                <Button
                  variant="danger"
                  disabled={disabledBtn === 1}
                  onClick={() => {
                    acceptRejectCall(0);
                  }}
                >
                  Reject
                </Button>
              ) : (
                <Button variant="danger">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          )}

        {formInputs?.show_accept_confirm_option === 1 &&
          formInputs?.quote_company_status === "confirmed" && (
            <div>
              <Button
                variant="success me-3"
                disabled={disabledBtn === 0}
                onClick={() => {
                  navigate("/contracts/create", {
                    state: { quotationDetails: formInputs },
                  });
                }}
              >
                Create Contract
              </Button>
            </div>
          )}
      </Card.Header>
      <Card.Body>
        {getDetailsLoading ? (
          <NewLoader />
        ) : (
          <Row className="address">
            <Col className="col-12 col-xl-6">
              <dl className="mb-0 row">
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Property Manager:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.client_name}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.property_name}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Duration:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.duration_title}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Service Hours:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.service_hours_title}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Category Type:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.property_info?.property_category_type_title}
                </dd>
                {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property Type:</dt>
              <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                {formInputs?.property_info?.property_development_type_title}
              </dd> */}
                {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Status:</dt>
              <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                {formInputs?.quote_company_status &&
                  badgesEnum[formInputs.quote_company_status]}
              </dd> */}
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Property Location:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {getLocation()}
                </dd>
              </dl>
            </Col>
            <Col className="col-12 col-xl-5">
              <dl className="mb-0 row">
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Status:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.custom_quote_status &&
                    badgesEnum[formInputs.custom_quote_status]}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of Guards:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.no_of_guards}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of patrolling in a day:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.no_of_patrolling_in_day}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of checkpoints:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs.no_of_checkpoints}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Submission Date:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.created_at &&
                    moment(formInputs?.created_at).format("DD MMM, YYYY")}
                </dd>
                {/* {formInputs?.amount > 0 && (
                <>
                  <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">Amount:</dt>
                  <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                    {formInputs.amount}
                  </dd>
                </>
              )} */}
              </dl>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );

  const renderClientDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Property Manager Details</h4>
      </Card.Header>
      <Card.Body>
        {getDetailsLoading ? (
          <NewLoader />
        ) : (
          <Row className="address align-items-center">
            {/* <Col className="col-sm-12 col-md-12 col-lg-2 col-12">
            <img
              src={
                formInputs?.client_profile_img
                  ? process.env.REACT_APP_ASSETS_URL +
                  formInputs?.client_profile_img
                  : DefaultImg
              }
              className={
                "mb-3 mb-xl-0 col-8 col-sm-6 col-md-4 col-lg-12 m-auto d-block rounded"
              }
            />
          </Col> */}
            <Col className="col-12 col-xl-8">
              <dl className="mb-0 row">
                <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Name:</dt>
                <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                  {formInputs?.client_name}
                </dd>
                <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Email:</dt>
                <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                  {formInputs?.client_email}
                </dd>
                <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Phone:</dt>
                <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                  {formInputs?.client_phone}
                </dd>
                <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Location:</dt>
                <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                  {getLocation()}
                </dd>
                <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Position:</dt>
                <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                  {formInputs?.client_info?.position}
                </dd>
              </dl>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              View Quotation
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              {/* <Breadcrumb.Item href="/quotationreq">Setup</Breadcrumb.Item> */}
              <Breadcrumb.Item href="/quotationreq">Quotation</Breadcrumb.Item>
              <Breadcrumb.Item active>View</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>

      {renderQuotationDetails()}
      {renderClientDetails()}

      {!activate_doc_view_arr.includes(formInputs?.quote_company_status) && (
        <Card>
          <Card.Header>
            <h4 className="card-title">Quotation Documents</h4>
            {activate_doc_arr.includes(formInputs?.quote_company_status) && (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleShow}
              >
                <i className="fa-solid fa-plus"></i> Upload Documents
              </button>
            )}
          </Card.Header>
          <Card.Body>
            {getDetailsLoading ? (
              <NewLoader />
            ) : (
              <div className="row">
                {formInputs.my_documents.length > 0
                  ? formInputs.my_documents.map(renderDocumentCard)
                  : renderNoData()}
              </div>
            )}
          </Card.Body>
        </Card>
      )}

      <Modal
        className="modal-popup"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog modal-xl modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            {/* {(formInputs?.amount === 0 ||
              formInputs?.amount.toString().trim() === "") && (
              <InputText
                type={"number"}
                title={"Amount"}
                size={"12"}
                value={formInputs.new_amount}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    new_amount: value,
                  })
                }
                required
                validationText={error.amount}
              />
            )} */}
            <div>
              <Form.Label>Quotation Documents</Form.Label>
              <Form.Control
                type="file"
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                multiple
                ref={fileRef}
                onChange={async (e) => {
                  let base64Arr = [];
                  const files = Array.from(e.target.files);
                  if (files.length <= 5) {
                    let totalSize = 0;
                    for (let index = 0; index < files.length; index++) {
                      totalSize = totalSize + files[index].size;
                    }
                    totalSize = commonHelper.formatBytes(totalSize);
                    if (totalSize <= 50) {
                      setError({
                        document: "",
                      });
                      for (let index = 0; index < files.length; index++) {
                        try {
                          let ext = files[index].name.split(".").pop();
                          let extArray = [
                            "xlsx",
                            "xls",
                            "ppt",
                            "pdf",
                            "png",
                            "txt",
                            "pptx",
                            "doc",
                            "docx",
                            "jpg",
                            "jpeg",
                            "svg",
                            "webp",
                          ];
                          if (extArray.includes(ext)) {
                            setError({
                              document: "",
                            });
                            const result = await getBase64(files[index]);
                            base64Arr.push({
                              name: files[index].name,
                              base64: result,
                              ext: ext,
                            });
                          } else {
                            e.target.value = "";
                            setError({
                              document: "Invalid documents have been selected.",
                            });
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      }
                    } else {
                      e.target.value = "";
                      setError({
                        document: `Files size must be upto 50 MB. Total files size is ${totalSize} MB.`,
                      });
                    }
                  } else {
                    e.target.value = "";
                    setError({
                      document:
                        "Total five files are allowed to upload at once.",
                    });
                  }

                  setSelectedDocuments(base64Arr);
                }}
              />
              {error?.document && (
                <span className="error">{error?.document}</span>
              )}
            </div>
            <div
              className="scrollbar-container media-list scrollable-container ps ps--active-y"
              style={{ "max-height": "30rem" }}
            >
              <div className="row pt-4 me-2">
                {selectedDocuments.length > 0 &&
                  selectedDocuments.map(renderImagePreview)}
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Close
          </Button>
          {isLoading === false ? (
            <>
              <Button variant="primary" onClick={onSaveCompanies}>
                Send
              </Button>
            </>
          ) : (
            <Button variant="primary">
              <Spinner animation="border" size="sm" /> Loading...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Create;
