import React from 'react'
import { useLocation } from 'react-router-dom'
import { Enums } from "../../constants/enums";
import AdminProfile from './AdminProfile';
import BranchProfile from './BranchProfile';
import ClientProfile from './ClientProfile';
import CompanyProfile from './CompanyProfile';

export default function Profile() {
    const location = useLocation();
    if (location.state.user_role == Enums.UserRole.admin) {
        return <AdminProfile/>
    } else if (location.state.user_role == Enums.UserRole.company) {
        return <CompanyProfile/>
    }
    else if (location.state.user_role == Enums.UserRole.branch_manager) {
        return <BranchProfile/>
    }
    else if (location.state.user_role == Enums.UserRole.client) {
       return <ClientProfile/>
    }
}
