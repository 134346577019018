import { FloatingLabel, Form } from "react-bootstrap";

function TextareaPage() {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Default</h4>
            </div>
            <div className="card-body">
              <FloatingLabel
                controlId="floatingTextarea"
                label="Comments"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingTextarea2" label="Comments">
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextareaPage;
