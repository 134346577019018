import React, { useState } from 'react'
import { useEffect } from 'react';
import { Card, Col, Row, Nav, Tab, Breadcrumb, Button } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { ApiRoutes, contractStatusEnum, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import DataTable from "react-data-table-component";
import { commonService } from '../../services/common.service';
import NewLoader from '../../components/loader/NewLoader';

export default function View() {

  const navigate = useNavigate()
  const userType = JSON.parse(commonService.getItem("user")).association_type_term;
  const params = useParams();
  const guard_id = params.guard_id;
  const [isLoading, setIsLoading] = useState(true)

  const [itemDetails, setItemDetails] = useState({});
  const [bankDetails, setBankDetails] = useState({});
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [contractList, setContractList] = useState({
    isData: false,
    data: [],
  });
  const [contractTotalRows, setContractTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [pageNo, setPageNo] = useState(1);

  const fetchDetails = async () => {
    let res = await APICall(ApiRoutes.GuardEditDetails, {
      id: guard_id
    })
    if (res.status === 1) {
      setItemDetails(res.data)
      if (res.data.bank_details.length) {
        setBankDetails(res.data.bank_details[0])
      }
      if (res.data.emergency_contacts.length) {
        setEmergencyContacts(res.data.emergency_contacts)

      }

      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  const renderLocation = () => {
    if (itemDetails.locations.length) {
      const locationString = itemDetails.locations[0];
      let str = (locationString.address1
      ? locationString.address1 + ", "
      : "") + (locationString.address2
      ? locationString.address2 + ", "
      : "") +
        (locationString.city
          ? locationString.city + ", "
          : "") +
        (locationString.state
          ? locationString.state + ", "
          : "") +
        (locationString.country
          ? locationString.country + ", "
          : "") +
        (locationString.postal_code
          ? locationString.postal_code + ", "
          : "");
        str = str.trim();
        str = str.slice(0, -1);
        if (str.length && str && str !== "null") {
          return str;
        } else {
          return "-";
        }
    } else {
      return "";
    }
  }

  const fetchContractList = async (column = "",
    sortDirection = "",
    isFilter = 0
  ) => {
    setDataTableLoading(true)
    let response = await APICall(ApiRoutes.viewContractsList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      association_type_term: Enums.UserRole.guard,
      association_id: guard_id
    })
    if (response.status === 1) {
      setContractList({ isData: true, data: response.data.list });
      setContractTotalRows(response.data.total);
      setDataTableLoading(false)
    }
  }

  const columns = [
    {
      name: "#",
      selector: (row) => row.full_name,
      sortable: false,
      cell: (row, index, column, id) => {
        return (
          <img
            src={
              row.profile_img
                ? process.env.REACT_APP_ASSETS_URL + row.profile_img
                : DefaultImg
            }
            className="round"
            alt="user"
            width="40"
            style={{ objectFit: "cover" }}
            height="40"
          />
        );
      },
    },
    {
      name: "Company",
      selector: (row) => row.company_name,
      sortable: true,
      sortField: "company_name",
      omit: userType === Enums.UserRole.admin ? false : true
    },
    {
      name: "Property",
      // selector: (row) => row.property_name,
      sortable: true,
      sortField: "property_name",
      // omit : userType === Enums.UserRole.admin ? true : false ,
      cell: (row, index, column, id) => (
        <a className="text-primary cursor-pointer"
          onClick={() => {
            navigate("/contracts/view", {
              state: {
                id: row.contract_id,
              },
            });
          }}
        >
          {row.property_name}
        </a>
      ),
    },
    {
      name: "Branch Manager",
      selector: (row) => row.branch_name,
      sortable: true,
      sortField: "branch_name",
    },
    // {
    //   name: "Property",
    //   selector: (row) => row.property_name,
    //   sortable: true,
    //   sortField: "property_name",
    //   omit : userType === Enums.UserRole.admin ? false : true ,
    // },
    // {
    //   name: "Branch Manager",
    //   selector: (row) => row.branch_name,
    //   sortable: true,
    //   sortField: "branch_name",
    // },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      sortField: "start_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.start_date),
    },
    {
      name: "Expiry Date",
      selector: (row) => row.end_date,
      sortable: true,
      sortField: "end_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.end_date),
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      sortField: "is_active",
      cell: (row, index, column, id) => {
        return (

          <span className="justify-content-center align-items-center d-flex">
            {contractStatusEnum[row.contract_status]}
          </span>
        );
      },
    },

  ];

  const emergencyColums = [

    {
      name: "Name",
      selector: (row) => row.emr_contact_name,
      sortable: true,
      sortField: "emr_contact_name"
    },
    {
      name: "Phone",
      selector: (row) => row.emr_contact_phone,
      sortable: true,
      sortField: "emr_contact_phone"
    },
    {
      name: "Relation",
      selector: (row) => row.emr_contact_relation,
      sortable: true,
      sortField: "emr_contact_relation"
    },

  ];
  React.useEffect(() => {
    fetchContractList("", "", 1);
  }, [pageNo, perPageRecords]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };
  const handleSort = async (column, sortDirection) => {
    fetchContractList(column.sortField, sortDirection , 1);
  };
  const handlePageChange = (page) => {
    setPageNo(page);
  };
  useEffect(() => {
    fetchDetails()
    fetchContractList()
  }, []);


  const ContractsList = () => {
    return (<><DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
      columns={columns}
      progressPending={dataTableLoading}
      progressComponent={<NewLoader />}
      data={contractList.data}
      pagination
      paginationTotalRows={contractTotalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onSort={handleSort}
      paginationPerPage={perPageRecords}
    />
    </>)
  }

  const EmergencyContacts = () => {

    return (<><DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
      columns={emergencyColums}
      progressPending={dataTableLoading}
      progressComponent={<NewLoader />}
      data={emergencyContacts}
      pagination
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onSort={handleSort}
      paginationPerPage={perPageRecords}
    />
    </>)

  }



  const renderDetails = () => (
    <Col className="order-1 col-12 order-lg-0 col-lg-5 col-xl-4 contract-company-view">
      <Card>
        <Card.Body>
          <div className="company-profile-avatar">
            <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
              <span className="avatar-content">
                <img className="w-100 h-100 obj-fit-cover" src={itemDetails.profile_img ? process.env.REACT_APP_ASSETS_URL + itemDetails.profile_img : DefaultImg}></img>
              </span>
            </div>
            <div className="d-flex flex-column align-items-center text-center user-info">
              <span className="mb-2 text-primary h4">{itemDetails.guard_name}</span>
              <span className="text-capitalize badge bg-light-primary">
                #guard
              </span>
            </div>
            <div className="star-rating static-rating text-center  w-100">
              {[...Array(5).keys()].map((item, index) => {
                return (<>
                  <input checked={Math.ceil(parseFloat(itemDetails.rating)) == 5 - item ? true : false} id={`star-${item}`} type="radio" name="rating" value={item} />
                  <label htmlFor={`star-${item}`} title={`${item} stars`}>
                    <i className="active fa fa-star" aria-hidden="true"></i>
                  </label>
                </>)
              })}
            </div>
          </div>
          <div className="company-figures d-flex justify-content-between my-3">
            {/* <div className="d-flex align-items-start me-2">
              <span className="rounded p-75 badge bg-light-primary">
                <i className=" fa-solid fa-code-branch"></i>
              </span>
              <div className="complete-texts">
                <h4 className="mb-0">- </h4>
                <small className="h4 text-dark">Total Incharge</small>
              </div>
            </div> */}
            <div className="d-flex align-items-start me-2">
              <span className="rounded p-75 badge bg-light-primary">
                <i className=" fa-solid fa-th-large"></i>
              </span>
              <div className="complete-texts">
                <h4 className="mb-0">{itemDetails.total_contract_count}</h4>
                <small className="h4 text-dark">Total Contracts</small>
              </div>
            </div>
          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-envelope text-primary me-2"></i>
              {itemDetails.guard_email ?
                itemDetails.guard_email : "-"}
            </div>
          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-phone text-primary me-2"></i>
              {itemDetails.guard_phone ?
                itemDetails.guard_phone : "-"}
            </div>
          </div>
          {itemDetails.locations.length ? <div className="company-details mt-1">
            <div className="left-field">{itemDetails?.locations[0] ? <><i className="fa-solid fa-location-dot text-primary me-2"></i> </> : <i className="fa-solid fa-location-dot text-primary me-2"></i>}
              {renderLocation()}
            </div>
          </div> : ""}

        </Card.Body>
      </Card>

    </Col>
  );


  return (<>
    <div className="content-header row">
      <div className="content-header-left col-md-9 col-12 mb-2">
        <div className="breadcrumbs-top d-flex align-items-center">
          <h2 className="content-header-title float-start mb-0">
            Guard
          </h2>
          <Breadcrumb className="breadcrumb-chevron">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/guards">Setup</Breadcrumb.Item>
            <Breadcrumb.Item href="/guards">Guards</Breadcrumb.Item>
            <Breadcrumb.Item active>Guard Details</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    </div>

    {!isLoading ? (<div className="app-user-view">
      <Row>
        {renderDetails()}

        <Col className="order-0 col-12 order-lg-1 col-lg-8 col-xl-8 col">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="mb-3">
              <Nav.Item className="d-flex cursor-pointer">
                <Nav.Link eventKey="first">
                  <i className="fa-regular fa-th-large"></i>
                  <span className="pills-tab-text">Contracts</span>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="d-flex cursor-pointer">
                <Nav.Link eventKey="second">
                  <i className="fa-regular fa-user"></i>
                  <span className="pills-tab-text">Details</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>

              <Tab.Pane eventKey="first">
                <ContractsList />
              </Tab.Pane>

              <Tab.Pane eventKey="second">

                <Col className="order-1 col-12 order-lg-0 col-lg-5 col-xl-12">
                  <Card>
                    <div class="card-header"><h4 class="card-title">Basic Details</h4></div>
                    <Card.Body>
                      <Row className="address align-items-center">
                        <Col className="col-12 col-xl-12">

                          <dl className="mb-1 row">
                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">ID No</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.id_number}
                            </dd>
                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Passport</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.passport_number}
                            </dd>
                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Gender</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.gender}
                            </dd>
                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Qualification</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.qualification}
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Year of Experience</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.year_of_exp}
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">EPF</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.epf}
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Sosco</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.sosco}
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Date Of Joining</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {commonService.convertUTCToLocal(itemDetails.date_of_join)}
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Bank Name</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {bankDetails?.bank_name}
                            </dd>


                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Bank Acc No.</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              <u>{bankDetails?.account_no}</u>
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Created By</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.created_by_name ? itemDetails.created_by_name : ''}
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3"></dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">Vetting Image</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.vetting_img ?
                                <a href={itemDetails.vetting_img ? process.env.REACT_APP_ASSETS_URL + itemDetails.vetting_img : DefaultImg} target="_blank">
                                  <u>Click Here</u>
                                </a>
                                : "-"}
                            </dd>

                            <dt className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">ID Proof</dt>
                            <dd className="mb-2 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                              {itemDetails.id_proof_doc ? <a href={itemDetails.id_proof_doc ? process.env.REACT_APP_ASSETS_URL + itemDetails.id_proof_doc : DefaultImg} target="_blank"><u>Click Here</u></a>:"-"}
                            </dd>
                          </dl>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card>
                    <div class="card-header"><h4 class="card-title">Emergency Contacts</h4></div>
                    <Card.Body>
                      <EmergencyContacts />
                    </Card.Body>
                  </Card>
                </Col>

              </Tab.Pane>

            </Tab.Content>
          </Tab.Container>
        </Col>



      </Row>
    </div>) : <NewLoader/>}
  </>)
}
