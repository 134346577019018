import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Form, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DropDown from "../../components/common/DropDown";
import InputText from "../../components/form/inputText";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { updateLoading } from "../../store/actions";
import InputSearchLocation from "../../components/form/InputSearchLocation";
import ImgInput from "../../components/common/ImgInput";
import DatePickerInput from "../../components/common/DatePickerInput";
import FileImageInput from "../../components/common/FileImageInput";
import { Enums } from "../../constants";

function Create() {
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [fileImageSrc, setFileImageSrc] = useState(null);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const userData = JSON.parse(commonService.getItem("user"));
  const getPaymentList = async () => {
    const res = await APICall(ApiRoutes.PaymentModeList);
    if (res) {
      setPaymentList(res.data.list);
    }
  };

  useEffect(() => {
    getPaymentList();
    getCompanyList();
  }, []);

  const [isCompanyFormClick, setIsCompanyFormClick] = useState(false);
  const [paymentModeList, setPaymentModeList] = useState([]);
  const dispatch = useDispatch();

  const [formInputs, setInput] = useState({
    company_id: userData.association_type_term === Enums.UserRole.company ? userData.association_id : "",
    branch_name: "",
    // branch_register_no: "",
    branch_email: "",
    branch_phone: "",
    branch_contact_person_email: "",
    branch_contact_person_name: "",
    branch_contact_person_phone: "",
    branch_payment_duration: "",
    branch_summary: "",
    // branch_website_url: "",
    id_number: "",
    qualification: "",
    total_experience: "",
    // contact_by_reference: "",
    start_date: new Date(),
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        longitude: "",
        latitude: ""
      },
    ],
  });
  const [formInputsErrors, setInputErrors] = useState({
    company_id: "",
    branch_name: "",
    // branch_register_no: "",
    branch_email: "",
    branch_phone: "",
    branch_contact_person_email: "",
    branch_contact_person_name: "",
    branch_contact_person_phone: "",
    branch_payment_duration: "",
    start_date: "",
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
    ],
  });

  const validation_msg = {
    branch_name: "Company name is required",
    // branch_register_no: "Registration number is required",
    valid_email: "Please enter valid email address",
    branch_email: "Email is required",
    branch_phone: "Phone is required",
    branch_contact_person_email: "Contact person email is required",
    branch_contact_person_name: "Contact person name is required",
    branch_contact_person_phone: "Contact person phone is required",
    start_date: "This field is required",
    branch_payment_duration: "Payment duration is required",
    company_id: "Company is required",
    address1: "This field is required",
    city: "This field is required",
    state: "This field is required",
    postal_code: "This field is required",
    country: "This field is required",
    invalid_email: "This email is invalid"
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  async function submitCompanyForm(event) {
    event.preventDefault();
    if (validateCompanyForm()) {
      dispatch(updateLoading(true));
      setIsCompanyFormClick(true);
      const requestMetadata = await APICall(ApiRoutes.BranchSave, {
        ...formInputs,
        start_date: commonService.convertLocalToUTC(commonService.formatDate(formInputs.start_date) + ' 00:00:00'),
        branch_profile_img: formInputs.branch_profile_img?.includes(
          process.env.REACT_APP_ASSETS_URL
        )
          ? null
          : formInputs.branch_profile_img,
        documents: selectedDocuments
      });
      setIsCompanyFormClick(false);
      dispatch(updateLoading(false));
      if (requestMetadata) {
        navigate("/branch");
      }
    }
  }

  const getCompanyList = async () => {
    const res = await APICall(ApiRoutes.CompanyDropdownList);
    if (res) {
      setPaymentModeList(res.data.list);
    }
  };

  function validateCompanyForm() {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors);
    const requiredArr = ["bank_details", "locations"];
    errorKeys.forEach((item) => {
      if (requiredArr.includes(item)) {
        let newArr = [];
        formInputsErrors[item].forEach((childLoop, childIndex) => {
          let newObj = {};
          const childErrorsArr = Object.keys(childLoop);
          childErrorsArr.forEach((grandChildLoop) => {
            if (formInputs[item][childIndex][grandChildLoop]?.toString().trim() === "") {
              newObj[grandChildLoop] = validation_msg[grandChildLoop];
              isValid = false;
            } else {
              newObj[grandChildLoop] = "";
            }
          });
          newArr.push(newObj);
        });
        errors[item] = newArr;
      } else {
        if (
          formInputs[item] === null ||
          formInputs[item]?.toString()?.trim() === ""
        ) {
          errors[item] = validation_msg[item];
          isValid = false;
        } else {
          if (
            item === "branch_email" &&
            !pattern.test(formInputs.branch_email)
          ) {
            errors[item] = validation_msg.invalid_email;
            isValid = false;
          } else {
            errors[item] = "";
          }
        }
      }
    });
    setInputErrors(errors);
    return isValid;
  }

  const handleLocation = (addressInfoArray) => {
    setInput({
      ...formInputs,
      locations: [{
        ...formInputs.locations[0],
        address1: addressInfoArray.street_address,
        city: addressInfoArray.city,
        state: addressInfoArray.state,
        postal_code: addressInfoArray.postal_code,
        country: addressInfoArray.country,
        longitude: addressInfoArray.lng,
        latitude: addressInfoArray.lat,
      }]
    })
  }

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              Add Branch Manager
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/branch">Setup</Breadcrumb.Item>
              <Breadcrumb.Item href="/branch">Branch Manager List</Breadcrumb.Item>
              <Breadcrumb.Item active>Create</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Card className="pt-4">
        <Card.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row>
              <InputText
                type={"text"}
                title={"Branch Manager Name"}
                value={formInputs.branch_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_name: value,
                  })
                }
                validationText={formInputsErrors.branch_name}
                required
              />
              <InputText
                type={"email"}
                title={"Branch Manager Email"}
                value={formInputs.branch_email}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_email: value,
                  })
                }
                validationText={formInputsErrors.branch_email}
                required
              />
            </Row>
            <Row>
              <InputText
                type={"number"}
                title={"Branch Manager Phone"}
                value={formInputs.branch_phone}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_phone: value,
                  })
                }
                validationText={formInputsErrors.branch_phone}
                required
              />
              <InputText
                type={"text"}
                title={"Contact Person Name"}
                value={formInputs.branch_contact_person_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_contact_person_name: value,
                  })
                }
                validationText={formInputsErrors.branch_contact_person_name}
                required
              />
            </Row>
            <Row>

              <InputText
                type={"email"}
                title={"Contact Person Email"}
                value={formInputs.branch_contact_person_email}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_contact_person_email: value,
                  })
                }
                validationText={formInputsErrors.branch_contact_person_email}
                required
              />
              <InputText
                type={"number"}
                title={"Contact Person Phone Number"}
                value={formInputs.branch_contact_person_phone}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_contact_person_phone: value,
                  })
                }
                validationText={formInputsErrors.branch_contact_person_phone}
                required
              />
            </Row>
            <Row>


              {/* <DropDown
                        data={paymentModeList}
                        title={"Payment Duration"}
                        onChange={(value) =>
                          setInput({
                            ...formInputs,
                            branch_payment_duration: value,
                          })
                        }
                        validationText={formInputsErrors.branch_payment_duration}
                        required
                      /> */}
              {userData.association_type_term === "admin" && (
                <DropDown
                  data={paymentModeList}
                  title={"Company"}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      company_id: value,
                    })
                  }
                  validationText={formInputsErrors.company_id}
                  required
                />
              )}
              {/* <InputText
                type={"text"}
                title={"Registration Number"}
                value={formInputs.branch_register_no}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_register_no: value,
                  })
                }
                validationText={formInputsErrors.branch_register_no}
                required
              /> */}
            </Row>
            <Row>
              <DropDown
                data={paymentList}
                title={"Payment Duration"}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_payment_duration: value,
                  })
                }
                required
                validationText={formInputsErrors.branch_payment_duration}
              />
              <DatePickerInput
                title={"Start Date"}
                selectedDate={formInputs.start_date}
                onChange={(value) => {
                  setInput({
                    ...formInputs,
                    start_date: value,
                  });
                }}
                error={formInputsErrors.start_date}
                required
              />
            </Row>
            <Row>
              {/* <InputText
                type={"text"}
                title={"Website URL"}
                value={formInputs.branch_website_url}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_website_url: value,
                  })
                }
                validationText={formInputsErrors.branch_website_url}
              /> */}
              <InputText
                type={"text"}
                title={"Id Number"}
                value={formInputs.id_number}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    id_number: value,
                  })
                }
                validationText={formInputsErrors.id_number}
              />
               <InputText
                type={"text"}
                title={"Highest Level Education"}
                value={formInputs.qualification}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    qualification: value,
                  })
                }
                validationText={formInputsErrors.qualification}
              />
            </Row>
            <Row>
             
              <InputText
                type={"number"}
                title={"Years of Experience"}
                value={formInputs.total_experience}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    total_experience: value,
                  })
                }
                validationText={formInputsErrors.total_experience}
              />

              {/* <InputText
                type={"text"}
                size={'12'}
                title={"How did you hear about us ?"}
                multiLine={true}
                value={formInputs.contact_by_reference}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    contact_by_reference: value,
                  })
                }
                validationText={formInputsErrors.contact_by_reference}
              /> */}
              {/* <InputText
                type={"text"}
                title={"Summary"}
                style={{ div: "col-md-12 col-12 mb-4" }}
                textarea={true}
                multiLine={true}
                size={"12"}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_summary: value,
                  })
                }
              /> */}
            </Row>

            <div className="col-12 row">
              <blockquote className="blockquote border-left-3 border-left-primary float-left pl-1 mt-3">
                <h4 className="mb-0 shop-title float-left">
                  Location Details
                </h4>
              </blockquote>
            </div>
            <InputSearchLocation size={"12"} placeholder="Search location" onPlaceChange={handleLocation} />
            {formInputs?.locations?.map((mapItem, mapIndex) => (
              <div key={mapIndex}>
                <Row>
                  <InputText
                    type={"text"}
                    title={"Address"}
                    value={mapItem.address1}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                address1: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={
                      formInputsErrors?.locations[mapIndex]?.address1
                    }
                  />
                  <InputText
                    type={"text"}
                    title={"City"}
                    value={mapItem.city}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                city: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={formInputsErrors?.locations[mapIndex]?.city}
                  />
                </Row>
                <Row>
                  <InputText
                    type={"text"}
                    title={"State"}
                    value={mapItem.state}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                state: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={formInputsErrors?.locations[mapIndex]?.state}
                  />
                  <InputText
                    type={"text"}
                    title={"Postal code"}
                    value={mapItem.postal_code}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                postal_code: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={
                      formInputsErrors?.locations[mapIndex]?.postal_code
                    }
                  />
                </Row>
                <Row>
                  <InputText
                    type={"text"}
                    title={"Country"}
                    value={mapItem.country}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                country: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={
                      formInputsErrors?.locations[mapIndex]?.country
                    }
                  />
                </Row>



              </div>
            ))}

            <div className="col-12 row">
              <ImgInput
                title={"Logo"}
                img_src={formInputs.branch_profile_img}
                input_ref_id="branch_profile_img"
                onChange={(value) => {
                  setInput({
                    ...formInputs,
                    branch_profile_img: value,
                  });
                }}
              />
              <FileImageInput
                fileImageSrc={fileImageSrc}
                setFileImageSrc={setFileImageSrc}
                validateExts={['doc', 'docx', 'pdf']}
                accept_file_type={"doc, .docx,.pdf"}
                title={"Profile"}
                selectedDocuments={selectedDocuments}
                input_ref_id="portfolio_url"
                onChange={(value) => {
                  setSelectedDocuments(value)
                }}
              />



            </div>
            <div className="col-12 d-flex justify-content-end">
              <Button
                variant="outline-primary"
                className="btn-15"
                // type="button"
                onClick={() => {
                  navigate("/branch");
                }}
              >
                Cancel
              </Button>

              {isCompanyFormClick === false ? (
                <>
                  <Button
                    variant="primary"
                    className="btn-15"
                    onClick={submitCompanyForm}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button variant="primary" className="btn-15">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default Create;
