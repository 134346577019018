import React from "react";
import { Modal } from "react-bootstrap";
import Parser from "html-react-parser";
import { ReactComponent as PageLoading } from "../../assets/Icons/page-loading.svg";
import { useEffect } from "react";
import { useState } from "react";
import { APICall } from "../../services/axiosService";
import { ApiRoutes } from "../../constants";

// export default function PrivacyPolicy({ showPrivacyPolicy, setShowPrivacyPolicy, policy }) {
export default function PrivacyPolicy() {
  const [pageLoading, setPageLoading] = useState(true);
  const [policy, setPolicy] = useState("");

  const fetchPolicy = async () => {
    let res = await APICall(ApiRoutes.fetchPolicy);
    if (res.status == 0) {
    } else if (res.status == 1) {
      setPolicy(res.data.client_privacy_policy != null ? res.data.client_privacy_policy : '');
    }
    setPageLoading(false);
  };
  useEffect(() => {
    fetchPolicy();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <h3 className="mb-4">Privacy Policy</h3>
        {Parser(policy)}
      </div>
      {/* <Modal className="modal-popup" size="lg" show={showPrivacyPolicy} onHide={closePrivacyPolicy}>
    <Modal.Header closeButton>
      <Modal.Title>Privacy Policy</Modal.Title>
    </Modal.Header>
    <Modal.Body> */}

      {/* </Modal.Body>
  </Modal> */}
    </div>
  );
}
