import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import InputText from "../../../components/form/inputText";

export default function CreateWizardTwo({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Row>
            <InputText
              type={"text"}
              title={"Bank Name"}
              value={formInputs.bank_name}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  bank_name: value,
                })
              }
              validationText={formInputsErrors.bank_name}
              required
            />
            <InputText
              type={"number"}
              title={"Account Number"}
              value={formInputs.account_no}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  account_no: value,
                })
              }
              validationText={formInputsErrors.account_no}
              required
            />
          </Row>
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15"
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
