import React from 'react'

export default function NoRecordMsg({ msg = "There are no records to display" }) {
    return (<div role="tabpanel" aria-labelledby="left-tabs-example-tab-sec" className="fade tab-pane active show">
        <div className="my-tabel-main">
            <div className="sc-dmRaPn gelpCx">
                <div className="sc-fLlhyt ifOHjV">
                    <div className="sc-bczRLJ eSTlnH rdt_Table" role="table">
                        <div className="sc-ivTmOn fwKvpK">
                            <div style={{ padding: "24px" }}>
                                {msg}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
