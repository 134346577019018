import { actionType } from "../actionType";

export default function LoaderReducer(state = false, action) {
  switch (action.type) {
    case actionType.UPDATE_LOADER_STATE:
      return action.payload;
    default:
      return state;
  }
}
