import React, { useState } from "react";
import { Breadcrumb , Button , InputGroup , Form , Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";

import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { ApiRoutes } from "../../constants/apiroutes";
import { Enums } from "../../constants/enums";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import InputText from "../../components/form/inputText";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import NewLoader from "../../components/loader/NewLoader";

function Index() {
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [companyTotalRows, setGuardTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [propertyList, setPropertyList] = useState({
    isData: false,
    data: [],
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);

  const RoleRights = getUserAccess(Enums.UserRights.properties);

  const companyTblColumns = [
    {
      name: "#",
      selector: (row) => row.full_name,
      sortable: false,
      cell: (row, index, column, id) => {
        return (
          <img
            src={
              row.profile_img
                ? process.env.REACT_APP_ASSETS_URL + row.profile_img
                : DefaultImg
            }
            className="round"
            alt="user"
            width="40"
            height="40"
          />
        );
      },
    },
    {
      name: "Property Name",
      selector: (row) => row.property_name,
      sortable: true,
      sortField: "property_name",
    },
    {
      name: "Corporate Email",
      selector: (row) => row.email,
      sortable: true,
      sortField: "email",
    },
    {
      name: "Office Number",
      selector: (row) => row.phone,
      sortable: true,
      sortField: "phone",
    },
    {
      name: "Register Number",
      selector: (row) => row.register_no,
      sortable: true,
      sortField: "register_no",
    },
    {
      name: "Category Type",
      selector: (row) => row.property_category_type_title,
      sortable: true,
      sortField: "property_category_type",
    },
    // {
    //   name: "Development Type",
    //   selector: (row) => row.property_development_type_title,
    //   sortable: true,
    //   sortField: "property_development_type",
    // },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      sortField: "is_active",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={(value) => onSwitchToggle(value, row, index)}
              checked={row.is_active === "1" ? true : false}
              height={20}
              width={40}
              disabled={!RoleRights.is_update}
              onColor="#263A96"
            />
          </span>
        );
      },
    },
    {
      name: "Created On",
      selector: (row) => row.created_at,
      sortable: true,
      sortField: "created_at",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.created_at),
    },
    {
      name: "Action",
      selector: (row) => row.year,
      cell: (row, index, column, id) =>
        RoleRights.is_update === 1 && (
          <div className="justify-content-center align-items-center d-flex">
            <div
              onClick={() => {
                navigate("edit", {
                  state: {
                    id: row.property_id,
                  },
                });
              }}
            >
              <i className="fa fa-edit text-primary"></i>
            </div>
          </div>
        ),
    },
  ];

  const navigate = useNavigate();

  React.useEffect(() => {
    getGuardsList("", "", 1);
  }, [pageNo, perPageRecords]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  function filterCompaniesList() {
    getGuardsList("", "", 1);
  }

  const resetFilter = () => {
    setFromDate(null)
    setToDate(null)
    setTxtSearch("")
    setFilterStatus(null)
    getGuardsList("", "", 0);
  };

  const searchData = (e) => {
    e.preventDefault();

    let isValidate = true;
    if (toDate || fromDate) {
      if (toDate && fromDate) {
        const dateFrom = new Date(fromDate);
        const dateTo = new Date(toDate);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getGuardsList("", "", 1);
    }
  };

  React.useEffect(() => {
    getGuardsList("", "", 1);
  }, [txtSearch]);

  async function getGuardsList(column = "", sortDirection = "", isFilter = 0) {
    setDataTableLoading(true);
    setPropertyList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.PropertyList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        status : filterStatus,
        start_date:
          fromDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(fromDate) + " 00:00:00"
            )
            : null,
        end_date:
          toDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(toDate) + " 23:59:59"
            )
            : null,
      },
    });

    if (requestMetadata) {
      setPropertyList({ isData: true, data: requestMetadata.data.list });

      setGuardTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
   
  }

  const handleSort = async (column, sortDirection) => {
    getGuardsList(column.sortField, sortDirection , 1);
  };

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.PropertyStatusUpdate, {
      id: item.property_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setPropertyList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };

  const handleFilterStatus = (value)=>{
    if (value) {
      setFilterStatus(parseInt(value));
    } else {
      setFilterStatus(null);
    }
  }

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Property List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/property">Setup</Breadcrumb.Item>
                <Breadcrumb.Item active>Property</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className="col-12 d-flex align-items-center justify-content-end mb-3 mt-2">
          {RoleRights.is_update === 1 && (
            <Link to="/property/create">
              <button
                className="btn btn-primary px-3 pr-4 popup-send-button"
                type="button"
              >
                <i className="fa-regular fa-plus"></i> Add Property
              </button>
            </Link>
          )}
        </div>
      </div>
<div>
        <Card>
          <Card.Body className="p-3">
            <div className="row">

              <div className="col-3">
                <InputGroup>
                  <DatePicker
                    className="datepicker form-control"
                    selected={fromDate ? fromDate : ""}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Date"
                    onChange={(date) => {
                      if (date) {
                        setFromDate(date);
                      } else {
                        setFromDate(null);
                      }
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-3">
                <div>
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={toDate ? toDate : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="End Date"
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        } else {
                          setToDate(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

              </div>
              <div className="col-3">
                <Form.Select onChange={(e) => { handleFilterStatus(e.target.value) }}>
                  <option value="" selected={filterStatus == null  && true}>
                    Select Property Status
                  </option>
                  <option value="1" selected={filterStatus == 1 && true}>
                    Active
                  </option>
                  <option value="0" selected={filterStatus == 0 && true}>
                    Inactive
                  </option>

                </Form.Select>
              </div>
              <div className="col-3">
                <div className="d-flex">
                  <div className="me-2">
                    <Button
                      onClick={searchData}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </Card.Body>
        </Card>
      </div>
      {/* <FilterComponent
        searchText={txtSearch}
        PHSearchText={DisplayText.SearchText}
        PHDatepickerFrom={DisplayText.DatepickerFrom}
        PHDatepickerTo={DisplayText.DatepickerTo}
        startDate={filterStartDate}
        endDate={filterEndDate}
        onSearchTextChange={(value) => setTxtSearch(value)}
        onStartDateChange={(value) => setFilterStartDate(value)}
        onEndDateChange={(value) => setFilterEndDate(value)}
        onSearchClick={() => filterCompaniesList()}
      /> */}

      {/* {propertyList.data.length === 0 ? (
        <div className="event-details">
          <NoDataFound fontsize={"150px"} />
        </div>
      ) : ( */}
      <DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
        columns={companyTblColumns}
        data={propertyList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={companyTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
          <div className="col-4">
            <InputText
              formGroupClass={"mb-0"}
              size={'12'}
              type={"text"}
              Label={false}
              placeholder={"Search..."}
              value={txtSearch}
              onChange={(value) => {
                if (value) {
                  setTxtSearch(value)
                } else {
                  setTxtSearch(value)
                }
              }}
            />
          </div>
        </>}
      />
      {/* )} */}
    </>
  );
}

export default Index;
