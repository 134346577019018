import React, { useState } from 'react'
import { Breadcrumb } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { ApiRoutes } from '../../constants';
import { APICall } from '../../services/axiosService';
import { ReactComponent as EyeIcon } from "../../assets/Icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/Icons/eyeSlash.svg";
import { Form } from "react-bootstrap";

export default function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassHide, setcurrentPassHide] = useState(true);
  const [passHide, setPassHide] = useState(true);
  const [conPassHide, setConPassHide] = useState(true);

  console.log("log added inside the changepassword in clinet auth..")

  const errorMessages = {
    current_password: "This feild is required.",
    password: "This feild is required.",
    confirm_password: 'This feild is required.',
    not_matched: 'Password and Confirm password must match.'
  }
  const initialState = {
    current_password: "",
    password: "",
    confirm_password: ""
  }
  const [formInputs, setFormInputs] = useState({
    current_password: "",
    password: "",
    confirm_password: ""
  });
  const [formInputsErrors, setFormInputsErrors] = useState({
    current_password: "",
    password: "",
    confirm_password: ""
  });
  const checkValidation = () => {
    let feildEmpty = false;
    let isValidate = true;
    let er_current_password = "";
    let er_password = "";
    let er_confirm_password = "";
    if (formInputs.current_password == "") {
      er_current_password = errorMessages.current_password;
      feildEmpty = true;
      isValidate = false;
    }
    if (formInputs.password == "") {
      er_password = errorMessages.password;
      feildEmpty = true;
      isValidate = false;
    }
    if (formInputs.confirm_password == "") {
      er_confirm_password = errorMessages.confirm_password;
      feildEmpty = true;
      isValidate = false;
    }
    if (!feildEmpty) {
      if (formInputs.confirm_password != formInputs.password) {
        isValidate = false;
        er_confirm_password = errorMessages.not_matched;
      }
    }
    setFormInputsErrors({
      ...formInputsErrors,
      current_password: er_current_password,
      password: er_password,
      confirm_password: er_confirm_password,
    })
    return isValidate;
  }
  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (checkValidation()) {
      setIsLoading(true)
      let data = {
        current_password: formInputs.current_password,
        password: formInputs.password
      }
      let res = await APICall(ApiRoutes.changePassword, data)
      if (res.status == 1) {
        setFormInputs(initialState);
      }
      setIsLoading(false)
    }
  }
  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">Change Password</h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="card pt-4">
        <div className="card-body w-50">
          <form className="form" onSubmit={handleChangePassword} method="POST" action="#">
            <div className="form-body">
              <div className="row">
                <Form.Group md="12" className="mb-3">
                  <Form.Label>Current Password</Form.Label>
                  <div className='position-relative'>
                    <Form.Control
                      value={formInputs.current_password}
                      type={currentPassHide ? 'password' : 'text'}
                      placeholder="Current Password"
                      onChange={(e) =>
                        setFormInputs({
                          ...formInputs,
                          current_password: e.target.value,
                        })}
                    />
                    {currentPassHide ? <EyeIcon onClick={() => setcurrentPassHide(false)} className="position-absolute me-3 end-0 top-0 mt-2" />
                      : <EyeSlashIcon onClick={() => setcurrentPassHide(true)} className="position-absolute me-3 end-0 top-0 mt-2" />
                    }
                  </div>
                  {formInputsErrors.current_password && <span className="error">{formInputsErrors.current_password}</span>}
                </Form.Group>
                <Form.Group md="12" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <div className='position-relative'>
                    <Form.Control
                      value={formInputs.password}
                      type={passHide ? 'password' : 'text'}
                      placeholder="Password"
                      onChange={(e) =>
                        setFormInputs({
                          ...formInputs,
                          password: e.target.value,
                        })}
                    />
                    {passHide ? <EyeIcon onClick={() => setPassHide(false)} className="position-absolute me-3 end-0 top-0 mt-2" />
                      : <EyeSlashIcon onClick={() => setPassHide(true)} className="position-absolute me-3 end-0 top-0 mt-2" />
                    }
                  </div>
                  {formInputsErrors.password && <span className="error">{formInputsErrors.password}</span>}
                </Form.Group>
                <Form.Group md="12" className="mb-3">
                  <Form.Label>Confirm Password</Form.Label>
                  <div className='position-relative'>
                    <Form.Control
                      value={formInputs.confirm_password}
                      type={conPassHide ? 'password' :'text'}
                      placeholder="Confirm Password"
                      onChange={(e) =>
                        setFormInputs({
                          ...formInputs,
                          confirm_password: e.target.value,
                        })}
                    />
                    {conPassHide ? <EyeIcon onClick={() => setConPassHide(false)} className="position-absolute me-3 end-0 top-0 mt-2" />
                      : <EyeSlashIcon onClick={() => setConPassHide(true)} className="position-absolute me-3 end-0 top-0 mt-2" />
                    }
                  </div>
                  {formInputsErrors.confirm_password && <span className="error">{formInputsErrors.confirm_password}</span>}
                </Form.Group>
                <div className="col-12 d-flex justify-content-end">
                  {isLoading === false ? (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                      >
                        Change
                      </button>
                    </>
                  ) : (
                    <button className="btn btn-primary mr-1">
                      <Spinner animation="border" size="sm" /> Loading...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
