import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import InputText from "../../../components/form/inputText";
import { Enums } from "../../../constants";
import DropDown from "../../../components/common/DropDown";
import NewLoader from "../../../components/loader/NewLoader";

export default function CreateWizardTwo({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
  // companyTblColumns,
  guardsList,
  dataTableLoading,
  companyTotalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  onSelectedRowsChange,
  setGuardsList,
  guardTypes, 
  setGuardTypes
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const [selectableRowSelected, setSelectableRowSelected] = useState(false);
  const companyTblColumns = [
    {
      name: "Name",
      selector: (row) => row.guard_name,
      sortable: true,
      sortField: "guard_name",
    },
    {
      name: "Email",
      selector: (row) => row.guard_email,
      sortable: true,
      sortField: "guard_email",
    },
    {
      name: "Phone",
      selector: (row) => row.guard_phone,
      sortable: true,
      sortField: "guard_phone",
    },
    {
      name: "ID Number",
      selector: (row) => row.id_number,
      sortable: true,
      sortField: "id_number",
    },
    {
      name: "Staff Type",
      selector: (row, i) => {

        return (<>

          <select onChange={(e) => {
         
            setGuardTypes({
              ...guardTypes ,
              [row.guard_id] :  e.target.value ? e.target.value :""
            })
            // guardsList.data[i] = {
            //   ...guardsList.data[i],
            //   guard_type: e.target.value ? e.target.value :""
            // }
            // setGuardsList(guardsList)

            // let newGuardList = [];

            // formInputs.guard_list.forEach((itm)=>{
            //   if(guardsList.data[i].guard_id == itm.guard_id){
            //     newGuardList = [...newGuardList , {
            //       ...itm,
            //       guard_type : e.target.value ? e.target.value :""
            //     }]
            //   }else{
            //     newGuardList = [...newGuardList , itm]
            //   }
            // })


            // setInput({
            //   ...formInputs,
            //   guard_list : newGuardList
            // })
          }} class="form-select" aria-label="Default select example">
            {/* <option selected={'guard_type' in row ? row.guard_type == "" ? true : false : true} value="" >Select Guard Type</option> */}
            {Enums.GuardTypesDropDown.map((itm) => {
              // return (<option selected={'guard_type' in row ? row.guard_type == itm.value ? true : false : false} value={itm.value}>{itm.value}</option>)
              return (<option selected={guardTypes[row.guard_id] == itm.value ? true : false } value={itm.value}>{itm.value}</option>)

            })}

          </select>

        </>)
      },
      sortable: false,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      sortField: "gender",
    },
  ];
  const handleSelect = (row) => {
    setSelectableRowSelected(true);
    let guardDetails =  formInputs.guard_list.filter((obj) => obj.guard_id ==  row.guard_id)
    if(guardDetails.length){
      return true 
    }else{
      return false
    }
  }
  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <DataTable
          paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
            columns={companyTblColumns}
            data={guardsList.data}
            progressPending={dataTableLoading}
            progressComponent={<NewLoader />}
            pagination
            paginationServer
            paginationTotalRows={companyTotalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
            selectableRowSelected={!selectableRowSelected ? (row) => handleSelect(row) : false}
            //   {
            //     (row) => {
            //     console.log(formInputs.guard_list.includes(row.guard_id));
            //     // formInputs.guard_list.includes(row.guard_id);
            //     return formInputs.guard_list.includes(row.guard_id);
            //   }
            // }
            selectableRows
            onSelectedRowsChange={(state) => onSelectedRowsChange(state)}
          />
          {/* {console.log(formInputsErrors)} */}
          {formInputsErrors?.guard_list && (
            <Row>
              <span className="error">{formInputsErrors.guard_list}</span>
            </Row>
          )}
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15"
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
