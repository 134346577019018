import React, { useState } from "react";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { getBase64 } from "../../helpers/imageHelper";

export default function ImgInput({
  title,
  input_ref_id,
  value,
  onChange,
  error,
  accept_file_type,
  style,
  img_src,
  setCLogoImgSize,
}) {
  const [localError, setLocalError] = useState("");
  return (
    <div className={`${style?.div ? style.div : "col-md-4 col-12"}`}>
      <div className="card border shadow-none mb-3 app-file-info">
        <div className="p-3 text-center">
          <div id="profile_pic_1_preview" className="image-fixed mb-3">
            <img
              className="obj_fit_img rounded"
              src={img_src == null ? DefaultImg : img_src}
              alt=""
              height="150"
              width="150"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = DefaultImg;
              }}
            />
          </div>
          <div className="form-group add-new-file text-center mb-0">
            <label
              for={input_ref_id}
              className="btn btn-primary btn-block glow d-block add-file-btn text-capitalize"
            >
              Select File
            </label>
            <input
              type="file"
              className="d-none"
              id={input_ref_id}
              accept={`${
                accept_file_type != ""
                  ? accept_file_type
                  : "image/x-png, image/jpeg"
              }`}
              onChange={(e) => {
                if (title === "Company Logo") {
                  setCLogoImgSize(e.target.files[0]?.size);
                }
                if (
                  e.target.files[0]?.name?.match(/\.(jpg|jpeg|png|svg|webp)$/)
                ) {
                  if (e.target.files[0]?.size <= 5242880) {
                    // if ((e.target.files[0]?.size / 1000000).toFixed(2) <= 5) {
                    setLocalError("");
                    getBase64(e.target.files[0])
                      .then((result) => {
                        onChange(result);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  } else {
                    setLocalError("Maximum allowed file size is 5MB.");
                  }
                } else {
                  setLocalError(
                    "Only jpg, jpeg, png, svg and webp files are supported."
                  );
                  onChange("");
                }
              }}
            />
            <label className="form-label-input mt-3 text-break">{title}</label>
          </div>
        </div>
      </div>

      {localError && <span className="error">{localError}</span>}
      {error && <span className="error mt-2">{error}</span>}
    </div>
  );
}
