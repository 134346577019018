import React, { useLayoutEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiRoutes, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';
import { Breadcrumb, Card, Col, Button, Spinner, Row } from "react-bootstrap";
import { contractStatusEnum, ComplaintEnum } from "../../constants";
import NoImage from "../../assets/Images/Icon/no_image.png"
import { commonService } from "../../services/common.service";
import NewLoader from '../../components/loader/NewLoader';

export default function View() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [visitorDetails, setVisitorDetails] = useState(null);
    // const [actionLoading, setActionLoading] = useState(false);
    const [loadingVariant, setLoadingVariant] = useState(null);
    const fetchVisitorDetails = async (visitor_id) => {
        setLoading(true)
        let res = await APICall(ApiRoutes.visitorDetails, { visitor_id: visitor_id })
        if (res.status == 1) {
            setLoading(false)
            setVisitorDetails(res.data)
        }
    }
    useLayoutEffect(() => {
        if (params.visitor_id) {
            fetchVisitorDetails(params.visitor_id)
        }
    }, []);

    const getLocation = () => {
        return (
            visitorDetails.contract_details.address1 +
            ", " +
            (visitorDetails.contract_details.address2 ? visitorDetails.contract_details.address2 + ", " : "") +
            visitorDetails.contract_details.city +
            ", " +
            visitorDetails.contract_details.state +
            ", " +
            visitorDetails.contract_details.country +
            " - " +
            visitorDetails.contract_details.postal_code
        );
    };

    const getClientLocation = () => {
        let str = (visitorDetails.client_details.address1 ? visitorDetails.client_details.address1 + ", " : "") +
            (visitorDetails.client_details.address2 ? visitorDetails.client_details.address2 + ", " : "") +
            (visitorDetails.client_details.city ? visitorDetails.client_details.city + ", " : "") +
            (visitorDetails.client_details.state ? visitorDetails.client_details.state + ", " : "") +
            (visitorDetails.client_details.country ? visitorDetails.client_details.country + ", " : "") +
            (visitorDetails.client_details.postal_code ? visitorDetails.client_details.postal_code + ", " : "");
        str = str.trim();
        str = str.slice(0, -1);
        if (str.length && str && str !== "null") {
            return str;
        } else {
            return "-";
        }
    }

    if (!loading) {
        return (<>
            <div className="content-header row">
                <div className="content-header-left col-md-9 col-12 mb-2">
                    <div className="breadcrumbs-top d-flex align-items-center">
                        <h2 className="content-header-title float-start mb-0">
                            Visitor
                        </h2>
                        <Breadcrumb className="breadcrumb-chevron">
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item href="/visitors">Reports</Breadcrumb.Item>
                            <Breadcrumb.Item href="/visitors">Visitors</Breadcrumb.Item>
                            <Breadcrumb.Item active>Visitor Details</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </div>
            <Row className='contract-company-view'>
                <div className='row'>
                    <div className='col-8'>
                        <Card>
                            <Card.Header>
                                <h4 className="card-title">Visitor Details</h4>

                            </Card.Header>
                            <Card.Body>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Visitor Name :</dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.visitor_name}
                                    </dd>
                                </dl>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Vehicle Number  :</dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.visitor_vehicle_number}
                                    </dd>
                                </dl>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Visiting Address :</dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.visiting_address}
                                    </dd>
                                </dl>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Remark :</dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.remark}
                                    </dd>
                                </dl>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Contact No. :</dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.visitor_phone}
                                    </dd>
                                </dl>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Time In:
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {commonService.convertUTCToLocal(visitorDetails.visit_time_in, 'HH:mm')}
                                    </dd>
                                </dl>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Time Out:
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {commonService.convertUTCToLocal(visitorDetails.visit_time_out, 'HH:mm')}
                                    </dd>
                                </dl>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Visited In Date:
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {commonService.convertUTCToLocal(visitorDetails.created_at)}
                                    </dd>
                                </dl>

                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Visited Out Date:
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {commonService.convertUTCToLocal(visitorDetails.visit_time_out)}
                                    </dd>
                                </dl>
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Submitted By:
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.association_name}
                                    </dd>
                                </dl>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header>
                                <h4 className="card-title">Contract Details</h4>
                                <div>
                                    <Button size="sm" variant="primary me-3" onClick={() => { navigate("/contracts/view", { state: { id: visitorDetails.contract_id } }) }}> View Contract </Button>
                                </div>
                            </Card.Header>
                            <Card.Body >
                                <dl className="mb-0 row">
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property Name :</dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.contract_details.property_name}
                                    </dd>
                                    {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property Manager :</dt>
                        <dd className="mb-2 col-sm-12 col-md-8 col-lg-6"> 
                          {visitorDetails.contract_details.property_manager}
                        </dd> */}
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Service Hours :
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.contract_details.contract_service_hours}
                                    </dd>
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Start Date :
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {commonService.convertUTCToLocal(visitorDetails.contract_details.start_date)}
                                    </dd>
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Expiry Date :
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {commonService.convertUTCToLocal(visitorDetails.contract_details.end_date)}
                                    </dd>
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Status :</dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {visitorDetails.contract_details.contract_status &&
                                            contractStatusEnum[visitorDetails.contract_details.contract_status]}
                                    </dd>
                                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                                        Property Location :
                                    </dt>
                                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                                        {getLocation()}
                                    </dd>
                                </dl>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-4'>
                        <Card>
                            <Card.Header>
                                <h4 className="card-title">Property Manager Details</h4>
                            </Card.Header>
                            <Card.Body>
                                <div className="company-profile-avatar">
                                    {/* <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
                                        <span className="w-100 h-100 avatar-content">
                                            <img className="w-100 h-100 obj-fit-cover" src={visitorDetails.client_details.profile_img ? process.env.REACT_APP_ASSETS_URL + visitorDetails.client_details.profile_img : NoImage}></img>
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column align-items-center text-center user-info">
                                        <span onClick={() => { navigate(`/clients/view/${visitorDetails.client_details.client_id}`) }} className="cursor-pointer mb-2 text-primary h4">{visitorDetails.client_details.client_name}</span>
                                        <span className="text-capitalize badge bg-light-primary">
                                            #Property Manager
                                        </span>
                                    </div> */}
                                </div>
                                <div className="company-details mt-1">
                                     <div className="left-field nav-link p-0"><i className="fa-solid fa-user text-primary me-2"></i>
                                        <span onClick={() => { navigate(`/clients/view/${visitorDetails.client_details.client_id}`) }} className='cursor-pointer'>{visitorDetails.client_details.client_name}</span> 
                                     </div>
                                </div>
                                <div className="company-details mt-1">
                                    <div className="left-field"><i className="fa-solid fa-envelope text-primary me-2"></i>
                                        {visitorDetails.client_details.email}
                                    </div>
                                </div>
                                <div className="company-details mt-1">
                                    <div className="left-field"><i className="fa-solid fa-phone text-primary me-2"></i>
                                        {visitorDetails.client_details.phone}
                                    </div>
                                </div>

                                <div className="company-details mt-1">
                                    <div className="left-field"><i className="fa-solid fa-map-marker text-primary me-2"></i>
                                        {getClientLocation()}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Row>
        </>)
    } else {
        return <NewLoader/>
    }

}
