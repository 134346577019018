import React, { useState } from "react";
import { Button, FormControl, Modal, Spinner } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { ApiRoutes, Enums } from "../../constants";
import { APICall } from "../../services/axiosService";
import InputText from "../../components/form/inputText";

export default function AddCheckPointModal({
  getDetails,
  contractId,
  showAddCheckpoint,
  setShowAddCheckpoint,
}) {
  const [checkpointError, setCheckpointError] = useState("");
  const [checkpointNumberError, setCheckpointNumberError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [totalCheckPoints, setTotalCheckPoints] = useState("");
  const [checkPoinsView, setCheckPoinsView] = useState(false);

  const [checkPoints, setCheckPoints] = useState({});
  const [checkPointsErrors, setCheckPointsErrors] = useState({});

  const closeAddCheckPointPopUp = () => {
    setShowAddCheckpoint(false);
    setCheckpointError("");
    setTotalCheckPoints("");
    setCheckPoinsView(false);
    setCheckPoints({});
    setCheckPointsErrors({});
  };
  const handleViewCheckPointsView = () => {
    if (totalCheckPoints != "") {
      setCheckpointNumberError("");
      setCheckPoinsView(true);
    } else {
      setCheckpointNumberError("This feild is required.");
    }
  };
  const handlePrevious = () => {
    setCheckPoinsView(false);
    setCheckPoints({});
    setCheckPointsErrors({});
    setTotalCheckPoints("");
  };
  const handleAddCheckPoints = async () => {
    let jsonArrayErrors = {};
    let isValidate = true;
    Array.from(Array(parseInt(totalCheckPoints)).keys()).forEach((i) => {
      if (checkPoints[i + 1]) {
        if (checkPoints[i + 1] == "") {
          jsonArrayErrors = {
            ...jsonArrayErrors,
            [i + 1]: "This feild is required.",
          };
          isValidate = false;
        }
      } else {
        jsonArrayErrors = {
          ...jsonArrayErrors,
          [i + 1]: "This feild is required.",
        };
        isValidate = false;
      }
    });
    setCheckPointsErrors(jsonArrayErrors);
    if (isValidate) {
      let checkPointsData = [];
      Array.from(Array(parseInt(totalCheckPoints)).keys()).forEach((i) => {
        checkPointsData.push(checkPoints[i + 1]);
      });
      setIsLoading(true);
      let res = await APICall(ApiRoutes.addCheckPoint, {
        contract_id: contractId,
        checkpoint_list: checkPointsData,
      });
      if (res.status == 1) {
        closeAddCheckPointPopUp();
        getDetails();
        setIsLoading(false);
      }
      window.location.reload();
    }
  };
  return (
    <Modal
      className="modal-popup"
      show={showAddCheckpoint}
      onHide={closeAddCheckPointPopUp}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Checkpoints</Modal.Title>
      </Modal.Header>
      {!checkPoinsView ? (
        <>
          <Modal.Body>
            <FormControl
              type="text"
              title={"Enter Number of Checkpoints"}
              value={totalCheckPoints}
              size="12"
              maxLength={2}
              onChange={(e) => {
                if (
                  e.target.value &&
                  e.target.value !== "" &&
                  parseInt(e.target.value) < 51
                ) {
                  setTotalCheckPoints(e.target.value);
                } else {
                  setTotalCheckPoints("");
                }
              }}
              validationText={checkpointNumberError}
              required
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />

            {/* <DropDown
            style={{ div: "col-md-12 col-12" }}
            data={checkPoints}
            title={"Checkpoint"}
            value={checkPoints.value}
            isMulti={true}
            onChange={(value) => setSelectedCheckPoints(value)}
            required
            validationText={checkpointError}
          /> */}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleViewCheckPointsView} variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body>
            <div>
              {[...Array(parseInt(totalCheckPoints))].map(
                (totalCheckPoint, index) => {
                  return (
                    <InputText
                      type={"text"}
                      title={`Title of checkpoint ${index + 1}`}
                      value={checkPoints[index + 1]}
                      size="12"
                      onChange={(value) => {
                        setCheckPoints({
                          ...checkPoints,
                          [index + 1]: value,
                        });
                      }}
                      validationText={checkPointsErrors[index + 1]}
                      required
                    />
                  );
                }
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handlePrevious}>
              Previous
            </Button>
            {!isLoading ? (
              <>
                <Button variant="primary" onClick={handleAddCheckPoints}>
                  Save
                </Button>
              </>
            ) : (
              <Button variant="primary">
                <Spinner animation="border" size="sm" /> Loading...
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
      {/*  */}
    </Modal>
  );
}
