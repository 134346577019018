import React, { useState } from 'react'
import { useLayoutEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { ApiRoutes } from '../../../constants';
import { APICall } from '../../../services/axiosService';
import DataTable from "react-data-table-component";
import { useEffect } from 'react';
import Switch from "react-switch";
import InputText from '../../../components/form/inputText';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';
import { Enums } from "../../../constants/enums";
import NewLoader from '../../../components/loader/NewLoader';

export default function Index() {
    const [propertyCategories, setPropertyCategories] = useState({
        isData: false,
        data: [],
    })
    const [total, setTotal] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
    const [dataTableLoading, setDataTableLoading] = useState(false);
    const [filterStatus, setFilterStatus] = useState(null);
    const [txtSearch, setTxtSearch] = useState("");

    const [showAddCategory, setShowAddCategory] = useState(false);

    const [propCatId, setPropCatId] = useState(null);
    const [showEditCategory, setShowEditCategory] = useState(false);

    const fetchPropertyCategories = async (column = "", sortDirection = "", isFilter = 0) => {
        setDataTableLoading(true)
        let res = await APICall(ApiRoutes.fetchPropertyCategories, {
            page: pageNo,
            limit: perPageRecords,
            order: { column: column, dir: sortDirection },
            is_filter: isFilter,
            filter: {
                search_text: txtSearch,
                status: filterStatus,
            },
        })
        if (res.status == 1) {
            setPropertyCategories({ isData: true, data: res.data.list });
            setTotal(res.data.total)
            setDataTableLoading(false)
        }
        
    }

    useLayoutEffect(() => {
        fetchPropertyCategories()
    }, [])


    useEffect(() => {
        fetchPropertyCategories("", "", 1);
    }, [pageNo, perPageRecords]);


    const handleSort = async (column, sortDirection) => {
        fetchPropertyCategories(column.sortField, sortDirection, 1);
    };

    const handleFilterStatus = (value) => {
        if (value) {
            setFilterStatus(parseInt(value));
        } else {
            setFilterStatus(null);
        }
    }
    const onSwitchToggle = async (value, item, index) => {
        const data = await APICall(ApiRoutes.PropertyCategoryStatusUpdate, {
            id: item.term_id,
            is_active: value  ? 1 : 0,
        });
        if (data) {
            setPropertyCategories((prevState) => ({
                ...prevState,
                data: prevState.data.map((MapItem, MapIndex) => ({
                    ...MapItem,
                    is_active:
                        MapIndex === index ? (value ? 1 : 0) : MapItem.is_active,
                })),
            }));
        }
    };

    useEffect(() => {
        fetchPropertyCategories("", "", 1);
    }, [txtSearch]);

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPageRecords(newPerPage);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const columns = [
        {
            name: "Property Type Code",
            selector: (row) => row.term_code,
            sortable: true,
            sortField: "term_code",
        },
        {
            name: "Property Type Name",
            selector: (row) => row.term,
            sortable: true,
            sortField: "term",
        },
        {
            name: "Property Type Details",
            selector: (row) => row.term_detail,
            sortable: true,
            sortField: "term_detail",
        },
        {
            name: "Status",
            selector: (row) => parseInt(row.is_active),
            sortable: true,
            sortField: "is_active",
            cell: (row, index, column, id) => {
                return (
                    <span className="justify-content-center align-items-center d-flex">
                        <Switch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={(value) => onSwitchToggle(value, row, index)}
                            checked={parseInt(row.is_active) === 1 ? true : false}
                            height={20}
                            width={40}
                            onColor="#263A96"
                        />
                    </span>
                );
            },
        },
        // {
        //   name: "Created On",
        //   selector: (row) => row.created_at,
        //   sortable: true,
        //   sortField: "created_at",
        //   cell: (row, index, column, id) =>
        //     commonService.convertUTCToLocal(row.created_at),
        // },
        {
            name: "Action",
            selector: (row) => row.year,
            cell: (row, index, column, id) =>
                <div className="justify-content-center align-items-center d-flex">
                    <div
                        className='cursor-pointer'
                        onClick={() => { setShowEditCategory(true) ; setPropCatId(row.term_id)}}
                    >
                        <i className="fa fa-edit text-primary"></i>
                    </div>
                </div>
            ,
        },
    ];

    return (<>
        <AddCategory fetchPropertyCategories={fetchPropertyCategories} showAddCategory={showAddCategory} setShowAddCategory={setShowAddCategory}/>
       
        <EditCategory fetchPropertyCategories={fetchPropertyCategories}  showEditCategory={showEditCategory} setShowEditCategory={setShowEditCategory} propCatId={propCatId} setPropCatId={setPropCatId}/>
        <div className="row">
            <div className="content-header row">
                <div className="content-header-left col-md-12 col-12 mb-2 ">
                    <div className="breadcrumbs-top d-flex align-items-center d-flex justify-content-between">
                        <h2 className="content-header-title float-start mb-0">
                        </h2>
                        <div onClick={() => {
                            setShowAddCategory(true);
                        }
                        } className='float-end'>
                            <Button variant="primary">
                                <i className="fa-regular fa-plus"></i>
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <DataTable
        paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
            columns={columns}
            data={propertyCategories.data}
            progressPending={dataTableLoading}
            progressComponent={<NewLoader />}
            pagination
            paginationServer
            paginationTotalRows={total}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
            paginationPerPage={perPageRecords}
            actions={<>
                <div className="col-4">
                    <InputText
                        formGroupClass={"mb-0"}
                        size={'12'}
                        type={"text"}
                        Label={false}
                        placeholder={"Search..."}
                        value={txtSearch}
                        onChange={(value) => {
                            if (value) {
                                setTxtSearch(value)
                            } else {
                                setTxtSearch(value)
                            }
                        }}
                    />
                </div>
            </>}
        />

    </>)
}
