import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "../../constants";
import { Breadcrumb, Button, InputGroup, Badge } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import Export from "../../components/common/Export";
import { Enums } from "../../constants/enums";
import NewLoader from "../../components/loader/NewLoader";

export default function Index() {
  const navigate = useNavigate();
  const userType = JSON.parse(
    commonService.getItem("user")
  ).association_type_term;
  const [pageNo, setPageNo] = useState(1);
  const [perPageRecords, setPerPageRecords] = useState(
    Enums.DefaultPerPageLength
  );
  const [invoiceFrom, setInvoiceFrom] = useState(null);
  const [invoiceTo, setInvoiceTo] = useState(null);
  const [invoiceTotalRows, setInvoiceTotalRows] = useState(0);
  const [invoiceList, setInvoiceList] = useState({
    isData: false,
    data: [],
  });
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [txtSearch, setTxtSearch] = useState("");
  const [propertyList, setPropertyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedClientList, setSelectedClientList] = useState([]);
  const [selectedPropertyList, setSelectedPropertyList] = useState([]);

  // React.useEffect(() => {
  //     getInvoiceList();
  // }, [pageNo, perPageRecords]);

  useEffect(() => {
    getInvoiceList("", "", 1);
  }, [txtSearch, pageNo, perPageRecords]);

  const fetchClients = async () => {
    const res = await APICall(ApiRoutes.getInvoiceClients);
    if (res.status == 1) {
      setClientList(
        res.data.map((mapitem) => ({
          value: mapitem.client_name,
          label: mapitem.client_name,
        }))
      );
    }
  };
  const fetchProperties = async () => {
    const res = await APICall(ApiRoutes.getInvoiceProperties);

    if (res.status === 1) {
      setPropertyList(
        res.data.map((mapitem) => ({
          value: mapitem.property_name,
          label: mapitem.property_name,
        }))
      );
    }
  };
  useEffect(() => {
    fetchClients();
    fetchProperties();
  }, []);
  async function getInvoiceList(column = "", sortDirection = "", isFilter = 0) {
    setDataTableLoading(true);
    setInvoiceList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.getInvoices, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          invoiceFrom != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(invoiceFrom) + " 00:00:00"
              )
            : null,
        end_date:
          invoiceTo != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(invoiceTo) + " 23:59:59"
              )
            : null,
        client_list: selectedClientList,
        property_list: selectedPropertyList,
      },
    });

    if (requestMetadata) {
      setInvoiceList({ isData: true, data: requestMetadata.data.list });

      setInvoiceTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
  }

  const handleSort = async (column, sortDirection) => {
    getInvoiceList(column.sortField, sortDirection, 1);
  };

  const searchDateWise = (e) => {
    e.preventDefault();
    let isValidate = true;
    if (invoiceTo || invoiceFrom) {
      if (invoiceTo && invoiceFrom) {
        const dateFrom = new Date(invoiceTo);
        const dateTo = new Date(invoiceFrom);
        if (dateFrom < dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getInvoiceList("", "", 1);
      setInvoiceTotalRows(1);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const resetFilter = () => {
    setSelectedClientList([]);
    setSelectedPropertyList([]);
    setInvoiceTo(null);
    setTxtSearch("");
    setInvoiceFrom(null);
    getInvoiceList("", "", 0);
  };

  const invoiceTblColumns = [
    {
      name: "Contract/Property",
      selector: (row) => (
        <span
          className="cursor-pointer text-primary"
          onClick={() =>
            navigate(`/contracts/view`, {
              state: {
                id: row.contract_id,
              },
            })
          }
        >
          {row.property_name}
        </span>
      ),
      sortable: true,
      sortField: "property_name",
    },
    {
      name: "Property Manager",
      selector: (row) => (
        <span
          className="cursor-pointer text-primary"
          onClick={() => navigate(`/clients/view/${row.client_id}`)}
        >
          {row.client_name}
        </span>
      ),
      sortable: true,
      sortField: "client_name",
    },
    {
      name: "Invoice Document",
      selector: (row) => (
        <a
          target="_blank"
          href={process.env.REACT_APP_ASSETS_URL + row.invoice_doc}
        >
          {" "}
          Invoice preview{" "}
        </a>
      ),
      sortable: false,
      sortField: "invoice_doc",
    },
    {
      name: "Invoice Date",
      sortable: true,
      sortField: "created_at",
      cell: (row) =>
        commonService.convertUTCToLocal(row.created_at, "Do MMMM, YYYY"),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Invoice List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/invoices">Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Invoices</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div>
          <Card>
            <Card.Body className="p-3">
              <div className="row">
                <div className="col-2">
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={invoiceFrom ? invoiceFrom : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Start Date"
                      onChange={(date) => {
                        if (date) {
                          setInvoiceFrom(date);
                        } else {
                          setInvoiceFrom(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col-2">
                  <div>
                    <InputGroup>
                      <DatePicker
                        className="datepicker form-control"
                        selected={invoiceTo ? invoiceTo : ""}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="End Date"
                        onChange={(date) => {
                          if (date) {
                            setInvoiceTo(date);
                          } else {
                            setInvoiceTo(null);
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                {userType != "" &&
                userType != "undefined" &&
                userType != Enums.UserRole.client ? (
                  <div className="col-3">
                    <DropDown
                      data={clientList}
                      value={selectedClientList}
                      style={{ div: "col-12" }}
                      isMulti={true}
                      onChange={(value) => setSelectedClientList(value)}
                      placeholder="Property Manager"
                      required
                      // validationText={formInputsErrors.duration_term}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="col-3">
                  <DropDown
                    value={selectedPropertyList}
                    data={propertyList}
                    style={{ div: "col-12" }}
                    isMulti={true}
                    onChange={(value) => setSelectedPropertyList(value)}
                    placeholder="Contract/Property"
                    required
                    // validationText={formInputsErrors.duration_term}
                  />
                </div>
                <div className="col-2">
                  <div className="d-flex">
                    <div className="me-2">
                      <Button
                        onClick={searchDateWise}
                        variant="outline-primary"
                        type="button"
                      >
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                    <div className="ms-">
                      <Button
                        onClick={resetFilter}
                        variant="outline-primary"
                        type="button"
                      >
                        <i className="fa fa-refresh"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="z-adjust-for-dropdown hideDiv">
        <DataTable
        paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
          columns={invoiceTblColumns}
          data={invoiceList.data}
          progressPending={dataTableLoading}
          progressComponent={<NewLoader />}
          pagination
          paginationServer
          paginationTotalRows={invoiceTotalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSort={handleSort}
          paginationPerPage={perPageRecords}
          actions={
            <>
              <div className="col-4">
                <InputText
                  formGroupClass={"mb-0"}
                  size={"12"}
                  type={"text"}
                  Label={false}
                  placeholder={"Search..."}
                  value={txtSearch}
                  onChange={(value) => {
                    if (value) {
                      setTxtSearch(value);
                    } else {
                      setTxtSearch(value);
                    }
                  }}
                />
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
