import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, InputGroup , Form , Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { badgesEnum } from "../../constants";
import InputText from "../../components/form/inputText";
// import FilterComponent from "../../components/common/FilterComponent";
import { ApiRoutes } from "../../constants/apiroutes";
// import NoDataFound from "../../components/common/NoDataFound";
import { Enums } from "../../constants/enums";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { commonService } from "../../services/common.service";
import { alertService, AlertType } from "../../services/alert.service";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import NewLoader from "../../components/loader/NewLoader";


function Index() {
  const userType = JSON.parse(commonService.getItem("user")).association_type_term;
  const [txtSearch, setTxtSearch] = useState("");
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [clientTotalRows, setClientTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [clientList, setClientList] = useState({
    isData: false,
    data: [],
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const clientTblColumns = [
    // {
    //   name: "Profile",
    //   selector: (row) => row.client_name,
    //   sortable: false,
    //   width: "110px",
    //   cell: (row, index, column, id) => {
    //     return (
    //       <img
    //         src={
    //           row.client_profile_img
    //             ? process.env.REACT_APP_ASSETS_URL + row.client_profile_img
    //             : DefaultImg
    //         }
    //         className="round"
    //         alt="user"
    //         width="40"
    //         style={{ objectFit: "cover" }}
    //         height="40"
    //       />
    //     );
    //   },
    // },
    {
      name: "Property Manager",
      selector: (row) => <div className="cursor-pointer text-primary" onClick={()=>{
        navigate(`view/${row.client_id}`);
      }}> {row.client_name}</div>,
      sortable: true,
      sortField: "client_name",
    },
   
    {
        name: "Email",
        selector: (row) => row.client_email,
        sortable: true,
        sortField: "client_email",
    },
    {
        name: "Mobile No.",
        selector: (row) => row.client_phone,
        sortable: true,
        sortField: "client_phone",
    },
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
      sortField: "position",
    },
    {
      name: "Total Contracts",
      selector: (row) => row.total_contracts,
      sortable: true,
      omit : userType === Enums.UserRole.admin ? false : true , 
      sortField: "total_contracts",
    },
    {
      name: "Created On",
      selector: (row) =>  commonService.convertUTCToLocal(row.created_at),
      sortable: true,
      omit : userType === Enums.UserRole.admin ? false : true , 
      sortField: "created_at",
    },
    {
        name: "Action",
        selector: (row) => row.contract_id,
        cell: (row, index, column, id) => (
          <div className="justify-content-center align-items-center d-flex">
                <i   onClick={() => {
                navigate(`view/${row.client_id}`);
              }} className="cursor-pointer fa fa-eye text-primary me-2"></i>
                 { userType == Enums.UserRole.admin ? <i onClick={(e) => {
                  e.preventDefault()
                  handleDelete(e , row.client_id)
              }} className="cursor-pointer fa fa-trash text-danger"></i> :""}
            
          </div>
        ),
      },
  ];

  const navigate = useNavigate();

  React.useEffect(() => {
        getClientList();
  }, [txtSearch,pageNo, perPageRecords]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };
  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const resetFilter = () => {
    setFromDate(null)
    setToDate(null)
    setTxtSearch("")
    getClientList("", "", 0);
  };

  const searchData = (e) => {
    e.preventDefault();

    let isValidate = true;
    if (toDate || fromDate) {
      if (toDate && fromDate) {
        const dateFrom = new Date(fromDate);
        const dateTo = new Date(toDate);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getClientList("", "", 1);
      setClientTotalRows(1)
    }
  };

  // React.useEffect(() => {
  //   getClientList("", "", 1);
  // }, [txtSearch]);

  async function getClientList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setDataTableLoading(true);

    setClientList({ isData: false, data: [] });

    const requestMetadata = await APICall(ApiRoutes.ClientList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          fromDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(fromDate) + " 00:00:00"
            )
            : null,
        end_date:
          toDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(toDate) + " 23:59:59"
            )
            : null,
      },
    });
    if (requestMetadata) {
      setClientList({ isData: true, data: requestMetadata.data.list });

      setClientTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
    
  }

  const handleSort = async (column, sortDirection) => {
    getClientList(column.sortField, sortDirection , 1);
  };

  useEffect(() => {
    getClientList("", "", 1);
  }, [txtSearch]);



  const handleDelete = async (e, client_id) => {

    e.preventDefault();

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      let res = await APICall(ApiRoutes.ClientDelete, {
        client_id: client_id
      })
      if (res.status === 1) {
        
        alertService.alert({
          type: AlertType.Success,
          message: res.message,
        });
        getClientList("", "", 1);
      }
    }
  }

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Property Manager List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Property Manager</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>

{/* 
        <div>
          <Card>

          </Card>
        </div> */}


      
      </div>
      <div>
        <Card>
          <Card.Body className="p-3">
            <div className="row">

              <div className="col-3">
                <InputGroup>
                  <DatePicker
                    className="datepicker form-control"
                    selected={fromDate ? fromDate : ""}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Date"
                    onChange={(date) => {
                      if (date) {
                        setFromDate(date);
                      } else {
                        setFromDate(null);
                      }
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-3">
                <div>
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={toDate ? toDate : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="End Date"
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        } else {
                          setToDate(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

              </div>
              <div className="col-3">
                <div className="d-flex">
                  <div className="me-2">
                    <Button
                      onClick={searchData}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </Card.Body>
        </Card>
      </div>
      <DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
        columns={clientTblColumns}
        data={clientList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={clientTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
          <div className="col-4">
            <InputText
              formGroupClass={"mb-0"}
              size={'12'}
              type={"text"}
              Label={false}
              placeholder={"Search..."}
              value={txtSearch}
              onChange={(value) => {
                if (value) {
                  setTxtSearch(value)
                } else {
                  setTxtSearch(value)
                }
              }}
            />
          </div>
        </>}
      />
     
     
    </>
  );


}

export default Index;