import React, { createRef, useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as ToggleIcon } from "../../assets/Images/Header/toggle.svg";
import { ReactComponent as EditHeaderMenu } from "../../assets/Icons/editHeaderMenu.svg";
import { ReactComponent as LogoutMenu } from "../../assets/Icons/logoutIcon.svg";
import { ReactComponent as ChangePassword } from "../../assets/Icons/changePassword.svg";
import { Enums } from "../../constants/enums";
import { commonService } from "../../services/common.service";
import "./Header.css";
import profileimg from "../../assets/Images/Profile/avatar_placeholder.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { APICall } from "../../services/axiosService";
import { ApiRoutes } from "../../constants/apiroutes";
import { Button, Modal } from "react-bootstrap";
import emergencyTone from "../../assets/sostone/honk-alarm-repeat-loop-101015.mp3";

// import { getTokenfun , onMessageListener } from "../../firebaseinit"

export default function Header({ onHeaderButtonClick }) {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showSosModal, setShowSosModal] = useState(false);

  // onMessageListener()
  // .then((payload) => {
  //   console.log("Payload on meesage" , payload)
  //   payload.notification['notification_description'] = payload.notification.body
  //   if(notifications){
  //     setNotifications([
  //       ...notifications,
  //       payload.notification
  //     ])
  //   }
  //   else{
  //     setNotifications(payload.notification)
  //   }
  // })
  // .catch((err) => console.log('failed: ', err));

  // onBackgoundMessageListener()
  // .then((payload) => {
  //   console.log("Background Payload on meesage" , payload)
  //   // payload.notification['notification_description'] = payload.notification.body

  // })
  // .catch((err) => console.log('failed: ', err));

  let authUserDtl = commonService.getItem("user");
  if (authUserDtl !== "") {
    authUserDtl = JSON.parse(authUserDtl);
  }
  async function logOut(e) {
    e.preventDefault();
    // console.log("logOut" , ApiRoutes.logout)
    // let data = await APICall(ApiRoutes.logout);
    // console.log("data" , data)
    // if(data.status == 1){
    localStorage.clear();
    // }
    if (authUserDtl.association_type_term !== Enums.UserRole.client) {
      navigate("/portal/login");
    } else {
      navigate("/");
    }
  }

  const handleGotoProfile = () => {
    if (authUserDtl != "" && authUserDtl != "undefined") {
      navigate("profile/edit", {
        state: { user_role: authUserDtl.association_type_term },
        replace: true,
      });
    }
  };

  const handleGotoProfileView = () => {
    if (authUserDtl != "" && authUserDtl != "undefined") {
      if (authUserDtl.association_type_term == "branch_manager") {
        navigate(`branch/view/${authUserDtl.association_id}`, {
          state: { is_profile: true },
        });
      } else if (authUserDtl.association_type_term == "company") {
        navigate(`company/view/${authUserDtl.association_id}`, {
          state: { is_profile: true },
        });
      } else if (authUserDtl.association_type_term == "client") {
        navigate(`/clients/view/${authUserDtl.association_id}`, {
          state: { is_profile: true },
        });
      }
    }
  };

  const handleGotoChangePassword = () => {
    navigate("/change/password", { replace: true });
  };

  const fetchNotificatons = async () => {
    let res = await APICall(ApiRoutes.unreadNotifications);
    if (res.status === 1) {
      setNotifications(res.data.list);
    }
  };
  useEffect(() => {
    let fetchNotInterval = setInterval(() => {
      fetchNotificatons();
    }, 5000);
    return () => {
      clearInterval(fetchNotInterval);
    };
  }, []);

  /*
   * SOS Notification Functionality.
   */

  const audioRef = useRef(null);
  const [sosDetails, setSosDetails] = useState(null);
  const [sosData, setSosData] = useState(null);
  const [isSOS, setIsSOS] = useState(false);

  const AcknowledgeFunc = async () => {
    let inputData = JSON.parse(notifications[0]?.data);
    let sosReqId = inputData?.sos_req_id;

    let resp = await APICall(ApiRoutes.acknowledgeSosRequest, {
      sos_req_id: sosReqId,
    });
    if (resp.status == 1) {
      handleCloseSoSModal();
    }
  };

  const handleCloseSoSModal = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setShowSosModal(false);
  };

  useEffect(() => {
    if (
      notifications?.length > 0 &&
      notifications[0]?.notification_type === "sos_request_alert"
    ) {
      setSosDetails(notifications[0]);
      setSosData(JSON.parse(notifications[0].data));
      setIsSOS(true);
    } else {
      setIsSOS(false);
    }
  }, [notifications]);

  useEffect(() => {
    const showModalInterval = setInterval(() => {
      if (isSOS) {
        setShowSosModal(true);
        if (sosDetails !== null) {
          playAudio();
        } else {
          pauseAudio();
        }
      }
    }, 25000);

    if (isSOS) {
      if (sosDetails !== null) {
        playAudio();
      } else {
        pauseAudio();
      }
      setShowSosModal(true);
    }

    return () => {
      clearInterval(showModalInterval);
    };
  }, [isSOS]);

  const playAudio = () => {
    if (audioRef.current) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.error("Autoplay error:", error);
        });
      }
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };
  // console.log("notifications.length ::",notifications.length);
   return (
    <>
      <Modal show={showSosModal} onHide={() => handleCloseSoSModal()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {sosDetails?.notification_title
              ? sosDetails?.notification_title
              : "N/A"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b> Contract/Property : </b>
          {sosData?.contract_property_name
            ? sosData?.contract_property_name
            : "N/A"}
        </Modal.Body>
        <Modal.Body>
          <b> Raised by : </b>
          {sosDetails?.notification_description
            ? sosDetails?.notification_description
            : "N/A"}
        </Modal.Body>
        <Modal.Body>
          <b> Created by : </b>

          {sosData?.sos_created_by ? sosData?.sos_created_by : "N/A"}
        </Modal.Body>
        <Modal.Body>
          <b> Description : </b>

          {sosData?.note ? sosData?.note : "N/A"}
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button variant="primary" onClick={() => AcknowledgeFunc()}>
            Acknowledge
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="header-navbar floating-nav navbar navbar-col navbar-shadow">
        <audio ref={audioRef} src={emergencyTone} loop />
        <div className="menu-toggle" onClick={() => onHeaderButtonClick()}>
          <ToggleIcon />
        </div>
        <div className="right-section">
          <Dropdown
            show={showDropDown}
            onToggle={() => {
              setShowDropDown(!showDropDown);
            }}
            className="dropdown-notification"
          >
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="nav-link nav-link-custom dropdown-user-link">
                <i className="fa-regular fa-bell"></i>
              </div>
              {notifications != null ? (
                // notifications.length > 0 ? (
                <span className="badge-up badge bg-danger rounded-pill">
                  {notifications.length}
                </span>
              ) : (
                // ) : (
                //   ""
                // )
                ""
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-media">
              <div className="d-flex dropdown-header">
                <h4 className="notification-title mb-0 me-auto">
                  Notifications
                </h4>
                {notifications != null ? (
                  notifications.length > 0 ? (
                    <div className="badge bg-light-primary rounded-pill">
                      {notifications.length} New
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
              <div className="scrollbar-container media-list scrollable-container ps ps--active-y">
                {notifications != null ? (
                  notifications.length > 0 ? (
                    notifications.map((notification, index) => {
                      return (
                        <div key={index}>
                          <div className="list-item d-flex align-items-start ">
                            {/* <div className="avatar me-3">
                          <img
                            src={profileimg}
                            className="notification-img"
                            alt="user"
                            width="32"
                            height="32"
                          />
                        </div> */}
                            <div
                              onClick={() => {
                                setShowDropDown(false);
                                navigate("notifications", { replace: true });
                              }}
                              className="list-item-body flex-grow-1 cursor-pointer"
                            >
                              {/* <p className="media-heading mb-0">
                            <span className="new-message"> { notification.notification_title }</span>
                          </p> */}
                              <small className="notification-text">
                                {notification.notification_description}
                              </small>
                              <div className="noti-date">
                                {commonService.convertUTCToLocal(
                                  notification.created_at
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-found-noti text-muted">
                      No notifications!
                    </div>
                  )
                ) : (
                  <div className="noti-loader-main">
                    <div className="skeleton"></div>
                    <div className="skeleton"></div>
                    <div className="skeleton"></div>
                  </div>
                )}
              </div>
              <div className="dropdown-menu-footer">
                <button
                  onClick={() => {
                    navigate("notifications", { replace: true });
                    setShowDropDown(false);
                  }}
                  className="btn btn-primary d-block w-100"
                >
                  Read all notifications
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="profile-section ml-10p">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="nav-link nav-link-custom dropdown-user-link">
                <div className="user-nav d-sm-flex d-none">
                  <span className="user-name">
                    {authUserDtl != "" && authUserDtl != "undefined"
                      ? authUserDtl.association_details != "undefined" &&
                        authUserDtl.association_details != false
                        ? (authUserDtl.association_type_term ==
                            Enums.UserRole.company &&
                            authUserDtl.association_details.company_name) ||
                          (authUserDtl.association_type_term ==
                            Enums.UserRole.branch_manager &&
                            authUserDtl.association_details.branch_name) ||
                          (authUserDtl.association_type_term ==
                            Enums.UserRole.client &&
                            authUserDtl.association_details.client_name)
                        : authUserDtl.username != null &&
                          authUserDtl.username != ""
                        ? authUserDtl.username.charAt(0).toUpperCase() +
                          authUserDtl.username.slice(1)
                        : authUserDtl.email.charAt(0).toUpperCase() +
                          authUserDtl.email.slice(1)
                      : ""}
                  </span>
                  <span className="user-status">
                    {authUserDtl != "" && authUserDtl != "undefined"
                      ? authUserDtl.association_type_term ==
                        Enums.UserRole.admin
                        ? "Admin"
                        : authUserDtl.association_type_term ==
                          Enums.UserRole.company
                        ? "Company"
                        : authUserDtl.association_type_term ==
                          Enums.UserRole.branch_manager
                        ? "Branch Manager"
                        : authUserDtl.association_type_term ==
                          Enums.UserRole.client
                        ? "Property Manager"
                        : ""
                      : ""}
                  </span>
                </div>
                <span className="user">
                  <img
                    src={
                      authUserDtl?.user_profile
                        ? process.env.REACT_APP_ASSETS_URL +
                          authUserDtl?.user_profile
                        : profileimg
                    }
                    className="round"
                    alt="user"
                    width="40"
                    height="40"
                    style={{ objectFit: "cover" }}
                  />
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* {(authUserDtl.association_type_term  == Enums.UserRole.client || authUserDtl.association_type_term  == Enums.UserRole.branch_manager || authUserDtl.association_type_term  == Enums.UserRole.company) && <Dropdown.Item onClick={handleGotoProfile}>
                <EditHeaderMenu />
                Edit Profile
              </Dropdown.Item> } */}

              {(authUserDtl.association_type_term ==
                Enums.UserRole.branch_manager ||
                authUserDtl.association_type_term == Enums.UserRole.company ||
                authUserDtl.association_type_term == Enums.UserRole.client) && (
                <Dropdown.Item onClick={handleGotoProfileView}>
                  <EditHeaderMenu />
                  View Profile
                </Dropdown.Item>
              )}

              {authUserDtl.association_type_term !== Enums.UserRole.client && (
                <Dropdown.Item onClick={handleGotoChangePassword}>
                  <ChangePassword />
                  Change Password
                </Dropdown.Item>
              )}
              <Dropdown.Item
                href={
                  authUserDtl.association_type_term !== Enums.UserRole.client
                    ? "/portal/login"
                    : "/"
                }
                onClick={logOut}
              >
                <LogoutMenu />
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}
