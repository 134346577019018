import { Form } from "react-bootstrap";

function RadioPage() {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Basic</h4>
            </div>
            <div className="card-body">
              {["radio"].map((type) => (
                <div key={`default-${type}`} className="demo-inline-spacing">
                  <Form.Check
                    className="radio-button"
                    type={type}
                    label={`Checked`}
                    id={`default-${type}`}
                    checked
                    name="group1"
                  />
                  <Form.Check
                    className="radio-button"
                    type={type}
                    label={`Unchecked`}
                    id={`unchecked-default-${type}`}
                    Unchecked
                    name="group1"
                  />
                  <Form.Check
                    className="radio-button"
                    type={type}
                    label={`Checked disabled`}
                    id={`default-${type}`}
                    checked
                    disabled
                    name="group2"
                  />
                  <Form.Check
                    className="radio-button"
                    type={type}
                    label={`Unchecked disabled`}
                    id={`disabled-default-${type}`}
                    disabled
                    Unchecked
                    name="group2"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Colors</h4>
            </div>
            <div className="card-body">
              {["radio"].map((type) => (
                <div key={`default-${type}`} className="demo-inline-spacing">
                  <Form.Check
                    className="form-check-primary"
                    type={type}
                    label={`Primary`}
                    id={`primary-${type}`}
                    checked
                  />
                  <Form.Check
                    className="form-check-secondary"
                    type={type}
                    label={`Secondary`}
                    id={`secondary-${type}`}
                    checked
                  />
                  <Form.Check
                    className="form-check-success"
                    type={type}
                    label={`Success`}
                    id={`success-${type}`}
                    checked
                  />
                  <Form.Check
                    className="form-check-danger"
                    type={type}
                    label={`Danger`}
                    id={`danger-${type}`}
                    checked
                  />
                  <Form.Check
                    className="form-check-warning"
                    type={type}
                    label={`Warning`}
                    id={`warning-${type}`}
                    checked
                  />
                  <Form.Check
                    className="form-check-info"
                    type={type}
                    label={`Info`}
                    id={`info-${type}`}
                    checked
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RadioPage;
