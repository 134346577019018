import React from "react";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import InputText from "../../../components/form/inputText";
import { ApiRoutes } from "../../../constants";
import { APICall } from "../../../services/axiosService";

export default function DeleteGuardPopup({
  showDeleteGuardPopup,
  setShowDeleteGuardPopup,
  setDeleteGuardDtls,
  deleteGuardDtls,
  getDetails,
  type,
  param,
  getparam
}) {
  

  const [isLoading, setIsLoading] = useState(false);

  let iniFormInputs = {
    reason: "",
  };
  let iniFormInputsEr = {
    reason: "",
  };
  const [formInput, setFormInput] = useState(iniFormInputs);

  const [formInputEr, setFormInputEr] = useState(iniFormInputsEr);

  const errors = {
    reason: "Reason is required.",
  };

  const handleClose = () => {
    setShowDeleteGuardPopup(false);
    setDeleteGuardDtls(null);
    setFormInput(iniFormInputs);
    setFormInputEr(iniFormInputsEr);
  };
  const handleCloseDecline = () => {
    setShowDeleteGuardPopup(false);
    getparam(param)
    // setDeleteGuardDtls(null);
    // setFormInput(iniFormInputs);
    // setFormInputEr(iniFormInputsEr);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValidate = true;
    Object.keys(formInputEr).forEach((key) => {
      newErrors = {
        ...newErrors,
        [key]: "",
      };
    });

    Object.keys(newErrors).forEach((er_key) => {
      if (formInput[er_key] == "") {
        newErrors = {
          ...newErrors,
          [er_key]: errors[er_key],
        };
        isValidate = false;
      }
    });

    setFormInputEr(newErrors);

    return isValidate;
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      const res = await APICall(ApiRoutes.deleteStaffMember, {
        contract_id: deleteGuardDtls.contract_id,
        association_id: deleteGuardDtls.guard_id,
        delete_reason: formInput.reason,
      });
      if (res) {
        handleClose();
        getDetails();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };
  const handleDeleteDecline = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      const res = await APICall(ApiRoutes.complainAction, {
        // contract_id: deleteGuardDtls.contract_id,
        // association_id: deleteGuardDtls.association_id,
        // delete_reason: formInput.reason,
        ticket_request_id: deleteGuardDtls.ticket_request_id,
        action: "reject",
        reason: formInput.reason,
      });
      if (res) {
        handleCloseDecline();
        getDetails();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {type !== "complain" ? (
        <Modal
          className="modal-popup"
          show={showDeleteGuardPopup}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Guard</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputText
              type={"text"}
              title={"Reason"}
              size={12}
              multiLine={true}
              value={formInput.reason}
              onChange={(value) =>
                setFormInput({
                  ...formInput,
                  reason: value,
                })
              }
              validationText={formInputEr.reason}
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleCloseDecline}>
              Close
            </Button>
            {isLoading === false ? (
              <>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    handleDelete(e);
                  }}
                >
                  Delete
                </Button>
              </>
            ) : (
              <Button variant="primary">
                <Spinner animation="border" size="sm" /> Loading...
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          className="modal-popup"
          show={showDeleteGuardPopup}
          onHide={handleCloseDecline}
        >
          <Modal.Header closeButton>
            <Modal.Title>Decline Request/Complaint</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputText
              type={"text"}
              title={"Reason"}
              size={12}
              multiLine={true}
              value={formInput.reason}
              onChange={(value) =>
                setFormInput({
                  ...formInput,
                  reason: value,
                })
              }
              validationText={formInputEr.reason}
              required
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleCloseDecline}>
              Close
            </Button>
            {isLoading === false ? (
              <>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    handleDeleteDecline(e);
                  }}
                >
                  Decline
                </Button>
              </>
            ) : (
              <Button variant="primary">
                <Spinner animation="border" size="sm" /> Loading...
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
