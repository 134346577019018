import React from "react";
import { Button, Spinner } from "react-bootstrap";

const ExportLoader = () => {
  return (
    <Button variant="primary export-loader">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Button>
  );
};

export default ExportLoader;
