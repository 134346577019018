import React from 'react'
import { Col, Nav, Row, Tab, Breadcrumb } from 'react-bootstrap';
import { default as PropertyCategoryList } from './PropertyCategory/Index';

export default function GeneralSetting() {
    return (<>
        <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
                <div className="breadcrumbs-top d-flex align-items-center">
                    <h2 className="content-header-title float-start mb-0">
                    Property Category
                    </h2>
                    <Breadcrumb className="breadcrumb-chevron">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="/general_setting">Settings</Breadcrumb.Item>
                        <Breadcrumb.Item active>Property Category</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>

        <PropertyCategoryList />
    </>)
}
