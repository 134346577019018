import React from 'react'
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Spinner, Form } from "react-bootstrap";
import InputText from "../../../components/form/inputText";
import { AppConfigData, commonService } from "../../../services/common.service";
import { alertService, AlertType } from "../../../services/alert.service";
import logo from "../../../assets/Images/logo/logo.svg";
import { APICall } from '../../../services/axiosService';
import { ApiRoutes } from '../../../constants';
import { ReactComponent as PageLoading } from "../../../assets/Icons/page-loading.svg";
import axios from "axios";
const CryptoJS = require("crypto-js");

export default function VerfiyOtp() {

  const navigate = useNavigate()

  const cryptoKey = "VeriFy_OTP@USER_NAme@123546789";

  const params = useParams();
  // const enc_username = params.enc_username;



  const location = useLocation();
  const query_params = new URLSearchParams(location.search);
  const enc_username = query_params.get("username");

  var bytes = CryptoJS.AES.decrypt(enc_username.replaceAll(" ", "+"), cryptoKey);

  const username = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  const [isResendClicked, setIsResendClicked] = useState(false);
  const [input, setInput] = useState({
    otp: "",
    errors: {
      otp: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);


  const handleResendOTP = async () => {
    if (!isResendClicked) {
      setIsResendClicked(true)
      let newData = { username : username };
      const requestMetadata = {
        url: AppConfigData.APIBasePath + "send_email_otp",
        method: "POST",
        headers: commonService.getHeaders(),
        data: newData,
      };
      const response = await axios(
        AppConfigData.APIBasePath + "send_email_otp",
        requestMetadata
      );

      if (response.data.status === 1) {
        alertService.alert({
          type: AlertType.Success,
          message: response.data.message,
        });
        setIsResendClicked(false)
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: response.data.message,
        });
        setIsResendClicked(false);
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      let newData = {
        ...input,
        username: username
      };
      const requestMetadata = {
        url: AppConfigData.APIBasePath + "login_via_email_otp",
        method: "POST",
        headers: commonService.getHeaders(),
        data: newData,
      };
      const response = await axios(
        AppConfigData.APIBasePath + "login_via_email_otp",
        requestMetadata
      );
      if (response.data.status === 1) {
        commonService.setItem("auth_token", response.data.data.auth_token);
        commonService.setItem(
          "user_id",
          JSON.stringify(response.data.data.user_id)
        );
        let res = await APICall(ApiRoutes.ProfileDetails);
        if (res) {
          response.data.data['association_details'] = res.data;
          commonService.setItem("user", JSON.stringify(response.data.data));
        }
        else {
          commonService.setItem("user", JSON.stringify(response.data.data));
        }

        navigate("/");
      } else {
        if ('type' in response.data) {
          if (response.data.type == 'otp') {
            setInput({
              ...input,
              ["errors"]: {
                ...input.errors,
                otp: response.data.message
              },
            });
          } else {
            alertService.alert({
              type: AlertType.Error,
              message: response.data.message,
            });
          }
        } else {
          alertService.alert({
            type: AlertType.Error,
            message: response.data.message,
          });
        }


        setIsLoading(false);
      }
    }
  }

  function validateForm() {
    let isValid = true;
    let errors = {};
    if (!input.otp) {
      errors.otp = "Otp is required";
      isValid = false;
    } else {
      errors.otp = "";
    }

    setInput({
      ...input,
      ["errors"]: errors,
    });

    return isValid;
  }


  return (<>
    <div className="blank-page">
      <div className="content-body">
        <div className="auth-wrapper auth-basic px-4">
          <div className="auth-inner my-4">
            <div className="mb-0 card">
              <div className="card-body p-4">
                <Link to="/" className="login-logo mb-4">
                  <img src={logo} alt="logo" width="160" height="48" />
                </Link>
                <div className="mb-3 card-title text-center text-dark">
                  <span>OTP Verification</span>
                </div>
                <div className="mb-3 text-center verify-otp-text text-start text-secondary">
                  We have sent the otp to your email address <b>{username}</b>
                </div>
                <Form onSubmit={(e) => { handleSubmit(e) }}>
                  <InputText
                    type={"text"}
                    size={12}
                    formGroupClass={'mb-4'}
                    // title={"Verify OTP"}
                    placeholder={'Enter OTP'}
                    value={input.otp}
                    onChange={(value) =>
                      setInput({
                        ...input,
                        otp: value,
                      })
                    }
                    validationText={input.errors.otp}
                  />
                  {/* <Link to="/"> */}
                  {isLoading ? (
                    <Button variant="primary w-100">
                      <Spinner animation="border" size="sm" /> Loading...
                    </Button>
                  ) : (
                    <Button
                      variant="primary w-100"
                      type="submit"
                    // disabled={agreementDiv ? !agreement : false}
                    // onClick={loginClick}
                    >
                      Verify Otp
                    </Button>
                  )}
                  {/* </Link> */}
                </Form>
                <p className="create-login-account-bottom text-center mt-3">
                  {/* <span>New on our platform?</span> */}

                  <span>Don't receive th OTP?
                    <span onClick={() => {
                      handleResendOTP()
                    }} className="cursor-pointer text-primary h6">
                      <span className='me-2'> Resend OTP</span>{isResendClicked && <span><Spinner animation="border" size="sm" /></span>}
                    </span>
                  </span>  
                </p>
                <p className="create-login-account-bottom text-center mt-3">
                      <span>Back to </span>
                      <Link to="/" className="text-primary h6">
                        <span>Login</span>
                      </Link>
                    </p>
                {/* <p className="create-login-account-bottom text-center mt-3">

                                <Link to="/portal/forgot/password" className="text-primary h6">
                                    <span>Forgot Password?</span>
                                </Link>
                            </p> */}

                {/* <div onClick={() => history('/forgot/password')} className="mb-2 text-end cursor-pointer"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
