import { ApiRoutes } from "../constants/apiroutes";
import { commonService } from "./common.service";

const postCallAPIs = [
  ApiRoutes.CompanySave,
  ApiRoutes.BranchSave,
  ApiRoutes.CompanyList,
  ApiRoutes.BranchList,
  ApiRoutes.CompanyStatusUpdate,
  ApiRoutes.BranchStatusUpdate,
  ApiRoutes.CompanyDropdownList,
  ApiRoutes.PaymentModeList,
];

export default function getAPICallData(APITYPE, requestBody, cancelToken) {
  let postCall;
  if (cancelToken) {
    postCall = {
      url: APITYPE,
      method: "POST",
      headers: commonService.getHeaders(),
      data: requestBody,
      cancelToken: cancelToken.token
    };
  } else {
    postCall = {
      url: APITYPE,
      method: "POST",
      headers: commonService.getHeaders(),
      data: requestBody,
    };
  }

  if (postCallAPIs.includes(APITYPE)) {
    return postCall;
  } else {
    return postCall;
  }
}

