import { Form } from "react-bootstrap";

function SwitchPage() {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Basic</h4>
            </div>
            <div className="card-body">
              <div className="demo-inline-spacing">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="Check this switch"
                />
                <Form.Check
                  disabled
                  type="switch"
                  label="disabled switch"
                  id="disabled-custom-switch"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Colors</h4>
            </div>
            <div className="card-body">
              <div className="demo-inline-spacing">
                <Form.Check
                  type="switch"
                  id="form-check-primary"
                  className="form-check-primary"
                  defaultChecked
                />
                <Form.Check
                  type="switch"
                  id="form-check-secondary"
                  className="form-check-secondary"
                  defaultChecked
                />
                <Form.Check
                  type="switch"
                  id="form-check-success"
                  className="form-check-success"
                  defaultChecked
                />
                <Form.Check
                  type="switch"
                  id="form-check-danger"
                  className="form-check-danger"
                  defaultChecked
                />
                <Form.Check
                  type="switch"
                  id="form-check-warning"
                  className="form-check-warning"
                  defaultChecked
                />
                <Form.Check
                  type="switch"
                  id="form-check-info"
                  className="form-check-info"
                  defaultChecked
                />
                <Form.Check
                  type="switch"
                  id="form-check-dark"
                  className="form-check-dark"
                  defaultChecked
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SwitchPage;
