import React, { useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import InputText from "../../../components/form/inputText";

export default function EditWizardFour({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
  isCompanyFormClick,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          {formInputs.emergency_contacts.map((mapItem, mapIndex) => (
            <>
              <Row>
                <InputText
                  type={"text"}
                  title={"Contact Name"}
                  value={mapItem.emr_contact_name}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      emergency_contacts: formInputs.emergency_contacts.map(
                        (innerItem, innerIndex) => {
                          if (innerIndex === mapIndex) {
                            return {
                              ...innerItem,
                              emr_contact_name: value,
                            };
                          }
                        }
                      ),
                    })
                  }
                />
                <InputText
                  type={"number"}
                  title={"Contact Phone"}
                  value={mapItem.emr_contact_phone}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      emergency_contacts: formInputs.emergency_contacts.map(
                        (innerItem, innerIndex) => {
                          if (innerIndex === mapIndex) {
                            return {
                              ...innerItem,
                              emr_contact_phone: value,
                            };
                          }
                        }
                      ),
                    })
                  }
                />
              </Row>
              <Row>
                <InputText
                  type={"text"}
                  title={"Contact Relation"}
                  value={mapItem.emr_contact_relation}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      emergency_contacts: formInputs.emergency_contacts.map(
                        (innerItem, innerIndex) => {
                          if (innerIndex === mapIndex) {
                            return {
                              ...innerItem,
                              emr_contact_relation: value,
                            };
                          }
                        }
                      ),
                    })
                  }
                />
              </Row>
            </>
          ))}
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              {isCompanyFormClick === false ? (
                <>
                  <Button
                    className="btn-15"
                    variant="primary"
                    onClick={onNextPress}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button className="btn-15" variant="primary">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
