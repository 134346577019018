import moment from "moment";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Tab, Nav, Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";

import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { badgesEnum, CheckpointActiveStatusEnum, Enums } from "../../constants";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import NoImage from "../../assets/Images/Icon/no_image.png";
import Index from "./ReviewRating/Index";
import NewLoader from "../../components/loader/NewLoader";
import ExportLoader from "../../components/loader/ExportLoader";

const activate_doc_arr = ["accepted", "in_progress"];
const activate_doc_view_arr = ["pending"];

export default function Create() {
  const [formInputs, setInput] = useState({
    amount: "",
    new_amount: "",
    duration_title: "",
    service_hours_title: "",
    no_of_guards: "",
    no_of_checkpoints: "",
    no_of_patrolling_in_day: "",
    company_list: [],
    checkpoint_list: null,
    my_documents: [],
    checkpoint_list: null,
    quote_company_id: "",
  });
  const [dataTableLoading, setDataTableLoading] = useState(true);
  const [CheckpoinPdfLoading, setCheckpoinPdfLoading] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  const routeParams = location.state;

  const guardsTblColumns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return (
          <img
            src={
              row.profile_img
                ? process.env.REACT_APP_ASSETS_URL + row.profile_img
                : DefaultImg
            }
            className="round"
            alt="user"
            width="40"
            height="40"
            style={{ objectFit: "cover" }}
          />
        );
      },
    },
    {
      name: "Guard Name",
      selector: (row) => row.guard_name,
      sortField: "guard_name",
    },
    {
      name: "Email",
      selector: (row) => row.guard_email,
      sortField: "guard_email",
    },
    {
      name: "Phone",
      selector: (row) => row.guard_phone,
      sortField: "guard_phone",
    },
    {
      name: "Staff Type",
      selector: (row) => (
        <span title={Enums.GuardTypesDetails[row.staff_type]}>
          {Enums.GuardTypesDetails[row.staff_type]}
        </span>
      ),
      sortField: "guard_phone",
      width: "140px",
    },
    {
      name: "ID number",
      selector: (row) => row.id_number,
      sortField: "id_number",
    },
  ];

  const inchargeTblColumns = [
    {
      name: "#",
      cell: (row, index, column, id) => {
        return (
          <img
            src={
              row.profile_img
                ? process.env.REACT_APP_ASSETS_URL + row.profile_img
                : DefaultImg
            }
            className="round"
            alt="user"
            width="40"
            height="40"
            style={{ objectFit: "cover" }}
          />
        );
      },
    },
    {
      name: "Guard Name",
      selector: (row) => row.incharge_name,
      sortField: "incharge_name",
    },
    {
      name: "Email",
      selector: (row) => row.incharge_email,
      sortField: "incharge_email",
    },
    {
      name: "Phone",
      selector: (row) => row.incharge_phone,
      sortField: "incharge_phone",
    },
    {
      name: "ID number",
      selector: (row) => row.id_number,
      sortField: "id_number",
    },
  ];

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    setDataTableLoading(true);
    const responseData = await APICall(ApiRoutes.ContractDetails, {
      id: routeParams.id,
    });
    if (responseData) {
      setInput((prevState) => ({
        ...prevState,
        ...responseData.data,
        company_list: responseData.data.companies_list,
        checkpoint_list: responseData.data.checkpoints,
      }));
      setDataTableLoading(false);
    }
  };

  const getLocation = (obj) => {
    if (formInputs[obj]?.location) {
      const locationString = formInputs[obj]?.location;
      return (
        locationString.address1 +
        ", " +
        (locationString?.address2 ? locationString.address2 + ", " : "") +
        locationString.city +
        ", " +
        locationString.state +
        ", " +
        locationString.country +
        " - " +
        locationString.postal_code
      );
    } else {
      return "";
    }
  };

  const getBranchLocation = (obj) => {
    if (obj.length) {
      const locationString = obj[0];
      return (
        locationString.address1 +
        ", " +
        (locationString?.address2 ? locationString.address2 + ", " : "") +
        locationString.city +
        ", " +
        locationString.state +
        ", " +
        locationString.country +
        " - " +
        locationString.postal_code
      );
    } else {
      return "";
    }
  };

  const renderContractDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Contract Details</h4>
      </Card.Header>
      <Card.Body>
        {dataTableLoading ? (
          <NewLoader />
        ) : (
          <Row className="address">
            <Col className="col-12 col-xl-6">
              <dl className="mb-0 row">
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.property_info?.property_name}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Duration:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.duration_title}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Service Hours:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.service_hours_title}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Status:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.quote_company_status &&
                    badgesEnum[formInputs.quote_company_status]}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Property Location:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {getLocation("property_info")}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of Guards:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.no_of_guards}
                </dd>
              </dl>
            </Col>
            <Col className="col-12 col-xl-5">
              <dl className="mb-0 row">
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of patrolling in a day:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.no_of_patrolling_in_day}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of checkpoints:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs.no_of_checkpoints}
                </dd>
                {/* <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">Created on:</dt>
              <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                {formInputs?.created_at &&
                  moment(formInputs?.created_at).format("DD MMM, YYYY")}
              </dd> */}
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">Amount:</dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs.total_amount}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Start date:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.start_date
                    ? commonService.convertUTCToLocal(
                        formInputs.start_date,
                        "DD-MM-YYYY"
                      )
                    : ""}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                  Expiry Date:
                </dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.end_date
                    ? commonService.convertUTCToLocal(
                        formInputs.end_date,
                        "DD-MM-YYYY"
                      )
                    : ""}
                </dd>
                {/* <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">Received amount:</dt>
              <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                {formInputs.received_amount}
              </dd> */}
              </dl>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );

  const renderClientDetails = () => (
    <>
      <Card>
        <Card.Header>
          <h4 className="card-title">Company Details</h4>
        </Card.Header>
        <Card.Body>
          {dataTableLoading ? (
            <NewLoader />
          ) : (
            <Row className="address align-items-center">
              <Col className="col-sm-12 col-md-12 col-lg-2 col-12">
                <img
                  src={
                    formInputs?.company_info?.profile_img
                      ? process.env.REACT_APP_ASSETS_URL +
                        formInputs?.company_info?.profile_img
                      : DefaultImg
                  }
                  className={
                    "mb-3 mb-xl-0 col-8 col-sm-6 col-md-4 col-lg-12 m-auto d-block rounded"
                  }
                />
              </Col>
              <Col className="col-12 col-xl-8">
                <dl className="mb-0 row">
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Name:</dt>
                  <dd
                    onClick={() => {
                      navigate("/contract/company/profile", {
                        state: {
                          comapny_id: formInputs.company_info["company_id"],
                        },
                      });
                    }}
                    className="mb-2 col-sm-12 col-md-10 col-lg-10 text-decoration-underline nav-link cursor-pointer mb-0 py-0"
                  >
                    {formInputs?.company_info?.company_name}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Email:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs?.company_info?.email}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Phone:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs?.company_info?.phone}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Location:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {getLocation("company_info")}
                  </dd>
                </dl>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      {formInputs.branch_info ? (
        <Card>
          <Card.Header>
            <h4 className="card-title">Branch Details</h4>
          </Card.Header>
          <Card.Body>
            <Row className="address align-items-center">
              <Col className="col-sm-12 col-md-12 col-lg-2 col-12">
                <img
                  src={
                    formInputs.branch_info.branch_profile_img
                      ? process.env.REACT_APP_ASSETS_URL +
                        formInputs.branch_info.branch_profile_img
                      : DefaultImg
                  }
                  className={
                    "mb-3 mb-xl-0 col-8 col-sm-6 col-md-4 col-lg-12 m-auto d-block rounded"
                  }
                />
              </Col>
              <Col className="col-12 col-xl-8">
                <dl className="mb-0 row">
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Name:</dt>
                  <dd
                    onClick={() => {
                      navigate("/contract/branch/profile", {
                        state: { branch_id: formInputs.branch_info.branch_id },
                      });
                    }}
                    className="mb-2 col-sm-12 col-md-10 col-lg-10 text-decoration-underline nav-link cursor-pointer mb-0 py-0"
                  >
                    {formInputs.branch_info.branch_name}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Email:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs.branch_info.branch_email}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Phone:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {formInputs.branch_info.branch_phone}
                  </dd>
                  <dt className="mb-2 col-md-2 col-sm-8 col-lg-2">Location:</dt>
                  <dd className="mb-2 col-sm-12 col-md-10 col-lg-10">
                    {getBranchLocation(
                      formInputs.branch_info.locations
                        ? formInputs.branch_info.locations.length
                          ? formInputs.branch_info.locations
                          : []
                        : []
                    )}
                  </dd>
                </dl>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </>
  );

  const renderGuardDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Guard Details</h4>
      </Card.Header>
      <Card.Body>
        {dataTableLoading ? (
          <NewLoader />
        ) : (
          <DataTable
          paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
            columns={guardsTblColumns}
            data={formInputs.guard_list}
            progressPending={dataTableLoading}
            progressComponent={<NewLoader />}
          />
        )}
      </Card.Body>
    </Card>
  );
  const renderInvoicesDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Invoices</h4>
      </Card.Header>
      <Card.Body>
        <div className="row">
          {formInputs.invoice_list != null && !dataTableLoading ? (
            formInputs.invoice_list.length ? (
              formInputs.invoice_list.map((invoice_list, index) => {
                return (
                  <div className="col-sm-6 col-lg-4" key={index}>
                    <div className="card b-radius-10px">
                      <div className="card-body b-radius-10px p-3 d-flex border border-primary">
                        <div className="truncate w-100 d-flex justify-content-between align-items-center">
                          <h6 className="font-weight-bolder text-dark mb-0">
                            {commonService.convertUTCToLocal(
                              invoice_list.created_at
                            )}
                          </h6>
                          <a
                            target="_blank"
                            href={
                              process.env.REACT_APP_ASSETS_URL +
                              invoice_list.invoice_doc
                            }
                            className="text-primary cursor-pointer"
                          >
                            Preview Invoice
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{ fontSize: "24px", fontWeight: "500" }}
                className="text-center text-muted"
              >
                No invoices!
              </div>
            )
          ) : (
            // <div
            //   style={{ fontSize: "24px", fontWeight: "700" }}
            //   className="text-center text-dark"
            // >
            //   Loading...
            // </div>
            <NewLoader />
          )}
        </div>
      </Card.Body>
    </Card>
  );
  const renderInchargeDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Incharge Details</h4>
      </Card.Header>
      <Card.Body>
      {dataTableLoading ? (
          <NewLoader />
        ) :
        <DataTable
        paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
          columns={inchargeTblColumns}
          data={formInputs.incharge_list}
          progressPending={dataTableLoading}
          progressComponent={<NewLoader />}
        />}
      </Card.Body>
    </Card>
  );

  const fnCheckpointPdfAPICall = () => {


    setCheckpoinPdfLoading(true)
    const endpoint = ApiRoutes.checkpointQRcodePdf;

    const requestParams = {
      contract_id: routeParams.id
    };

    // Prepare request metadata
    const requestMetadata = {
      method: "POST",
      headers: {
        ...commonService.getHeaders(),
        "Content-Type": "application/json", // Assuming JSON is expected by the API
      },
      body: JSON.stringify(requestParams), // Convert requestParams to JSON string
    };

    // Make the POST request to the API
    fetch(endpoint, requestMetadata)
      .then((response) => {
        setCheckpoinPdfLoading(false)
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        var iFrame = document.createElement("iframe");
        iFrame.src = URL.createObjectURL(blob);
        window.open(iFrame.src, "_blank");

        // document.body.appendChild(iFrame);
        // const url = window.URL.createObjectURL(blob);

        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("target", "_blank");
        // document.body.appendChild(link);
        // link.click();
        // link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  
  const renderCheckPointsDetails = () => (
    <Card>
      <Card.Header>
        <h4 className="card-title">Checkpoint Title</h4>
        <div>
          {/* {formInputs.checkpoint_pdf && (
            <a
              target="_blank"
              href={
                process.env.REACT_APP_ASSETS_URL + formInputs.checkpoint_pdf
              }
              className="btn btn-primary btn-sm"
            >
              <i className="fas fa-file-pdf"></i>
            </a>
          )} */}
          <>
              {CheckpoinPdfLoading ? <span className=" me-2 "><ExportLoader /></span> :
                <div
                  onClick={() => fnCheckpointPdfAPICall()}
                  className="btn me-2 btn-primary btn-sm"
                >

                  <i className="fas fa-file-pdf"></i> 

                </div>
              }
            </>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="row">
          {formInputs.checkpoint_list != null && !dataTableLoading ? (
            formInputs.checkpoint_list.length ? (
              formInputs.checkpoint_list.map((checkpointrecord, index) => {
                return (
                  <div className="col-sm-6 col-lg-4" key={index}>
                    <div className="card b-radius-10px">
                      <div className="card-body b-radius-10px p-3 d-flex border border-primary">
                        <div className="d-flex">
                          <span className="rounded-circle me-3">
                            <span className="b-avatar-custom">
                              <img
                                className="img-checkpoint"
                                width={"200px"}
                                height={"200px"}
                                src={`${
                                  checkpointrecord.qr_code
                                    ? process.env.REACT_APP_ASSETS_URL +
                                      checkpointrecord.qr_code
                                    : NoImage
                                }`}
                              ></img>
                            </span>
                          </span>
                          <div className="truncate">
                            <h6 className="mb-25 font-weight-bolder text-dark">
                              Title Of Checkpoint
                            </h6>
                            <span>{checkpointrecord.title}</span>
                          </div>
                        </div>
                        <span>
                          {/* {Enums.CheckPointStatus[checkpointrecord.activation_status]} */}
                          {
                            CheckpointActiveStatusEnum[
                              checkpointrecord.activation_status
                            ]
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{ fontSize: "24px", fontWeight: "500" }}
                className="text-center text-muted"
              >
                No checkpoints!
              </div>
            )
          ) : (
            // <div style={{ fontSize: '24px', fontWeight: "700" }} className="text-center text-dark">Loading...</div>
            <NewLoader />
          )}
        </div>
      </Card.Body>
    </Card>
  );
  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              View Contract
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/client_contracts">
                Contract
              </Breadcrumb.Item>
              <Breadcrumb.Item active>View</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Col className="order-0 col-12 order-lg-1 col-lg-12 col-xl-12 col">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="mb-3">
            <Nav.Item className=" cursor-pointer">
              <Nav.Link eventKey="first">
                <i className="fa-regular fa-th-large"></i>
                <span className="pills-tab-text">Contract Information</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className=" cursor-pointer">
              <Nav.Link eventKey="sec">
                <i className="fa-regular fa-start"></i>
                <span className="pills-tab-text">Review and Rating</span>
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item className=" cursor-pointer">
              <Nav.Link eventKey="third">
                <i className="fa-regular fa-star"></i>
                <span className="pills-tab-text">Review and Rating</span>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              {renderContractDetails()}
              {renderClientDetails()}
              {renderGuardDetails()}
              {renderInchargeDetails()}
              {renderInvoicesDetails()}
              {renderCheckPointsDetails()}
            </Tab.Pane>
            <Tab.Pane eventKey="sec">
              {!dataTableLoading && (
                <Index
                  getDetails={getDetails}
                  contractId={routeParams.id}
                  formInputs={formInputs}
                />
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </>
  );
}
