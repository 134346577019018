import axios from "axios";
import { ApiRoutes } from "../constants/apiroutes";
// import { historyRef } from "../layout/Layout";
import { alertService, AlertType } from "./alert.service";
import getAPICallData from "./getAPICallData";
const popUp = [
  ApiRoutes.BranchSave,
  ApiRoutes.CompanySave,
  ApiRoutes.GuardSave,
  ApiRoutes.InchargeSave,
  ApiRoutes.UpdateProfileDetails,
  ApiRoutes.SignUp,
  ApiRoutes.UserSave,
  ApiRoutes.PropertySave,
  ApiRoutes.sendResetPassLink,
  ApiRoutes.changePassword,
  ApiRoutes.resetPassword,
  ApiRoutes.ContractSave,
  ApiRoutes.saveSchedules,
  ApiRoutes.checkResetToken,
  ApiRoutes.QuotationSave,
  ApiRoutes.updateWebSettings,
  ApiRoutes.updateAppSettings,
  ApiRoutes.contractStatusUpdate,
  ApiRoutes.PropertyCategoryStatusUpdate,
  ApiRoutes.deleteStaffMember,
  ApiRoutes.BranchAcceptReject
];

const returnAllResponses = [
  ApiRoutes.checkResetToken,
  ApiRoutes.resetPassword,
  ApiRoutes.leaveDetails,
  ApiRoutes.fetchPolicy
]

const returnAllResponsesWithoutError = [
  ApiRoutes.fetchPolicy
]
const WithoutCancelToken = [
  ApiRoutes.fetchReviews,
  ApiRoutes.fetchReviewContractList
]
let cancelTokensHashMap = new Map();
export const APICall = async (APITYPE, requestBody = null) => {
  try {
    if (cancelTokensHashMap.has(APITYPE)) {

      cancelTokensHashMap.get(APITYPE).cancel();
      cancelTokensHashMap.delete(APITYPE);
    }
    let cancelToken = null;
    if (!WithoutCancelToken.includes(APITYPE)) {
      cancelToken = axios.CancelToken.source();
      cancelTokensHashMap.set(APITYPE, cancelToken);
    }


    const requestMetadata = await axios(getAPICallData(APITYPE, requestBody, cancelToken));
    if (requestMetadata.data.status === 1) {
      if (popUp.includes(APITYPE)) {
        alertService.alert({
          type: AlertType.Success,
          message: requestMetadata.data.message,
        });
      }
      return requestMetadata.data;
    } else if (returnAllResponses.includes(APITYPE)) {
      if (!returnAllResponsesWithoutError.includes(APITYPE)) {
        alertService.alert({
          type: AlertType.Error,
          message: requestMetadata.data.message,
        });
      }
      return requestMetadata.data;
    } else {
      alertService.alert({
        type: AlertType.Error,
        message: requestMetadata.data.message,
      });
      return false;
    }
  } catch (error) {
    if (error?.response?.status === 500) {
      alertService.alert({
        type: AlertType.Error,
        message: "Unable to process your request",
      });
    } else if (error?.response?.status === 401) {
      localStorage.clear();
    }
    return false;
  }
};
