import { useEffect, useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { commonService } from "../../services/common.service";
import { ApiRoutes, PatrollingSchedulesEnum } from "../../constants";
import { APICall } from "../../services/axiosService";
import NewLoader from "../../components/loader/NewLoader";
import NoDataFound from "../../components/common/NoDataFound";

function DailyPatrollingTableModal(props) {
  const [Loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const fetchGuardSchedules = async () => {
    setLoading(true);
    let res = await APICall(ApiRoutes.guardContraSchedulesById, {
      contract_schedule_id: props?.data?.contract_schedule_id,
      contract_id: props?.data?.contract_id,
    });
    if (res.status == 1) {
      setLoading(false);
      setSchedules(res.data);
    }
  };

  useEffect(() => {
    fetchGuardSchedules();
  }, [props]);
  return (
    <>
      <Modal
        className="scheduleCheckListModal daily-table-modal overflow-hidden"
        size="xl"
        centered
        {...props}
      >
        <Modal.Header closeButton>
          <Modal.Title>Patrolling Schedules</Modal.Title>
        </Modal.Header>
        {Loading ? (
          <div className="py-3 text-center">
            <NewLoader />
          </div>
        ) : (
          <Modal.Body>
            {schedules?.contract_sc_dtls?.length > 0 ? (
              <>
                <Table striped bordered hover>
                  {props.flag !== "ContractShedule" && (
                    <>
                      <thead>
                        <tr>
                          <th>Total Checkpoints </th>
                          <th>Visited Checkpoints</th>
                          <th>Pending Checkpoints</th>
                          <th>Incomplete Checkpoints</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{schedules?.total_checkpoints}</td>
                          <td>{schedules?.visited_checkpoints}</td>
                          <td>{schedules?.pending_checkpoints}</td>
                          <td>{schedules?.incomplete_checkpoints}</td>
                        </tr>
                      </tbody>
                    </>
                  )}
                </Table>
                <Card>
                  {/* <Card.Header className="p-2">Patrolling Schedules</Card.Header> */}
                  <Card.Body className="p-2">
                    <ul className="timeline ms-1">
                      {schedules?.contract_sc_dtls?.map(
                        (contract_sc_dtl, index) => {
                          return (
                            <li className="timeline-item" key={index}>
                              <span className="timeline-point timeline-point-indicator"></span>
                              <div className="timeline-event">
                                <div className="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                  <h6>
                                    {commonService.convertUtcTimeToLocalTime(
                                      contract_sc_dtl.checkpoint_start_time,
                                      "MM-DD-YYYY HH:mm",
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}{" "}
                                    -{" "}
                                    {commonService.convertUtcTimeToLocalTime(
                                      contract_sc_dtl.checkpoint_end_time,
                                      "MM-DD-YYYY HH:mm",
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </h6>
                                  <span className="timeline-event-time h6 text-dark">
                                    {/* {PatrollingSchedulesEnum[contract_sc_dtl.checkpoint_status]} */}
                                    {
                                      contract_sc_dtl.sc_checkpoint_history
                                        .visited_checkpoints_count
                                    }{" "}
                                    /{" "}
                                    {
                                      contract_sc_dtl.sc_checkpoint_history
                                        .total_checkpoints_count
                                    }
                                  </span>
                                </div>
                                <p className="login-time mb-1">
                                  Patrolling schedule Summary
                                </p>
                                {contract_sc_dtl.sc_checkpoint_history.list
                                  .length ? (
                                  <div className="mt-3">
                                    <div>
                                      <div
                                        style={{ paddingRight: "0.5rem" }}
                                        className="d-flex justify-content-between h6 mb-0 align-item-center"
                                      >
                                        <div className="h6">Checkpoints</div>
                                        <div className="h6">Visited time</div>
                                        <div className="h6">Visit Status</div>
                                      </div>
                                    </div>
                                    <ul className="mt-2 p-0">
                                      {contract_sc_dtl.sc_checkpoint_history.list.map(
                                        (sc_checkpoint_history) => {
                                          return (
                                            <li className="p-2 ">
                                              <div className="d-flex justify-content-between mb-0 align-item-center">
                                                <div className="h6">
                                                  {sc_checkpoint_history.title}
                                                </div>
                                                <div className="h6">
                                                  {sc_checkpoint_history.checkpoint_visited_time
                                                    ? commonService.convertUTCToLocal(
                                                        sc_checkpoint_history.checkpoint_visited_time,
                                                        "DD/MM/YYYY hh:mm A"
                                                      )
                                                    : "-"}
                                                </div>
                                                {/* <div>{!sc_checkpoint_history.checkpoint_visited_time ? PatrollingSchedulesEnum['pending'] : PatrollingSchedulesEnum['completed']}</div> */}
                                                <div>
                                                  {sc_checkpoint_history.activation_status ===
                                                    "inactive" &&
                                                  sc_checkpoint_history.visited_status ===
                                                    "incomplete" ? (
                                                    <>
                                                      <Badge bg="light-danger">
                                                        Inactive
                                                      </Badge>
                                                    </>
                                                  ) : sc_checkpoint_history.activation_status ===
                                                      "inactive" &&
                                                    sc_checkpoint_history.visited_status ===
                                                      "pending" ? (
                                                    <>
                                                      <Badge bg="light-danger">
                                                        Inactive
                                                      </Badge>
                                                    </>
                                                  ) : sc_checkpoint_history.activation_status ===
                                                      "pending" &&
                                                    sc_checkpoint_history.visited_status ===
                                                      "pending" ? (
                                                    <>
                                                      <Badge bg="light-danger">
                                                        Inactive
                                                      </Badge>
                                                    </>
                                                  ) : sc_checkpoint_history.activation_status ===
                                                      "active" &&
                                                    sc_checkpoint_history.visited_status ===
                                                      "pending" ? (
                                                    <>
                                                      <Badge bg="light-warning">
                                                        Pending
                                                      </Badge>
                                                    </>
                                                  ) : sc_checkpoint_history.activation_status ===
                                                      "active" &&
                                                    sc_checkpoint_history.visited_status ===
                                                      "visited" ? (
                                                    <>
                                                      <Badge bg="light-primary">
                                                        Visited
                                                      </Badge>
                                                    </>
                                                  ) : sc_checkpoint_history.activation_status ===
                                                      "active" &&
                                                    sc_checkpoint_history.visited_status ===
                                                      "incomplete" ? (
                                                    <>
                                                      <Badge bg="light-danger">
                                                        InCompleted
                                                      </Badge>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  {/* sc_checkpoint_history.activation_status ===
                                                  "pending" ? (
                                                    <Badge bg="light-danger">
                                                      Inactive
                                                    </Badge>
                                                  ) : (
                                                    PatrollingSchedulesEnum[
                                                      sc_checkpoint_history
                                                        .visited_status
                                                    ]
                                                  ) */}
                                                  {/* {sc_checkpoint_history.activation_status ===
                                                  "pending" ? (
                                                    <Badge bg="light-danger">
                                                      Inactive
                                                    </Badge>
                                                  ) : (
                                                    PatrollingSchedulesEnum[
                                                      sc_checkpoint_history
                                                        .visited_status
                                                    ]
                                                  )} */}
                                                </div>
                                              </div>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>{" "}
                                  </div>
                                ) : (
                                  <div>No checkpoints found</div>
                                )}
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </Card.Body>
                </Card>
              </>
            ) : (
              <NoDataFound fontsize={"150px"} />
            )}
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

export default DailyPatrollingTableModal;
