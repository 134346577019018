import React, { useState } from "react";
import { Breadcrumb, Button, Card, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { contractStatusEnum } from "../../constants";
// import FilterComponent from "../../components/common/FilterComponent";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { ApiRoutes } from "../../constants/apiroutes";
// import NoDataFound from "../../components/common/NoDataFound";
import { Enums } from "../../constants/enums";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DropDown from "../../components/common/DropDown";
import DatePicker from "react-datepicker";
import InputText from "../../components/form/inputText";
import { alertService, AlertType } from "../../services/alert.service";
import NewLoader from "../../components/loader/NewLoader";

function Index() {
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [contractTotalRows, setGuardTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [contractsList, setContractsList] = useState({
    isData: false,
    data: [],
  });
  const [branchList, setBranchList] = useState([]);
  const [allCompanyList, setAllCompanyList] = useState([]);
  const [selectedBranchList, setSelectedBranchList] = useState([]);
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [contractStatus, setContractStatus] = useState(null);
  const [contractFrom, setContractFrom] = useState(null);
  const [contractTo, setContractTo] = useState(null);

  const userType = JSON.parse(commonService.getItem("user")).association_type_term;

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.contractStatusUpdate, {
      id: item.contract_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setContractsList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };

  const contractTblColumns = [
    // {
    //   name: "#",
    //   selector: (row) => row.full_name,
    //   sortable: false,
    //   cell: (row, index, column, id) => {
    //     return (
    //       <img
    //         src={
    //           row.client_profile_img
    //             ? process.env.REACT_APP_ASSETS_URL + row.client_profile_img
    //             : DefaultImg
    //         }
    //         className="round"
    //         alt="user"
    //         width="40"
    //         style={{ objectFit: "cover" }}
    //         height="40"
    //       />
    //     );
    //   },
    // },
    {
      name: "Company",
      selector: (row) => <div className="cursor-pointer text-primary" onClick={()=>{
        navigate(`/company/view/${row.company_id}`)
      }}> {row.company_name}</div>,
      sortable: true,
      omit: userType == Enums.UserRole.company || userType === Enums.UserRole.branch_manager ? true : false,
      width: "170px",
      sortField: "company_name",
    },
    {
      name: "Branch Manager",
      selector: (row) => row.branch_name ?  <div className="cursor-pointer text-primary" onClick={()=>{
        navigate(`/branch/view/${row.branch_id}`)
      }}> {row.branch_name}</div> :"",
      omit: userType === Enums.UserRole.branch_manager ? true : false ,
      sortable: true,
      width: "170px",
      sortField: "branch_name",
    },
    {
      name: "Property Manager",
      selector: (row) => <span className="nav-link cursor-pointer mb-0 p-0" onClick={() => navigate(`/clients/view/${row.client_id}`)}>{row.client_name}</span>,
      sortable: true,
      width: "170px",
      sortField: "client_name",
    },
    {
      name: "Email",
      selector: (row) => row.client_email,
      sortable: true,
      sortField: "client_email",
    },
    {
      name: "Phone",
      selector: (row) => row.client_phone,
      sortable: true,
      sortField: "client_phone",
    },
    {
      name: "Property",
      selector: (row) => row.property_name,
      sortable: true,
      sortField: "property_name",
    },
    {
      name: "Guards",
      selector: (row) => row.total_guard,
      sortable: true,
      sortField: "total_guard",
    },

    {
      name: "Incharge",
      selector: (row) => row.total_incharge,
      sortable: true,
      sortField: "total_incharge",
    },
    {
      name: "Checkpoints",
      selector: (row) => row.no_of_checkpoints,
      sortable: true,
      sortField: "no_of_checkpoints",
    },
  
    {
      name: "Amount",
      selector: (row) => row.total_amount,
      sortable: true,
      omit: userType == Enums.UserRole.admin ? true : false,
      sortField: "total_amount",
    },
    // {
    //   name: "Received Amount",
    //   selector: (row) => row.received_amount,
    //   sortable: true,
    //   sortField: "received_amount",
    // },
    {
      name: "Contract Status",
      selector: (row) => row.is_active,
      sortable: true,
      sortField: "is_active",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            {contractStatusEnum[row.contract_status]}
          </span>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      omit: userType === Enums.UserRole.admin ? false : true,
      sortField: "is_active",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={(value) => onSwitchToggle(value, row, index)}
              checked={row.is_active === "1" ? true : false}
              height={20}
              width={40}
              onColor="#263A96"
            />
          </span>
        );
      },
    },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      sortField: "start_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.start_date),
    },
    {
      name: "Expiry Date",
      selector: (row) => row.end_date,
      sortable: true,
      sortField: "end_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.end_date),
    },
    {
      name: "Action",
      selector: (row) => row.year,
      cell: (row, index, column, id) => (
        <div className="justify-content-center align-items-center d-flex">
          <Button
            style={{ fontSize : "0.7rem", padding: "0.3rem 1rem" }}
            variant="primary"
            type="button"
            onClick={() => {
              navigate("view", {
                state: {
                  id: row.contract_id,
                },
              });
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();

  const getBranchList = async () => {
    let res = await APICall(ApiRoutes.BranchDropDownList)
    setAllCompanyList(res.data.company_list)
    setBranchList(res.data.list)
  }
  // React.useEffect(() => {
  //   getContractsList("", "", 1);
  // }, [txtSearch]);

  React.useEffect(() => {
    getContractsList("", "", 1);
  }, [txtSearch,pageNo, perPageRecords]);

  React.useEffect(() => {
    getBranchList();
  }, []);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  function filterCompaniesList() {
    getContractsList("", "", 1);
  }

  async function getContractsList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setDataTableLoading(true);

    setContractsList({ isData: false, data: [] });



    const requestMetadata = await APICall(ApiRoutes.ContractList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          contractFrom != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(contractFrom) + " 00:00:00"
            )
            : null,
        end_date:
          contractTo != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(contractTo) + " 23:59:59"
            )
            : null,
        branch_list: selectedBranchList.map((item) => (item.value)),
        company_list: selectedCompanyList.map((item) => (item.value)),
        contract_status: contractStatus
      },
    });
    if (requestMetadata) {

      setContractsList({ isData: true, data: requestMetadata.data.list });

      setGuardTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
    
  }

  const handleSort = async (column, sortDirection) => {
    getContractsList(column.sortField, sortDirection , 1);
  };
  const resetFilter = () => {
    setSelectedBranchList([])
    setSelectedCompanyList([])
    setContractStatus(null)
    setContractFrom(null)
    setContractTo(null)
    setTxtSearch("")
    getContractsList("", "", 0);
  };

  const filterContractStatus = (value) => {
    // quotation_status
    if (value) {
      setContractStatus(value);
    } else {
      setContractStatus(null);
    }
  };

  const searchData = (e) => {
    e.preventDefault();

    let isValidate = true;
    if (contractTo || contractFrom) {
      if (contractTo && contractFrom) {
        const dateFrom = new Date(contractFrom);
        const dateTo = new Date(contractTo);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getContractsList("", "", 1);
    }
  };

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Contract List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Contracts</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div>

          <div>
            <Card>
              <Card.Body className="p-3">
                <div className="row">

                  <div className="col-3">
                    <InputGroup>
                      <DatePicker
                        className="datepicker form-control"
                        selected={contractFrom ? contractFrom : ""}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="Start Date"
                        onChange={(date) => {
                          if (date) {
                            setContractFrom(date);
                          } else {
                            setContractFrom(null);
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-3">
                    <div>
                      <InputGroup>
                        <DatePicker
                          className="datepicker form-control"
                          selected={contractTo ? contractTo : ""}
                          dateFormat="MM-dd-yyyy"
                          placeholderText="End Date"
                          onChange={(date) => {
                            if (date) {
                              setContractTo(date);
                            } else {
                              setContractTo(null);
                            }
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>

                  {userType == Enums.UserRole.admin && <div className="col-3">
                    <DropDown
                      data={allCompanyList}
                      value={selectedCompanyList}
                      style={{ div: "col-12" }}
                      isMulti={true}
                      onChange={(value) =>
                        setSelectedCompanyList(value)
                      }
                      placeholder="Select Company"
                      required
                    // validationText={formInputsErrors.duration_term}
                    />
                  </div>}
                  {userType !== Enums.UserRole.branch_manager &&
                    <div className="col-3">
                      <DropDown
                        data={branchList}
                        value={selectedBranchList}
                        style={{ div: "col-12" }}
                        isMulti={true}
                        onChange={(value) =>
                          setSelectedBranchList(value)
                        }
                        placeholder="Select Branch Manager"
                        required
                      // validationText={formInputsErrors.duration_term}
                      />

                    </div>}

                  <div className="col-3">
                    <Form.Select onChange={(e) => { filterContractStatus(e.target.value) }}>
                      <option value="" selected={!contractStatus && true}>
                        Select Contract Status
                      </option>

                      {Object.keys(Enums.ContractStatus).map((status) => {
                        return (<option
                          value={status}
                          selected={contractStatus == status && true}
                        >
                          {Enums.ContractStatus[status]}
                        </option>)
                      })}

                    </Form.Select>
                  </div>
                  <div className="col-3">
                    <div className="d-flex">
                      <div className="me-2">
                        <Button
                          onClick={searchData}
                          variant="outline-primary"
                          type="button"
                        >
                          <i className="fa fa-search"></i>
                        </Button>
                      </div>
                      <div className="ms-">
                        <Button
                          onClick={resetFilter}
                          variant="outline-primary"
                          type="button"
                        >
                          <i className="fa fa-refresh"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

              </Card.Body>
            </Card>
          </div>

        </div>
      </div>

      {/* <FilterComponent
        searchText={txtSearch}
        PHSearchText={DisplayText.SearchText}
        PHDatepickerFrom={DisplayText.DatepickerFrom}
        PHDatepickerTo={DisplayText.DatepickerTo}
        startDate={filterStartDate}
        endDate={filterEndDate}
        onSearchTextChange={(value) => setTxtSearch(value)}
        onStartDateChange={(value) => setFilterStartDate(value)}
        onEndDateChange={(value) => setFilterEndDate(value)}
        onSearchClick={() => filterCompaniesList()}
      /> */}

      {/* {contractsList.data.length === 0 ? (
        <div className="event-details">
          <NoDataFound fontsize={"150px"} />
        </div>
      ) : ( */}
      <DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
        columns={contractTblColumns}
        data={contractsList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={contractTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
          <div className="col-4">
            <InputText
              formGroupClass={"mb-0"}
              size={'12'}
              type={"text"}
              Label={false}
              placeholder={"Search..."}
              value={txtSearch}
              onChange={(value) => {
                if (value) {
                  setTxtSearch(value)
                } else {
                  setTxtSearch(value)
                }
              }}
            />
          </div>
        </>}
      />
      {/* )} */}
    </>
  );
}

export default Index;
