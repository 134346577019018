import Moment from "moment";
const cryptoKey = "SqT_m&M_$hIv@_393987";

export const commonService = {
  setItem,
  getItem,
  getHeaders,
  getHeadersFromData,
  getLoginUserData,
  getFileType,
  CreateColor,
  convertUTCToLocal,
  convertLocalToUTC,
  convertLocalTimeToUtcTime,
  convertUtcTimeToLocalTime,
  changeTimeformate,
  formatDate,
  checkIsDateDiffInMinus,
  addDayIfInMinus,
  convertTimeToOffsetTime,
  convertTimeToOffsetTimeUTC,
  TimestampConverter,
};

export const AppConfigData = {
  APIBasePath: process.env.REACT_APP_API_URL,
  APIFileBasePath: "https://apiv2.orataro.com/",
  CryptoKey: cryptoKey,
  ERPBasePath: "http://erp.orataro.com/",
  ERPFileBasePath: "http://erp.orataro.com/Files/",
  toolbarConfig: {
    options: ["inline", "list", "history"],
    inline: {
      inDropdown: false,
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    list: {
      inDropdown: false,
      options: ["unordered", "ordered"],
    },
    history: {
      inDropdown: false,
      options: ["undo", "redo"],
    },
  },
  regex: {
    URL: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    YoutubeEmbed:
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  },
  fileIcon: {
    LINK: "fa-regular fa-link-simple",
    YOUTUBE: "fa-brands fa-youtube",
    IMAGE: "fa-regular fa-image",
    AUDIO: "fa-regular fa-music",
    VIDEO: "fa-regular fa-play",
    PDF: "fa-regular fa-file-pdf",
    FILE: "fa-regular fa-file-lines",
  },
};

export const DisplayText = {
  BrowserNotSupportVideo: "Your browser does not support the video tag.",
  BrowserNotSupportAudio: "Your browser does not support the audio tag.",
  UnableToLoadContent:
    "Sorry we are unable to load this content in current page.<br /> Click here for view in new tab",
  Of: "of",
  Welcome: "Welcome",
  MenuHome: "Home",
  SearchText: "Search here...",
};

export const ApiRoutes = {
  CompanySave: AppConfigData.APIBasePath + "company/create",
};

export const SideMenuText = {
  Home: "Home",
  Company: "Companies",
  PaymentTrans: "Payment Transactions",
  Settings: "Settings",
  Setup: "Setup",
  Branch: "Branch Manager",
  ClientRequestQuotations: "Request Quotations",
  Client: "Property Manager",
  Properties: "Properties",
  ClientContacts: "Users",
  Guard: "Guards",
  Incharge: "Incharges",
  Leave: "Manage Leaves",
  AdvancePaymentReq: "Advance Payments",
  Report: "Reports",
  Incident: "Incident",
  MyQuotations: "My Quotations",
  ReviewRatings: "Reviews & Ratings",
  Complaint: "Complaint",
  Notifications: "Notifications",
  Profile: "Profile",
  Logout: "Logout",
};

export const SideMenuRightName = {
  Properties: "properties",
  ClientContacts: "client_contacts",
  PaymentTrans: "payment_transaction",
  Settings: "settings",
  Client: "clients",
  ClientRequestQuotations: "request_quotations",
  Leave: "leaves",
  AdvancePaymentRequests: "advance_payment_requests",
  Reports: "reports",
  MyQuotations: "my_quotations",
  ReviewsRatings: "reviews_ratings",
  Complaint: "complaint",
};

function setItem(key, strString) {
  let CryptoJS = require("crypto-js");
  localStorage.setItem(key, CryptoJS.AES.encrypt(strString, cryptoKey));
}

function getItem(key) {
  let CryptoJS = require("crypto-js");
  let dataValues = localStorage.getItem(key) || "";
  let dataStr = "";
  if (dataValues !== "") {
    var bytes = CryptoJS.AES.decrypt(dataValues, cryptoKey);
    dataStr = bytes.toString(CryptoJS.enc.Utf8);
  }
  return dataStr;
}

function getHeaders() {
  return {
    "Content-type": "application/json",
    Accept: "application/json",
    //admin
    // Authorization: "Bearer 64|ShxzPwN0UQGKHBrfCxrr0gd64SxkHj5cTqNcJdTi",
    //company
    // Authorization: "Bearer 63|rIDvKrRNqWZW2Tj3oWMIJsw3tX1XzXqex4mPfabr",
    //user
    // Authorization: "Bearer 66|1sFfU16qpfHn6rOcclbtmq3PFTNGxuXiA4rPGYSm",
    Authorization: "Bearer " + getItem("auth_token"),
  };
}

function getHeadersFromData() {
  return {
    //'Content-Type': 'multipart/form-data; boundary=classroom',
    //'accept': '*/*',
    Authorization: "Bearer " + getItem("auth_token"),
  };
}

function getLoginUserData() {
  var data = [
    {
      UserID: "00000000-0000-0000-0000-000000000000",
      UserName: "",
      UserType_Term: "",
      WallID: "00000000-0000-0000-0000-000000000000",
      FirstName: "",
      LastName: "",
    },
  ];
  let dataStr = getItem("home");
  if (dataStr !== "") {
    data = JSON.parse(dataStr);
  }

  return data;
}

function convertUTCToLocal(date, format = "") {
  let localDate = "";
  if (date !== null && date !== "" && date !== undefined) {
    let stillUtc = Moment.utc(date).toDate();
    localDate = Moment(stillUtc)
      .local()
      .format(format != "" ? format : "DD-MM-YYYY");
  }
  return localDate;
}

function convertLocalToUTC(date, format = "") {
  let localDate = "";
  if (date !== null && date !== "" && date !== undefined) {
    localDate = Moment(date)
      .utc()
      .format(format != "" ? format : "YYYY-MM-DD HH:mm:ss");
  }
  return localDate;
}

function convertLocalTimeToUtcTime(date, format = "") {
  let localDate = "";
  if (date !== null && date !== "" && date !== undefined) {
    localDate = Moment(date, "HH:mm:ss")
      .utc()
      .format(format != "" ? format : "HH:mm:ss");
  }
  return localDate;
}

function convertUtcTimeToLocalTime(date, format = "", initial_format = "") {
  let localDate = "";
  if (date !== null && date !== "" && date !== undefined) {
    // let stillUtc = Moment.utc(date).toDate();
    localDate = Moment.utc(
      date,
      initial_format != "" ? initial_format : "HH:mm:ss"
    )
      .local()
      .format(format != "" ? format : "HH:mm:ss");
  }
  return localDate;
}
function convertTimeToOffsetTime(
  time,
  offset,
  format = "",
  initial_format = ""
) {
  let localTime = "";
  let mainOffsetStr = offset;
  offset = offset.replaceAll("+", "");
  offset = offset.replaceAll("-", "");
  let timeArray = offset.split(":");
  let hours = 0;
  let minutes = 0;
  if (timeArray.length) {
    if (typeof timeArray[0] !== "undefined") {
      hours = parseInt(timeArray[0]);
    }
    if (typeof timeArray[1] !== "undefined") {
      minutes = parseInt(timeArray[1]);
    }
  }
  if (time !== null && time !== "" && time !== undefined) {
    if (mainOffsetStr.includes("+")) {
      localTime = Moment(time, initial_format != "" ? initial_format : "HH:mm")
        .add(hours, "hours")
        .format(format != "" ? format : "HH:mm");
      localTime = Moment(
        localTime,
        initial_format != "" ? initial_format : "HH:mm"
      )
        .add(minutes, "minutes")
        .format(format != "" ? format : "HH:mm");
    } else {
      localTime = Moment(time, initial_format != "" ? initial_format : "HH:mm")
        .subtract(hours, "hours")
        .format(format != "" ? format : "HH:mm");
      localTime = Moment(
        localTime,
        initial_format != "" ? initial_format : "HH:mm"
      )
        .subtract(minutes, "minutes")
        .format(format != "" ? format : "HH:mm");
    }
  }
  return localTime;
}
function convertTimeToOffsetTimeUTC(
  time,
  offset,
  format = "",
  initial_format = ""
) {
  let localTime = "";
  let mainOffsetStr = offset;
  offset = offset.replaceAll("+", "");
  offset = offset.replaceAll("-", "");
  let timeArray = offset.split(":");
  let hours = 0;
  let minutes = 0;
  if (timeArray.length) {
    if (typeof timeArray[0] !== "undefined") {
      hours = parseInt(timeArray[0]);
    }
    if (typeof timeArray[1] !== "undefined") {
      minutes = parseInt(timeArray[1]);
    }
  }
  if (time !== null && time !== "" && time !== undefined) {
    if (mainOffsetStr.includes("+")) {
      localTime = Moment(time, initial_format != "" ? initial_format : "HH:mm")
        .subtract(hours, "hours")
        .format(format != "" ? format : "HH:mm");
      localTime = Moment(
        localTime,
        initial_format != "" ? initial_format : "HH:mm"
      )
        .subtract(minutes, "minutes")
        .format(format != "" ? format : "HH:mm");
    } else {
      localTime = Moment(time, initial_format != "" ? initial_format : "HH:mm")
        .add(hours, "hours")
        .format(format != "" ? format : "HH:mm");
      localTime = Moment(
        localTime,
        initial_format != "" ? initial_format : "HH:mm"
      )
        .add(minutes, "minutes")
        .format(format != "" ? format : "HH:mm");
    }
  }
  return localTime;
}

function changeTimeformate(time, format = "") {
  let localDate = "";
  if (time !== null && time !== "" && time !== undefined) {
    // let stillUtc = Moment.utc(date).toDate();
    localDate = Moment.utc(time, "HH:mm:ss").format(
      format != "" ? format : "HH:mm:ss"
    );
  }
  return localDate;
}

function formatDate(date, format = "") {
  let localDate = "";
  if (date !== null && date !== "" && date !== undefined) {
    localDate = Moment(date).format(format != "" ? format : "YYYY-MM-DD");
  }
  return localDate;
}

function getFileType(file) {
  let FileType = "FILE";
  if (file !== null && file !== undefined) {
    if (file.type !== null && file.type !== "" && file.type !== undefined) {
      if (file.type.toUpperCase().indexOf("IMAGE") >= 0) {
        FileType = "IMAGE";
      } else if (file.type.toUpperCase().indexOf("AUDIO") >= 0) {
        FileType = "AUDIO";
      } else if (file.type.toUpperCase().indexOf("VIDEO") >= 0) {
        FileType = "VIDEO";
      } else if (file.type.toUpperCase().indexOf("PDF") >= 0) {
        FileType = "PDF";
      }
    }
  }
  return FileType;
}

function CreateColor(str) {
  let BGRGB = "000000";
  let FRGB = "FFFFFF";
  if (str !== "") {
    str = str + "000000";
    let hash = hashCode(str);
    BGRGB = intToRGB(hash);
    FRGB = invertColor(BGRGB);
  }
  return { BGColor: BGRGB, FontColor: FRGB };
}

function hashCode(str) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "000000".substring(0, 6 - c.length) + c;
}

function invertColor(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  //var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
  //    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
  //    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);

  var r = (255).toString(16),
    g = (255).toString(16),
    b = (255).toString(16);
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}
function checkIsDateDiffInMinus(start_time, end_time) {
  return Moment(start_time).diff(end_time, "seconds");
}
function addDayIfInMinus(start_time, end_time, date, format = "MM-DD-YYYY") {
  let new_date = date;
  if (Moment(start_time).diff(end_time, "seconds") < 0) {
    new_date = Moment(new_date, "MM-DD-YYYY").add(1, "days").format(format);
  }
  return new_date;
}
function TimestampConverter(timeString) {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  let result = "";

  if (hours > 0) {
    result += `${hours} h `;
  }
  if (minutes > 0 || hours > 0) {
    result += `${minutes} m `;
  }
  result += `${seconds} s`;

  return result.trim();
}
