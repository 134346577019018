import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import DefaultImg from "../../assets/Images/Icon/no_image.png";
import DocImg from "../../assets/Icons/doc.png";
import XlsImg from "../../assets/Icons/xls.png";
import PdfImg from "../../assets/Icons/pdf.png";
import JpgImg from "../../assets/Icons/jpg.png";
import DropDown from "../../components/common/DropDown";
import { badgesEnum } from "../../constants";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import MultiSelDropDown from "../../components/common/MultiSelDropDown";
import NewLoader from "../../components/loader/NewLoader";

// import MasterIcon from "../../assets/Images/Payment/MasterCard.png";

const initialSelectedDocuments = { company_name: "", documents: [] };
const showAddCompanyArr = ["in_progress", "pending"];

function Create() {
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [docShow, setDocShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState(
    initialSelectedDocuments
  );
  const location = useLocation();

  const routeParams = location.state;

  const navigate = useNavigate();

  const onSaveCompanies = async () => {
    if (selectedCompanies.length > 0) {
      setIsLoading(true);
      const response = await APICall(ApiRoutes.QuotationAddCompany, {
        quotation_id: formInputs.quotation_id,
        company_list: selectedCompanies,
      });
      await getDetails();
      handleClose();
      setIsLoading(false);
    } else {
      setCompanyError("Please select at least one company");
    }
  };

  const handleViewCompanyInfo = (comapny_id) => {
    navigate('/contract/company/profile', { state: { comapny_id: comapny_id } })
  }
  const handleViewBranchInfo = (branch_id) => {
    navigate('/contract/branch/profile', { state: { branch_id: branch_id } })
  }

  let auth_user = commonService.getLoginUserData();

  useEffect(() => {
    getDetails();
  }, []);


  const [companyList, setCompanyList] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [formInputs, setInput] = useState({
    property_name: "",
    duration_title: "",
    service_hours_title: "",
    no_of_guards: "",
    no_of_checkpoints: "",
    no_of_patrolling_in_day: "",
    company_list: [],
  });
  const [companyError, setCompanyError] = useState("");
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const deleteCompany = async (item) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      await APICall(ApiRoutes.QuotationCancelCompany, {
        quote_company_id: item.quote_company_id,
      });
      await getDetails();
    }
  };

  //TODO: To be changed.
  const getCompanyList = async (company_list) => {
    const res = await APICall(ApiRoutes.QuotationCompanyList, {
      ids: company_list.map((mapItem) => mapItem.association_id),
    });
    if (res) {
      setCompanyList(res.data.list);
    }
  };

  const getExtBaseFile = (url, ext, isPreview = false) => {
    let result = "";
    const imgExtensions = ["jpg", "jpeg", "png", "gif", "JPG", "svg"];
    const pdfExtensions = ["pdf"];
    const xlsExtensions = ["xlsx", "xls"];
    const docExtensions = ["doc", "docx", "ppt", "pptx", "txt"];


    if (imgExtensions.includes(ext)) {
      isPreview ? (result = url) : (result = JpgImg);
    } else if (docExtensions.includes(ext)) {
      result = DocImg;
    } else if (pdfExtensions.includes(ext)) {
      result = PdfImg;
    } else if (xlsExtensions.includes(ext)) {
      result = XlsImg;
    } else {
      result = DefaultImg;
    }
    return result;
  };

  const renderCompanyCard = (item, index) => {
    const getConditionalText = (keyName) => {
      if (item.association_type_term === "branch_manager") {
        return item["branch_" + keyName];
      } else {
        return item["company_" + keyName];
      }
    };
    return (
      <div className="companies-section">
        <div className="rounded border p-3 mb-3">
          <div className="d-md-flex justify-content-between">
            <div className="d-sm-flex align-items-center">
              <div className="text-center mb-3 mb-sm-0 ">
                <img
                  src={
                    item.company_profile_img !== null ||
                      item.branch_profile_img !== null
                      ? item.association_type_term === "branch_manager"
                        ? item.branch_profile_img === null
                          ? DefaultImg
                          : process.env.REACT_APP_ASSETS_URL +
                          item.branch_profile_img
                        : process.env.REACT_APP_ASSETS_URL +
                        item.company_profile_img
                      : DefaultImg
                  }
                  className="rounded"
                  alt="user"
                  width="100"
                  height="100"
                />
              </div>
              <div className="card-information align-items-center ms-sm-4">
                {item.association_type_term === "branch_manager" && (
                  <div className="d-flex">
                    <dt>Branch Manager:</dt>
                    <dd onClick={(e) => {
                      e.stopPropagation();

                      if (item.quote_company_status == 'accepted' || item.quote_company_status == 'confirmed' || item.quote_company_status == 'completed') {

                        handleViewBranchInfo(item.branch_id)
                      }
                    }} className={`ms-sm-2 ms-0 p-0 ${item.quote_company_status == 'accepted' || item.quote_company_status == 'confirmed' || item.quote_company_status == 'completed' ? "text-decoration-underline nav-link cursor-pointer" : ""}`}>{item.branch_name}</dd>
                  </div>
                )}
                <div className="d-sm-flex">
                  <dt>Comapany:</dt>
                  <dd onClick={(e) => {
                    e.stopPropagation();
                    // {item.quote_company_status == 'accepted' || item.quote_company_status == 'confirmed'  || item.quote_company_status == 'completed' ?   :"" }
                    if (item.quote_company_status == 'accepted' || item.quote_company_status == 'confirmed' || item.quote_company_status == 'completed') {
                      handleViewCompanyInfo(item.company_id)
                    }
                  }} className={`ms-sm-2 ms-0 p-0 ${item.quote_company_status == 'accepted' || item.quote_company_status == 'confirmed' || item.quote_company_status == 'completed' ? "text-decoration-underline nav-link cursor-pointer" : ""}`}>{item.company_name}</dd>
                </div>
                <div className="d-sm-flex">
                  <dt>Contact:</dt>
                  <dd className="ms-sm-2 ms-0">{getConditionalText("phone")}</dd>
                </div>
                <div className="d-sm-flex">
                  <dt>Email:</dt>
                  <dd className="ms-sm-2 ms-0">{getConditionalText("email")}</dd>
                </div>
                <div className="d-sm-flex">
                  <dt>Status:</dt>
                  <dd className="ms-sm-4 ms-0 mb-0">
                    {formInputs?.quotation_status &&
                      badgesEnum[
                      item.quote_company_status
                      // item.quote_company_status === "in_progress"
                      //   ? "quote_rec"
                      //   : item.quote_company_status
                      ]}
                  </dd>
                </div>
                <div className="d-sm-flex">
                  <dt>Company Profile:</dt>
                  <dd className="ms-sm-2 ms-0">
                    <a href={item.company_portfolio_url ? process.env.REACT_APP_ASSETS_URL + item.company_portfolio_url : DefaultImg} target="_blank"><u>Click Here</u></a>
                  </dd>
                </div>
                {/* <div className="d-sm-flex">
                  <dt>Company Profile:</dt>
                  {item.company_portfolio_url

                    ? (item.quote_company_status == 'accepted' || item.quote_company_status == 'confirmed' || item.quote_company_status == 'completed')

                      ? <dd className="ms-sm-2 ms-0">
                        <a href={item.company_portfolio_url ? process.env.REACT_APP_ASSETS_URL + item.company_portfolio_url : DefaultImg} target="_blank"><u>Click Here</u></a>
                      </dd>

                      : <dd className="ms-sm-2 ms-0">-</dd> : <dd className="ms-sm-2 ms-0">-</dd>}
                </div> */}
                {item.documents.length > 0 && (
                  <div
                    className="d-flex mt-2"
                    onClick={() => {
                      handleDocShow({
                        company_name: item.company_name,
                        documents: item.documents,
                      });
                    }}
                  >
                    {item.documents.map(renderImageIcons)}
                  </div>
                )}
              </div>
            </div>
            <div className="d-md-flex flex-md-column text-md-start text-lg-end mt-4 mt-md-0">
              {item.quote_company_status === "in_progress" &&
                (isConfirmLoading === index ? (
                  <Button variant="success mb-2">
                    <Spinner animation="border" size="sm" /> Loading...
                  </Button>
                ) : (<>

                  <Button
                    variant="success mb-2 w-100 w-sm-auto"
                    style={{ padding: "0.25rem 0.8rem" }}
                    onClick={() => {
                      onConfirmContract(item.quote_company_id, index);
                    }}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="danger mb-2 w-100 w-sm-auto"
                    style={{ padding: "0.25rem 0.8rem" }}
                    onClick={() => {
                      deleteCompany(item);
                    }}
                  >
                    Decline
                  </Button>
                </>))}
              {item.quote_company_status === "pending" && (
                <Button
                  variant="danger w-100 w-sm-auto"
                  style={{ padding: "0.25rem 0.8rem" }}
                  onClick={() => {
                    deleteCompany(item);
                  }}
                >
                  Decline
                </Button>
              )}
              {item.documents.length > 0 && (
                <Button
                  variant="outline-primary w-100 w-sm-auto"
                  style={{ padding: "0.2rem 0.8rem" }}
                  onClick={() => {
                    handleDocShow({
                      company_name: item.company_name,
                      documents: item.documents,
                    });
                  }}
                >
                  View Documents
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderNoData = () => (
    <div className="added-cards">
      <div className="cardMaster rounded border p-3 mb-3">
        <div className="d-flex justify-content-center flex-sm-row flex-column">
          <div className="card-information">
            <div className="d-flex align-items-center mb-2">
              <h4 className="card-title text-center my-2">
                No selected company, select some to get Quotation!
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderImagePreview = (item, index) => {
    return (
      <div className="added-cards col-lg-2">
        <div className="cardMaster rounded border p-3 mb-3">
          <div className="card-information col-12">
            <div className="align-items-center mb-2">
              <div id="profile_pic_1_preview" className="image-fixed">
                <img
                  className="obj_fit_img rounded w-100"
                  src={getExtBaseFile(
                    process.env.REACT_APP_ASSETS_URL + item.doc_url,
                    item.doc_title.split(".").pop(),
                    true
                  )}
                  alt=""
                  height="90"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DefaultImg;
                  }}
                />
              </div>
              <h6 className="text-center text-truncate mt-2 col-12">
                {item.doc_title}
              </h6>
              <a
                href={process.env.REACT_APP_ASSETS_URL + item.doc_url}
                target="_blank"
                className=" d-flex justify-content-center mt-2 col-12 text-primary h6"
              >
                Preview
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getDetails = async () => {
    setDataTableLoading(true)
    const responseData = await APICall(ApiRoutes.QuotationDetails, {
      id: routeParams.id,
    });
    if (responseData) {
      setDataTableLoading(false)
      setInput({
        ...responseData.data,
        company_list: responseData.data.companies_list,
      });
    }
    await getCompanyList(responseData.data.companies_list);
  };

  const onConfirmContract = async (quote_company_id, index) => {
    setIsConfirmLoading(index);
    const response = await APICall(ApiRoutes.QuotationClientConfirm, {
      quote_company_id: quote_company_id,
    });
    if (response) {
      await getDetails();
    }
    setIsConfirmLoading(null);
  };

  const renderImageIcons = (item, index) => {
    return (
      <img
        className="obj_fit_img rounded me-1"
        src={getExtBaseFile(
          process.env.REACT_APP_ASSETS_URL + item.doc_url,
          item.doc_title.split(".").pop()
        )}
        alt=""
        height="30"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = DefaultImg;
        }}
      />
    );
  };

  const handleClose = () => {
    setShow(false);
    setCompanyError("");
    setSelectedCompanies([]);
  };
  const handleDocClose = () => {
    setDocShow(false);
    setSelectedDocuments(initialSelectedDocuments);
  };
  const handleShow = () => setShow(true);
  const handleDocShow = (items) => {
    setSelectedDocuments(items);
    setDocShow(true);
  };

  const getLocation = () => {
    if (formInputs?.property_location) {
      const locationString = formInputs.property_location;
      return (
        locationString.address1 +
        ", " +
        (locationString?.address2 ? locationString.address2 + ", " : "") +
        locationString.city +
        ", " +
        locationString.state +
        ", " +
        locationString.country +
        " - " +
        locationString.postal_code
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-block d-sm-flex align-items-center">
            <h2 className="content-header-title float-sm-start float-none mb-2 mb-sm-0">
              Get Quotation
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              {/* <Breadcrumb.Item href="/myquotation">Setup</Breadcrumb.Item> */}
              <Breadcrumb.Item href="/myquotation">Quotation</Breadcrumb.Item>
              <Breadcrumb.Item active>Request</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Card>
        <Card.Header>
          <h4 className="card-title">Quotation Details</h4>
        </Card.Header>
        <Card.Body>
          {dataTableLoading ? <NewLoader/>:
          <Row className="address">
            <Col className="col-12 col-xl-6">
              <dl className="mb-0 row">
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.property_name}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Category Type:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.property_info?.property_category_type_title}
                </dd>
                {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property Type:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.property_info?.property_development_type_title}
                </dd> */}
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Duration:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.duration_title}
                </dd>
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Service Hours:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.service_hours_title}
                </dd>
                {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Status:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.quotation_status &&
                    badgesEnum[formInputs.quotation_status]}
                </dd> */}
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">Submission Date:</dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.created_at &&
                    moment(formInputs?.created_at).format("DD MMM, YYYY")}
                </dd>
              </dl>
            </Col>
            <Col className="col-12 col-xl-5">
              <dl className="mb-0 row">
                <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Status:</dt>
                <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                  {formInputs?.quotation_status &&
                    badgesEnum[formInputs.quotation_status]}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of Guards:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.no_of_guards}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of patrolling in a day:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs?.no_of_patrolling_in_day}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Number of checkpoints:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">
                  {formInputs.no_of_checkpoints}
                </dd>
                <dt className="mb-2 col-sm-12 col-md-4 col-lg-6">
                  Property Location:
                </dt>
                <dd className="mb-2 col-md-8 col-sm-8 col-lg-6">{getLocation()}</dd>
              </dl>
            </Col>
          </Row>}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <h4 className="card-title">Companies / Branch Manager</h4>
          {/* {showAddCompanyArr.includes(formInputs?.quotation_status) && (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleShow}
            >
              <i className="fa-solid fa-plus"></i> Select Company / Branch Manager
            </button>
          )} */}
        </Card.Header>
       
        <Card.Body>
        {dataTableLoading ? <NewLoader/>:
          <>
          {formInputs.company_list.length > 0
            ? formInputs.company_list.map(renderCompanyCard)
            : renderNoData()}
            </>}
        </Card.Body>
      </Card>

      <Modal className="modal-popup" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Company / Branch Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <MultiSelDropDown
            data={companyList}
            onChangeValue={(value) => {
              setSelectedCompanies(value)
            }}
            disabled={false}
            colDiv={'col-md-12 col-12'}
            title={'Select Company / Branch Manager'}
            inputClassName={'class-custom'}
            inputLable={'Select Company / Branch Manager'}
            validationText={companyError}
          />
          {/* <DropDown
            style={{ div: "col-md-12 col-12" }}
            data={companyList}
            // title={"Company / Branch Manager"}
            value={selectedCompanies}
            isMulti={true}
            onChange={(value) => setSelectedCompanies(value)}
            required
            validationText={companyError}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleClose}>
            Close
          </Button>
          {isLoading === false ? (
            <>
              <Button variant="primary" onClick={onSaveCompanies}>
                Save
              </Button>
            </>
          ) : (
            <Button variant="primary">
              <Spinner animation="border" size="sm" /> Loading...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        className="modal-popup"
        show={docShow}
        onHide={handleDocClose}
        dialogClassName="modal-dialog modal-xl modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Documents uploaded by {selectedDocuments.company_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="scrollbar-container media-list scrollable-container ps ps--active-y"
            style={{ "max-height": "30rem" }}
          >
            <div className="row pt-4 me-2">
              {selectedDocuments.documents.length > 0 &&
                selectedDocuments.documents.map(renderImagePreview)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleDocClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Create;
