import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import DatePickerInput from "../../../components/common/DatePickerInput";
import DropDown from "../../../components/common/DropDown";
import ImgInput from "../../../components/common/ImgInput";
import InputText from "../../../components/form/inputText";
import InputSearchLocation from "../../../components/form/InputSearchLocation";

export default function CreateWizardThree({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
}) {
  const [validated, setValidated] = useState(false);
  const handleLocation = (addressInfoArray) => {
    setInput({
      ...formInputs,
      // locations :[{
      // ...formInputs.locations[0],
      address1: addressInfoArray.street_address,
      city: addressInfoArray.city,
      state: addressInfoArray.state,
      postal_code: addressInfoArray.postal_code,
      country: addressInfoArray.country,
      longitude: addressInfoArray.lng,
      latitude: addressInfoArray.lat,
      // }]
    })
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header">
          <InputSearchLocation size={"12"} placeholder="Search location" onPlaceChange={handleLocation} />

        </div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Row>
            <InputText
              type={"text"}
              title={"Address"}
              value={formInputs.address1}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  address1: value,
                })
              }
              validationText={formInputsErrors.address1}
              required
            />
            <InputText
              type={"text"}
              title={"City"}
              value={formInputs.city}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  city: value,
                })
              }
              validationText={formInputsErrors.city}
              required
            />
          </Row>
          <Row>
            <InputText
              type={"text"}
              title={"State"}
              value={formInputs.state}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  state: value,
                })
              }
              validationText={formInputsErrors.state}
              required
            />
            <InputText
              type={"text"}
              title={"Postal code"}
              value={formInputs.postal_code}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  postal_code: value,
                })
              }
              validationText={formInputsErrors.postal_code}
              required
            />
          </Row>
          <Row>
            <InputText
              type={"text"}
              title={"Country"}
              value={formInputs.country}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  country: value,
                })
              }
              validationText={formInputsErrors.country}
              required
            />
          </Row>
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15 "
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
