import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Breadcrumb, Button, Card, Form, Row, Spinner } from "react-bootstrap";
import InputText from '../../components/form/inputText';
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import ImgInput from "../../components/common/ImgInput";

export default function WebSetting() {
    const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    const [formInputs, setInput] = useState({
        setting_id: "",
        default_email: "",
        email_protocol: "",
        smtp_port: "",
        smtp_crypto: "",
        smtp_host: "",
        smtp_user: "",
        from_name: "",
        smtp_password: "",
        website_logo :""
    });
    const [formInputsErrors, setInputErrors] = useState({
        default_email: "",
        email_protocol: "",
        smtp_port: "",
        smtp_crypto: "",
        smtp_host: "",
        smtp_user: "",
        from_name: "",
        smtp_password: ""
    });
    const validateMessage = {
        default_email: 'Default email is required',
        email_protocol: "Email protocol is required",
        smtp_port: "SMTP Port is required",
        smtp_crypto: "SMTP Crypto is required",
        smtp_host: "SMTP host is required",
        smtp_user: "SMTP user is required",
        from_name: "SMTP from name is required",
        smtp_password: "SMTP password is required",
        validate_email: "Please enter valid email address"
    }
    const [isFormClick, setIsFormClick] = useState(false);



    const validateForm = () => {
        let isValidate = true;
        let errorJson = {};
        Object.keys(formInputsErrors).forEach((error_key) => {
            let error_ms = "";
            if (formInputs[error_key] === "") {
                error_ms = validateMessage[error_key]
                isValidate = false;
            }
            if (error_key === 'default_email') {
                if (formInputs[error_key] !== "" && !pattern.test(formInputs.default_email)) {
                    error_ms = validateMessage.validate_email
                    isValidate = false;
                }
            }
            errorJson = {
                ...errorJson,
                [error_key]: error_ms
            }
        })
        setInputErrors(errorJson)
        return isValidate;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsFormClick(true)
            let res = await APICall(ApiRoutes.updateWebSettings, {
                ...formInputs,
                website_logo : formInputs.website_logo?.includes(
                    process.env.REACT_APP_ASSETS_URL
                  )
                    ? null
                    : formInputs.website_logo,
            })
            if (res.status === 1) {
                fetchSettingDetails()
                setIsFormClick(false)
            }

        }
    }
    const fetchSettingDetails = async () => {
        let res = await APICall(ApiRoutes.getWebSettings)
        if (res.status === 1) {
            if(res.data){
                setInput({
                    setting_id: res.data.id,
                    default_email: res.data.default_email,
                    email_protocol: res.data.email_protocol,
                    smtp_port: res.data.smtp_port,
                    smtp_crypto: res.data.smtp_crypto,
                    smtp_host: res.data.smtp_host,
                    smtp_user: res.data.smtp_user,
                    from_name: res.data.from_name,
                    smtp_password: res.data.smtp_password
                })
            }           
        }

    }

    useEffect(() => {
        fetchSettingDetails()
    }, []);

    return (<>
        <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
                <div className="breadcrumbs-top d-flex align-items-center">
                    <h2 className="content-header-title float-start mb-0">
                        Web Setting
                    </h2>
                    <Breadcrumb className="breadcrumb-chevron">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>Web Setting</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>
        <Card className="pt-4">
            <Card.Body>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <Row>
                        <InputText
                            type={"text"}
                            title={"Default Email"}
                            value={formInputs.default_email}
                            onChange={(value) =>
                                setInput({
                                    ...formInputs,
                                    default_email: value,
                                })
                            }
                            validationText={formInputsErrors.default_email}
                        />

                        <InputText
                            type={"text"}
                            title={"Email Protocol"}
                            value={formInputs.email_protocol}
                            onChange={(value) =>
                                setInput({
                                    ...formInputs,
                                    email_protocol: value,
                                })
                            }
                            validationText={formInputsErrors.email_protocol}
                        />
                    </Row>
                    <Row>
                        <InputText
                            type={"text"}
                            title={"SMTP Port"}
                            value={formInputs.smtp_port}
                            onChange={(value) =>
                                setInput({
                                    ...formInputs,
                                    smtp_port: value,
                                })
                            }
                            validationText={formInputsErrors.smtp_port}
                        />

                        <InputText
                            type={"text"}
                            title={"SMTP Crypto"}
                            value={formInputs.smtp_crypto}
                            onChange={(value) =>
                                setInput({
                                    ...formInputs,
                                    smtp_crypto: value,
                                })
                            }
                            validationText={formInputsErrors.smtp_crypto}
                        />
                    </Row>
                    <Row>
                        <InputText
                            type={"text"}
                            title={"SMTP Host"}
                            value={formInputs.smtp_host}
                            onChange={(value) =>
                                setInput({
                                    ...formInputs,
                                    smtp_host: value,
                                })
                            }
                            validationText={formInputsErrors.smtp_host}
                        />

                        <InputText
                            type={"text"}
                            title={"SMTP From Name"}
                            value={formInputs.from_name}
                            onChange={(value) =>
                                setInput({
                                    ...formInputs,
                                    from_name: value,
                                })
                            }
                            validationText={formInputsErrors.from_name}
                        />
                    </Row>
                    <Row>
                        <InputText
                            type={"text"}
                            title={"SMTP Password"}
                            value={formInputs.smtp_password}
                            onChange={(value) =>
                                setInput({
                                    ...formInputs,
                                    smtp_password: value,
                                })
                            }
                            validationText={formInputsErrors.smtp_password}
                        />

                        <InputText
                            type={"text"}
                            title={"SMTP User"}
                            value={formInputs.smtp_user}
                            onChange={(value) =>
                                setInput({
                                    ...formInputs,
                                    smtp_user: value,
                                })
                            }
                            validationText={formInputsErrors.smtp_user}
                        />
                    </Row>

                    {/* <div className="col-12 row">
                        <ImgInput
                            title={"Website Logo"}
                            img_src={formInputs.website_logo}
                            input_ref_id="website_logo"
                            onChange={(value) => {
                                setInput({
                                    ...formInputs,
                                    website_logo: value,
                                });
                            }}
                        />
                    </div> */}


                    <div className="col-12 d-flex justify-content-end">
                        {isFormClick === false ? (
                            <>
                                <Button
                                    variant="primary"
                                    className="btn-15"
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </>
                        ) : (
                            <Button variant="primary" className="btn-15">
                                <Spinner animation="border" size="sm" /> Loading...
                            </Button>
                        )}
                    </div>
                </Form>
            </Card.Body>
        </Card>
    </>)
}
