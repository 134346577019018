import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClientUsers from "../../components/clientusers/ClientUsers";



// import FilterComponent from "../../components/common/FilterComponent";
// import NoDataFound from "../../components/common/NoDataFound";
import { Enums } from "../../constants/enums";
import getUserAccess from "../../helpers/userAccess";

export default function Listing() {

  const RoleRights = getUserAccess(Enums.UserRights.users);

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                User List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/property">Setup</Breadcrumb.Item>
                <Breadcrumb.Item active>Users</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>

        <div className="col-12 d-flex align-items-center justify-content-end mb-3 mt-2">
          {RoleRights.is_update === 1 && (
            <Link to="/user/create">
              <button
                className="btn btn-primary px-3 pr-4 popup-send-button"
                type="button"
              >
                <i className="fa-regular fa-plus"></i> Add User
              </button>
            </Link>
          )}
        </div>
      </div>

      {/* <FilterComponent
        searchText={txtSearch}
        PHSearchText={DisplayText.SearchText}
        PHDatepickerFrom={DisplayText.DatepickerFrom}
        PHDatepickerTo={DisplayText.DatepickerTo}
        startDate={filterStartDate}
        endDate={filterEndDate}
        onSearchTextChange={(value) => setTxtSearch(value)}
        onStartDateChange={(value) => setFilterStartDate(value)}
        onEndDateChange={(value) => setFilterEndDate(value)}
        onSearchClick={() => filterCompaniesList()}
      />

      {usersList.data.length === 0 ? (
        <div className="event-details">
          <NoDataFound fontsize={"150px"} />
        </div> */}
      {/* ) : ( */}
      <ClientUsers RoleRights={RoleRights}/>
      {/* <DataTable
        columns={companyTblColumns}
        data={usersList.data}
        progressPending={dataTableLoading}
        pagination
        paginationServer
        paginationTotalRows={companyTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
      /> */}
      {/* )} */}
    </>
  );
}
