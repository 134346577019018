import React, { useState } from 'react'
import { useEffect } from 'react';
import { Card, Col, Row, Nav, Tab, Breadcrumb, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApiRoutes, contractStatusEnum, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import DataTable from "react-data-table-component";
import { commonService } from '../../services/common.service';
import CompanyBranchList from './CompanyBranchList';
import CompanyInchargeList from './CompanyInchargeList';
import CompanyGuardList from './CompanyGuardList';
import NewLoader from '../../components/loader/NewLoader';

export default function View() {
  const navigate = useNavigate()

  let is_profile = null;
  const location = useLocation()
  if (location.state) {
    if ('is_profile' in location.state) {
      if (location.state.is_profile) {
        is_profile = true
      }
    }
  }


  const userType = JSON.parse(commonService.getItem("user")).association_type_term;
  const userData = JSON.parse(commonService.getItem("user"));
  const params = useParams();
  const company_id = params.company_id;
  const [isLoading, setIsLoading] = useState(true)

  const [itemDetails, setItemDetails] = useState({});
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [contractList, setContractList] = useState({
    isData: false,
    data: [],
  });
  const [contractTotalRows, setContractTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [pageNo, setPageNo] = useState(1);

  const fetchDetails = async () => {
    let res = await APICall(ApiRoutes.CompanyEditDetails, {
      id: company_id
    })
    if (res.status === 1) {
      setItemDetails(res.data)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }
  const renderLocation = () => {
    if (itemDetails.locations.length) {
      const locationString = itemDetails.locations[0];
      let str = (locationString.address1
        ? locationString.address1 + ", "
        : "" )+ (locationString.address2
          ? locationString.address2 + ", "
          : "" )+
          (locationString.city
            ? locationString.city + ", "
            : "") +
          (locationString.state
            ? locationString.state + ", "
            : "") +
          (locationString.country
            ? locationString.country + ", "
            : "") +
          (locationString.postal_code
            ? locationString.postal_code + ", "
            : "");
      str = str.trim();
      str = str.slice(0, -1);
      if (str.length && str && str !== "null") {
        return str;
      } else {
        return "-";
      }
    } else {
      return "";
    }
  }

  const fetchContractList = async (column = "",
    sortDirection = "",
    isFilter = 0
  ) => {
    setDataTableLoading(true)
    let response = await APICall(ApiRoutes.viewContractsList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      association_type_term: Enums.UserRole.company,
      association_id: company_id
    })
    if (response.status === 1) {
      setContractList({ isData: true, data: response.data.list });
      setContractTotalRows(response.data.total);
      setDataTableLoading(false)
    }
  }

  const columns = [
    {
      name: "#",
      selector: (row) => row.full_name,
      sortable: false,
      cell: (row, index, column, id) => {
        return (
          <img
            src={
              row.property_profile_img
                ? process.env.REACT_APP_ASSETS_URL + row.property_profile_img
                : DefaultImg
            }
            className="round"
            alt="user"
            width="40"
            style={{ objectFit: "cover" }}
            height="40"
          />
        );
      },
    },
    // {
    //   name: "Company",
    //   selector: (row) => row.company_name,
    //   sortable: true,
    //   sortField: "company_name",
    //   omit: userType === Enums.UserRole.admin ? false : true
    // },
    {
      name: "Property",
      // selector: (row) => row.property_name,
      sortable: true,
      sortField: "property_name",
      // omit : userType === Enums.UserRole.admin ? true : false ,
      cell: (row, index, column, id) => (
        <a className="text-primary cursor-pointer"
          onClick={() => {
            navigate("/contracts/view", {
              state: {
                id: row.contract_id,
              },
            });
          }}
        >
          {row.property_name}
        </a>
      ),
    },
    // {
    //   name: "Property",
    //   selector: (row) => row.property_name,
    //   sortable: true,
    //   sortField: "property_name",
    //   omit : userType === Enums.UserRole.admin ? false : true ,
    // },
    {
      name: "Branch Manager",
      selector: (row) => row.branch_name,
      sortable: true,
      sortField: "branch_name",
    },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      sortField: "start_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.start_date),
    },
    {
      name: "Expiry Date",
      selector: (row) => row.end_date,
      sortable: true,
      sortField: "end_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.end_date),
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      sortField: "is_active",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            {contractStatusEnum[row.contract_status]}
          </span>
        );
      },
    },

  ];
  React.useEffect(() => {
    fetchContractList("", "", 1);
  }, [pageNo, perPageRecords]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };
  const handleSort = async (column, sortDirection) => {
    fetchContractList(column.sortField, sortDirection, 1);
  };
  const handlePageChange = (page) => {
    setPageNo(page);
  };
  useEffect(() => {
    fetchDetails()
    fetchContractList("", "", 0)
  }, []);


  const ContractsList = () => {
    return (<><DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
      columns={columns}
      progressPending={dataTableLoading}
      progressComponent={<NewLoader />}
      data={contractList.data}
      pagination
      paginationTotalRows={contractTotalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      onSort={handleSort}
      paginationPerPage={perPageRecords}
    />
    </>)
  }

  const renderDetails = () => (
    <Col className="order-1 col-12 order-lg-0 col-lg-5 col-xl-4 contract-company-view">
      <Card>
        <Card.Body>
          <div className="company-profile-avatar">
            <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
              <span className="avatar-content">
                <img className="w-100 h-100 obj-fit-cover" src={itemDetails.profile_img ? process.env.REACT_APP_ASSETS_URL + itemDetails.profile_img : DefaultImg}></img>
              </span>
            </div>
            <div className="d-flex flex-column align-items-center text-center user-info">
              <span className="mb-2 text-primary h4">{itemDetails.company_name}</span>
              <span className="text-capitalize badge bg-light-primary">
                #Company
              </span>
            </div>
            <div className="star-rating static-rating text-center  w-100">
              {[...Array(5).keys()].map((item, index) => {
                return (<>
                  <input checked={Math.ceil(parseFloat(itemDetails.rating)) == 5 - item ? true : false} id={`star-${item}`} type="radio" name="rating" value={item} />
                  <label htmlFor={`star-${item}`} title={`${item} stars`}>
                    <i className="active fa fa-star" aria-hidden="true"></i>
                  </label>
                </>)
              })}
            </div>
          </div>
          <div className="company-figures  company-figures-company">
            <div className="d-flex align-items-start me-2">
              <span className="rounded p-75 badge bg-light-primary">
                <i className="fa-solid fa-code-branch"></i>
              </span>
              <div className="complete-texts">
                <h4 className="mb-0">{itemDetails.total_branches_count}</h4>
                <small className="h4 text-dark">Total Branches</small>
              </div>
            </div>


            <div className="d-flex align-items-start me-2">
              <span className="rounded p-75 badge bg-light-primary">
                <i className=" fa-solid fa-user-group"></i>
              </span>
              <div className="complete-texts">
                <h4 className="mb-0">{itemDetails.total_incharges_count}</h4>
                <small className="h4 text-dark">Total Incharge</small>
              </div>
            </div>

          </div>
          <div className="company-figures company-figures-company">

            <div className="d-flex align-items-start me-2">
              <span className="rounded p-75 badge bg-light-primary">
                <i className="fa-solid fa-users"></i>
              </span>
              <div className="complete-texts">
                <h4 className="mb-0"> {itemDetails.total_guards_count} </h4>
                <small className="h4 text-dark">Total Guards</small>
              </div>
            </div>
            <div className="d-flex align-items-start me-2">
              <span className="rounded p-75 badge bg-light-primary">
                <i className=" fa-solid fa-th-large"></i>
              </span>
              <div className="complete-texts">
                <h4 className="mb-0">{itemDetails.total_contract_count} </h4>
                <small className="h4 text-dark">Total Contracts</small>
              </div>
            </div>

          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-envelope text-primary me-2"></i>
              {itemDetails.email ?
                itemDetails.email : "-"}
            </div>
          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-phone text-primary me-2"></i>
              {itemDetails.phone ?
                itemDetails.phone : "-"}
            </div>
          </div>



          {itemDetails.locations.length ? <div className="company-details mt-1">
            <div className="left-field">{itemDetails?.locations[0] ? <><i className="fa-solid fa-location-dot text-primary me-2"></i> </> : <i className="fa-solid fa-location-dot text-primary me-2"></i>}
              {renderLocation()}
            </div>
          </div> : ""}

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-file  text-primary me-2"></i>
              {itemDetails.company_portfolio_url ?
                <a href={process.env.REACT_APP_ASSETS_URL + itemDetails.company_portfolio_url} target="_blank"><u>Click Here</u></a>
                : "-"}
            </div>
          </div>

          {userData.association_id === itemDetails.company_id && <div className="company-details mt-1">
            <div className="left-field"><i class="fa-solid text-primary me-2 fa-calendar" aria-hidden="true"></i>
              {commonService.convertUTCToLocal(itemDetails.start_date)} - {commonService.convertUTCToLocal(itemDetails.end_date)}
            </div>
          </div>}

          <h6 className='mt-3 font-weight-bold'> Contact Person Details</h6>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-user text-primary me-2"></i>
              {itemDetails.contact_person_name ?
                itemDetails.contact_person_name : "-"}
            </div>
          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-envelope text-primary me-2"></i>
              {itemDetails.contact_person_email ?
                itemDetails.contact_person_email : "-"}
            </div>
          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-phone text-primary me-2"></i>
              {itemDetails.contact_person_phone ?
                itemDetails.contact_person_phone : "-"}
            </div>
          </div>

        </Card.Body>
      </Card>

    </Col>
  );


  return (<>

    {!is_profile ? (
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              Company
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/company">Setup</Breadcrumb.Item>
              <Breadcrumb.Item href="/company">Company</Breadcrumb.Item>
              <Breadcrumb.Item active>Company Details</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>) : ''}

    {!isLoading ? (<div className="app-user-view">
      <Row>
        {renderDetails()}

        <Col className="order-0 col-12 order-lg-1 col-lg-8 col-xl-8 col">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" className="mb-3">
              <Nav.Item className="d-flex cursor-pointer">
                <Nav.Link eventKey="first">
                  <i className="fa-regular fa-th-large"></i>
                  <span className="pills-tab-text">Contracts</span>
                </Nav.Link>
                <Nav.Link eventKey="sec">
                  <i className="fa-regular fa-code-branch"></i>
                  <span className="pills-tab-text">Branches</span>
                </Nav.Link>
                <Nav.Link eventKey="third">
                  <i className="fa-regular fa-users"></i>
                  <span className="pills-tab-text">Guards</span>
                </Nav.Link>
                <Nav.Link eventKey="four">
                  <i className="fa-regular fa-user-group"></i>
                  <span className="pills-tab-text">Incharges</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <ContractsList />
              </Tab.Pane>
              <Tab.Pane eventKey="sec">
                <CompanyBranchList company_id={company_id} />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <CompanyGuardList company_id={company_id} />
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <CompanyInchargeList company_id={company_id} />
              </Tab.Pane>

              {/* {userType == Enums.UserRole.admin && <Tab.Pane eventKey="sec">
                 <ClientUsers client_id = { params.client_id }/>
               </Tab.Pane> } */}
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </div>) : <NewLoader/>}
  </>)
}
