import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { ApiRoutes } from '../../constants';
import { APICall } from '../../services/axiosService';
import { Card, Col, Row, Nav, Tab, Breadcrumb, Button } from "react-bootstrap";
import NewLoader from "../../components/loader/NewLoader";

export default function Guidelines() {
    const [loading, setLoading] = useState(true);

    const [guidelineFile, setGuidelineFile] = useState("");

    const fetchSettingDetails = async () => {

        let res = await APICall(ApiRoutes.getGuidelines)
        if (res.status === 1) {
            if (res.data) {
                setLoading(false)
                setGuidelineFile(res.data.guideline_file ? process.env.REACT_APP_ASSETS_URL + res.data.guideline_file : "")
            } else {
                setLoading(false)
            }
        } else if (res.status === 0) {
            setLoading(false)
        } else {
            setLoading(false)
        }


    }

    useEffect(() => {
        fetchSettingDetails()
    }, []);

    return (<>
        <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
                <div className="breadcrumbs-top d-flex align-items-center">
                    <h2 className="content-header-title float-start mb-0">
                        User Guideline
                    </h2>
                    <Breadcrumb className="breadcrumb-chevron">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        <Breadcrumb.Item active>User Guideline</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
        </div>

        {!loading ? <div className='container vh-100'>
            <div className='row h-100'>
                <div className='col-12 h-100'>
                    {guidelineFile &&
                        <iframe width='100%' height='100%' src={guidelineFile+"#toolbar=0&navpanes=0&scrollbar=0"}></iframe>
                    }
                </div>
            </div>
        </div> : <NewLoader />}
    </>

    )
}
