import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { ApiRoutes, Enums } from "../../constants";
import { APICall } from "../../services/axiosService";
import { alertService, AlertType } from "../../services/alert.service";
import FirstStep from "./AssignScheduleWizard/FirstStep";
import SecondStep from "./AssignScheduleWizard/SecondStep";
import { commonService } from "../../services/common.service";

export default function AssignSchedule({
  getDetails,
  setShowModel,
  guard,
  contractId,
  showModel,
  setGuard,
  offset,
}) {
  let weekDays = Enums.WeekDays;
  //Initial Jsons
  let daysTimeInitialJson = {
    [weekDays.monday]: { start_time: "", end_time: "" },
    [weekDays.tuesday]: { start_time: "", end_time: "" },
    [weekDays.wednesday]: { start_time: "", end_time: "" },
    [weekDays.thursday]: { start_time: "", end_time: "" },
    [weekDays.friday]: { start_time: "", end_time: "" },
    [weekDays.saturday]: { start_time: "", end_time: "" },
    [weekDays.sunday]: { start_time: "", end_time: "" },
  };

  const allDaysTimeInitial = {
    start_time: "",
    end_time: "",
  };

  const validateErrorinitial = {
    start_time: "",
    end_time: "",
  };

  const initialCheckPointsSchedules = {
    [weekDays.monday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.tuesday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.wednesday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.thursday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.friday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.saturday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.sunday]: [{ sc_start_time: "", sc_end_time: "" }],
  };

  const initialWeekTimeValidateErrors = {
    [weekDays.monday]: { start_time: "", end_time: "" },
    [weekDays.tuesday]: { start_time: "", end_time: "" },
    [weekDays.wednesday]: { start_time: "", end_time: "" },
    [weekDays.thursday]: { start_time: "", end_time: "" },
    [weekDays.friday]: { start_time: "", end_time: "" },
    [weekDays.saturday]: { start_time: "", end_time: "" },
    [weekDays.sunday]: { start_time: "", end_time: "" },
  };

  const initialPatrollingSchedulesError = {
    [weekDays.monday]: {},
    [weekDays.tuesday]: {},
    [weekDays.wednesday]: {},
    [weekDays.thursday]: {},
    [weekDays.friday]: {},
    [weekDays.saturday]: {},
    [weekDays.sunday]: {},
  };
  // useStates

  const [weekDaysArray, setWeekDaysArray] = useState([]);
  const [activeWizard, setActiveWizard] = useState(1);
  const [isScheduleAssigned, setIsScheduleAssigned] = useState(false);
  const [loading, setLoading] = useState(false);

  const [daysTime, setDaysTime] = useState({
    [weekDays.monday]: { start_time: "", end_time: "" },
    [weekDays.tuesday]: { start_time: "", end_time: "" },
    [weekDays.wednesday]: { start_time: "", end_time: "" },
    [weekDays.thursday]: { start_time: "", end_time: "" },
    [weekDays.friday]: { start_time: "", end_time: "" },
    [weekDays.saturday]: { start_time: "", end_time: "" },
    [weekDays.sunday]: { start_time: "", end_time: "" },
  });

  const [checkPoinSchedules, setCheckPointSchedules] = useState({
    [weekDays.monday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.tuesday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.wednesday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.thursday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.friday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.saturday]: [{ sc_start_time: "", sc_end_time: "" }],
    [weekDays.sunday]: [{ sc_start_time: "", sc_end_time: "" }],
  });

  const [validateError, setValidateErrors] = useState({
    start_time: "",
    end_time: "",
  });
  const [weekTimeValidateErrors, setWeekTimeValidateErrors] = useState({
    [weekDays.monday]: { start_time: "", end_time: "" },
    [weekDays.tuesday]: { start_time: "", end_time: "" },
    [weekDays.wednesday]: { start_time: "", end_time: "" },
    [weekDays.thursday]: { start_time: "", end_time: "" },
    [weekDays.friday]: { start_time: "", end_time: "" },
    [weekDays.saturday]: { start_time: "", end_time: "" },
    [weekDays.sunday]: { start_time: "", end_time: "" },
  });
  const [allDaysTime, setAllDaysTime] = useState({
    start_time: "",
    end_time: "",
  });

  const [patrollingSchedulesErrors, setPatrollingSchedulesErrors] = useState({
    [weekDays.monday]: {},
    [weekDays.tuesday]: {},
    [weekDays.wednesday]: {},
    [weekDays.thursday]: {},
    [weekDays.friday]: {},
    [weekDays.saturday]: {},
    [weekDays.sunday]: {},
  });

  if (!isScheduleAssigned) {
    if (guard) {
      if (guard.week_schedules.length) {
        let makeJsonWeekTimeArray = {};
        let makeCheckPoinSchedulesJson = {};
        Object.keys(weekDays).forEach((week_term) => {
          let isDataFound = false;
          guard.week_schedules.forEach((gaurdSchedule) => {
            if (gaurdSchedule.day_term === week_term) {
              makeJsonWeekTimeArray = {
                ...makeJsonWeekTimeArray,
                [week_term]: {
                  start_time: gaurdSchedule.start_time
                    ? commonService.convertUtcTimeToLocalTime(
                        gaurdSchedule.start_time,
                        "HH:mm"
                      )
                    : "",
                  end_time: gaurdSchedule.end_time
                    ? commonService.convertUtcTimeToLocalTime(
                        gaurdSchedule.end_time,
                        "HH:mm"
                      )
                    : "",
                },
              };
              let new_patrolling_schedule = [];
              if (gaurdSchedule.patroling_schedules.length) {
                gaurdSchedule.patroling_schedules.forEach(
                  (patroling_schedule) => {
                    let jsonGuard = {
                      sc_end_time: patroling_schedule.sc_end_time
                        ? commonService.convertUtcTimeToLocalTime(
                            patroling_schedule.sc_end_time,
                            "HH:mm"
                          )
                        : "",
                      sc_start_time: patroling_schedule.sc_start_time
                        ? commonService.convertUtcTimeToLocalTime(
                            patroling_schedule.sc_start_time,
                            "HH:mm"
                          )
                        : "",
                    };
                    new_patrolling_schedule = [
                      ...new_patrolling_schedule,
                      jsonGuard,
                    ];
                  }
                );
              } else {
                let jsonGuard = {
                  sc_end_time: "",
                  sc_start_time: "",
                };
                new_patrolling_schedule = [
                  ...new_patrolling_schedule,
                  jsonGuard,
                ];
              }
              makeCheckPoinSchedulesJson = {
                ...makeCheckPoinSchedulesJson,
                [gaurdSchedule.day_term]: new_patrolling_schedule,
              };
              isDataFound = true;
            }
          });
          if (isDataFound == false) {
            makeJsonWeekTimeArray = {
              ...makeJsonWeekTimeArray,
              [week_term]: {
                start_time: "",
                end_time: "",
              },
            };
            let new_patrolling_schedule = [];
            let jsonGuard = {
              sc_end_time: "",
              sc_start_time: "",
            };
            new_patrolling_schedule = [...new_patrolling_schedule, jsonGuard];
            makeCheckPoinSchedulesJson = {
              ...makeCheckPoinSchedulesJson,
              [week_term]: new_patrolling_schedule,
            };
          }
        });
        setDaysTime(makeJsonWeekTimeArray);
        setIsScheduleAssigned(true);
        setCheckPointSchedules(makeCheckPoinSchedulesJson);
      }
    }
  }

  const handleCloseModel = () => {
    setShowModel(false);
    setPatrollingSchedulesErrors(initialPatrollingSchedulesError);
    setCheckPointSchedules(initialCheckPointsSchedules);
    setGuard(null);
    setDaysTime(daysTimeInitialJson);
    setAllDaysTime(allDaysTimeInitial);
    setValidateErrors(validateErrorinitial);
    setWeekTimeValidateErrors(initialWeekTimeValidateErrors);
    setIsScheduleAssigned(false);
    setActiveWizard(1);
    setIsScheduleAssigned(false);
  };

  const handleDayTime = (value, day, type) => {
    if (type == "START") {
      setDaysTime({
        ...daysTime,
        [day]: {
          ...daysTime[day],
          start_time: value,
        },
      });
    } else {
      setDaysTime({
        ...daysTime,
        [day]: {
          ...daysTime[day],
          end_time: value,
        },
      });
    }
  };

  const removeTime = (day) => {
    setDaysTime({
      ...daysTime,
      [day]: {
        ...daysTime[day],
        start_time: "",
        end_time: "",
      },
    });

    setWeekTimeValidateErrors({
      ...weekTimeValidateErrors,
      [day]: {
        start_time: "",
        end_time: "",
      },
    });
  };

  const clearAllDaysInput = () => {
    setAllDaysTime(allDaysTimeInitial);
    setValidateErrors(validateErrorinitial);
    setDaysTime(daysTimeInitialJson);
    setWeekTimeValidateErrors(initialWeekTimeValidateErrors);
  };

  const applyToALl = () => {
    let daysTimeJson = {};
    let isValidate = true;
    var allDaysTimeError = {
      start_time: "",
      end_time: "",
    };
    if (allDaysTime.start_time == "") {
      allDaysTimeError.start_time = "Start time is required";
      isValidate = false;
    }
    if (allDaysTime.end_time == "") {
      allDaysTimeError.end_time = "End time is required";
      isValidate = false;
    }
    // if (
    //   allDaysTime.start_time >= allDaysTime.end_time &&
    //   allDaysTime.start_time != "" &&
    //   allDaysTime.end_time != ""
    // ) {
    //   allDaysTimeError.end_time = "End time must be greater than start time";
    //   isValidate = false;
    // }
    setValidateErrors({
      ...validateError,
      ...allDaysTimeError,
    });
    if (isValidate) {
      weekDaysArray.forEach((weekDay) => {
        daysTimeJson = {
          ...daysTimeJson,
          [weekDay.term]: {
            start_time: allDaysTime.start_time,
            end_time: allDaysTime.end_time,
          },
        };
      });
      setDaysTime(daysTimeJson);
    }
  };
  const validateScheduleForm = () => {
    let isValidate = true;
    let validateErrorsJson = weekTimeValidateErrors;
    weekDaysArray.forEach((weekDay) => {
      let startTimeError = "";
      let endTimeError = "";
      if (
        daysTime[weekDay.term].start_time == "" ||
        daysTime[weekDay.term].end_time == ""
      ) {
        if (
          daysTime[weekDay.term].start_time == "" &&
          daysTime[weekDay.term].end_time != ""
        ) {
          startTimeError = "Start time is required";
          isValidate = false;
        }
        if (
          daysTime[weekDay.term].start_time != "" &&
          daysTime[weekDay.term].end_time == ""
        ) {
          endTimeError = "End time is required";
          isValidate = false;
        }
      }
      // if (
      //   daysTime[weekDay.term].start_time >= daysTime[weekDay.term].end_time &&
      //   daysTime[weekDay.term].start_time != "" &&
      //   daysTime[weekDay.term].end_time != ""
      // ) {
      //   endTimeError = "End time must be greater than start time";
      //   isValidate = false;
      // }
      validateErrorsJson = {
        ...validateErrorsJson,
        [weekDay.term]: {
          start_time: startTimeError,
          end_time: endTimeError,
        },
      };
    });
    setWeekTimeValidateErrors(validateErrorsJson);
    return isValidate;
  };
  function getMinutes(value) {
    const [hours, minutes] = value.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes; // 420
    return totalMinutes;
  }
  function TimeValidateFN(startTime, endTime, targetStartTime, targetEndTime) {
    const startMinutes = getMinutes(startTime);
    const endMinutes = getMinutes(endTime);
    const targetStartMinutes = getMinutes(targetStartTime);
    const targetEndMinutes = getMinutes(targetEndTime);

    if (startMinutes < endMinutes) {
      return true;
    }

    const targetDiff = targetEndMinutes - targetStartMinutes;

    // Handle case where the time range crosses midnight
    const isOvernightRange = endMinutes <= startMinutes;

    const isTargetWithinSingleDayRange =
      targetStartMinutes >= startMinutes && targetEndMinutes <= endMinutes;

    const isTargetAcrossMidnight =
      targetStartMinutes < endMinutes || targetEndMinutes > startMinutes;

    const isTargetValidInOvernightRange =
      (targetStartMinutes >= startMinutes || targetEndMinutes <= endMinutes) &&
      targetDiff < 24 * 60;

    if (isOvernightRange) {
      return isTargetValidInOvernightRange;
    } else {
      return isTargetWithinSingleDayRange || isTargetAcrossMidnight;
    }
  }

  const validatePatrollingSchedule = () => {
    let isPatrollingSchedule = true;
    let patrollingSchedulesErrorsJson = {};

    Object.keys(checkPoinSchedules).forEach((elmDay, index) => {
      checkPoinSchedules[elmDay].forEach((checkpoints_time, j) => {
        let isGreater = false;
        if (
          checkpoints_time.sc_start_time == "" &&
          checkpoints_time.sc_end_time != ""
        ) {
          patrollingSchedulesErrorsJson[elmDay] = {
            ...patrollingSchedulesErrorsJson[elmDay],
            [j]: {
              sc_start_time: "Start time is required",
            },
          };
          isPatrollingSchedule = false;
        }
        if (
          checkpoints_time.sc_start_time != "" &&
          checkpoints_time.sc_end_time == ""
        ) {
          patrollingSchedulesErrorsJson[elmDay] = {
            ...patrollingSchedulesErrorsJson[elmDay],
            [j]: {
              sc_end_time: "End time is required",
            },
          };
          isPatrollingSchedule = false;
        }

        // if (checkpoints_time.sc_start_time != "" && checkpoints_time.sc_end_time != "") {
        //   if (checkpoints_time.sc_start_time >= checkpoints_time.sc_end_time) {
        //     patrollingSchedulesErrorsJson[elmDay] = {
        //       ...patrollingSchedulesErrorsJson[elmDay],
        //       [j]: {
        //         sc_end_time: "End time must be greater than start time"
        //       }
        //     }
        //     isPatrollingSchedule = false;
        //     isGreater = true
        //   }

        // }

        // daysTime[elmDay].end_time < "23:59" && checkpoints_time.end_time >= checkpoints_time.sc_start_time
        //
        // prakash
       

        
        // time validation 9AM - 8AM
        // if (
        //   !TimeValidateFN(
        //     daysTime[elmDay].start_time,
        //     daysTime[elmDay].end_time,
        //     checkpoints_time.sc_start_time,
        //     checkpoints_time.sc_end_time
        //   )
        // ) {
        //   patrollingSchedulesErrorsJson[elmDay] = {
        //     ...patrollingSchedulesErrorsJson[elmDay],
        //     [j]: {
        //       sc_end_time: `Time must be between ${daysTime[elmDay].start_time} to ${daysTime[elmDay].end_time}`,
        //     },
        //   };
        //   isPatrollingSchedule = false;
        // }

        if (
          checkpoints_time.sc_start_time != "" &&
          checkpoints_time.sc_end_time != ""
        ) {
          if (daysTime[elmDay].start_time > daysTime[elmDay].end_time) {
            if (
              !(
                (
                  (checkpoints_time.sc_start_time >=
                    daysTime[elmDay].start_time ||
                    (checkpoints_time.sc_start_time >= "00:00" &&
                      checkpoints_time.sc_start_time <=
                        daysTime[elmDay].end_time)) &&
                  (checkpoints_time.sc_end_time <= daysTime[elmDay].end_time ||
                    (checkpoints_time.sc_end_time <= "23:59" &&
                      checkpoints_time.sc_end_time >=
                        daysTime[elmDay].start_time))
                )

                // checkpoints_time.sc_start_time >= "00:00" &&
                // checkpoints_time.sc_start_time <= daysTime[elmDay].end_time &&
                // checkpoints_time.sc_end_time >= daysTime[elmDay].start_time &&
                // checkpoints_time.sc_end_time <= daysTime[elmDay].end_time
              )
            ) {
              patrollingSchedulesErrorsJson[elmDay] = {
                ...patrollingSchedulesErrorsJson[elmDay],
                [j]: {
                  sc_end_time: `Time must be between ${daysTime[elmDay].start_time} to ${daysTime[elmDay].end_time}`,
                },
              };
              isPatrollingSchedule = false;
            }
          } else {
            if (
              !(
                checkpoints_time.sc_start_time >= daysTime[elmDay].start_time &&
                checkpoints_time.sc_start_time <= daysTime[elmDay].end_time &&
                checkpoints_time.sc_end_time >= daysTime[elmDay].start_time &&
                checkpoints_time.sc_end_time <= daysTime[elmDay].end_time
              )
            ) {
              patrollingSchedulesErrorsJson[elmDay] = {
                ...patrollingSchedulesErrorsJson[elmDay],
                [j]: {
                  sc_end_time: `Time must be between ${daysTime[elmDay].start_time} to ${daysTime[elmDay].end_time}`,
                },
              };
              isPatrollingSchedule = false;
            }
          }
        }
      });
    });

    if (isPatrollingSchedule) {
      if (Object.keys(patrollingSchedulesErrorsJson).length) {
        isPatrollingSchedule = false;
        alertService.alert({
          type: AlertType.Error,
          message: "Please add patrolling schedules of guard.",
        });
      }
    } else {
      alertService.alert({
        type: AlertType.Error,
        message: "Review all schedules",
      });
    }

    setPatrollingSchedulesErrors(patrollingSchedulesErrorsJson);

    return isPatrollingSchedule;
  };

  const submitSchedule = async () => {
    if (validatePatrollingSchedule()) {
      let scheduleDaysArray = [];
      weekDaysArray.forEach((weekDay) => {
        let utlPatrollingSchedule = [];
        if (typeof checkPoinSchedules[weekDay.term] !== "undefined") {
          checkPoinSchedules[weekDay.term].forEach((schedule) => {
            let utlDayTimeJson = {
              sc_start_time: schedule.sc_start_time
                ? commonService.convertTimeToOffsetTimeUTC(
                    schedule.sc_start_time,
                    offset
                  )
                : "",
              sc_end_time: schedule.sc_end_time
                ? commonService.convertTimeToOffsetTimeUTC(
                    schedule.sc_end_time,
                    offset
                  )
                : "",
            };
            utlPatrollingSchedule = [...utlPatrollingSchedule, utlDayTimeJson];
          });
        }

        let scheduleDayJson = {
          day_term: weekDay.term,
          start_time:
            daysTime[weekDay.term].start_time != ""
              ? commonService.convertTimeToOffsetTimeUTC(
                  daysTime[weekDay.term].start_time,
                  offset
                )
              : "",
          end_time:
            daysTime[weekDay.term].end_time != ""
              ? commonService.convertTimeToOffsetTimeUTC(
                  daysTime[weekDay.term].end_time,
                  offset
                )
              : "",
          patroling_schedules: utlPatrollingSchedule,
        };
        scheduleDaysArray.push(scheduleDayJson);
      });
      let data = {
        contract_id: contractId,
        association_id: guard.guard_id,
        association_type_term: Enums.UserRole.guard,
        schedule_days: scheduleDaysArray,
        staff_type:
          guard && guard.staff_type
            ? guard.staff_type
            : Enums.GuardTypes.patrolling_guard,
      };
      let res = await APICall(ApiRoutes.saveSchedules, data);
      if (res.status == 1) {
        handleCloseModel();
        getDetails();
        setLoading(false);
      }
    }
  };

  const validateFirstWizard = () => {
    let isSelected = true;
    let isAllValid = false;
    weekDaysArray.forEach((weekDay) => {
      if (daysTime[weekDay.term].start_time != "") {
        isSelected = false;
      }
      if (daysTime[weekDay.term].end_time != "") {
        isSelected = false;
      }
    });
    if (!isSelected) {
      if (validateScheduleForm()) {
        isAllValid = true;
      }
    } else {
      alertService.alert({
        type: AlertType.Error,
        message: "Select atleast one schedule.",
      });
    }
    return isAllValid;
  };

  const handleNextClick = async () => {
    if (activeWizard === 1) {
      if (validateFirstWizard()) {
        let newCheckPoinSchedules = {};
        Object.keys(daysTime).forEach((check_key) => {
          if (
            daysTime[check_key].start_time != "" &&
            daysTime[check_key].end_time != ""
          ) {
            newCheckPoinSchedules = {
              ...newCheckPoinSchedules,
              [check_key]: checkPoinSchedules[check_key],
            };
          }
        });
        setCheckPointSchedules(newCheckPoinSchedules);
        if (guard && guard.staff_type == Enums.GuardTypes.gate_guard) {
          submitSchedule();
        } else {
          setActiveWizard(activeWizard + 1);
        }
      }
    } else if (activeWizard === 2) {
      submitSchedule();
    }
  };

  const handlePreviousClick = () => {
    if (activeWizard === 1) {
      handleCloseModel();
    } else if (activeWizard === 2) {
      setActiveWizard(activeWizard - 1);
    }
  };

  let fetchWeekDays = async () => {
    let res = await APICall(ApiRoutes.weekDays);
    if (res.status == 1) {
      setWeekDaysArray(res.data.list);
    }
  };
  useEffect(() => {
    fetchWeekDays();
  }, []);

  const renderWizardScreen = () => {
    switch (activeWizard) {
      case 1:
        return (
          <FirstStep
            allDaysTime={allDaysTime}
            setAllDaysTime={setAllDaysTime}
            validateError={validateError}
            applyToALl={applyToALl}
            weekDaysArray={weekDaysArray}
            handleDayTime={handleDayTime}
            daysTime={daysTime}
            removeTime={removeTime}
            weekTimeValidateErrors={weekTimeValidateErrors}
            clearAllDaysInput={clearAllDaysInput}
          />
        );
      case 2:
        return (
          <SecondStep
            daysTime={daysTime}
            checkPoinSchedules={checkPoinSchedules}
            setCheckPointSchedules={setCheckPointSchedules}
            patrollingSchedulesErrors={patrollingSchedulesErrors}
            validatePatrollingSchedule={validatePatrollingSchedule}
          />
        );
      default:
        break;
    }
  };

  return (
    <Modal
      onHide={handleCloseModel}
      className="modal-popup"
      show={showModel}
      dialogClassName="modal-dialog modal-xl modal-dialog-centered"
    >
      <Modal.Header closeButton>
        <Modal.Title>Weekly Availability of {guard?.guard_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="col-sm-12">
            <div className="horizontal-wizard">
              <div
                className="bs-stepper linear"
                style={{ backgroundColor: "unset", boxShadow: "unset" }}
              >
                <div className="bs-stepper-header">
                  <div className={`step ${activeWizard === 1 ? "active" : ""}`}>
                    <button type="button" className="step-trigger">
                      <span className="bs-stepper-box">1</span>
                      <span className="bs-stepper-label">
                        <span className="bs-stepper-title">
                          Guard Avaibility
                        </span>
                        <span className="bs-stepper-subtitle">
                          Enter Guard Availability.
                        </span>
                      </span>
                    </button>
                  </div>
                  {guard &&
                    guard.staff_type == Enums.GuardTypes.patrolling_guard && (
                      <>
                        {" "}
                        <div className="line">
                          <i className="fa-solid fa-angle-right"></i>
                        </div>
                        <div
                          className={`step ${
                            activeWizard === 2 ? "active" : ""
                          }`}
                        >
                          <button type="button" className="step-trigger">
                            <span className="bs-stepper-box">2</span>
                            <span className="bs-stepper-label">
                              <span className="bs-stepper-title">
                                Patrolling Schedules
                              </span>
                              <span className="bs-stepper-subtitle">
                                Add Patrolling Schedule
                              </span>
                            </span>
                          </button>
                        </div>
                      </>
                    )}
                </div>
                {renderWizardScreen()}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
            <h5>
              <span className="text-bold text-danger">
                Note : Changes in the schedule will be reflected in the system
                after 2 hours.
              </span>
            </h5>
          </div>
          <div>
            {activeWizard == 2 && (
              <Button
                onClick={() => {
                  setCheckPointSchedules(initialCheckPointsSchedules);
                }}
                variant="outline-primary"
              >
                Clear All
              </Button>
            )}
          </div>
          <div>
            {activeWizard !== 1 && (
              <>
                <Button
                  onClick={handlePreviousClick}
                  variant="outline-primary me-2"
                >
                  Previous
                </Button>
              </>
            )}
            {!loading ? (
              <>
                <Button
                  type="button"
                  onClick={handleNextClick}
                  variant="primary"
                >
                  {activeWizard === 2 ||
                  (guard && guard.staff_type == Enums.GuardTypes.gate_guard)
                    ? "Save"
                    : "Next"}
                </Button>
              </>
            ) : (
              <Button variant="primary">
                <Spinner animation="border" size="sm" /> Loading...
              </Button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
