import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Images/logo/logo.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { AppConfigData, commonService } from "../../services/common.service";
import { alertService, AlertType } from "../../services/alert.service";
import InputText from "../../components/form/inputText";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { APICall } from "../../services/axiosService";
import { ApiRoutes, Enums } from "../../constants";
import { ReactComponent as EyeIcon } from "../../assets/Icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/Icons/eyeSlash.svg";
import { ReactComponent as PageLoading } from "../../assets/Icons/page-loading.svg";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndCondition from "./TermsAndCondition";
// import { getTokenfun } from "../../firebaseinit"

function Login() {
  const [validated, setValidated] = useState(false);

  // const handleSubmit = (event) => {
  // const form = event.currentTarget;
  // if (form.checkValidity() === false) {
  //   event.preventDefault();
  //   event.stopPropagation();
  // }
  // setValidated(true);
  // };
  // document.getElementById("main").classList.add("login");
  // document.getElementById("main").classList.remove("menu-open");
  // document.getElementById("main").style.marginLeft = null;

  const history = useNavigate();
  const [passHide, setPassHide] = useState(true);
  const [input, setInput] = useState({
    username: "",
    password: "",
    errors: {
      username: "",
      password: "",
    },
  });
  const [ViewMode, setViewMode] = useState("Login");
  const [ShowPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [agreementEr, setAgreementEr] = useState("");
  const [agreementDiv, setAgreementDiv] = useState(false);
  const [policy, setPolicy] = useState(null);
  const [term, setTerm] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  const [showTermsCon, setShowTermsCon] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [ForgotPassInput, setForgotPassInput] = useState({
    username: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    errors: {
      username: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const [IsloginClick, setIsloginClick] = useState(false);
  const [IsforgotPasswordSendOTPClick, setIsforgotPasswordSendOTPClick] =
    useState(false);
  const [
    IsforgotPasswordVerifyAndUpdatePasswordClick,
    setIsforgotPasswordVerifyAndUpdatePasswordClick,
  ] = useState(false);

  function fnLoginPageMode(Mode) {
    setViewMode(Mode);
    if (Mode === "Login") {
      setForgotPassInput({
        username: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
        errors: {
          username: "",
          password: "",
          newPassword: "",
          confirmPassword: "",
        },
      });
    }
  }

  async function loginClick(event) {
    event.preventDefault();

    // let agreementEr = false 
    // if(agreementDiv){
    //   agreementEr  = true
    // }
    // if(agreementDiv){

    // }
    // if(agreement){
    if (validateLogin()) {
      setIsLoading(true);
      // let device_token = await getTokenfun();
      // let newData = { ...input, ...{device_token : device_token}};
      let newData = { ...input };
      const requestMetadata = {
        url: AppConfigData.APIBasePath + "login",
        method: "POST",
        headers: commonService.getHeaders(),
        data: newData,
      };
      const response = await axios(
        AppConfigData.APIBasePath + "login",
        requestMetadata
      );
      // console.log("🚀 --> loginClick --> response", response);
      // .then((response) => response.json())
      // .then(function (json) {

      if (response.data.status === 1) {

        commonService.setItem("auth_token", response.data.data.auth_token);
        commonService.setItem(
          "user_id",
          JSON.stringify(response.data.data.user_id)
        );
        let res = await APICall(ApiRoutes.ProfileDetails);
        if (res) {
          response.data.data['association_details'] = res.data;
          commonService.setItem("user", JSON.stringify(response.data.data));
        }
        else {
          commonService.setItem("user", JSON.stringify(response.data.data));
        }

        history("/");
      } else {
        alertService.alert({
          type: AlertType.Error,
          message: response.data.message,
        });
      }
    }
    // }else{
    //   setAgreementEr ("You must accept terms and conditions")
    // }

    setIsLoading(false);
  }

  function validateLogin() {
    let isValid = true;
    let errors = {};
    if (agreementDiv) {
      if (!agreement) {
        isValid = false;
        setAgreementEr("You must accept terms and conditions");
      } else {
        setAgreementEr("")
      }
    }
    if (!input.username) {
      errors.username = "Email is required";
      isValid = false;
    } else {
      errors.username = "";
    }

    if (!input.password) {
      errors.password = "Password is required";
      isValid = false;
    } else {
      errors.password = "";
    }
    setInput({
      ...input,
      ["errors"]: errors,
    });

    return isValid;
  }

  function forgotPasswordSendOTPClick() {
    if (forgotPasswordSendOTPValidate()) {
      setIsforgotPasswordSendOTPClick(true);
      const requestMetadata = {
        method: "POST",
        headers: commonService.getHeaders(),
        body: JSON.stringify({
          username: ForgotPassInput.username,
          password: "",
        }),
      };
      fetch(
        AppConfigData.APIBasePath + "v1/user/forgotpasswordcheckdetail",
        requestMetadata
      )
        .then((response) => response.json())
        .then(function (json) {
          setIsforgotPasswordSendOTPClick(false);
          if (json.status === 1) {
            alertService.alert({
              type: AlertType.Success,
              message: json.message,
            });
            fnLoginPageMode("Set New Password");
          } else {
            alertService.alert({
              type: AlertType.Error,
              message: json.message,
            });
          }
        });
    }
  }

  function forgotPasswordSendOTPValidate() {
    let isValid = true;
    let errors = {
      username: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
    };
    if (!ForgotPassInput.username) {
      errors.username = "Email is required";
      isValid = false;
    } else {
      errors.username = "";
    }

    setForgotPassInput({
      ...ForgotPassInput,
      ["errors"]: errors,
    });

    return isValid;
  }

  function forgotPasswordVerifyAndUpdatePasswordClick() {
    if (forgotPasswordVerifyAndUpdatePasswordValidate()) {
      setIsforgotPasswordVerifyAndUpdatePasswordClick(true);
      const requestMetadata = {
        method: "POST",
        headers: commonService.getHeaders(),
        body: JSON.stringify({
          username: ForgotPassInput.username,
          password: ForgotPassInput.password,
          newPassword: ForgotPassInput.newPassword,
        }),
      };
      fetch(
        AppConfigData.APIBasePath + "v1/user/forgotpasswordverifydetail",
        requestMetadata
      )
        .then((response) => response.json())
        .then(function (json) {
          if (json.status === 1) {
            alertService.alert({
              type: AlertType.Success,
              message: json.message,
            });

            const requestMetadata = {
              method: "POST",
              headers: commonService.getHeaders(),
              body: JSON.stringify({
                username: ForgotPassInput.username,
                password: ForgotPassInput.newPassword,
                errors: {
                  username: "",
                  password: "",
                },
              }),
            };
            fetch(
              AppConfigData.APIBasePath +
              "v1/user/checklogindetailsverifiypassword",
              requestMetadata
            )
              .then((response) => response.json())
              .then(function (json) {
                setIsloginClick(false);
                if (json.status === 1) {
                  commonService.setItem("switch", JSON.stringify(json.data));
                  history.push("switch");
                } else {
                  alertService.alert({
                    type: AlertType.Error,
                    message: "Invalid Login Details",
                  });
                }
              });
          } else {
            setIsforgotPasswordVerifyAndUpdatePasswordClick(false);
            alertService.alert({
              type: AlertType.Error,
              message: json.message,
            });
          }
        });
    }
  }

  function forgotPasswordVerifyAndUpdatePasswordValidate() {
    let isValid = true;
    let errors = {
      username: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
    };
    if (!ForgotPassInput.password) {
      errors.password = "OTP number is required";
      isValid = false;
    } else {
      errors.password = "";
    }

    if (!ForgotPassInput.newPassword) {
      errors.newPassword = "New password is required";
      isValid = false;
    } else {
      errors.newPassword = "";
    }

    if (!ForgotPassInput.confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
      isValid = false;
    } else {
      errors.confirmPassword = "";
    }

    if (
      ForgotPassInput.newPassword !== "" &&
      ForgotPassInput.confirmPassword !== ""
    ) {
      if (ForgotPassInput.confirmPassword !== ForgotPassInput.newPassword) {
        errors.confirmPassword = "New password and confirm password not match";
        isValid = false;
      } else {
        errors.confirmPassword = "";
      }
    }

    setForgotPassInput({
      ...ForgotPassInput,
      ["errors"]: errors,
    });

    return isValid;
  }

  const openTerms = () => {
    setShowTermsCon(true)
  }

  const openPrivacyPolicy = () => {
    setShowPrivacyPolicy(true)
  }

  const fetchPolicy = async () => {
    let res = await APICall(ApiRoutes.fetchPolicy)
    if (res.status == 0) {
      setAgreementDiv(false)
    } else if (res.status == 1) {
      setAgreementDiv(true)
      setPolicy(res.data.policy)
      setTerm(res.data.company_term_and_condition ?res.data.company_term_and_condition:"")
    }
    setPageLoading(false)
  }
  useEffect(() => {
    fetchPolicy()

  }, []);

  function showPassword() {
    setShowPassword(!ShowPassword);
  }

  if (!pageLoading) {
    return (
      <>
        <div className="blank-page">
          <div className="content-body">
            <div className="auth-wrapper auth-basic px-4">
              <div className="auth-inner my-4">
                <div className="mb-0 card">
                  <div className="card-body p-4">
                    <Link to="/" className="login-logo">
                      <img src={logo} alt="logo" width="160" height="48" />
                    </Link>
                    <h4 className="mb-4 card-title text-center text-dark">
                      Welcome to Monitor & Manage
                    </h4>
                    <Form onSubmit={loginClick}>
                      <InputText
                        type={"text"}
                        size={12}
                        title={"Email Address"}
                        value={input.username}
                        onChange={(value) =>
                          setInput({
                            ...input,
                            username: value,
                          })
                        }
                        validationText={input.errors.username}
                      />
                      <div className='position-relative'>
                        <Form.Group md="12" className="mb-3">
                          <Form.Label>Password</Form.Label>
                          <div className='position-relative'>
                            <Form.Control
                              value={input.password}
                              type={passHide ? 'password' : 'text'}
                              placeholder="Password"
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  password: e.target.value,
                                })}
                            />
                            {passHide ? <EyeIcon onClick={() => setPassHide(false)} className="position-absolute me-3 end-0 top-0 mt-2" />
                              : <EyeSlashIcon onClick={() => setPassHide(true)} className="position-absolute me-3 end-0 top-0 mt-2" />
                            }
                          </div>
                          {input.errors.password && <span className="error">{input.errors.password}</span>}
                        </Form.Group>
                        {agreementDiv && <><div className="mt-2">
                          <span>
                            <input checked={agreement} onChange={() => {
                              setAgreement(!agreement)
                            }} className="cursor-pointer" type="checkbox"></input>  I agree to the <span className="cursor-pointer text-primary" onClick={() => openTerms()}>Terms and Conditions</span>
                          </span>
                        </div>
                          <div className="my-2">
                            {agreementEr != "" ? <span className="text-danger">
                              {agreementEr}
                            </span> : ""}
                          </div></>}
                      </div>
                    
                      {/* <Link to="/"> */}
                      {isLoading ? (
                        <Button variant="primary w-100">
                          <Spinner animation="border" size="sm" /> Loading...
                        </Button>
                      ) : (
                        <Button
                          variant="primary w-100"
                          type="submit"
                          // disabled={agreementDiv ? !agreement : false}
                        // onClick={loginClick}
                        >
                          Sign in
                        </Button>
                      )}
                      {/* </Link> */}
                    </Form>
                    {/* <p className="create-login-account-bottom text-center mt-3">
                      <span>New on our platform?</span>
                      <Link to="/signup" className="text-primary h6">
                        <span>Create an Account</span>
                      </Link>
                    </p> */}
                    <p className="create-login-account-bottom text-center mt-3">
                    
                      <Link to="/portal/forgot/password" className="text-primary h6">
                        <span>Forgot Password?</span>
                      </Link>
                    </p>

                    {/* <div onClick={() => history('/forgot/password')} className="mb-2 text-end cursor-pointer"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PrivacyPolicy policy={policy} showPrivacyPolicy={showPrivacyPolicy} setShowPrivacyPolicy={setShowPrivacyPolicy} /> */}
        <TermsAndCondition term={term} showTermsCon={showTermsCon} setShowTermsCon={setShowTermsCon} />
      </>
    );
  } else {
    return <div className="page-loading">
      <PageLoading />
    </div>
  }

}

export default Login;
