import React, { useState } from "react";
import { Breadcrumb, Button, Card, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Switch from "react-switch";

// import FilterComponent from "../../components/common/FilterComponent";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import { ApiRoutes } from "../../constants/apiroutes";
// import NoDataFound from "../../components/common/NoDataFound";
import { Enums } from "../../constants/enums";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import InputText from "../../components/form/inputText";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import DropDown from "../../components/common/DropDown";
import NewLoader from "../../components/loader/NewLoader";

function Index() {
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [contractTotalRows, setGuardTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [contractsList, setContractsList] = useState({
    isData: false,
    data: [],
  });
  const [branchList, setBranchList] = useState([]);
  const [allCompanyList, setAllCompanyList] = useState([]);
  const [selectedBranchList, setSelectedBranchList] = useState([]);
  const [selectedCompanyList, setSelectedCompanyList] = useState([]);
  const [contractFrom, setContractFrom] = useState(null);
  const [contractTo, setContractTo] = useState(null);

  const contractTblColumns = [
    // {
    //   name: "#",
    //   selector: (row) => row.full_name,
    //   sortable: false,
    //   cell: (row, index, column, id) => {
    //     return (
    //       <img
    //         src={
    //           row.client_profile_img
    //             ? process.env.REACT_APP_ASSETS_URL + row.client_profile_img
    //             : DefaultImg
    //         }
    //         className="round"
    //         alt="user"
    //         width="40"
    //         style={{ objectFit: "cover" }}
    //         height="40"
    //       />
    //     );
    //   },
    // },
    {
      name: "Company",
      selector: (row) => <span className="nav-link cursor-pointer mb-0 p-0" onClick={() => {
        navigate('/contract/company/profile', { state: { comapny_id: row.company_id } })
      }}>{row.company_name}</span>,
      sortable: true,
      sortField: "company_name",
    }, {


      name: "Branch",
      selector: (row) => <span className="nav-link cursor-pointer mb-0 p-0" onClick={() => {
        navigate('/contract/branch/profile', { state: { branch_id: row.branch_id } })
      }}>{row.branch_name}</span>,
      sortable: true,
      width: "170px",
      sortField: "branch_name",
    },
    // {
    //   name: "Email",
    //   selector: (row) => row.company_email,
    //   sortable: true,
    //   sortField: "company_email",
    // },
    // {
    //   name: "Phone",
    //   selector: (row) => row.company_phone,
    //   sortable: true,
    //   sortField: "company_phone",
    // },
    {
      name: "Property name",
      selector: (row) => row.property_name,
      sortable: true,
      sortField: "property_name",
    },
    {
      name: "Guards",
      selector: (row) => row.no_of_guards,
      sortable: true,
      sortField: "no_of_guards",
    },
    {
      name: "Checkpoints",
      selector: (row) => row.no_of_checkpoints,
      sortable: true,
      sortField: "no_of_checkpoints",
    },
    // {
    //   name: "Paid amount",
    //   selector: (row) => row.received_amount,
    //   sortable: true,
    //   sortField: "received_amount",
    // },
    {
      name: "Total amount",
      selector: (row) => row.total_amount,
      sortable: true,
      sortField: "total_amount",
    },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      sortField: "start_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.start_date),
    }, {
      name: "Expiry Date",
      selector: (row) => row.end_date,
      sortable: true,
      sortField: "end_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.end_date),
    },
    {
      name: "Action",
      selector: (row) => row.year,
      cell: (row, index, column, id) => (
        <div className="justify-content-center align-items-center d-flex">
          <Button
            style={{ fontSize: "0.7rem", padding: "0.3rem 1rem" }}
            variant="primary"
            type="button"
            onClick={() => {
              navigate("view", {
                state: {
                  id: row.contract_id,
                },
              });
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();



  React.useEffect(() => {
    getContractsList();
  }, [txtSearch,pageNo, perPageRecords]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  function filterCompaniesList() {
    getContractsList("", "", 1);
  }

  const getBranchList = async () => {
    let res = await APICall(ApiRoutes.clientCompanyBranchDropDown)
    setAllCompanyList(res.data.company_list)
    setBranchList(res.data.branch_list)
  }

  React.useEffect(() => {
    getBranchList();
  }, []);

  async function getContractsList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setDataTableLoading(true);
    setContractsList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.ContractList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          contractFrom != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(contractFrom) + " 00:00:00"
            )
            : null,
        end_date:
          contractTo != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(contractTo) + " 23:59:59"
            )
            : null,
        branch_list: selectedBranchList.map((item) => (item.value)),
        company_list: selectedCompanyList.map((item) => (item.value)),
      },
    });

    if (requestMetadata) {
      setContractsList({ isData: true, data: requestMetadata.data.list });

      setGuardTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
    
  }

  const handleSort = async (column, sortDirection) => {
    getContractsList(column.sortField, sortDirection , 1);
  };

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.GuardStatusUpdate, {
      id: item.guard_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setContractsList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };

  // React.useEffect(() => {
  //   getContractsList("", "", 1);
  // }, [txtSearch]);

  const searchData = (e) => {
    e.preventDefault();

    let isValidate = true;
    if (contractTo || contractFrom) {
      if (contractTo && contractFrom) {
        const dateFrom = new Date(contractFrom);
        const dateTo = new Date(contractTo);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getContractsList("", "", 1);
      setGuardTotalRows(1)
    }
  };

  const resetFilter = () => {
    setSelectedBranchList([])
    setSelectedCompanyList([])
    setContractFrom(null)
    setContractTo(null)
    setTxtSearch("")
    getContractsList("", "", 0);
  };

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Contract List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Contracts</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Card>
          <Card.Body className="p-3">
            <div className="row">

              <div className="col-3">
                <InputGroup>
                  <DatePicker
                    className="datepicker form-control"
                    selected={contractFrom ? contractFrom : ""}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Date"
                    onChange={(date) => {
                      if (date) {
                        setContractFrom(date);
                      } else {
                        setContractFrom(null);
                      }
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-3">
                <div>
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={contractTo ? contractTo : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="End Date"
                      onChange={(date) => {
                        if (date) {
                          setContractTo(date);
                        } else {
                          setContractTo(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="col-3">
                <DropDown
                  data={allCompanyList}
                  value={selectedCompanyList}
                  style={{ div: "col-12" }}
                  isMulti={true}
                  onChange={(value) =>
                    setSelectedCompanyList(value)
                  }
                  placeholder="Select Company"
                  required
                // validationText={formInputsErrors.duration_term}
                />
              </div>

              <div className="col-3">
                <DropDown
                  data={branchList}
                  value={selectedBranchList}
                  style={{ div: "col-12" }}
                  isMulti={true}
                  onChange={(value) =>
                    setSelectedBranchList(value)
                  }
                  placeholder="Select Branch Manager"
                  required
                // validationText={formInputsErrors.duration_term}
                />

              </div>


              <div className="col-3">
                <div className="d-flex">
                  <div className="me-2">
                    <Button
                      onClick={searchData}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </Card.Body>
        </Card>
      </div>
      {/* <FilterComponent
        searchText={txtSearch}
        PHSearchText={DisplayText.SearchText}
        PHDatepickerFrom={DisplayText.DatepickerFrom}
        PHDatepickerTo={DisplayText.DatepickerTo}
        startDate={filterStartDate}
        endDate={filterEndDate}
        onSearchTextChange={(value) => setTxtSearch(value)}
        onStartDateChange={(value) => setFilterStartDate(value)}
        onEndDateChange={(value) => setFilterEndDate(value)}
        onSearchClick={() => filterCompaniesList()}
      /> */}

      {/* {contractsList.data.length === 0 ? (
        <div className="event-details">
          <NoDataFound fontsize={"150px"} />
        </div>
      ) : ( */}
      <DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
        columns={contractTblColumns}
        data={contractsList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={contractTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
          <div className="col-4">
            <InputText
              formGroupClass={"mb-0"}
              size={'12'}
              type={"text"}
              Label={false}
              placeholder={"Search..."}
              value={txtSearch}
              onChange={(value) => {
                if (value) {
                  setTxtSearch(value)
                } else {
                  setTxtSearch(value)
                }
              }}
            />
          </div>
        </>}
      />
      {/* )} */}
    </>
  );
}

export default Index;
