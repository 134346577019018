import React from "react";
import { Col, Form, FormControl } from "react-bootstrap";

export default function InputText({
  innerIconComponents,
  title,
  placeholder = null,
  validationText,
  required,
  size,
  value,
  onChange,
  type,
  disabled,
  multiLine,
  formGroupClass = null,
  Label = true,
}) {
  return (
    <Form.Group
      as={Col}
      md={size ? size : "6"}
      className={formGroupClass ? formGroupClass : "mb-3"}
    >
      {multiLine ? (
        <>
          {Label ? <Form.Label>{title}</Form.Label> : ""}
          <FormControl
            as="textarea"
            aria-label="With textarea"
            value={value}
            type={type}
            placeholder={placeholder ? placeholder : title}
            required={required}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
          />
        </>
      ) : (
        <>
          {Label ? <Form.Label>{title}</Form.Label> : ""}
          <Form.Control
            value={value}
            type={type}
            placeholder={placeholder ? placeholder : title}
            required={required}
            onWheelCapture={(e) => {
              if (type == "number") e.target.blur();
            }}
            onChange={(e) => {
              // if (type == "number") {
              //   if (e.target.value >= 0) {
              //     onChange(e.target.value);
              //   }
              // } else {
              //   onChange(e.target.value);
              // }
              if (type === "number") {
                if (e.target.value >= 0 && e.target.value.length <= 15) {
                  onChange(e.target.value);
                }
              } else {
                onChange(e.target.value);
              }
            }}
            disabled={disabled}
           
          />
        </>
      )}
      {validationText && <span className="error">{validationText}</span>}
    </Form.Group>
  );
}
