import React, { useState } from 'react'
import { MultiSelect } from "react-multi-select-component";
export default function MultiSelDropDown({ validationText, title, data, colDiv = null, onChangeValue, disabled = false, inputClassName, inputLable }) {

    const [selected, setSelected] = useState([]);

    return (<div className={`${colDiv ? colDiv : "col-md-6 col-12"} mb-3`}>
        <div className="form-group">
            <label className="form-label">{title}</label>
            <MultiSelect
                options={data}
                value={selected}
                disableSearch={false}
                onChange={(value) => {
                    setSelected(value)
                    onChangeValue(value)
                }}
                disabled={disabled}
                className={inputClassName}
                labelledBy={inputLable}
            />
            {validationText && <span className="error">{validationText}</span>}
        </div>
    </div>)
}
