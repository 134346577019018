import React from 'react'
import { useNavigate } from "react-router-dom";

export default function AdminDashboard({dashboardCounts}) {

  const navigate = useNavigate();

  return (<div className="row">
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={() => navigate('/company')}className="card-body dashboard-card-body cursor-pointer d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header">  {dashboardCounts?.company_count}</h2>
          <span className="h6 text-dark">Total Companies</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle">
          <span className="b-avatar-custom">
            <i className=" fa-solid fa-buildings"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={() => navigate('/branch')}className="card-body dashboard-card-body cursor-pointer d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header"> {dashboardCounts?.branch_count}</h2>
          <span className="h6 text-dark">Total Branch Managers</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle" style={{ width: '45px', height: '45px' }}>
          <span className="b-avatar-custom">
            <i className=" fa-solid fa-code-branch"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={() => navigate('/guards')}  className="card-body dashboard-card-body cursor-pointer d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header"> {dashboardCounts?.guard_count} </h2>
          <span className="h6 text-dark">Total Guards</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle" style={{ width: '45px', height: '45px' }}>
          <span className="b-avatar-custom">
            <i className=" fa-solid fa-users"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={() => navigate('/incharge')} className="card-body dashboard-card-body d-flex cursor-pointer justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header"> {dashboardCounts?.incharge_count} </h2>
          <span className="h6 text-dark">Total Incharges</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle" style={{ width: '45px', height: '45px' }}>
          <span className="b-avatar-custom">
            <i className=" fa-solid fa-user-group"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={() => navigate('/clients')} className="card-body dashboard-card-body cursor-pointer d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header"> {dashboardCounts?.property_manager_count} </h2>
          <span className="h6 text-dark">Total Property Managers</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle" style={{ width: '45px', height: '45px' }}>
          <span className="b-avatar-custom">
            <i className=" fa-solid fa-users"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={() => navigate('/contracts')}className="card-body dashboard-card-body cursor-pointer d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header"> {dashboardCounts?.contract_count} </h2>
          <span className="h6 text-dark">Total Contracts</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle" style={{ width: '45px', height: '45px' }}>
          <span className="b-avatar-custom">
            <i className=" fa-solid fa-users"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  
</div>)
}
