import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { ApiRoutes, Enums } from "../../constants";
import { APICall } from "../../services/axiosService";

export default function AddInchargeModal({
  setCurrInchargeId,
  setIsInchargeReplace,
  currInchargeId,
  isInchargeReplace,
  inchargeIds,
  getDetails,
  contractId,
  selectedIncahrges,
  setSelectedIncharges,
  // incharges,
  showAddIncharge,
  setShowAddIncharge,
}) {
  const [inchargeError, setInchargeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [incharges, setIncharges] = useState([]);

  const closeAddInchargePopUp = () => {
    setCurrInchargeId(false);
    setIsInchargeReplace(false);
    setShowAddIncharge(false);
    setSelectedIncharges([]);
    setInchargeError("");
  };
  const handleAddInchargeToContract = async () => {
    if (isInchargeReplace) {
      // Replace Incharge :
      if (!selectedIncahrges || selectedIncahrges.length === 0) {
        setInchargeError("Atleast one incharge should be selected.");
        return;
      }
      setIsLoading(true);

      let payload = {
        current_incharge_id: currInchargeId,
        new_incharge_id: selectedIncahrges,
        contract_id: contractId,
      };
      let res = await APICall(ApiRoutes.viewContractsReplaceIncharge, payload);
      if (res.status == 1) {
        setIsLoading(false);
        closeAddInchargePopUp();
        getDetails();
      } else {
        setIsLoading(false);
      }
    } else {
      if (selectedIncahrges.length) {
        setIsLoading(true);
        let inchargeIds = [];
        selectedIncahrges.forEach((selectedIncahrge) => {
          inchargeIds.push(selectedIncahrge.value);
        });
        let data = {
          contract_id: contractId,
          incharge_list: inchargeIds,
        };
        let res = await APICall(ApiRoutes.addStaffMember, data);
        if (res.status == 1) {
          setIsLoading(false);
          closeAddInchargePopUp();
          getDetails();
        } else {
          setIsLoading(false);
        }
      } else {
        setInchargeError("Atleast one incharge should be selected.");
      }
    }
  };

  const fetchInchargeList = async (inchagesIds) => {
    let res = await APICall(ApiRoutes.inchargeOptions, {
      ids: inchagesIds,
      is_all: false,
      is_fixed_association: 1,
    });
    if (res.status == 1) {
      setIncharges(res.data);
    }
  };

  useEffect(() => {
    if (inchargeIds) {
      fetchInchargeList(inchargeIds);
    }
  }, []);

  return (
    <Modal
      className="modal-popup"
      show={showAddIncharge}
      onHide={closeAddInchargePopUp}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Incharge</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DropDown
          style={{ div: "col-md-12 col-12" }}
          data={incharges}
          title={"Incharge"}
          value={incharges.value}
          isMulti={isInchargeReplace ? false : true}
          onChange={(value) => setSelectedIncharges(value)}
          required
          validationText={inchargeError}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={closeAddInchargePopUp}>
          Close
        </Button>
        {isLoading === false ? (
          <>
            <Button variant="primary" onClick={handleAddInchargeToContract}>
              Save
            </Button>
          </>
        ) : (
          <Button variant="primary">
            <Spinner animation="border" size="sm" /> Loading...
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
