import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Form, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import DropDown from "../../components/common/DropDown";
import InputText from "../../components/form/inputText";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { updateLoading } from "../../store/actions";
import DatePickerInput from "../../components/common/DatePickerInput";
import ImgInput from "../../components/common/ImgInput";
import FileImageInput from "../../components/common/FileImageInput";
import InputSearchLocation from "../../components/form/InputSearchLocation";
import { alertService, AlertType } from "../../services/alert.service";

function Edit(props) {
  const navigate = useNavigate();
  const location = useLocation();
  let auth_user = commonService.getLoginUserData();
  const [CProfilePdfSize, setCProfilePdfSize] = useState(0);
  const [validated, setValidated] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [fileImageSrc, setFileImageSrc] = useState(null);
  const [portfolioExt, setPortfolioExt] = useState(null);
  const [permenentFileImage, setPermenentFileImage] = useState(null);
  const [CLogoImgSize, setCLogoImgSize] = useState(0);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const { testvalue } = useParams();

  const routeParams = location.state;
  useEffect(() => {
    getDetails();
    getPaymentList();
  }, []);

  const [isCompanyFormClick, setIsCompanyFormClick] = useState(false);
  const [paymentModeList, setPaymentModeList] = useState([]);
  const dispatch = useDispatch();

  const [formInputs, setInput] = useState({
    company_name: "",
    register_no: "",
    email: "",
    phone: "",
    contact_person_email: "",
    contact_person_name: "",
    contact_person_phone: "",
    payment_duration: "",
    summary: "",
    start_date: "",
    website_url: "",
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
    ],
  });
  const [formInputsErrors, setInputErrors] = useState({
    company_name: "",
    register_no: "",
    email: "",
    phone: "",
    contact_person_email: "",
    contact_person_name: "",
    contact_person_phone: "",
    payment_duration: "",
    start_date: "",
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
    ],
  });

  const validation_msg = {
    company_name: "Company name is required",
    register_no: "Registration number is required",
    valid_email: "Please enter valid email address",
    email: "Email is required",
    phone: "Phone is required",
    contact_person_email: "Contact person email is required",
    contact_person_name: "Contact person name is required",
    contact_person_phone: "Contact person phone is required",
    start_date: "This field is required",
    payment_duration: "Payment duration is required",
    address1: "This field is required",
    city: "This field is required",
    state: "This field is required",
    postal_code: "This field is required",
    country: "This field is required",
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  async function submitCompanyForm(event) {
    event.preventDefault();
    if (validateCompanyForm()) {
      dispatch(updateLoading(true));
      let CLogo = CLogoImgSize < 5242880; //5 mb
      let CProfile = CProfilePdfSize < 15728640; //15 mb
      if (!CLogo || !CProfile) {
        alertService.alert({
          type: AlertType.Error,
          message: "please fill all the required fill.",
        });
        return;
      }
      setIsCompanyFormClick(true);
      const requestMetadata = await APICall(ApiRoutes.CompanySave, {
        ...formInputs,
        start_date: commonService.convertLocalToUTC(commonService.formatDate(formInputs.start_date) + ' 00:00:00'),
        profile_img: formInputs.profile_img?.includes(
          process.env.REACT_APP_ASSETS_URL
        )
          ? null
          : formInputs.profile_img,
        documents: selectedDocuments
      });
      setIsCompanyFormClick(false);
      dispatch(updateLoading(false));
      if (requestMetadata) {
        navigate("/company");
      }
    }
  }

  const getPaymentList = async () => {
    const res = await APICall(ApiRoutes.PaymentModeList);
    if (res) {
      setPaymentModeList(res.data.list);
    }
  };

  const getDetails = async () => {
    const res = await APICall(ApiRoutes.CompanyEditDetails, {
      id: routeParams.id,
    });
    if (res) {
      if (res.data.company_portfolio_url) {
        let urlPortfolio = process.env.REACT_APP_ASSETS_URL + res.data.company_portfolio_url
        setFileImageSrc(urlPortfolio)
        setPortfolioExt(urlPortfolio.split(".").pop())
        setPermenentFileImage(urlPortfolio)
      }
      setInput({
        company_id: res.data.company_id,
        // branch_id: res.data.branch_id,
        company_name: res.data.company_name,
        register_no: res.data.register_no,
        email: res.data.email,
        phone: res.data.phone,
        contact_person_email: res.data.contact_person_email,
        contact_person_name: res.data.contact_person_name,
        contact_person_phone: res.data.contact_person_phone,
        payment_duration: res.data.payment_duration,
        summary: res.data.summary,
        start_date: res.data.start_date ? new Date(commonService.convertUTCToLocal(res.data.start_date, 'YYYY-MM-DD HH:mm:ss')) : "",
        website_url: res.data.website_url,
        profile_img: res.data.profile_img
          ? process.env.REACT_APP_ASSETS_URL + res.data.profile_img
          : null,
        locations:
          res.data.locations.length > 0
            ? res.data.locations
            : [
              {
                address1: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
              },
            ],
      });
    }
  };

  function validateCompanyForm() {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors);
    const requiredArr = ["bank_details", "locations"];
    errorKeys.forEach((item) => {

      if (requiredArr.includes(item)) {
        let newArr = [];
        formInputsErrors[item].forEach((childLoop, childIndex) => {
          let newObj = {};
          const childErrorsArr = Object.keys(childLoop);
          childErrorsArr.forEach((grandChildLoop) => {
            if (formInputs[item][childIndex][grandChildLoop]?.toString().trim() === "") {
              newObj[grandChildLoop] = validation_msg[grandChildLoop];
              isValid = false;
            } else {
              newObj[grandChildLoop] = "";
            }
          });
          newArr.push(newObj);
        });
        errors[item] = newArr;
      } else {
        if (formInputs[item].toString().trim() === "") {
          errors[item] = validation_msg[item];
          isValid = false;
        } else {
          if (item === "branch_email" && !pattern.test(formInputs.branch_email)) {
            errors[item] = validation_msg.valid_email;
          } else {
            errors[item] = "";
          }
        }
      }


    });

    setInputErrors(errors);
    return isValid;
  }

  const handleLocation = (addressInfoArray) => {
    setInput({
      ...formInputs,
      locations: [{
        ...formInputs.locations[0],
        address1: addressInfoArray.street_address,
        city: addressInfoArray.city,
        state: addressInfoArray.state,
        postal_code: addressInfoArray.postal_code,
        country: addressInfoArray.country,
        longitude: addressInfoArray.lng,
        latitude: addressInfoArray.lat,
      }]
    })
  }

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              Edit Company
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/company">Setup</Breadcrumb.Item>
              <Breadcrumb.Item href="/company">Company</Breadcrumb.Item>
              <Breadcrumb.Item active>Edit</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Card className="pt-4">
        <Card.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row>
              <InputText
                type={"text"}
                title={"Company Name"}
                value={formInputs.company_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    company_name: value,
                  })
                }
                validationText={formInputsErrors.company_name}
                required
              />
              <InputText
                type={"email"}
                title={"Company Email"}
                value={formInputs.email}
                disabled={true}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    email: value,
                  })
                }
                validationText={formInputsErrors.email}
                required
              />
            </Row>
            <Row>
              <InputText
                type={"number"}
                title={"Company Phone Number"}
                value={formInputs.phone}
                disabled={true}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    phone: value,
                  })
                }
                validationText={formInputsErrors.phone}
                required
              />
              <InputText
                type={"text"}
                title={"Registration No"}
                value={formInputs.register_no}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    register_no: value,
                  })
                }
                validationText={formInputsErrors.register_no}
                required
              />
            </Row>
            <Row>
              <InputText
                type={"text"}
                title={"Contact Person Name"}
                value={formInputs.contact_person_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    contact_person_name: value,
                  })
                }
                validationText={formInputsErrors.contact_person_name}
                required
              />
              <InputText
                type={"email"}
                title={"Contact Person Email"}
                value={formInputs.contact_person_email}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    contact_person_email: value,
                  })
                }
                validationText={formInputsErrors.contact_person_email}
                required
              />
            </Row>
            <Row>
              <InputText
                type={"number"}
                title={"Contact Person Phone Number"}
                value={formInputs.contact_person_phone}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    contact_person_phone: value,
                  })
                }
                validationText={formInputsErrors.contact_person_phone}
                required
              />

              <DropDown
                data={paymentModeList}
                value={formInputs.payment_duration}
                title={"Payment Duration"}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    payment_duration: value,
                  })
                }
                validationText={formInputsErrors.payment_duration}
                required
              />
            </Row>
            <Row>
              <DatePickerInput
                title={"Start Date"}
                selectedDate={formInputs.start_date}
                onChange={(value) => {
                  setInput({
                    ...formInputs,
                    start_date: value,
                  });
                }}
                error={formInputsErrors.start_date}
                required
              />
              <InputText
                type={"text"}
                title={"Website Url"}
                value={formInputs.website_url}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    website_url: value,
                  })
                }
              />

              {/* <InputText
                type={"text"}
                title={"Summary"}
                style={{ div: "col-md-12 col-12 mb-4" }}
                textarea={true}
                onChange={(value) =>
                  setInput({
                    summary: value,
                  })
                }
              /> */}
            </Row>
            <div className="col-12 row">
              <blockquote className="blockquote border-left-3 border-left-primary float-left pl-1 mt-3">
                <h4 className="mb-0 shop-title float-left">
                  Location Details
                </h4>
              </blockquote>
            </div>
            <InputSearchLocation size={"12"} placeholder="Search location" onPlaceChange={handleLocation} />
            {formInputs?.locations?.map((mapItem, mapIndex) => (
              <>
                <Row>
                  <InputText
                    type={"text"}
                    title={"Address"}
                    value={mapItem.address1}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                address1: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={
                      formInputsErrors?.locations[mapIndex]?.address1
                    }
                  />
                  <InputText
                    type={"text"}
                    title={"City"}
                    value={mapItem.city}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                city: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={formInputsErrors?.locations[mapIndex]?.city}
                  />
                </Row>
                <Row>
                  <InputText
                    type={"text"}
                    title={"State"}
                    value={mapItem.state}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                state: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={formInputsErrors?.locations[mapIndex]?.state}
                  />
                  <InputText
                    type={"text"}
                    title={"Postal code"}
                    value={mapItem.postal_code}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                postal_code: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={
                      formInputsErrors?.locations[mapIndex]?.postal_code
                    }
                  />
                </Row>
                <Row>
                  <InputText
                    type={"text"}
                    title={"Country"}
                    value={mapItem.country}
                    onChange={(value) =>
                      setInput({
                        ...formInputs,
                        locations: formInputs.locations.map(
                          (innerItem, innerIndex) => {
                            if (innerIndex === mapIndex) {
                              return {
                                ...innerItem,
                                country: value,
                              };
                            }
                          }
                        ),
                      })
                    }
                    validationText={
                      formInputsErrors?.locations[mapIndex]?.country
                    }
                  />
                </Row>
              </>
            ))}
            <div className="col-12 row">
              <ImgInput
                title={"Company Logo"}
                setCLogoImgSize={setCLogoImgSize}
                img_src={formInputs.profile_img}
                input_ref_id="profile_img"
                onChange={(value) => {
                  setInput({
                    ...formInputs,
                    profile_img: value,
                  });
                }}
              />

              <FileImageInput
                fileImageSrc={fileImageSrc}
                setCProfilePdfSize={setCProfilePdfSize}
                setFileImageSrc={setFileImageSrc}
                permenentFileImage={permenentFileImage}
                portfolioExt={portfolioExt}
                setPortfolioExt={setPortfolioExt}
                validateExts={['doc', 'docx', 'pdf']}
                accept_file_type={"doc, .docx,.pdf"}
                title={"Company Profile"}
                selectedDocuments={selectedDocuments}
                input_ref_id="portfolio_url"
                onChange={(value) => {
                  setSelectedDocuments(value)
                }}
              />

            </div>
            <div className="col-12 d-flex justify-content-end">
              <Button
                className="btn-15"
                variant="outline-primary"
                onClick={() => {
                  navigate("/company");
                }}
              >
                Cancel
              </Button>

              {isCompanyFormClick === false ? (
                <>
                  <Button
                    variant="primary"
                    className="btn-15"
                    onClick={submitCompanyForm}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button variant="primary" className="btn-15">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default Edit;
