import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import DatePickerInput from "../../../components/common/DatePickerInput";
import DropDown from "../../../components/common/DropDown";
import ImgInput from "../../../components/common/ImgInput";
import InputText from "../../../components/form/inputText";
import { alertService, AlertType } from "../../../services/alert.service";

export default function CreateWizardOne({
  formInputs,
  setInput,
  formInputsErrors,
  dropDownData,
  onNextPress,
  setInputErrors
}) {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Row>
            <InputText
              type={"text"}
              title={"Property Name"}
              value={formInputs.property_name}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  property_name: value,
                })
              }
              required
              validationText={formInputsErrors.property_name}
            />

            <InputText
              type={"text"}
              title={"Registration Number"}
              value={formInputs.register_no}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  register_no: value,
                })
              }
              required
              validationText={formInputsErrors.register_no}
            />
          </Row>
          <Row>
            <InputText
              type={"email"}
              title={"Corporate Email"}
              value={formInputs.email}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  email: value,
                })
              }
              required
              validationText={formInputsErrors.email}
            />
            <InputText
              type={"number"}
              title={"Office Number"}
              value={formInputs.phone}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  phone: value,
                })
              }
              required
              validationText={formInputsErrors.phone}
            />
          </Row>
          <Row>
            <DropDown
              data={dropDownData != null ? dropDownData.property_category : []}
              title={"Category Type"}
              value={formInputs.property_category_type}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  property_category_type: value,
                })
              }
              required
              validationText={formInputsErrors.property_category_type}
            />
            {/* <DropDown
              data={
                dropDownData != null ? dropDownData.property_development : []
              }
              title={"Property Type"}
              value={formInputs.property_development_type}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  property_development_type: value,
                })
              }
              required
              validationText={formInputsErrors.property_development_type}
            /> */}
            <DropDown
              data={dropDownData != null ? dropDownData.client_contacts : []}
              title={"Users"}
              value={formInputs.contacts_list}
              isMulti
              onChange={(value) =>{
                if(value.length > 3 ){
                  setInputErrors((prevState) => ({
                    ...prevState, 
                    wizOne:{
                     ...prevState.wizOne,
                     contacts_list : "You can select only three users."
                 } }));
                }else{
                  setInput({
                    ...formInputs,
                    contacts_list: value,
                  })}
                }
           
              }
              required
              validationText={formInputsErrors.contacts_list}
            />
          </Row>
          {/* <Row>
            
          </Row> */}
          <br />
          <Row>
            <ImgInput
              title={"Property Picture"}
              img_src={formInputs.profile_img}
              input_ref_id="profile_img"
              onChange={(value) => {
                setInput({
                  ...formInputs,
                  profile_img: value,
                });
              }}
            />
          </Row>
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="disabled btn-15"
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15"
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
