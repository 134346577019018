import React, { useLayoutEffect, useState } from 'react'
import { useParams , useNavigate } from 'react-router-dom'
import { ApiRoutes, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';
import { Breadcrumb, Card , Button, Spinner, Row } from "react-bootstrap";
import { contractStatusEnum, PaymentReqStatusEnum } from "../../constants";
import NoImage from "../../assets/Images/Icon/no_image.png"
import { commonService } from "../../services/common.service";
import NewLoader from '../../components/loader/NewLoader';

export default function View() {

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false)
  const [paymentReqDetails, setPaymentReqDetails] = useState(null);
  const [loadingVariant, setLoadingVariant] = useState(null);

  const fetchPaymentReqDetails = async (payment_request_id) => {
    setLoading(true)
    let res = await APICall(ApiRoutes.paymentReqDetails, { payment_request_id : payment_request_id  })
    if (res.status == 1) {
      setLoadingVariant(null)
      setLoading(false)
      setPaymentReqDetails(res.data)
    }
    else {
      setLoading(false)
    }
  }

  const getLocation = () => {
    if(paymentReqDetails.contract_details){
      return (
        paymentReqDetails.contract_details.address1 +
      ", " +
      (paymentReqDetails.contract_details.address2 ? paymentReqDetails.contract_details.address2 + ", " : "") +
      paymentReqDetails.contract_details.city +
      ", " +
      paymentReqDetails.contract_details.state +
      ", " +
      paymentReqDetails.contract_details.country +
      " - " +
      paymentReqDetails.contract_details.postal_code
    );
    }else{
      return ""
    }
   
  };
  const getGuardLocation = () => {
    let str = (paymentReqDetails.applicant_details.address1
    ? paymentReqDetails.applicant_details.address1 + ", "
    : "" )+ (paymentReqDetails.applicant_details.address2
    ? paymentReqDetails.applicant_details.address2 + ", "
    : "" )+
      (paymentReqDetails.applicant_details.city
        ? paymentReqDetails.applicant_details.city + ", "
        : "") +
      (paymentReqDetails.applicant_details.state
        ? paymentReqDetails.applicant_details.state + ", "
        : "") +
      (paymentReqDetails.applicant_details.country
        ? paymentReqDetails.applicant_details.country + ", "
        : "") +
      (paymentReqDetails.applicant_details.postal_code
        ? paymentReqDetails.applicant_details.postal_code + ", "
        : "");
        
      str = str.trim();
      str = str.slice(0, -1);
      if (str.length && str && str !== "null") {
        return str;
      } else {
        return "-";
      }
  };
  
  useLayoutEffect(() => {
    if (params.payment_request_id) {
        fetchPaymentReqDetails(params.payment_request_id)
    }
  }, []);

  const paymentReqAction = async (action_code) => {
    if (action_code == 1) {
      setLoadingVariant(1)
    }
    else if (action_code == 0) {
      setLoadingVariant(0)
    }
    setLoading(true);
    let res = await APICall(ApiRoutes.paymentReqAction, { payment_request_id: params.payment_request_id, action_code: action_code })
    if (res.status == 1) {
        fetchPaymentReqDetails(params.payment_request_id)
    }
  }

  if (paymentReqDetails) {
    return (<>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
            Advance Salary
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/payment_requests">Setup</Breadcrumb.Item>
              <Breadcrumb.Item href="/payment_requests">Advance Salary</Breadcrumb.Item>
              <Breadcrumb.Item active>Advance Salary View</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Row className='contract-company-view'>
        <div className='row'>
          <div className='col-8'>
            <Card>
              <Card.Header>
                <h4 className="card-title">Payment Details</h4>
                {paymentReqDetails.payment_req_status == 'pending' ? <div>
                  {!loading ? <>
                    <Button variant="success me-3" onClick={() => { paymentReqAction(1) }}> Approve </Button>
                    <Button variant="danger me-3" onClick={() => { paymentReqAction(0) }}> Reject </Button>
                  </> : loadingVariant != null ? <Button variant={loadingVariant == 1 ? "success" : loadingVariant == 0 && "danger"} >
                    <Spinner animation="border" size="sm" /> Loading...
                  </Button> : ""}

                </div> : ""}
              </Card.Header>
              <Card.Body>
                <dl className="mb-0 row">
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Requested Amount :</dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {parseFloat(paymentReqDetails.request_amount).toFixed(2)}
                  </dd>
                  {paymentReqDetails.payment_req_status == 'approved' ?<>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Paid Amount :</dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {parseFloat(paymentReqDetails.paid_amount).toFixed(2)}
                  </dd>
                  </>:""}
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Requested On :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {commonService.convertUTCToLocal(paymentReqDetails.created_at, 'DD-MM-yyyy, hh:mm A')}
                  </dd>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Status :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {PaymentReqStatusEnum[paymentReqDetails.payment_req_status]}
                  </dd>
                </dl>
              </Card.Body>
            </Card>
          {paymentReqDetails.contract_details &&  <Card>
              <Card.Header>
                <h4 className="card-title">Contract Details</h4>
                <div>
                    <Button size="sm" variant="primary me-3" onClick={() => { navigate("/contracts/view", { state: { id: paymentReqDetails.contract_id }})}}> View Contract </Button>
                </div>
              </Card.Header>
              <Card.Body >
                <dl className="mb-0 row">
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property Name :</dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6"> 
                    {paymentReqDetails.contract_details?.property_name}
                  </dd>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property Manager :</dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6"> 
                    {paymentReqDetails.contract_details?.property_manager}
                  </dd>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Service Hours :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {paymentReqDetails.contract_details?.contract_service_hours}
                  </dd>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Start Date :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {commonService.convertUTCToLocal(paymentReqDetails.contract_details?.start_date)}
                  </dd>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Expiry Date :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {commonService.convertUTCToLocal(paymentReqDetails.contract_details?.end_date)}
                  </dd>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Status :</dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {paymentReqDetails.contract_details?.contract_status &&
                      contractStatusEnum[paymentReqDetails.contract_details.contract_status]}
                  </dd>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Property Location :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {getLocation()}
                  </dd>
                </dl>
              </Card.Body>
            </Card>}
          </div>
          <div className='col-4'>
            <Card>
              <Card.Body>
                <div className="company-profile-avatar">
                  <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
                    <span className="w-100 h-100 avatar-content">
                      <img className="w-100 h-100 obj-fit-cover" src={paymentReqDetails.applicant_details.profile_img ? process.env.REACT_APP_ASSETS_URL + paymentReqDetails.applicant_details.profile_img : NoImage}></img>
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-center text-center user-info">
                    <span className="mb-2 text-primary h4">{paymentReqDetails.applicant_details.applicant_name}</span>
                    <span className="text-capitalize badge bg-light-primary">
                      {paymentReqDetails.association_type_term === Enums.UserRole.guard && '#Guard'}
                      {paymentReqDetails.association_type_term === Enums.UserRole.incharge && '#Incharge'}
                    </span>
                  </div>
                </div>
                <div className="company-details mt-1">
                  <div className="left-field"><i className="fa-solid fa-envelope text-primary me-2"></i>
                  {paymentReqDetails.applicant_details.applicant_email}
                  </div>
                </div>
                <div className="company-details mt-1">
                  <div className="left-field"><i className="fa-solid fa-phone text-primary me-2"></i>
                  {paymentReqDetails.applicant_details.applicant_phone}
                  </div>
                </div>
                <div className="company-details mt-1">
                  <div className="left-field"><i className="fa-solid fa-hashtag text-primary me-2"></i>
                  {paymentReqDetails.applicant_details.id_number}
                  </div>
                </div>
                <div className="company-details mt-1">
                  <div className="left-field"><i className="fa-solid fa-map-marker text-primary me-2"></i>
                  {getGuardLocation()}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Row>
    </>
    )
  }
  else {
    return <NewLoader/>
  }
}
