import React from 'react'
import { Modal } from "react-bootstrap";
import Parser from 'html-react-parser';

export default function TermsAndCondition({ showTermsCon, setShowTermsCon, term }) {

    const closePrivacyPolicy = () => {
        setShowTermsCon(false);
    }
    return (<Modal className="modal-popup terms-condition" size="lg" show={showTermsCon} onHide={closePrivacyPolicy}>
        <Modal.Header closeButton>
            <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {Parser(term)}
        </Modal.Body>
    </Modal>)
}
