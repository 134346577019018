import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/Images/logo/logo.svg";
import { ReactComponent as IconClose } from "../../../assets/Icons/closeIcon.svg";
import DrawerButton from "../DrawerButton/Index";
import "./Sidebar.css";
import { ApiRoutes } from "../../../constants/apiroutes";
import { APICall } from "../../../services/axiosService";
import { commonService } from "../../../services/common.service";
import { useEffect } from "react";

const sidemenujson = [
  { form_name: "properties", path: "/property" },
  { form_name: "branch_manager", path: "/branch" },
  { form_name: "request_quotations", path: "/quotationreq" },
  { form_name: "clients", path: "/clients" },
  { form_name: "guards", path: "/guards" },
  { form_name: "incharges", path: "/incharge" },
  { form_name: "leaves", path: "/leaves" },
  { form_name: "incident", path: "/incident" },
  { form_name: "settings", path: "/settings" },
  { form_name: "web_setting", path: "/web_setting" },
  { form_name: "app_setting", path: "/app_setting" },
  { form_name: "general_setting", path: "/general_setting" },
  { form_name: "visitor_report", path: "/visitors" },
  { form_name: "invoice", path: "/invoices" },
  { form_name: "incidents", path: "/incidents" },
  { form_name: "guard_patrolling", path: "/guard_patrolling" },

  {
    form_name: "advance_payment_requests",
    path: "/paymentreq",
  },
  { form_name: "reports", path: "/reports" },
  { form_name: "my_quotations", path: "/myquotation" },
  { form_name: "company", path: "/company" },
  { form_name: "payment_transaction", path: "/payment_transaction" },
  { form_name: "reviews_ratings", path: "/reviews_ratings" },
  { form_name: "complaint", path: "/complaint" },
  { form_name: "setup", path: "/setup" },
  { form_name: "client_contacts", path: "/user" },
  {
    form_name: "client_own_quotation_requests",
    path: "/clientquotationreq",
  },
  { form_name: "client_contracts", path: "/client_contracts" },
  { form_name: "notifications", path: "/notifications" },
  { form_name: "service_contracts", path: "/contracts" },
  { form_name: "payment_requests", path: "/payment_requests" },
  { form_name: "attendance_report", path: "/attendance_report" },
  { form_name: "user_guideline", path: "/user_guideline" },
];

export default function Sidebar({ isHeaderBtnClick, onCloseClicked }) {
  const [sidemenu, setSidemenu] = useState([]);
  const [withChildMenu, setWithChildMenu] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();

  const getUserSidemenu = async () => {
    const requestMetadata = await APICall(ApiRoutes.UserRights);
    if (requestMetadata) {
      setSidemenu(requestMetadata.data.menu);
      commonService.setItem(
        "userRights",
        JSON.stringify(requestMetadata.data.rights)
      );
    }
  };

  const onSideMenuClick = (item, index) => {
    if (item?.submenu?.length === 0) {
      navigate(
        sidemenujson.find((findItem) => findItem.form_name === item.form_name)
          .path
      );
      if (
        withChildMenu !== null &&
        !withChildMenu.includes(
          sidemenujson.find((findItem) => findItem.form_name === item.form_name)
            .path
        )
      ) {
        setWithChildMenu(null);
      }
    } else {
      setWithChildMenu((prevState) =>
        prevState ===
        sidemenujson.find((findItem) => findItem.form_name === item.form_name)
          .path
          ? null
          : sidemenujson.find(
              (findItem) => findItem.form_name === item.form_name
            ).path
      );
    }
  };

  const renderChildButtons = (item, index) => (
    <Link
      to={
        sidemenujson.find((findItem) => findItem.form_name === item.form_name)
          .path
      }
      className={`link ${
        location.pathname.includes(
          sidemenujson.find((findItem) => findItem.form_name === item.form_name)
            .path
        )
          ? "active"
          : ""
      }`}
    >
      <span className="linkicon">
        <i
          className={` ${
            item.form_icon != "" ? item.form_icon : "fa-regular fa-circle"
          }`}
        ></i>
      </span>
      <span className="linktitle">{item.form_detail}</span>
    </Link>
  );

  const renderParentButtons = (item, index) => (
    <div key={index} className="DrawerButton">
      <DrawerButton
        icon={item.form_icon !== "" ? item.form_icon : "fa-regular fa-circle"}
        noChild={item?.submenu?.length === 0}
        isActive={true}
        item_name={item.form_detail}
        isParentOpen={getOpenDrawer(
          sidemenujson.find((findItem) => findItem.form_name === item.form_name)
            ?.path
        )}
        onExpandClick={() => onSideMenuClick(item, index)}
      />
      <div className="dropdownCol pl_10">
        {withChildMenu?.includes(
          sidemenujson.find((findItem) => findItem.form_name === item.form_name)
            ?.path
        ) &&
          item?.submenu?.length > 0 &&
          item.submenu.map(renderChildButtons)}
      </div>
    </div>
  );

  const getOpenDrawer = (routePath) => {
    return (
      location.pathname.includes(routePath) ||
      withChildMenu?.includes(routePath)
    );
  };

  useEffect(() => {
    getUserSidemenu();
  }, []);

  return (
    <div className={`MainMenu ${isHeaderBtnClick ? "hide-sidebar" : ""}`}>
      <div className="NavbarHeader">
        <div className="BrandLogo">
          <Link to="/">
            <img
              src={logo}
              className="Logo"
              alt="logo"
              width="160"
              height="48"
            />
          </Link>
        </div>
        <div className="CloseIcon" onClick={() => onCloseClicked()}>
          <IconClose />
        </div>
      </div>

      <div className="DrawerButton">
        <DrawerButton
          icon={"fa-regular fa-bar-chart"}
          noChild={true}
          isActive={true}
          item_name={"Dashboard"}
          isParentOpen={location.pathname === "/"}
          onExpandClick={() => navigate("/")}
        />
      </div>
      {sidemenu.length > 0 && sidemenu.map(renderParentButtons)}
    </div>
  );
}
