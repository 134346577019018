import Button from "react-bootstrap/Button";

function ButtonPage() {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Filled</h4>
            </div>
            <div className="card-body">
              <div className="demo-inline-spacing">
                <Button variant="primary btn-15">Primary</Button>
                <Button variant="secondary btn-15">Secondary</Button>
                <Button variant="success btn-15">Success</Button>
                <Button variant="warning btn-15">Warning</Button>
                <Button variant="danger btn-15">Danger</Button>
                <Button variant="info btn-15">Info</Button>
                <Button variant="dark btn-15">Dark</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Outline</h4>
            </div>
            <div className="card-body">
              <div className="demo-inline-spacing">
                <Button variant="outline-primary btn-15">Primary</Button>
                <Button variant="outline-secondary btn-15">Secondary</Button>
                <Button variant="outline-success btn-15">Success</Button>
                <Button variant="outline-warning btn-15">Warning</Button>
                <Button variant="outline-danger btn-15">Danger</Button>
                <Button variant="outline-info btn-15">Info</Button>
                <Button variant="outline-dark btn-15">Dark</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Round</h4>
            </div>
            <div className="card-body">
              <div className="demo-inline-spacing">
                <Button variant="outline-primary btn-15 rounded-pill">
                  Primary
                </Button>
                <Button variant="outline-secondary btn-15 rounded-pill">
                  Secondary
                </Button>
                <Button variant="outline-success btn-15 rounded-pill">
                  Success
                </Button>
                <Button variant="outline-warning btn-15 rounded-pill">
                  Warning
                </Button>
                <Button variant="outline-danger btn-15 rounded-pill">
                  Danger
                </Button>
                <Button variant="outline-info btn-15 rounded-pill">Info</Button>
                <Button variant="outline-dark btn-15 rounded-pill">Dark</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Sizes</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="demo-inline-spacing">
                    <Button variant="btn btn-primary btn-15 btn-lg">
                      Large
                    </Button>
                    <Button variant="btn btn-primary btn-15">Default</Button>
                    <Button variant="btn btn-primary btn-15 btn-sm">
                      Small
                    </Button>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="demo-inline-spacing">
                    <Button variant="btn btn-outline-primary btn-15 btn-lg">
                      Large
                    </Button>
                    <Button variant="btn btn-outline-primary btn-15">
                      Default
                    </Button>
                    <Button variant="btn btn-outline-primary btn-15 btn-sm">
                      Small
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Icons</h4>
            </div>
            <div className="card-body">
              <div className="demo-inline-spacing">
                <Button variant="btn btn-outline-primary btn-15">
                  <i className="fa-light fa-house button-icon"></i>
                  <span className="align-middle">Home</span>
                </Button>
                <Button variant="btn btn-warning btn-15">
                  <i className="fa-light fa-star button-icon"></i>
                  <span className="align-middle">Star</span>
                </Button>
                <Button variant="btn btn-flat-success btn-15">
                  <i className="fa-light fa-check button-icon"></i>
                  <span className="align-middle">Done</span>
                </Button>
                <Button
                  variant="btn btn-outline-primary disabled btn-15"
                  disabled=""
                >
                  <i className="fa-light fa-house button-icon"></i>
                  <span className="align-middle ">Home</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Icon Only</h4>
            </div>
            <div className="card-body">
              <div className="demo-inline-spacing">
                <Button variant="btn-icon btn-outline-primary btn-15">
                  <i className="fa-light fa-house"></i>
                </Button>
                <Button variant="btn-icon btn-warning btn-15">
                  <i className="fa-light fa-star"></i>
                </Button>
                <Button variant="btn-icon btn-flat-success btn-15">
                  <i className="fa-light fa-house"></i>
                </Button>
                <Button
                  variant="btn-icon btn-outline-primary disabled btn-15"
                  disabled=""
                >
                  <i className="fa-light fa-house"></i>
                </Button>
                <Button variant="btn-icon btn-outline-primary btn-15 rounded-circle">
                  <i className="fa-light fa-house"></i>
                </Button>
                <Button variant="btn-icon btn-warning btn-15 rounded-circle">
                  <i className="fa-light fa-star"></i>
                </Button>
                <Button variant="btn-icon btn-flat-success btn-15 rounded-circle">
                  <i className="fa-light fa-house"></i>
                </Button>
                <Button
                  variant="btn-icon btn-outline-primary disabled btn-15 rounded-circle"
                  disabled=""
                >
                  <i className="fa-light fa-house"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ButtonPage;
