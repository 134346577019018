import DocImg from "../assets/Icons/doc.png";
import PdfImg from "../assets/Icons/pdf.png";
import XlsImg from "../assets/Icons/xls.png";
import DefaultImg from "../assets/Images/Icon/no_image.png";
const cryptoKey = "SqT_cL@SsRoOm_S@T!Sh_393987";


export const commonHelper = {
  setItem,
  getItem,
  getHeaders,
  getHeadersFromData,
  getLoginUserData,
  getFileType,
  CreateColor,
  getExtBaseFile,
  formatBytes
};

export const AppConfigData = {
  APIBasePath: process.env.REACT_APP_API_URL,
  APIFileBasePath: "https://apiv2.orataro.com/",
  CryptoKey: cryptoKey,
  ERPBasePath: "http://erp.orataro.com/",
  ERPFileBasePath: "http://erp.orataro.com/Files/",
  toolbarConfig: {
    options: ["inline", "list", "history"],
    inline: {
      inDropdown: false,
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    list: {
      inDropdown: false,
      options: ["unordered", "ordered"],
    },
    history: {
      inDropdown: false,
      options: ["undo", "redo"],
    },
  },
  regex: {
    URL: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    YoutubeEmbed:
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  },
};

export const DisplayText = {
  BrowserNotSupportVideo: "Your browser does not support the video tag.",
  BrowserNotSupportAudio: "Your browser does not support the audio tag.",
  UnableToLoadContent:
    "Sorry we are unable to load this content in current page.<br /> Click here for view in new tab",
  Of: "of",
  Welcome: "Welcome",
  MenuHome: "Home",
};

export const ApiRoutes = {
  CompanySave: AppConfigData.APIBasePath + "company/create",
};

export const SideMenuText = {
  Home: "Home",
  Company: "Companies",
  PaymentTrans: "Payment Transactions",
  Settings: "Settings",
  Setup: "Setup",
  Branch: "Branch Manager",
  ClientRequestQuotations: "Request Quotations",
  Client: "Property Manager",
  Properties: "Properties",
  Guard: "Guards",
  Incharge: "Incharges",
  Leave: "Manage Leaves",
  AdvancePaymentReq: "Advance Payments",
  Report: "Reports",
  Incident: "Incident",
  MyQuotations: "My Quotations",
  ReviewRatings: "Reviews & Ratings",
  Complaint: "Complaint",
  Notifications: "Notifications",
  Profile: "Profile",
  Logout: "Logout",
};

function setItem(key, strString) {
  let CryptoJS = require("crypto-js");
  localStorage.setItem(key, CryptoJS.AES.encrypt(strString, cryptoKey));
}

function getItem(key) {
  let CryptoJS = require("crypto-js");
  let dataValues = localStorage.getItem(key) || "";
  let dataStr = "";
  if (dataValues !== "") {
    var bytes = CryptoJS.AES.decrypt(dataValues, cryptoKey);
    dataStr = bytes.toString(CryptoJS.enc.Utf8);
  }
  return dataStr;
}

function getHeaders() {
  return {
    "Content-Type": "application/json",
    accept: "*/*",
    Authorization: getItem("auth_token"),
  };
}

function getHeadersFromData() {
  return {
    //'Content-Type': 'multipart/form-data; boundary=classroom',
    //'accept': '*/*',
    Authorization: getItem("auth_token"),
  };
}

function getLoginUserData() {
  var data = [
    {
      Avatar: "",
      BGColor: "",
      BatchID: "00000000-0000-0000-0000-000000000000",
      ClientID: "00000000-0000-0000-0000-000000000000",
      CurrentDivisionID: "00000000-0000-0000-0000-000000000000",
      CurrentStandardID: "00000000-0000-0000-0000-000000000000",
      DateFormat_Term: "",
      DepartmentID: "00000000-0000-0000-0000-000000000000",
      DisplayName: "",
      EndDate: "",
      FontColor: "",
      FullName: "",
      Initials: "",
      InstituteCode: "",
      InstituteID: "00000000-0000-0000-0000-000000000000",
      InstituteLogo: "",
      InstituteName: "",
      InstituteType_Term: "",
      MediumOfEducation_Term: "",
      MemberID: "00000000-0000-0000-0000-000000000000",
      MemberType: "",
      PostByType: "",
      PrimaryName: "00000000-0000-0000-0000-000000000000",
      RoleID: "00000000-0000-0000-0000-000000000000",
      RoleName: "",
      RollEMPNo: "",
      StartDate: "",
      StdDivDept: "",
      StudentBusRegMasterID: "00000000-0000-0000-0000-000000000000",
      StudentCode: "",
      StudentHostelRegID: "00000000-0000-0000-0000-000000000000",
      TimeFormat_Term: "",
      UserID: "00000000-0000-0000-0000-000000000000",
      UserName: "",
      UserType_Term: "",
      WallID: "00000000-0000-0000-0000-000000000000",
      FirstName: "",
      LastName: "",
    },
  ];
  let dataStr = getItem("home");
  if (dataStr !== "") {
    data = JSON.parse(dataStr);
  }

  return data;
}

function getFileType(file) {
  let FileType = "FILE";
  if (file !== null && file !== undefined) {
    if (file.type !== null && file.type !== "" && file.type !== undefined) {
      if (file.type.toUpperCase().indexOf("IMAGE") >= 0) {
        FileType = "IMAGE";
      } else if (file.type.toUpperCase().indexOf("AUDIO") >= 0) {
        FileType = "AUDIO";
      } else if (file.type.toUpperCase().indexOf("VIDEO") >= 0) {
        FileType = "VIDEO";
      } else if (file.type.toUpperCase().indexOf("PDF") >= 0) {
        FileType = "PDF";
      }
    }
  }
  return FileType;
}

function CreateColor(str) {
  let BGRGB = "000000";
  let FRGB = "FFFFFF";
  if (str !== "") {
    str = str + "000000";
    let hash = hashCode(str);
    BGRGB = intToRGB(hash);
    FRGB = invertColor(BGRGB);
  }
  return { BGColor: BGRGB, FontColor: FRGB };
}

function hashCode(str) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "000000".substring(0, 6 - c.length) + c;
}

function invertColor(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  //var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
  //    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
  //    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);

  var r = (255).toString(16),
    g = (255).toString(16),
    b = (255).toString(16);
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}
function getExtBaseFile (url, ext) {
  let result = "";
  const imgExtensions = ["jpg", "jpeg", "png", "gif", "JPG", "svg"];
  const pdfExtensions = ["pdf"];
  const xlsExtensions = ["xlsx", "xls"];
  const docExtensions = ["doc", "docx", "ppt", "pptx", "txt"];
  if (imgExtensions.includes(ext)) {
    result = url;
  } else if (docExtensions.includes(ext)) {
    result = DocImg;
  } else if (pdfExtensions.includes(ext)) {
    result = PdfImg;
  } else if (xlsExtensions.includes(ext)) {
    result = XlsImg;
  } else {
    result = DefaultImg;
  }
  return result;
};

function formatBytes(bytes, decimals = 2) {

  return (parseFloat(bytes) / 1048576).toFixed(decimals);
  // if (bytes === 0) return '0 Bytes';

  // const k = 1024;
  // const dm = decimals < 0 ? 0 : decimals;
  // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  // const i = Math.floor(Math.log(bytes) / Math.log(k));

  // return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}