import React, { useState } from "react";
import { Breadcrumb, Button, InputGroup, Form, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";

// import FilterComponent from "../../components/common/FilterComponent";
import { ApiRoutes } from "../../constants/apiroutes";
// import NoDataFound from "../../components/common/NoDataFound";
import { Enums } from "../../constants/enums";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { alertService, AlertType } from "../../services/alert.service";
import InputText from "../../components/form/inputText";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { GuardStatusEnum } from "../../constants";
import GuardStatusPopup from "./GuardStatusPopup";
import NewLoader from "../../components/loader/NewLoader";

function Index() {
  const userType = JSON.parse(
    commonService.getItem("user")
  ).association_type_term;
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [companyTotalRows, setGuardTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(
    Enums.DefaultPerPageLength
  );
  const [guardsList, setGuardsList] = useState({
    isData: false,
    data: [],
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);

  const RoleRights = getUserAccess(Enums.UserRights.guard);

  const [guardStatusPopup, setGuardStatusPopup] = useState(false);
  const [guardStatusId, setGuardStatusId] = useState(null);

  // const handleAcceptStatus = async (guard_id) => {
  //   let res = await APICall(ApiRoutes.acceptGuardStatus, { guard_id: guard_id })
  //   if (res.status === 1) {
  //     getGuardsList("", "", 1);
  //   }
  // }

  const handleShowGuardStatusPopUp = (guard_id) => {
    setGuardStatusPopup(true);
    setGuardStatusId(guard_id);
  };

  const handleDelete = async (e, guard_id) => {
    e.preventDefault();

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      let res = await APICall(ApiRoutes.GuardDelete, {
        guard_id: guard_id,
      });
      if (res.status === 1) {
        alertService.alert({
          type: AlertType.Success,
          message: res.message,
        });
        getGuardsList("", "", 1);
      }
    }
  };

  const companyTblColumns = [
    {
      name: "Guard Name",
      selector: (row) => (
        <div
          className="cursor-pointer text-primary"
          onClick={() => {
            navigate(`/guard/view/${row.guard_id}`);
          }}
        >
          {" "}
          {row.guard_name}
        </div>
      ),
      sortable: true,
      sortField: "guard_name",
    },
    {
      name: "Email",
      selector: (row) => row.guard_email,
      sortable: true,
      sortField: "guard_email",
    },
    {
      name: "Phone",
      selector: (row) => row.guard_phone,
      sortable: true,
      sortField: "guard_phone",
    },
    {
      name: "ID Number",
      selector: (row) => row.id_number,
      sortable: true,
      sortField: "id_number",
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      sortField: "gender",
    },

    {
      name: "Company/Branch/Incharge",
      selector: (row) => row.created_by_name,
      sortable: true,
      omit: userType === Enums.UserRole.admin ? false : true,
      sortField: "created_by_name",
      width: "150px",
    },

    {
      name: "Approval Status",
      selector: (row) => (
        <div className="d-flex align-items-center">
          {GuardStatusEnum[row.status]}

          {(row.status == Enums.GuardStatus.pending ||
            row.status == Enums.BranchStatus.rejected) &&
          userType === Enums.UserRole.admin ? (
            <span className="ms-2 cursor-pointer h6 m-0">
              <i
                onClick={(e) => {
                  e.preventDefault();
                  handleShowGuardStatusPopUp(row.guard_id);
                }}
                className="fa fa-edit"
              ></i>
            </span>
          ) : (
            ""
          )}
        </div>
      ),
      sortable: true,
      sortField: "status",
      // cell: (row, index, column, id) => {
      // if (userType == Enums.UserRole.admin && row.status === Enums.GuardStatus.pending) {
      //   return (<Button type="button" onClick={(e) => {
      //     handleAcceptStatus(row.guard_id)
      //   }} style={{ fontSize: "10px" }} size="sm">
      //     Activate
      //   </Button>)
      // } else {
      //   return (
      //     <span className="justify-content-center align-items-center d-flex">
      //       {GuardStatusEnum[row.status]}
      //     </span>
      //   );
      // }
      // <div className="d-flex align-items-center">
      //   {GuardStatusEnum[row.status]}

      //   {row.status == Enums.GuardStatus.pending && userType === Enums.UserRole.admin ? <span className="ms-2 cursor-pointer h6 m-0"><i onClick={(e) => {
      //     e.preventDefault()
      //     handleAcceptStatus(row.guard_id)

      //   }} className="fa fa-edit"></i></span> : ""}
      // </div>
      // },
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      sortField: "is_active",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={(value) => onSwitchToggle(value, row, index)}
              checked={row.is_active === "1" ? true : false}
              height={20}
              width={40}
              // disabled={!RoleRights.is_update}
              onColor="#263A96"
            />
          </span>
        );
      },
    },
    {
      name: "Created On",
      selector: (row) => row.created_at,
      sortable: true,
      sortField: "created_at",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.created_at),
    },
    {
      name: "Action",
      selector: (row) => row.year,
      cell: (row, index, column, id) => (
        <div className="justify-content-center align-items-center d-flex">
          {RoleRights.is_update === 1 && (
            <div
              className="cursor-pointer me-2"
              onClick={() => {
                navigate("edit", {
                  state: {
                    id: row.guard_id,
                  },
                });
              }}
            >
              <i className="fa fa-edit text-primary"></i>
            </div>
          )}
          <div
            className="cursor-pointer me-2"
            onClick={() => {
              navigate(`/guard/view/${row.guard_id}`);
            }}
          >
            <i className="fa fa-eye text-primary"></i>
          </div>
          {(userType == Enums.UserRole.company ||
            userType == Enums.UserRole.branch_manager) &&
          parseInt(RoleRights.is_delete) === 1 ? (
            <div
              className="cursor-pointer"
              onClick={(e) => {
                handleDelete(e, row.guard_id);
              }}
            >
              <i className="text-danger fa fa-trash text-primary"></i>
            </div>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const navigate = useNavigate();

  React.useEffect(() => {
    getGuardsList("", "", 1);
  }, [txtSearch,pageNo, perPageRecords]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };
  const resetFilter = () => {
    setFromDate(null);
    setToDate(null);
    setTxtSearch("");
    setFilterStatus(null);
    getGuardsList("", "", 0);
  };

  const searchData = (e) => {
    e.preventDefault();

    let isValidate = true;
    if (toDate || fromDate) {
      if (toDate && fromDate) {
        const dateFrom = new Date(fromDate);
        const dateTo = new Date(toDate);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getGuardsList("", "", 1);
      setGuardTotalRows(1)
    }
  };

  // React.useEffect(() => {
  //   getGuardsList("", "", 1);
  // }, [txtSearch]);
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  function filterCompaniesList() {
    getGuardsList("", "", 1);
  }

  async function getGuardsList(column = "", sortDirection = "", isFilter = 0) {
    setDataTableLoading(true);
    setGuardsList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.GuardList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        status: filterStatus,
        start_date:
          fromDate != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(fromDate) + " 00:00:00"
              )
            : null,
        end_date:
          toDate != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(toDate) + " 23:59:59"
              )
            : null,
      },
    });

    if (requestMetadata) {
      setGuardsList({ isData: true, data: requestMetadata.data.list });

      setGuardTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
  }

  const handleSort = async (column, sortDirection) => {
    getGuardsList(column.sortField, sortDirection, 1);
  };

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.GuardStatusUpdate, {
      id: item.guard_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setGuardsList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };

  const handleFilterStatus = (value) => {
    if (value) {
      setFilterStatus(parseInt(value));
    } else {
      setFilterStatus(null);
    }
  };

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Guard List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/guards">Setup</Breadcrumb.Item>
                <Breadcrumb.Item active>Guards</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>

        <div className="col-12 d-flex align-items-center justify-content-end mb-3 mt-2">
          {RoleRights.is_update === 1 && userType != Enums.UserRole.admin ? (
            <Link to="/guards/create">
              <Button className="btn-15" variant="primary" type="button">
                <i className="fa-regular fa-plus"></i> Add Guard
              </Button>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <Card>
          <Card.Body className="p-3">
            <div className="row">
              <div className="col-3">
                <InputGroup>
                  <DatePicker
                    className="datepicker form-control"
                    selected={fromDate ? fromDate : ""}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Date"
                    onChange={(date) => {
                      if (date) {
                        setFromDate(date);
                      } else {
                        setFromDate(null);
                      }
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-3">
                <div>
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={toDate ? toDate : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="End Date"
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        } else {
                          setToDate(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
              </div>
              <div className="col-3">
                <Form.Select
                  onChange={(e) => {
                    handleFilterStatus(e.target.value);
                  }}
                >
                  <option value="" selected={filterStatus == null && true}>
                    Select Guard Status
                  </option>
                  <option value="1" selected={filterStatus == 1 && true}>
                    Active
                  </option>
                  <option value="0" selected={filterStatus == 0 && true}>
                    Inactive
                  </option>
                </Form.Select>
              </div>
              <div className="col-3">
                <div className="d-flex">
                  <div className="me-2">
                    <Button
                      onClick={searchData}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* <FilterComponent
        searchText={txtSearch}
        PHSearchText={DisplayText.SearchText}
        PHDatepickerFrom={DisplayText.DatepickerFrom}
        PHDatepickerTo={DisplayText.DatepickerTo}
        startDate={filterStartDate}
        endDate={filterEndDate}
        onSearchTextChange={(value) => setTxtSearch(value)}
        onStartDateChange={(value) => setFilterStartDate(value)}
        onEndDateChange={(value) => setFilterEndDate(value)}
        onSearchClick={() => filterCompaniesList()}
      /> */}

      {/* {guardsList.data.length === 0 ? (
        <div className="event-details">
          <NoDataFound fontsize={"150px"} />
        </div>
      ) : ( */}
      <DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
        columns={companyTblColumns}
        data={guardsList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={companyTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={
          <>
            <div className="col-4">
              <InputText
                formGroupClass={"mb-0"}
                size={"12"}
                type={"text"}
                Label={false}
                placeholder={"Search..."}
                value={txtSearch}
                onChange={(value) => {
                  if (value) {
                    setTxtSearch(value);
                  } else {
                    setTxtSearch(value);
                  }
                }}
              />
            </div>
          </>
        }
      />
      {/* )} */}

      {guardStatusPopup && (
        <GuardStatusPopup
          getGuardsList={getGuardsList}
          guardStatusPopup={guardStatusPopup}
          setGuardStatusPopup={setGuardStatusPopup}
          guardStatusId={guardStatusId}
          setGuardStatusId={setGuardStatusId}
        />
      )}
    </>
  );
}

export default Index;
