import { Card, Col, Row, Nav, Tab ,Breadcrumb} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { APICall } from "../../../services/axiosService";
import { ApiRoutes, Enums } from "../../../constants";
import { useEffect, useState } from "react";
import NoImage from "../../../assets/Images/Icon/no_image.png"
import { commonService } from "../../../services/common.service";

export default function BranchProfile() {

  const [branch, setBranch] = useState(null);
  const location = useLocation();
  const [reviewList, setReviewList] = useState([]);
  const [reviewLoading, setReviewLoading] = useState(true);
  
  const fetchContractBranchProfile = async (branch_id) => {

    const res = await APICall(ApiRoutes.contractBranchProfile, { branch_id: branch_id })
    if (res.status == 1) {
      setBranch(res.data)
    }
  }
  useEffect(() => {
    if (branch == null && location.state) {
      let branch_id = location.state.branch_id
      fetchContractBranchProfile(branch_id);
    }
  }, []);

  const fetchReviewListig = async () => {
    let res = await APICall(ApiRoutes.fetchAssociationReviews, {
      association_id: location.state.branch_id,
      association_type_term: Enums.UserRole.branch_manager
    })
    if (res.status === 1) {
      setReviewLoading(false)
      setReviewList(res.data.list)
    }else{
      setReviewLoading(false)
    }
  }
  useEffect(() => {
    fetchReviewListig()
  }, []);


  const CompanyReviews = () => {
    return (<Card>
      <Card.Header>
        <h4 className="card-title">Review & Rating</h4>
      </Card.Header>
      <Card.Body>
        {!reviewLoading ? reviewList.length ?  reviewList.map((review_item) => {
            return (<div className="companies-section">
              <div className="rounded border p-3 mb-3">
                <div className="d-md-flex justify-content-between">
                  <div className="d-sm-flex justify-content-between">
                    <div className="col-6">{review_item.review}</div>
                    <div className="col-4 d-flex flex-column justify-content-between">
                      <div className="star-rating static-rating text-center w-100">
                        {[...Array(5).keys()].map((item, index) => {
                          return (<>
                            <input checked={Math.ceil(parseFloat(review_item.rating)) == 5 - item ? true : false} id={`star-${item}`} type="radio" name="rating" value={item} />
                            <label htmlFor={`star-${item}`} title={`${item} stars`}>
                              <i className="active fa fa-star" aria-hidden="true"></i>
                            </label>
                          </>)
                        })}
                      </div>

                      <div className="text-end h6">{commonService.convertUTCToLocal(review_item.created_at)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)
          })
        :  <div className="w-100 p-3 text-center">No reviews!</div> :<div className="w-100 p-3 text-center">Loading...</div>}
      </Card.Body>
    </Card>)
  }
  return (<div className="contract-company-view">
    <Row>
    <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-block d-sm-flex align-items-center">
            <h2 className="content-header-title float-sm-start float-none mb-2 mb-sm-0">
              Branch Manager
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/myquotation">Quotation</Breadcrumb.Item>
              <Breadcrumb.Item active> Branch Manager</Breadcrumb.Item>
            </Breadcrumb>
          </div>
       
        </div>
      </div>
      <Col className="order-1 col-12 order-lg-0 col-lg-5 col-xl-4 contract-company-profile">
      
        <Card>
          <Card.Body>
            <div className="company-profile-avatar">
              <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
                <span className="avatar-content">
                  <img className="w-100 h-100 obj-fit-cover" src={branch?.branch_profile_img ? process.env.REACT_APP_ASSETS_URL + branch?.branch_profile_img : NoImage}></img>
                </span>
              </div>
              <div className="d-flex flex-column align-items-center text-center user-info">
                <span className="mb-2 text-primary h4">{branch?.branch_name}</span>
                <span className="text-capitalize badge bg-light-primary">
                  #Branch Manager
                </span>
              </div>
              <div className="star-rating static-rating text-center  w-100">
                {[...Array(5).keys()].map((item, index) => {
                  return (<>
                    <input checked={Math.ceil(parseFloat(branch?.rating)) == 5 - item ? true : false} id={`star-${item}`} type="radio" name="rating" value={item} />
                    <label htmlFor={`star-${item}`} title={`${item} stars`}>
                      <i className="active fa fa-star" aria-hidden="true"></i>
                    </label>
                  </>)
                })}
              </div>
            </div>
            <div className="company-details mt-4">
              <div className="left-field">{branch?.locations[0] ? <><i className="fa-solid fa-location-dot text-primary me-2"></i> </> : ""}
                {branch?.locations[0] ?
                  branch.locations[0].address1 + ', ' +
                  branch.locations[0].city + ', ' +
                  branch.locations[0].state + ', ' +
                  branch.locations[0].country + ', ' +
                  branch.locations[0].postal_code
                  : ""}
              </div>
              { branch ? branch.branch_website_url != '' && branch.branch_website_url != null ? 
              
              <div className="left-field"><i className="fa-solid fa-link text-primary me-2"></i> <a className="text-primary cursor-pointer" href={branch.branch_website_url} target="_blank"> {branch.branch_website_url} </a>  </div>   
            : ""  : ""} 
               {branch?.branch_portfolio_url &&
                    <div className="left-field mb-1"><i className="fa-solid fa-file text-primary me-2"></i> <a className="text-primary cursor-pointer" href={process.env.REACT_APP_ASSETS_URL + branch.branch_portfolio_url} target="_blank"> View branch profile </a>  </div>    
              }
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col className="order-0 col-12 order-lg-1 col-lg-7 col-xl-8">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="mb-3">
            <Nav.Item className="cursor-pointer">
              <Nav.Link eventKey="first">
                <i className="fa fa-user" aria-hidden="true"></i>
                <span className="pills-tab-text">Profile</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="cursor-pointer">
              <Nav.Link eventKey="third">
                <i className=" fa-solid fa-star-sharp-half-stroke"></i>
                <span className="pills-tab-text">Review & Rating</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Card>
                <Card.Header className="h4 company-details-head fw-bolder border-bottom pb-2 mb-3">Basic Information</Card.Header>
                <Card.Body>
                  <div className="company-details info-container">
                    <div className="info-field mb-2">
                      <span className="left-field">Branch Manager </span>
                      <span className="right-field">{branch?.branch_name}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Company Name </span>
                      <span className="right-field">{branch?.branch_company ? branch?.branch_company.company_name ? branch?.branch_company?.company_name : "" : ""}</span>
                    </div>
                    {/* <div className="info-field mb-2">
                      <span className="left-field">Company Email </span>
                      <span className="right-field">{branch?.branch_company ? branch?.branch_company.email ? branch?.branch_company?.email :"" :""}</span>
                    </div> */}
                    <div className="info-field mb-2">
                      <span className="left-field">Register Number </span>
                      <span className="right-field">{branch?.branch_register_no}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Branch Email </span>
                      <span className="right-field">{branch?.branch_email}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Branch Phone </span>
                      <span className="right-field">{branch?.branch_phone}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Contact Person </span>
                      <span className="right-field">{branch?.branch_contact_person_name}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Contact Person Email </span>
                      <span className="right-field">{branch?.branch_contact_person_email}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Contact Person Phone No.</span>
                      <span className="right-field">{branch?.branch_contact_person_phone}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Status </span>
                      <span className={`right-field text-capitalize badge bg-light-${branch?.is_active == '1' ? 'primary' : "danger"}`}>
                        {branch?.is_active == '1' ? 'Active' : "Inactive"}
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <CompanyReviews />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  </div>)
}
