import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import InputText from "../../../components/form/inputText";

export default function EditWizardTwo({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          {formInputs.bank_details.map((mapItem, mapIndex) => (
            <Row key={mapIndex}>
              <InputText
                type={"text"}
                title={"Bank Name"}
                value={mapItem.bank_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    bank_details: formInputs.bank_details.map(
                      (innerItem, innerIndex) => {
                        if (innerIndex === mapIndex) {
                          return {
                            ...innerItem,
                            bank_name: value,
                          };
                        }
                      }
                    ),
                  })
                }
                validationText={
                  formInputsErrors?.bank_details[mapIndex]?.bank_name
                }
                required
              />
              <InputText
                type={"number"}
                title={"Account Number"}
                value={mapItem.account_no}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    bank_details: formInputs.bank_details.map(
                      (innerItem, innerIndex) => {
                        if (innerIndex === mapIndex) {
                          return {
                            ...innerItem,
                            account_no: value,
                          };
                        }
                      }
                    ),
                  })
                }
                validationText={
                  formInputsErrors?.bank_details[mapIndex]?.account_no
                }
                required
              />
            </Row>
          ))}
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15"
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
