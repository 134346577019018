import React, { useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import InputText from "../../../components/form/inputText";
import InputSearchLocation from "../../../components/form/InputSearchLocation";

export default function EditWizardTwo({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
  isCompanyFormClick,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const handleLocation = (addressInfoArray) => {
    setInput({
      ...formInputs,
      location: {
        ...formInputs.location,
        address1: addressInfoArray.street_address,
        city: addressInfoArray.city,
        state: addressInfoArray.state,
        postal_code: addressInfoArray.postal_code,
        country: addressInfoArray.country,
        longitude: addressInfoArray.lng,
        latitude: addressInfoArray.lat,
      }
    })
  }
  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header">
        <InputSearchLocation size={"12"} placeholder="Search location" onPlaceChange={handleLocation} />
        </div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Row>
            <InputText
              type={"text"}
              title={"Address"}
              value={formInputs.location.address1}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  location: {
                    ...formInputs.location,
                    address1: value,
                  },
                })
              }
              required
              validationText={formInputsErrors?.address1}
            />
            <InputText
              type={"text"}
              title={"City"}
              value={formInputs.location.city}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  location: {
                    ...formInputs.location,
                    city: value,
                  },
                })
              }
              required
              validationText={formInputsErrors?.city}
            />
          </Row>
          <Row>
            <InputText
              type={"text"}
              title={"State"}
              value={formInputs.location.state}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  location: {
                    ...formInputs.location,
                    state: value,
                  },
                })
              }
              required
              validationText={formInputsErrors?.state}
            />
            <InputText
              type={"text"}
              title={"Postal code"}
              value={formInputs.location.postal_code}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  location: {
                    ...formInputs.location,
                    postal_code: value,
                  },
                })
              }
              required
              validationText={formInputsErrors?.postal_code}
            />
          </Row>
          <Row>
            <InputText
              type={"text"}
              title={"Country"}
              value={formInputs.location.country}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  location: {
                    ...formInputs.location,
                    country: value,
                  },
                })
              }
              required
              validationText={formInputsErrors?.country}
            />
          </Row>
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              {isCompanyFormClick === false ? (
                <>
                  <Button
                    className="btn-15"
                    variant="primary"
                    onClick={onNextPress}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button className="btn-15" variant="primary">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
