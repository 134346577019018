import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import logo from "../../assets/Images/logo/logo.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { ReactComponent as EyeIcon } from "../../assets/Icons/eye.svg";
import { ReactComponent as EyeSlashIcon } from "../../assets/Icons/eyeSlash.svg";
import { APICall } from "../../services/axiosService";
import { ApiRoutes } from '../../constants';

export default function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passHide, setPassHide] = useState(true);
  const [conPassHide, setConPassHide] = useState(true);
  const [loading, setLoading] = useState(true);
  const initialState = {
    password: "",
    confirm_password: ""
  }
  const [input, setInput] = useState({
    password: "",
    confirm_password: ""
  });
  const [inputError, setInputError] = useState({
    password: "",
    confirm_password: ""
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValidate = true;
    let feildEmpty = false;
    let er_pass = "";
    let er_con_pass = "";
    if (input.password == "") {
      isValidate = false;
      feildEmpty = true;
      er_pass = "This feild is required."
    }
    if (input.confirm_password == "") {
      isValidate = false;
      feildEmpty = true;
      er_con_pass = "This feild is required."
    }

    if (!feildEmpty) {
      if (input.password != input.confirm_password) {
        isValidate = false;
        er_con_pass = "Password and Confirm password must match."
      }
    }
    setInputError({
      ...inputError,
      password: er_pass,
      confirm_password: er_con_pass
    })
    if (isValidate) {
      setIsLoading(true);
      let data = {
        email_token: params.token,
        password: input.password
      }
      let res = await APICall(ApiRoutes.resetPassword, data);
      if (res.status == 1) {
        setIsLoading(false);
        navigate('/portal/login')
      }
      if(res.status == 0){
        setIsLoading(false);
        navigate('/portal/login')
      }
    }
  }

  const checkResetToken = async () => {
    const res = await APICall(ApiRoutes.checkResetToken, { email_token: params.token })
    if (res.status == 0) {
      navigate('/')
    }
    else {
      setLoading(false);
    }

  }
  useEffect(() => {
    checkResetToken()
  }, []);
  if(!loading){
    return (
      <>
        <div className="blank-page">
          <div className="content-body">
            <div className="auth-wrapper auth-basic px-4">
              <div className="auth-inner my-4">
                <div className="mb-0 card">
                  <div className="card-body p-4">
                    <Link to="/" className="login-logo">
                      <img src={logo} alt="logo" width="160" height="48" />
                    </Link>
                    <h4 className="mb-4 card-title text-center text-dark">
                      Reset Password
                    </h4>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group md="12" className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <div className='position-relative'>
                          <Form.Control
                            value={input.password}
                            type={passHide ? 'password' : 'text'}
                            placeholder="Password"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                password: e.target.value,
                              })}
                          />
                          {passHide ? <EyeIcon onClick={() => setPassHide(false)} className="position-absolute me-3 end-0 top-0 mt-2" />
                            : <EyeSlashIcon onClick={() => setPassHide(true)} className="position-absolute me-3 end-0 top-0 mt-2" />
                          }
                        </div>
                        {inputError.password && <span className="error">{inputError.password}</span>}
                      </Form.Group>
                      <Form.Group md="12" className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <div className='position-relative'>
                          <Form.Control
                            value={input.confirm_password}
                            type={conPassHide ? 'password' : 'text'}
                            placeholder="Confirm Password"
                            onChange={(e) =>
                              setInput({
                                ...input,
                                confirm_password: e.target.value,
                              })}
                          />
                          {conPassHide ? <EyeIcon onClick={() => setConPassHide(false)} className="position-absolute me-3 end-0 top-0 mt-2" />
                            : <EyeSlashIcon onClick={() => setConPassHide(true)} className="position-absolute me-3 end-0 top-0 mt-2" />
                          }
                        </div>
                        {inputError.confirm_password && <span className="error">{inputError.confirm_password}</span>}
                      </Form.Group>
                      {/* <Link to="/"> */}
                      {isLoading ? (
                        <Button variant="primary w-100">
                          <Spinner animation="border" size="sm" /> Loading...
                        </Button>
                      ) : (
                        <Button
                          variant="primary w-100"
                          type="submit"
                        // onClick={loginClick}
                        >
                          Reset Password
                        </Button>
                      )}
                      {/* </Link> */}
                    </Form>
                    <p className="create-login-account-bottom text-center mt-3">
                      <span>Already have account?</span>
                      <Link to="/" className="text-primary h6">
                        <span>Login here</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  else{
    return "";
  }
  
}
