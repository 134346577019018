import React from 'react'

const NoDataFound = () => {
  return (
    <div className='text-center'>
      There are no records to display
    </div>
  )
}

export default NoDataFound
