import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Breadcrumb, Button, Card, Form, Row, Spinner } from "react-bootstrap";
import InputText from '../../components/form/inputText';
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import DropDown from "../../components/common/DropDown";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileImageInput from '../../components/common/FileImageInput';

export default function AppSetting() {
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isFormClick, setIsFormClick] = useState(false);


  const [selectedClientGuidelineFile, setSelectedClientGuidelineFile] = useState([]);
  const [selectedClientDocSrc, setSelectedClientDocSrc] = useState(null);

  const [selectedCompanyGuidelineFile, setSelectedCompanyGuidelineFile] = useState([]);
  const [selectedCompanyDocSrc, setSelectedCompanyDocSrc] = useState(null);


  const [selectedBranchGuidelineFile, setSelectedBranchGuidelineFile] = useState([]);
  const [selectedBranchDocSrc, setSelectedBranchDocSrc] = useState(null);
 
  const [formInputs, setInput] = useState({
    setting_id: "",
    android_app_version: "",
    ios_app_version: "",
    is_undermaintenance: "",
    is_force_update_ios: "",
    is_force_update_android: "",
    base_url: "",
    image_base_url: "",
    mobile: "",
    email: "",
    client_guideline_file :"",
    company_guideline_file :"",
    company_term_and_condition: "",
    client_term_and_condition:"",
    client_privacy_policy:"",
    policy: "",
    about_us: "",
    faq: "",
    branch_guideline_file :"",
  });
  const handleSubmit = async(e) => {
    e.preventDefault();
    setIsFormClick(true)
    let res = await APICall(ApiRoutes.updateAppSettings, formInputs)
    if (res.status === 1) {
      fetchSettingDetails()
      setIsFormClick(false)
    }else if(res.status === 0){
      setIsFormClick(false)
    }else{
      setIsFormClick(false)
    }
  }
  const dataBoolean = [{ value: 1, label: 'Yes' }, { value: 0, label: 'No' }]

  const fetchSettingDetails = async () => {
    let res = await APICall(ApiRoutes.getAppSettings)
    if (res.status === 1) {
      if(res.data){
        setInput({
          setting_id: res.data.id ? res.data.id :"",
          android_app_version: res.data.android_app_version ? res.data.android_app_version :"",
          ios_app_version: res.data.ios_app_version ? res.data.ios_app_version :"",
          is_undermaintenance: res.data.is_undermaintenance ?res.data.is_undermaintenance:"'",
          is_force_update_ios: res.data.is_force_update_ios ? res.data.is_force_update_ios :"",
          is_force_update_android: res.data.is_force_update_android ? res.data.is_force_update_android :"",
          base_url: res.data.base_url ? res.data.base_url :"",
          image_base_url: res.data.image_base_url ?res.data.image_base_ur :"",
          mobile: res.data.mobile ? res.data.mobile :"",
          email: res.data.email ?res.data.email :"",
          company_term_and_condition: res.data.company_term_and_condition ?res.data.company_term_and_condition :"",
          client_term_and_condition:res.data.client_term_and_condition ?res.data.client_term_and_condition :"",
          client_privacy_policy:res.data.client_privacy_policy ?res.data.client_privacy_policy :"", 
          policy: res.data.policy?res.data.policy :"",
          about_us: res.data.about_us? res.data.about_us :"",
          // client_guideline_file :res.data.client_guideline_file ? process.env.REACT_APP_ASSETS_URL + res.data.client_guideline_file :"",
          // company_guideline_file :res.data.company_guideline_file_url ? process.env.REACT_APP_ASSETS_URL + res.data.company_guideline_file_url :"",
          // branch_guideline_file :res.data.branch_guideline_file ? process.env.REACT_APP_ASSETS_URL + res.data.branch_guideline_file :"", 
          faq: res.data.faq ?res.data.faq :"",
        })
        setSelectedClientDocSrc(res.data.client_guideline_file ? process.env.REACT_APP_ASSETS_URL + res.data.client_guideline_file :"")
        setSelectedCompanyDocSrc(res.data.company_guideline_file_url ? process.env.REACT_APP_ASSETS_URL + res.data.company_guideline_file_url :"")
        setSelectedBranchDocSrc(res.data.branch_guideline_file ? process.env.REACT_APP_ASSETS_URL + res.data.branch_guideline_file :"")
      }
    
      setIsDataFetched(true)
    }


  }

  useEffect(() => {
    fetchSettingDetails()
  }, []);

  return (<>
    <div className="content-header row">
      <div className="content-header-left col-md-9 col-12 mb-2">
        <div className="breadcrumbs-top d-flex align-items-center">
          <h2 className="content-header-title float-start mb-0">
            App Setting
          </h2>
          <Breadcrumb className="breadcrumb-chevron">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/app_setting">Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>App Setting</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    </div>
    <Card className="pt-4">
      <Card.Body>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <InputText
              type={"text"}
              title={"Android Version"}
              value={formInputs.android_app_version}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  android_app_version: value,
                })
              }
              validationText={""}
            />

            <InputText
              type={"text"}
              title={"ISO Version"}
              value={formInputs.ios_app_version}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  ios_app_version: value,
                })
              }
              validationText={""}
            />
          </Row>
          <Row>
            <DropDown
              data={dataBoolean}
              title={"Under Maintenance"}
              value={formInputs.is_undermaintenance}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  is_undermaintenance: value,
                })
              }
              required
              validationText={""}
            />
            <DropDown
              data={dataBoolean}
              title={"Force Update For ISO"}
              value={formInputs.is_force_update_ios}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  is_force_update_ios: value,
                })
              }
              required
              validationText={""}
            />
          </Row>
          <Row>
            <DropDown
              data={dataBoolean}
              title={"Force Update For Android"}
              value={formInputs.is_force_update_android}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  is_force_update_android: value,
                })
              }
              required
              validationText={""}
            />
            {/* <InputText
              type={"text"}
              title={"Base Url"}
              value={formInputs.base_url}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  base_url: value,
                })
              }
              validationText={""}
            /> */}
              <InputText
              type={"number"}
              title={"Admin Contact Number"}
              value={formInputs.mobile}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  mobile: value,
                })
              }
              validationText={""}
            />
          </Row>
          <Row>
            {/* <InputText
              type={"text"}
              title={"Image Base Url"}
              value={formInputs.image_base_url}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  image_base_url: value,
                })
              }
              validationText={""}
            /> */}
          
            <InputText
              type={"text"}
              title={"Admin Email"}
              value={formInputs.email}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  email: value,
                })
              }
              validationText={""}
            />
          </Row>
          <Row className='w-100 m-0 row mb-3'>
            
            <FileImageInput
                style={{
                  div : 'col-md-4 col-12 p-0'
                }}
                fileImageSrc={selectedClientDocSrc}
                setFileImageSrc={setSelectedClientDocSrc}
                permenentFileImage={selectedClientDocSrc}
                permenentFileImageTitle={"View Document"}
                validateExts={['pdf']}
                portfolioExt={'pdf'}
                accept_file_type={".pdf"}
                title={"Client Guideline"}
                selectedDocuments={selectedClientGuidelineFile}
                input_ref_id="g-client-doc-1"
                onChange={(value) => {
                  setSelectedClientGuidelineFile(value)
                  setSelectedClientDocSrc(value[0].base64)
                  setInput({
                    ...formInputs,
                    client_guideline_file :value[0].base64
                  })
                }}
                />
                 <FileImageInput
                style={{
                  div : 'col-md-4 col-12 pl-2'
                }}
                fileImageSrc={selectedCompanyDocSrc}
                setFileImageSrc={setSelectedCompanyDocSrc}
                permenentFileImage={selectedCompanyDocSrc}
                permenentFileImageTitle={"View Document"}
                validateExts={['pdf']}
                portfolioExt={'pdf'}
                accept_file_type={".pdf"}
                title={"Company Guideline"}
                selectedDocuments={selectedCompanyGuidelineFile}
                input_ref_id="g-company-doc-1"
                onChange={(value) => {
                  setSelectedCompanyGuidelineFile(value)
                  setSelectedCompanyDocSrc(value[0].base64)
                  setInput({
                    ...formInputs,
                    company_guideline_file :value[0].base64
                  })
                }}
                />


<FileImageInput
                style={{
                  div : 'col-md-4 col-12 pl-2'
                }}
                fileImageSrc={selectedBranchDocSrc}
                setFileImageSrc={setSelectedBranchDocSrc}
                permenentFileImage={selectedBranchDocSrc}
                permenentFileImageTitle={"View Document"}
                validateExts={['pdf']}
                portfolioExt={'pdf'}
                accept_file_type={".pdf"}
                title={"Branch Guideline"}
                selectedDocuments={selectedBranchGuidelineFile}
                input_ref_id="g-branch-doc-1"
                onChange={(value) => {
                  setSelectedBranchGuidelineFile(value)
                  setSelectedBranchDocSrc(value[0].base64)
                  setInput({
                    ...formInputs,
                    branch_guideline_file :value[0].base64
                  })
                }}
                />

          </Row>
         
          <Row className='w-100 m-0 row mb-3'>
            <div className="title-editor-ck">Company Terms and Conditions</div>
            <CKEditor
              editor={ClassicEditor}
              data={formInputs.company_term_and_condition}
              // onReady={ editor => {
              //     // You can store the "editor" and use when it is needed.
              //     console.log( 'Editor is ready to use!', editor );
              // } }
              onChange={(event, editor) => {
                if (isDataFetched) {
                  const data = editor.getData();
                  setInput({
                    ...formInputs,
                    company_term_and_condition: data,
                  })
                }
              }}
            // onBlur={ ( event, editor ) => {
            //     console.log( 'Blur.', editor );
            // } }
            // onFocus={ ( event, editor ) => {
            //     console.log( 'Focus.', editor );
            // } }
            />
          </Row>

          <Row className='w-100 m-0 row mb-3'>
            <div className="title-editor-ck">Property Manager Terms and Conditions</div>
            <CKEditor
              editor={ClassicEditor}
              data={formInputs.client_term_and_condition}
              // onReady={ editor => {
              //     // You can store the "editor" and use when it is needed.
              //     console.log( 'Editor is ready to use!', editor );
              // } }
              onChange={(event, editor) => {
                if (isDataFetched) {
                  const data = editor.getData();
                  setInput({
                    ...formInputs,
                    client_term_and_condition: data,
                  })
                }
              }}
            // onBlur={ ( event, editor ) => {
            //     console.log( 'Blur.', editor );
            // } }
            // onFocus={ ( event, editor ) => {
            //     console.log( 'Focus.', editor );
            // } }
            />
          </Row>

          {/* <Row className='w-100 m-0 row mb-3'>
            <div className="title-editor-ck">Policy</div>
            <CKEditor
              editor={ClassicEditor}
              data={formInputs.policy}
              onChange={(event, editor) => {
                if (isDataFetched) {
                  const data = editor.getData();
                  setInput({
                    ...formInputs,
                    policy: data,
                  })
                }
              }}
            />
          </Row> */}
          <Row className='w-100 m-0 row mb-3'>
            <div className="title-editor-ck">About Us</div>
            <CKEditor
              editor={ClassicEditor}
              data={formInputs.about_us}
              onChange={(event, editor) => {
                if (isDataFetched) {
                  const data = editor.getData();
                  setInput({
                    ...formInputs,
                    about_us: data,
                  })
                }
              }}
            />
          </Row>
          <Row className='w-100 m-0 row mb-3'>
            <div className="title-editor-ck">Property Manager Privacy Policy</div>
            <CKEditor
              editor={ClassicEditor}
              data={formInputs.client_privacy_policy}
              onChange={(event, editor) => {
                if (isDataFetched) {
                  const data = editor.getData();
                  setInput({
                    ...formInputs,
                    client_privacy_policy: data,
                  })
                }
              }}
            />
          </Row>
          {/* <Row className='w-100 m-0 row mb-3'>
            <div className="title-editor-ck">FAQ</div>
            <CKEditor
              editor={ClassicEditor}
              data={formInputs.faq}
              onChange={(event, editor) => {
                if (isDataFetched) {
                  const data = editor.getData();
                  setInput({
                    ...formInputs,
                    faq: data,
                  })
                }
              }}
            />
          </Row> */}

          <div className="col-12 d-flex justify-content-end">
            {isFormClick === false ? (
              <>
                <Button
                  variant="primary"
                  className="btn-15"
                  type="submit"
                >
                  Save
                </Button>
              </>
            ) : (
              <Button variant="primary" className="btn-15">
                <Spinner animation="border" size="sm" /> Loading...
              </Button>
            )}
          </div>

        </Form>
      </Card.Body>
    </Card>
  </>
  )
}
