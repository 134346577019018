import React, { useState } from 'react'
import { Button, Modal, Spinner } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { ApiRoutes, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';

export default function BranchStatusPopup({ getBranchList, branchStatusPopup, setBranchStatusPopup, branchStatusId, setBranchStatusId }) {

    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [statusError, setStatusError] = useState("");


    const handleSubmit = async(e) => {

        if(selectedStatus){
            setIsLoading(true)
            e.preventDefault()
            const res = await APICall(ApiRoutes.BranchAcceptReject, {
                branch_status : selectedStatus,
                branch_id : branchStatusId
            })
            if(res){
                getBranchList("", "", 1);
                closePopUp()
                setIsLoading(false)
            }else{
                setIsLoading(false)
            }

            
        }else{
            setStatusError("Please select branch status.")
        }

        
    }


    const closePopUp = () => {

        setBranchStatusPopup(false)
        setBranchStatusId(null)
        setStatusError("")
    }

    return (<Modal className="modal-popup" show={branchStatusPopup} onHide={closePopUp}>
        <Modal.Header closeButton>
            <Modal.Title>Select Branch Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <DropDown
                style={{ div: "col-md-12 col-12" }}
                data={Enums.BranchDropDown}
                title={"Branch Status"}
                value={selectedStatus}
                isMulti={false}
                onChange={(value) => setSelectedStatus(value)}
                required
                validationText={statusError}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-primary" onClick={closePopUp}>
                Close
            </Button>
            {isLoading === false ? (
                <>
                    <Button variant="primary"
                        onClick={(e) => { handleSubmit(e) }}
                    >
                        Save
                    </Button>
                </>
            ) : (
                <Button variant="primary">
                    <Spinner animation="border" size="sm" /> Loading...
                </Button>
            )}
        </Modal.Footer>
    </Modal>)
}
