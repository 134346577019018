import { Card, Col, Row, Nav, Tab, Breadcrumb } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { APICall } from "../../../services/axiosService";
import { ApiRoutes, Enums } from "../../../constants";
import { useEffect, useState } from "react";
import NoImage from "../../../assets/Images/Icon/no_image.png"
import { commonService } from "../../../services/common.service";
export default function CompanyProfile() {
  const [company, setCompany] = useState(null);
  const [reviewList, setReviewList] = useState([]);
  const [reviewLoading, setReviewLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchContractCompanyProfile = async (comapny_id) => {
    const res = await APICall(ApiRoutes.contractCompanyProfile, { comapny_id: comapny_id })
    if (res.status == 1) {
      setCompany(res.data)
    }
  }
  const fetchReviewListig = async () => {
    let res = await APICall(ApiRoutes.fetchAssociationReviews, {
      association_id: location.state.comapny_id,
      association_type_term: Enums.UserRole.company
    })
    if (res.status === 1) {
      setReviewLoading(false)
      setReviewList(res.data.list)
    }else{
      setReviewLoading(false)
    }
  }
  useEffect(() => {
    fetchReviewListig()
  }, []);


  useEffect(() => {
    if (company == null && location.state) {
      let comapny_id = location.state.comapny_id
      fetchContractCompanyProfile(comapny_id)
    }

  }, []);


  const CompanyReviews = () => {
    return (<Card>
      <Card.Header>
        <h4 className="card-title">Review & Rating</h4>
      </Card.Header>
      <Card.Body>       
        {!reviewLoading ? reviewList.length ?  reviewList.map((review_item) => {
            return (<div className="companies-section">
              <div className="rounded border p-3 mb-3">
            
                  <div className="row justify-content-between">
                    <div className="col-6">{review_item.review}</div>
                    <div className="col-4 w-fit d-flex flex-column justify-content-between">
                      <div className="star-rating static-rating text-center w-100">
                        {[...Array(5).keys()].map((item, index) => {
                          return (<>
                            <input checked={Math.ceil(parseFloat(review_item.rating)) == 5 - item ? true : false} id={`star-${item}`} type="radio" name="rating" value={item} />
                            <label htmlFor={`star-${item}`} title={`${item} stars`}>
                              <i className="active fa fa-star" aria-hidden="true"></i>
                            </label>
                          </>)
                        })}
                      </div>
                      <div className="text-end h6">{commonService.convertUTCToLocal(review_item.created_at)}</div>
                    </div>
                </div>
              </div>
            </div>)
          })
        :  <div className="w-100 p-3 text-center">No reviews!</div> :<div className="w-100 p-3 text-center">Loading...</div>}
      </Card.Body>
    </Card>)
  }

  return (<div className="contract-company-view">
    <Row>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-block d-sm-flex align-items-center">
            <h2 className="content-header-title float-sm-start float-none mb-2 mb-sm-0">
              Company
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/myquotation">Quotation</Breadcrumb.Item>
              <Breadcrumb.Item active>Company</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Col className="order-1 col-12 order-lg-0 col-lg-5 col-xl-4 contract-company-profile">
        <Card>
          <Card.Body>
            <div className="company-profile-avatar">
              <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
                <span className="avatar-content">
                  <img className="w-100 h-100 obj-fit-cover" src={company?.profile_img ? process.env.REACT_APP_ASSETS_URL + company?.profile_img : NoImage}></img>
                </span>
              </div>
              <div className="d-flex flex-column align-items-center text-center user-info">
                <span className="mb-2 text-primary h4">{company?.company_name}</span>
                <span className="text-capitalize badge bg-light-primary">
                  #Company
                </span>
              </div>
              <div className="star-rating static-rating text-center  w-100">
                {[...Array(5).keys()].map((item, index) => {
                  return (<>
                    <input checked={Math.ceil(parseFloat(company?.rating)) == 5 - item ? true : false} id={`star-${item}`} type="radio" name="rating" value={item} />
                    <label htmlFor={`star-${item}`} title={`${item} stars`}>
                      <i className="active fa fa-star" aria-hidden="true"></i>
                    </label>
                  </>)
                })}
              </div>
            </div>
            <div className="company-figures d-flex justify-content-between my-3">
              <div className="d-flex align-items-start me-2">
                <span className="rounded p-75 badge bg-light-primary">
                  <i className=" fa-solid fa-code-branch"></i>
                </span>
                <div className="complete-texts">
                  <h4 className="mb-0">{company?.total_branches_count}</h4>
                  <small className="h4 text-dark">Total Branch Manager</small>
                </div>
              </div>
            </div>
            <div className="company-details mt-1">
              <div className="left-field mb-1">{company?.locations[0] ? <><i className="fa-solid fa-location-dot text-primary me-2"></i> </> : ""}
                {company?.locations[0] ?
                  company.locations[0].address1 + ', ' +
                  company.locations[0].city + ', ' +
                  company.locations[0].state + ', ' +
                  company.locations[0].country + ', ' +
                  company.locations[0].postal_code
                  : ""}
              </div>

              {company ? company.website_url != '' && company.website_url != null ?

                <div className="left-field mb-1"><i className="fa-solid fa-link text-primary me-2"></i> <a className="text-primary cursor-pointer" href={company.website_url} target="_blank"> {company.website_url} </a>  </div>
                : "" : ""}

            </div>
            {company?.company_portfolio_url &&
              <div className="left-field mb-1"><i className="fa-solid fa-file text-primary me-2"></i> <a className="text-primary cursor-pointer" href={process.env.REACT_APP_ASSETS_URL + company.company_portfolio_url} target="_blank"> View company profile </a>  </div>
            }
          </Card.Body>
        </Card>
      </Col>
      <Col className="order-0 col-12 order-lg-1 col-lg-7 col-xl-8">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="mb-3">
            <Nav.Item className="cursor-pointer">
              <Nav.Link eventKey="first">
                <i className="fa fa-user" aria-hidden="true"></i>
                <span className="pills-tab-text">Profile</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="cursor-pointer">
              <Nav.Link eventKey="second">
                <i className=" fa-solid fa-code-branch"></i>
                <span className="pills-tab-text">Branch Manager</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="cursor-pointer">
              <Nav.Link eventKey="third">
                <i className=" fa-solid fa-star-sharp-half-stroke"></i>
                <span className="pills-tab-text">Review & Rating</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Card>
                <Card.Header className="h4 company-details-head fw-bolder border-bottom pb-2 mb-3">Basic Information</Card.Header>
                <Card.Body>
                  <div className="company-details info-container">
                    <div className="info-field mb-2">
                      <span className="left-field">Company Name </span>
                      <span className="right-field">{company?.company_name}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Register Number </span>
                      <span className="right-field">{company?.register_no}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Company Email </span>
                      <span className="right-field">{company?.email}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Company Phone No.</span>
                      <span className="right-field">{company?.phone}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Contact Person </span>
                      <span className="right-field">{company?.contact_person_name}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Contact Person Email </span>
                      <span className="right-field">{company?.contact_person_email}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Contact Person Phone No.</span>
                      <span className="right-field">{company?.contact_person_phone}</span>
                    </div>
                    <div className="info-field mb-2">
                      <span className="left-field">Status </span>
                      <span className={`right-field text-capitalize badge bg-light-${company?.is_active == '1' ? 'primary' : "danger"}`}>
                        {company?.is_active == '1' ? 'Active' : "Inactive"}
                      </span>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <Card>
                <Card.Header>
                  <h4 className="card-title">Branch Manager</h4>
                </Card.Header>
                <Card.Body>
                  {company?.total_branches.length ?
                    company?.total_branches.map((branchEle) => {
                      return (<div className="companies-section">
                        <div className="rounded border p-3 mb-3">
                          <div className="d-md-flex justify-content-between">
                            <div className="d-sm-flex align-items-center">
                              <div className="text-center mb-3 mb-sm-0 ">
                                <img

                                  src={branchEle.branch_profile_img == null ? NoImage : process.env.REACT_APP_ASSETS_URL + branchEle.branch_profile_img}
                                  className="rounded obj-fit-cover"
                                  alt="Branch Manager"
                                  width="100"
                                  height="100"
                                />
                              </div>
                              <div className="card-information align-items-center ms-sm-4">
                                <div className="d-flex">
                                  <dt>Branch Manager:</dt>
                                  <dd onClick={() => { navigate('/contract/branch/profile', { state: { branch_id: branchEle.branch_id } }) }} className="ms-sm-2 ms-0 p-0 text-decoration-underline nav-link cursor-pointer">{branchEle.branch_name}</dd>
                                </div>
                                <div className="d-sm-flex">
                                  <dt>Contact:</dt>
                                  <dd className="ms-sm-2 ms-0">{branchEle.branch_contact_person_phone}</dd>
                                </div>
                                <div className="d-sm-flex">
                                  <dt>Email:</dt>
                                  <dd className="ms-sm-2 ms-0">{branchEle.branch_email}</dd>
                                </div>
                                <div className="d-sm-flex">
                                  <dt>Location:</dt>
                                  <dd className="ms-sm-2 ms-0">{branchEle.branch_location.length ?
                                    branchEle.branch_location[0].address1 + ', ' +
                                    branchEle.branch_location[0].city + ', ' +
                                    branchEle.branch_location[0].state + ', ' +
                                    branchEle.branch_location[0].country + ', ' +
                                    branchEle.branch_location[0].postal_code
                                    : ""}</dd>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)
                    })
                    :
                    <div className="text-center h4 text-dark">No Branch Manager!</div>
                  }
                  {/* */}
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              <CompanyReviews />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Col>
    </Row>
  </div>)
}
