import { Link } from "react-router-dom";
import logo from "../../assets/Images/logo/logo.svg";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import InputText from "../../components/form/inputText";
import { Spinner } from "react-bootstrap";
import { APICall } from "../../services/axiosService";
import { ApiRoutes } from "../../constants/apiroutes";

export default function ForgotPassword() {
    const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );    
    const [username, setUsername] = useState("");
    const [usernameEr, setusernameEr] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) =>{
        e.preventDefault()
        if(username != ""){
            if(pattern.test(username)){
                setusernameEr("")
                setIsLoading(true);
                let res = await APICall(ApiRoutes.sendResetPassLink ,{username :username});
                if(res.status === 1){
                    setUsername("")
                    setIsLoading(false); 
                }else if(res.status === 0){
                    setIsLoading(false); 
                }else{
                    setIsLoading(false);
                }
            }else{
                setusernameEr("Enter valid email address.")    
            }
        }else{
            setusernameEr("This feild is required.")
        }
    }
    return (
        <div className="blank-page">
            <div className="content-body">
                <div className="auth-wrapper auth-basic px-4">
                    <div className="auth-inner my-4">
                        <div className="mb-0 card">
                            <div className="card-body p-4">
                                <Link to="/" className="login-logo">
                                    <img src={logo} alt="logo" width="160" height="48" />
                                </Link>
                                <h4 className="mb-4 card-title text-center text-dark">
                                    Forgot Password
                                </h4>
                                <Form onSubmit={handleSubmit}>
                                    <InputText
                                        type={"text"}
                                        size={12}
                                        title={"Email Address"}
                                        value={username}
                                        onChange={(value) => setUsername(value)}
                                        validationText={usernameEr}
                                    />
                                    {isLoading ? (
                                        <Button variant="primary w-100">
                                            <Spinner animation="border" size="sm" /> Loading...
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="primary w-100"
                                            type="submit"
                                        // onClick={loginClick}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                    {/* </Link> */}
                                </Form>
                                <p className="create-login-account-bottom text-center mt-3">
                                <span>Already have account?</span>
                                    <Link to="/portal/login" className="text-primary h6">
                                        <span>Login here</span>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
