import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DatePickerInput({
  title,
  value,
  onChange,
  error,
  style,
  selectedDate,
  disabled,
}) {
  return (
    <div className={`${style?.div ? style.div : "col-md-6 col-12"}`}>
      <div className={`form-group ${style?.formGroup ? style.formGroup : ""}`}>
        <label className="form-label">{title}</label>
        <DatePicker
          className={`form-control datepicker ${error && "invalid"}`}
          selected={selectedDate}
          dateFormat="MM-dd-yyyy"
          placeholderText="MM-DD-YYYY"
          onChange={(date) => onChange(date)}
          disabled={disabled}
        />
        {error && <span className="error">{error}</span>}
      </div>
    </div>
  );
}
