import React from 'react'
import { useNavigate } from "react-router-dom";

export default function ClientDashboard({dashboardCounts}) {

  const navigate = useNavigate();

  return (<div className="row">
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={()=>{navigate('/user')}} className="card-body cursor-pointer dashboard-card-body d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header"> {dashboardCounts?.client_users} </h2>
          <span className="h6 text-dark">Total Users</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle" style={{ width: '45px', height: '45px' }}>
          <span className="b-avatar-custom">
            <i className=" fa-solid fa-user-group"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={()=>{navigate('/property')}}  className="card-body cursor-pointer dashboard-card-body d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header"> {dashboardCounts?.properties_count} </h2>
          <span className="h6 text-dark">Total Properties</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle" style={{ width: '45px', height: '45px' }}>
          <span className="b-avatar-custom">
            <i className=" fa-solid fa-users"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div  className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={()=>{navigate('/myquotation')}} className="card-body dashboard-card-body cursor-pointer d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header">  {dashboardCounts?.quotation_count}</h2>
          <span className="h6 text-dark">Total Quotations</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle">
          <span className="b-avatar-custom">
            <i className="fa-regular fa-id-card"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
  <div className="col-sm-6 col-lg-4">
    <div className="card dashboard-card">
      <div onClick={()=>{navigate('/client_contracts')}}  className="card-body dashboard-card-body cursor-pointer d-flex justify-content-between align-items-center">
        <div className="truncate">
          <h2 className="mb-25 font-weight-bolder dashboard-card-header">  {dashboardCounts?.contract_count}</h2>
          <span className="h6 text-dark">Total Contracts</span>
        </div>
        <span className="dashboard-b-avatart b-avatar badge-light-primary rounded-circle">
          <span className="b-avatar-custom">
            <i className="fa-regular fa-th-large"></i>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>)
}
