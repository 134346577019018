import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import DatePickerInput from "../../components/common/DatePickerInput";
import DropDown from "../../components/common/DropDown";
import ImgInput from "../../components/common/ImgInput";
import TextInput from "../../components/form/inputText";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { updateLoading } from "../../store/actions";
import { Breadcrumb } from "react-bootstrap";
import InputSearchLocation from "../../components/form/InputSearchLocation";
import { commonService } from "../../services/common.service";

export default function ClientProfile() {
  const [isClientFormClick, setIsClientFormClick] = useState(false);
  const dispatch = useDispatch();
  const [formInputs, setInput] = useState({
    client_name: "",
    first_name: "",
    last_name: "",
    email: "",
    position: "",
    phone: "",
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        longitude:"",
        latitude:""
      },
    ]
  });
  const [formInputsErrors, setInputErrors] = useState({
    client_name: "",
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
    position: "",
    phone: "",
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
    ],
  })
  const validation_msg = {
    client_name: "This field is required",
    first_name: "This field is required",
    last_name: "This field is required",
    email: "This field is required",
    phone: "This field is required",
    position: "This field is required",
    address1: "This field is required",
    city: "This field is required",
    state: "This field is required",
    postal_code: "This field is required",
    country: "This field is required",
    invalid_email :"This email is invalid"
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );
  const getRequiredFields = (bank_details, locations) => {
    setInputErrors({
      ...formInputsErrors,
      locations  :
      locations != null ? locations.length > 0
      ? locations.map(() => ({
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      }))
      : [
        {
          address1: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
        },
      ]
      : [
          {
            address1: "",
            city: "",
            state: "",
            postal_code: "",
            country: "",
          },
        ],
    });
  };

  const getDetails = async (submitedData = null) => {
    const res = await APICall(ApiRoutes.ProfileDetails);
    if (res) {
      if(submitedData){
        submitedData.data.profile['association_details'] = res.data ;
        commonService.setItem("user", JSON.stringify(submitedData.data.profile));
      }
      
      setInput({
        ...res.data,
        profile_img: res.data.profile_img
          ? process.env.REACT_APP_ASSETS_URL + res.data.profile_img
          : null,
          locations:
          res.data.locations ?
          res.data.locations.length > 0
            ? res.data.locations
            : [
              {
                address1: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
              },
            ]:[
               {
                address1: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
              },
            ],
      });
      getRequiredFields(res.data.bank_details, res.data.locations);
    }
  };
  function validateClientForm() {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors);
    const requiredArr = ["locations"];
    errorKeys.forEach((item) => {
      if (requiredArr.includes(item)) {
        let newArr = [];
        formInputsErrors[item].forEach((childLoop, childIndex) => {
          let newObj = {};
          const childErrorsArr = Object.keys(childLoop);
          childErrorsArr.forEach((grandChildLoop) => {
            if (!formInputs[item][childIndex][grandChildLoop]) {
              newObj[grandChildLoop] = validation_msg[grandChildLoop];
              isValid = false;
            } else {
              newObj[grandChildLoop] = "";
            }
          });
          newArr.push(newObj);
        });
        errors[item] = newArr;
      } else {
        if (
          formInputs[item] === null ||
          formInputs[item]?.toString()?.trim() === ""
        ) {
          errors[item] = validation_msg[item];
          isValid = false;
        } else {
          if (item === "email" && !pattern.test(formInputs.email)) {
            errors[item] = validation_msg.invalid_email;
            isValid = false;
          } else {
            errors[item] = "";
          }
        }
      }
    });
    setInputErrors(errors);
    return isValid;
  }
  async function submitClientForm(event) {
    event.preventDefault();
    if (validateClientForm()) {
      setIsClientFormClick(true);
      dispatch(updateLoading(true));
      const requestMetadata = await APICall(ApiRoutes.UpdateProfileDetails, {
        ...formInputs,
        profile_img: formInputs.profile_img?.includes(
          process.env.REACT_APP_ASSETS_URL
        )
          ? null
          : formInputs.profile_img,
      });
      getDetails(requestMetadata);
     
      setIsClientFormClick(false);
      dispatch(updateLoading(false));
    }
  }
  useEffect(() => {
    getDetails();
  }, []);

  const handleLocation = (addressInfoArray)=>{
      setInput({
          ...formInputs,
          locations :[{
            ...formInputs.locations[0],
            address1: addressInfoArray.street_address,
            city: addressInfoArray.city,
            state: addressInfoArray.state,
            postal_code: addressInfoArray.postal_code,
            country: addressInfoArray.country,
            longitude: addressInfoArray.lng,
            latitude: addressInfoArray.lat,
          }]
         })
  }

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">Edit Profile</h2>
            <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Proifle</Breadcrumb.Item>
              </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="card pt-4">
        <div className="card-body">
          <form className="form" onSubmit={submitClientForm} method="POST" action="#">
            <div className="col-12 row">
              <blockquote className="blockquote border-left-3 border-left-primary float-left pl-1">
                <h4 className="mb-0 shop-title float-left">Basic Info</h4>
              </blockquote>
            </div>
            <div className="form-body">
              <div className="row">
                <TextInput
                  type={"text"}
                  title={"Name"}
                  value={formInputs.client_name}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      client_name: value,
                    })
                  }
                  validationText={formInputsErrors.client_name}
                />
                <TextInput
                  type={"text"}
                  title={"First Name"}
                  value={formInputs.first_name}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      first_name: value,
                    })
                  }
                  validationText={formInputsErrors.first_name}
                />
                <TextInput
                  type={"text"}
                  title={"Last Name"}
                  value={formInputs.last_name}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      last_name: value,
                    })
                  }
                  validationText={formInputsErrors.last_name}
                />
                <TextInput
                  type={"email"}
                  title={"Email"}
                  value={formInputs.email}
                  validationText={formInputsErrors.email}
                  disabled={true}
                />
                <TextInput
                  type={"number"}
                  title={"Phone"}
                  value={formInputs.phone}
                  validationText={formInputsErrors.phone}
                  disabled={true}
                />
                <TextInput
                  type={"text"}
                  title={"Position"}
                  value={formInputs.position}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      position: value,
                    })
                  }
                  validationText={formInputsErrors.position}
                />

                <div className="col-12 row">
                  <blockquote className="blockquote border-left-3 border-left-primary float-left pl-1 mt-3">
                    <h4 className="mb-0 shop-title float-left">
                      Location Details
                    </h4>
                  </blockquote>
                </div>
                  <InputSearchLocation size={"12"} placeholder="Search location" onPlaceChange={handleLocation}/>
                {formInputs?.locations?.map((mapItem, mapIndex) => (
                  <>
                    <TextInput
                      type={"text"}
                      title={"Address"}
                      value={mapItem.address1}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  address1: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={
                        formInputsErrors?.locations[mapIndex]?.address1
                      }
                    />
                    <TextInput
                      type={"text"}
                      title={"City"}
                      value={mapItem.city}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  city: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={formInputsErrors?.locations[mapIndex]?.city}
                    />
                    <TextInput
                      type={"text"}
                      title={"State"}
                      value={mapItem.state}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  state: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={formInputsErrors?.locations[mapIndex]?.state}
                    />
                    <TextInput
                      type={"text"}
                      title={"Postal code"}
                      value={mapItem.postal_code}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  postal_code: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={
                        formInputsErrors?.locations[mapIndex]?.postal_code
                      }
                    />
                    <TextInput
                      type={"text"}
                      title={"Country"}
                      value={mapItem.country}
                      onChange={(value) =>
                        setInput({
                          ...formInputs,
                          locations: formInputs.locations.map(
                            (innerItem, innerIndex) => {
                              if (innerIndex === mapIndex) {
                                return {
                                  ...innerItem,
                                  country: value,
                                };
                              }
                            }
                          ),
                        })
                      }
                      validationText={
                        formInputsErrors?.locations[mapIndex]?.country
                      }
                    />
                  </>
                ))}

                <div className="col-12 row">
                  <ImgInput
                    title={"Profile Picture"}
                    img_src={formInputs.profile_img}
                    input_ref_id="profile_img"
                    onChange={(value) => {
                      setInput({
                        ...formInputs,
                        profile_img: value,
                      });
                    }}
                  />
                </div>
                <div className="col-12 d-flex justify-content-end">
                  {!isClientFormClick ? (
                    <>
                      <button
                        type="submit"
                        className="btn btn-primary mr-1"
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <button className="btn btn-primary mr-1">
                      <Spinner animation="border" size="sm" /> Loading...
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
