import React from "react";
import { ToastContainer, toast } from "react-toastify";

function Toaster({ type, message }) {
  const Success = () =>
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  const Info = () =>
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const Error = () =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const Warning = () =>
    toast.warn(message, {
      position: "top-right",
      autoClose: 5000,
      theme: "colored",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  switch (type) {
    case "Success":
      Success();
      break;

    case "Info":
      Info();
      break;

    case "Error":
      Error();
      break;

    case "Warning":
      Warning();
      break;

    default:
      break;
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Toaster;
