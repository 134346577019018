import React, { useLayoutEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiRoutes, Enums } from "../../constants";
import { APICall } from "../../services/axiosService";
import {
  Breadcrumb,
  Card,
  Col,
  Button,
  Spinner,
  Row,
  Tab,
  Nav,
} from "react-bootstrap";
import { contractStatusEnum, leaveStatusEnum } from "../../constants";
import NoImage from "../../assets/Images/Icon/no_image.png";
import { commonService } from "../../services/common.service";
import ReplacedGuardsList from "./ReplacedGuardsList";
import NewLoader from "../../components/loader/NewLoader";

export default function View() {
  const [company, setCompany] = useState(null);

  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [leaveDetails, setleaveDetails] = useState(null);
  const [loadingVariant, setLoadingVariant] = useState(null);
  const fetchLeaveDetails = async (leave_id) => {
    setLoading(true);
    let res = await APICall(ApiRoutes.leaveDetails, { leave_id: leave_id });
    if (res.status == 1) {
      setLoadingVariant(null);
      setLoading(false);
      setleaveDetails(res.data);
    } else {
      setLoading(false);
    }
  };

  const getLocation = () => {
    if (leaveDetails.contract_details) {
      return (
        leaveDetails.contract_details.address1 +
        ", " +
        (leaveDetails.contract_details.address2
          ? leaveDetails.contract_details.address2 + ", "
          : "") +
        leaveDetails.contract_details.city +
        ", " +
        leaveDetails.contract_details.state +
        ", " +
        leaveDetails.contract_details.country +
        " - " +
        leaveDetails.contract_details.postal_code
      );
    } else {
      return "";
    }
  };
  const getGuardLocation = () => {
    let str =
      (leaveDetails.applicant_details.address1
        ? leaveDetails.applicant_details.address1 + ", "
        : "") +
      (leaveDetails.applicant_details.address2
        ? leaveDetails.applicant_details.address2 + ", "
        : "") +
      (leaveDetails.applicant_details.city
        ? leaveDetails.applicant_details.city + ", "
        : "") +
      (leaveDetails.applicant_details.state
        ? leaveDetails.applicant_details.state + ", "
        : "") +
      (leaveDetails.applicant_details.country
        ? leaveDetails.applicant_details.country + ", "
        : "") +
      (leaveDetails.applicant_details.postal_code
        ? leaveDetails.applicant_details.postal_code + ", "
        : "");

    str = str.trim();
    str = str.slice(0, -1);
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return "-";
    }
  };

  useLayoutEffect(() => {
    if (params.leave_id) {
      fetchLeaveDetails(params.leave_id);
    }
  }, []);

  const leaveAction = async (action_code) => {
    if (action_code == 1) {
      setLoadingVariant(1);
    } else if (action_code == 0) {
      setLoadingVariant(0);
    }
    setLoading(true);
    let res = await APICall(ApiRoutes.leaveAction, {
      leave_id: params.leave_id,
      action_code: action_code,
    });
    if (res.status == 1) {
      fetchLeaveDetails(params.leave_id);
    }
  };
  

  if (leaveDetails) {
    return (
      <>
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Leave Application
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/leaves">Setup</Breadcrumb.Item>
                <Breadcrumb.Item href="/leaves">Leaves</Breadcrumb.Item>
                <Breadcrumb.Item active>Leave Application</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <Row className="contract-company-view">
          <div className="row">
            <div className="col-8">
              <Card>
                <Card.Header>
                  <h4 className="card-title">Leave Details</h4>
                  {leaveDetails.leave_status == "pending" ? (
                    <div>
                      {!loading ? (
                        <>
                          <Button
                            variant="success me-3"
                            onClick={() => {
                              leaveAction(1);
                            }}
                          >
                            {" "}
                            Approve{" "}
                          </Button>
                          <Button
                            variant="danger me-3"
                            onClick={() => {
                              leaveAction(0);
                            }}
                          >
                            {" "}
                            Decline{" "}
                          </Button>
                        </>
                      ) : loadingVariant != null ? (
                        <Button
                          variant={
                            loadingVariant == 1
                              ? "success"
                              : loadingVariant == 0 && "danger"
                          }
                        >
                          <Spinner animation="border" size="sm" /> Loading...
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </Card.Header>
                <Card.Body>
                  <dl className="mb-0 row">
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Subject :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {leaveDetails.subject}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Leave Type :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {leaveDetails.leave_type_title}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Leave Start Date :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(leaveDetails.start_date)}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Leave End Date :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(leaveDetails.end_date)}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Applied On :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(
                        leaveDetails.created_at,
                        "DD-MM-yyyy, hh:mm A"
                      )}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Status :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {leaveStatusEnum[leaveDetails.leave_status]}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Reason :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {leaveDetails.reason}
                    </dd>
                  </dl>
                </Card.Body>
              </Card>
              {leaveDetails.contract_details && (
                <Card>
                  <Card.Header>
                    <h4 className="card-title">Contract Details</h4>
                    <div>
                      <Button
                        size="sm"
                        variant="primary me-3"
                        onClick={() => {
                          navigate("/contracts/view", {
                            state: { id: leaveDetails.contract_id },
                          });
                        }}
                      >
                        {" "}
                        View Contract{" "}
                      </Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <dl className="mb-0 row">
                      <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                        Property Name :
                      </dt>
                      <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                        {leaveDetails.contract_details?.property_name}
                      </dd>
                      <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                        Property Manager :
                      </dt>
                      <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                        {leaveDetails.contract_details?.property_manager}
                      </dd>
                      <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                        Service Hours :
                      </dt>
                      <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                        {leaveDetails.contract_details?.contract_service_hours}
                      </dd>
                      <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                        Start Date :
                      </dt>
                      <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                        {commonService.convertUTCToLocal(
                          leaveDetails.contract_details?.start_date
                        )}
                      </dd>
                      <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                        Expiry Date :
                      </dt>
                      <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                        {commonService.convertUTCToLocal(
                          leaveDetails.contract_details?.end_date
                        )}
                      </dd>
                      <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                        Status :
                      </dt>
                      <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                        {leaveDetails.contract_details?.contract_status &&
                          contractStatusEnum[
                            leaveDetails.contract_details?.contract_status
                          ]}
                      </dd>
                      <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                        Property Location :
                      </dt>
                      <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                        {getLocation()}
                      </dd>
                    </dl>
                  </Card.Body>
                </Card>
              )}
            </div>
            <div className="col-4">
              <Card>
                <Card.Body>
                  <div className="company-profile-avatar">
                    <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
                      <span className="w-100 h-100 avatar-content">
                        <img
                          className="w-100 h-100 obj-fit-cover"
                          src={
                            leaveDetails.applicant_details.profile_img
                              ? process.env.REACT_APP_ASSETS_URL +
                                leaveDetails.applicant_details.profile_img
                              : NoImage
                          }
                        ></img>
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center text-center user-info">
                      <span className="mb-2 text-primary h4">
                        {leaveDetails.applicant_details.applicant_name}
                      </span>
                      <span className="text-capitalize badge bg-light-primary">
                        {leaveDetails.association_type_term ===
                          Enums.UserRole.guard && "#Guard"}
                        {leaveDetails.association_type_term ===
                          Enums.UserRole.incharge && "#Incharge"}
                      </span>
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-envelope text-primary me-2"></i>
                      {leaveDetails.applicant_details.applicant_email}
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-phone text-primary me-2"></i>
                      {leaveDetails.applicant_details.applicant_phone}
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-hashtag text-primary me-2"></i>
                      {leaveDetails.applicant_details.id_number}
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-map-marker text-primary me-2"></i>
                      {getGuardLocation()}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            
            {/* {leaveDetails.contract_id && 
            leaveDetails.contract_id !== "" &&
            leaveDetails.contract_id !== "undefined" ? ( */}
              <div className="col-12 replace-guard">
                <Card>
                  <Card.Body className="mt-4">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Nav variant="pills" className="mb-3">
                        <Nav.Item className=" cursor-pointer">
                          <Nav.Link eventKey="first">
                            <i className="fa-regular fa-th-large"></i>
                            <span className="pills-tab-text">
                              Replaced Guards
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <ReplacedGuardsList
                            leave_id={leaveDetails.leave_id}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card.Body>
                </Card>
              </div>
            {/* // ) : (
            //   ""
            // )} */}
          </div>
        </Row>
      </>
    );
  } else {
    return <NewLoader />;
  }
}
