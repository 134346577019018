import React, { useLayoutEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ApiRoutes, Enums } from "../../constants";
import { APICall } from "../../services/axiosService";
import { Breadcrumb, Card, Col, Button, Spinner, Row } from "react-bootstrap";
import { contractStatusEnum, ComplaintEnum } from "../../constants";
import NoImage from "../../assets/Images/Icon/no_image.png";
import { commonService } from "../../services/common.service";
import DeleteGuardPopup from "../Contracts/DeleteGuard/DeleteGuardPopup";
import NewLoader from "../../components/loader/NewLoader";

export default function View() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [ShowDeleteGuardPopup, setShowDeleteGuardPopup] = useState(false);
  const params = useParams();
  const [complainDetails, setComplainDetails] = useState(null);
  const [ReqId, setReqId] = useState(params.ticket_request_id);
  // const [actionLoading, setActionLoading] = useState(false);
  const [loadingVariant, setLoadingVariant] = useState(null);

  const fetchComplainDetails = async (ticket_request_id) => {
    setLoading(true);
    let res = await APICall(ApiRoutes.complainDetails, {
      ticket_request_id: ReqId,
    });
    if (res.status == 1) {
      setLoading(false);
      setComplainDetails(res.data);
    }
  };
  useLayoutEffect(() => {
    if (params.ticket_request_id) {
      fetchComplainDetails(params.ticket_request_id);
    }
  }, []);

  const getLocation = () => {
    return (
      complainDetails.contract_details.address1 +
      ", " +
      (complainDetails.contract_details.address2
        ? complainDetails.contract_details.address2 + ", "
        : "") +
      complainDetails.contract_details.city +
      ", " +
      complainDetails.contract_details.state +
      ", " +
      complainDetails.contract_details.country +
      " - " +
      complainDetails.contract_details.postal_code
    );
  };

  const complainAction = async (action) => {
    setLoadingVariant(action);
    let res = await APICall(ApiRoutes.complainAction, {
      ticket_request_id: params.ticket_request_id,
      action: action,
    });
    if (res.status == 1) {
      fetchComplainDetails(params.ticket_request_id);

      setLoadingVariant(null);
    }
  };
  const getClientLocation = () => {
    let str =
      (complainDetails.client_details.address1
        ? complainDetails.client_details.address1 + ", "
        : "") +
      (complainDetails.client_details.address2
        ? complainDetails.client_details.address2 + ", "
        : "") +
      (complainDetails.client_details.city
        ? complainDetails.client_details.city + ", "
        : "") +
      (complainDetails.client_details.state
        ? complainDetails.client_details.state + ", "
        : "") +
      (complainDetails.client_details.country
        ? complainDetails.client_details.country + ", "
        : "") +
      (complainDetails.client_details.postal_code
        ? complainDetails.client_details.postal_code + ", "
        : "");
    str = str.trim();
    str = str.slice(0, -1);
    if (str.length && str && str !== "null") {
      return str;
    } else {
      return "-";
    }
  };

  if (!loading) {
    return (
      <>
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Complaint
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/complaint">Setup</Breadcrumb.Item>
                <Breadcrumb.Item href="/complains">
                  Request / Complaint
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Complaint Details</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <Row className="contract-company-view">
          <div className="row">
            <div className="col-8">
              <Card>
                <Card.Header>
                  <h4 className="card-title">Complaint Details</h4>
                  {complainDetails.ticket_status == "pending" &&
                    !loadingVariant && (
                      <div>
                        <Button
                          variant="success me-3"
                          onClick={() => {
                            complainAction("accept");
                          }}
                        >
                          {" "}
                          Accept{" "}
                        </Button>
                        <Button
                          variant="danger me-3"
                          //  onClick={() => { complainAction('reject') }}
                          onClick={() => setShowDeleteGuardPopup(true)}
                        >
                          {" "}
                          Decline{" "}
                        </Button>
                      </div>
                    )}
                  {complainDetails.ticket_status == "accepted" &&
                    !loadingVariant && (
                      <div>
                        <Button
                          variant="success me-3"
                          onClick={() => {
                            complainAction("complete");
                          }}
                        >
                          {" "}
                          Complete{" "}
                        </Button>
                      </div>
                    )}
                  {(loadingVariant == "accept" ||
                    loadingVariant == "complete") && (
                    <div>
                      <Button variant="success me-3">
                        <Spinner animation="border" size="sm" /> Loading...
                      </Button>
                    </div>
                  )}
                  {loadingVariant == "reject" && (
                    <div>
                      <Button variant="danger me-3">
                        <Spinner animation="border" size="sm" /> Loading...
                      </Button>
                    </div>
                  )}
                </Card.Header>
                <Card.Body>
                  <dl className="mb-0 row">
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Subject :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {complainDetails.subject}
                    </dd>
                    {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Leave Type :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {leaveDetails.leave_type_title}
                  </dd> */}
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Complaint Date:
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(
                        complainDetails.created_at,
                        "MM-DD-YYYY, hh:mm A"
                      )}
                    </dd>
                    {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Leave End Date :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {commonService.convertUTCToLocal(leaveDetails.end_date)}
                  </dd>
                  <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                    Applied On :
                  </dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                    {commonService.convertUTCToLocal(leaveDetails.created_at)}
                  </dd> */}
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Status :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {ComplaintEnum[complainDetails.ticket_status]}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Complaint Description :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {complainDetails.description}
                    </dd>
                  </dl>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <h4 className="card-title">Contract Details</h4>
                  <div>
                    <Button
                      size="sm"
                      variant="primary me-3"
                      onClick={() => {
                        navigate("/contracts/view", {
                          state: { id: complainDetails.contract_id },
                        });
                      }}
                    >
                      {" "}
                      View Contract{" "}
                    </Button>
                  </div>
                </Card.Header>
                <Card.Body>
                  <dl className="mb-0 row">
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Property Name :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {complainDetails.contract_details.property_name}
                    </dd>
                    {/* <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">Property Manager :</dt>
                  <dd className="mb-2 col-sm-12 col-md-8 col-lg-6"> 
                    {complainDetails.contract_details.property_manager}
                  </dd> */}
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Service Hours :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {complainDetails.contract_details.contract_service_hours}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Start Date :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(
                        complainDetails.contract_details.start_date
                      )}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Expiry Date :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {commonService.convertUTCToLocal(
                        complainDetails.contract_details.end_date
                      )}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Status :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {complainDetails.contract_details.contract_status &&
                        contractStatusEnum[
                          complainDetails.contract_details.contract_status
                        ]}
                    </dd>
                    <dt className="mb-2 col-md-4 col-sm-8 col-lg-6">
                      Property Location :
                    </dt>
                    <dd className="mb-2 col-sm-12 col-md-8 col-lg-6">
                      {getLocation()}
                    </dd>
                  </dl>
                </Card.Body>
              </Card>
            </div>
            <div className="col-4">
              <Card>
                <Card.Header>
                  <h4 className="card-title">Property Manager Details</h4>
                </Card.Header>
                <Card.Body>
                  <div className="company-profile-avatar">
                    {/* <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
                    <span className="w-100 h-100 avatar-content">
                      <img className="w-100 h-100 obj-fit-cover" src={complainDetails.client_details.profile_img ? process.env.REACT_APP_ASSETS_URL + complainDetails.client_details.profile_img : NoImage}></img>
                    </span>
                  </div> */}
                    {/* <div className="d-flex flex-column align-items-center text-center user-info"> */}
                    {/* <span onClick={() => { navigate(`/clients/view/${complainDetails.client_details.client_id}`) }} className="cursor-pointer mb-2 text-primary h4">{complainDetails.client_details.client_name}</span> */}
                    {/* <span className="text-capitalize badge bg-light-primary">
                      #Property Manager
                    </span> */}
                    {/* </div> */}
                  </div>

                  <div className="company-details mt-1">
                    <div className="left-field nav-link p-0">
                      <i className="fa-solid fa-user text-primary me-2"></i>
                      <span
                        onClick={() => {
                          navigate(
                            `/clients/view/${complainDetails.client_details.client_id}`
                          );
                        }}
                        className="cursor-pointer"
                      >
                        {complainDetails.client_details.client_name}
                      </span>
                    </div>
                  </div>

                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-envelope text-primary me-2"></i>
                      {complainDetails.client_details.email}
                    </div>
                  </div>
                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-phone text-primary me-2"></i>
                      {complainDetails.client_details.phone}
                    </div>
                  </div>

                  <div className="company-details mt-1">
                    <div className="left-field">
                      <i className="fa-solid fa-map-marker text-primary me-2"></i>
                      {getClientLocation()}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </Row>
        <DeleteGuardPopup
          getDetails={fetchComplainDetails}
          param={ReqId}
          getparam={setReqId}
          deleteGuardDtls={complainDetails}
          // setDeleteGuardDtls={setDeleteGuardDtls}
          showDeleteGuardPopup={ShowDeleteGuardPopup}
          type="complain"
          setShowDeleteGuardPopup={setShowDeleteGuardPopup}
        />
      </>
    );
  } else {
    return (
      <div className="text-center">
        <NewLoader />
      </div>
    );
  }
}
