import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import DatePickerInput from "../../../components/common/DatePickerInput";

import DropDown from "../../../components/common/DropDown";
import InputText from "../../../components/form/inputText";
import { Enums } from "../../../constants";

export default function CreateWizardOne({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  durationList,
  serviceHoursList,
  timezoneList,
  branchData,
  setFetchGuardAssociation,
  fetchGuardAssociation,
  iniFetchGuardAssociation,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const getLocation = () => {
    if (formInputs?.property_location) {
      const locationString = formInputs.property_location;
      return (
        locationString.address1 +
        ", " +
        (locationString?.address2 ? locationString.address2 + ", " : "") +
        locationString.city +
        ", " +
        locationString.state +
        ", " +
        locationString.country +
        " - " +
        locationString.postal_code
      );
    } else {
      return "";
    }
  };
  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Row>
            <InputText
              type={"text"}
              title={"Property Manager"}
              value={formInputs.client_name}
              disabled
              // onChange={(value) =>
              //   setInput({
              //     ...formInputs,
              //     no_of_checkpoints: value,
              //   })
              // }
              // required
              // validationText={formInputsErrors.no_of_checkpoints}
            />
            <InputText
              type={"text"}
              title={"Property Name"}
              value={formInputs.property_name}
              disabled
              // onChange={(value) =>
              //   setInput({
              //     ...formInputs,
              //     no_of_checkpoints: value,
              //   })
              // }
              // required
              // validationText={formInputsErrors.no_of_checkpoints}
            />
            
          </Row>
          <Row>
          <InputText
              type={"text"}
              title={"Property Type"}
              value={formInputs?.property_info?.property_category_type_title}
              disabled
              // onChange={(value) =>
              //   setInput({
              //     ...formInputs,
              //     no_of_checkpoints: value,
              //   })
              // }
              // required
              // validationText={formInputsErrors.no_of_checkpoints}
            />
            <InputText
              type={"number"}
              title={"Contract Amount"}
              value={formInputs.total_amount}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  total_amount: value,
                })
              }
              required
              validationText={formInputsErrors.total_amount}
            />
            {/* <InputText
              type={"number"}
              title={"Received Amount"}
              value={formInputs.received_amount}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  received_amount: value,
                })
              }
              required
              validationText={formInputsErrors.received_amount}
            /> */}
            
          </Row>
          <Row>
          <InputText
              type={"text"}
              title={"Number of Guards"}
              value={formInputs.no_of_guards}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  no_of_guards: value,
                })
              }
              required
              validationText={formInputsErrors.no_of_guards}
            />
            <InputText
              type={"text"}
              title={"Number of Checkpoints"}
              value={formInputs.no_of_checkpoints}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  no_of_checkpoints: value,
                })
              }
              required
              validationText={formInputsErrors.no_of_checkpoints}
            />
           
          </Row>
          <Row>
          <InputText
              type={"number"}
              title={"Number of Patrolling in a day"}
              value={formInputs.no_of_patrolling_in_day}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  no_of_patrolling_in_day: value,
                })
              }
              required
              validationText={formInputsErrors.no_of_patrolling_in_day}
            />
             <DropDown
              data={timezoneList}
              title={"Timezone"}
              value={formInputs.timezone}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  timezone: value,
                })
              }
              validationText={formInputsErrors.timezone}
              required
            />
              <DropDown
              data={branchData}
              title={"Branch Manager"}
              value={formInputs.branch_id}
              onChange={(value) =>{
                setInput({
                  ...formInputs,
                  branch_id: value,
                })

                if(value){
                  setFetchGuardAssociation({
                    association_id : value,
                    association_type_term : Enums.UserRole.branch_manager
                  })
                }else{
                  setFetchGuardAssociation(iniFetchGuardAssociation)
                }
                
                
              }}
              validationText={formInputsErrors.branch_id}
              required
            />
            <DatePickerInput
              title={"Start Date"}
              selectedDate={formInputs.start_date}
              onChange={(value) => {
                setInput({
                  ...formInputs,
                  start_date: value,
                });
              }}
              validationText={formInputsErrors.start_date}
              required
            />
            
          </Row>
          <Row>
          <DropDown
              data={durationList}
              title={"Duration"}
              value={formInputs.duration_term}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  duration_term: value,
                })
              }
              validationText={formInputsErrors.duration_term}
              required
            />
            <DropDown
              data={serviceHoursList}
              value={formInputs.service_hours_term}
              title={"Service Hours"}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  service_hours_term: value,
                })
              }
              required
              validationText={formInputsErrors.service_hours_term}
            />
          </Row>
          <Row>
            <InputText
              type={"text"}
              title={"Property Location"}
              size={"12"}
              value={getLocation()}
              disabled
              multiLine
              // onChange={(value) =>
              //   setInput({
              //     ...formInputs,
              //     no_of_patrolling_in_day: value,
              //   })
              // }
              // required
              // validationText={formInputsErrors.no_of_patrolling_in_day}
            />
          </Row>
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="disabled btn-15"
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15"
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
