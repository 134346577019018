import React from "react";
import Select from "react-select";

export default function DropDown({
  data,
  validationText,
  title = null,
  onChange,
  style,
  value,
  isMulti,
  placeholder =null
}) {
  const dropDownData = data ? [{ value: "", label: `Select ${title}` }, ...data] : [{ value: "", label: `Select ${title}` }];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? 0 : 0,
      borderColor: state.isFocused ? "#263a96" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "#263a96" : base.borderColor,
      },
    }),
  };

  return (
    <div className={`${style?.div ? style.div : "col-md-6 col-12"} mb-3`}>
      <div className={`form-group ${style?.formGroup ? style.formGroup : ""}`}>
       {title && <label className="form-label">{title}</label>}
        <Select
        placeholder={placeholder  ? placeholder  : "Select..."}
          defaultValue={
            isMulti ? null : { value: "", label: `Select ${title}` }
          }
          value={
            isMulti ? value : dropDownData.find((item) => item.value === value)
          }
          options={isMulti ? data : dropDownData}
          isMulti={isMulti}
          onChange={
            isMulti
              ? (value) => onChange(value)
              : ({ value }) => {
                  onChange(value);
                }
          }
          styles={customStyles}
        />
        {/* <select
          onChange={(e) => onChange(e.target.value)}
          className={`form-control ${validationText && "invalid"}`}
        >
          <option value="">Select {title}</option>
          {data.length > 0 &&
            data.map((mapItem) => (
              <option
                key={mapItem.term}
                value={mapItem.value}
                selected={value == mapItem.value}
              >
                {mapItem.label}
              </option>
            ))}
        </select> */}

        {validationText && <span className="error">{validationText}</span>}
      </div>
    </div>
  );
}
