import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Enums } from "../../../constants/enums";
import { ApiRoutes } from "../../../constants/apiroutes";
import { commonService } from "../../../services/common.service";
import { APICall } from "../../../services/axiosService";
import { updateLoading } from "../../../store/actions";
import EditWizardFour from "./EditWizardFour";
import EditWizardOne from "./EditWizardOne";
import EditWizardThree from "./EditWizardThree";
import EditWizardTwo from "./EditWizardTwo";

const genderDropDownData = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

export default function Edit() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isCompanyFormClick, setIsCompanyFormClick] = useState(false);
  const [branchData, setBranchData] = useState([]);
  const authUserDtl = JSON.parse(commonService.getItem("user"));

  const routeParams = location.state;

  const [activeWizard, setActiveWizard] = useState(1);

  const dispatch = useDispatch();

  const [formInputs, setInput] = useState({
    guard_name: "",
    guard_phone: "",
    guard_email: "",
    id_number: "",
    passport_number: "",
    gender: "male",
    qualification: "",
    year_of_exp: "",
    profile_img: "",
    vetting_img: "",
    id_proof_doc: "",
    branch_list: [],
    epf: "",
    sosco: "",
    date_of_join: new Date(),
    bank_name: "",
    account_no: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    latitude: "",
    longitude: "",
    country: "",
    emergency_contacts: [
      {
        emr_contact_name: "",
        emr_contact_phone: "",
        emr_contact_relation: "",
      },
    ],
  });
  const [formInputsErrors, setInputErrors] = useState({
    wizOne: {
      guard_name: "",
      guard_phone: "",
      guard_email: "",
      id_number: "",
      gender: "",
      date_of_join: "",
      // branch_list: "",
    },
    bank_details: [
      {
        bank_name: "",
        account_no: "",
      },
    ],
    locations: [
      {
        address1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
      },
    ],
  });
  const getRequiredFields = (bank_details, locations) => {
    setInputErrors({
      ...formInputsErrors,
      bank_details: bank_details.length
        ? bank_details.map(() => ({
            bank_name: "",
            account_no: "",
          }))
        : [
            {
              bank_name: "",
              account_no: "",
            },
          ],
      locations: locations.length
        ? locations.map(() => ({
            address1: "",
            city: "",
            state: "",
            postal_code: "",
            country: "",
          }))
        : [
            {
              address1: "",
              city: "",
              state: "",
              postal_code: "",
              country: "",
            },
          ],
    });
  };

  useEffect(() => {
    getDetails();
    getBranchData();
  }, []);

  const validation_msg = {
    guard_name: "This field is required",
    guard_phone: "This field is required",
    guard_email: "This field is required",
    invalid_email: "Please enter valid email",
    id_number: "This field is required",
    gender: "This field is required",
    date_of_join: "This field is required",
    // branch_list: "This field is required",
    bank_name: "This field is required",
    account_no: "This field is required",
    address1: "This field is required",
    city: "This field is required",
    state: "This field is required",
    postal_code: "This field is required",
    country: "This field is required",
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  async function submitGuardsForm(event) {
    event.preventDefault();
    dispatch(updateLoading(true));
    const requestMetadata = await APICall(ApiRoutes.GuardSave, {
      ...formInputs,
      branch_list: formInputs.branch_list.map((item) => item.value),
      profile_img: formInputs.profile_img?.includes(
        process.env.REACT_APP_ASSETS_URL
      )
        ? null
        : formInputs.profile_img,
      vetting_img: formInputs.vetting_img?.includes(
        process.env.REACT_APP_ASSETS_URL
      )
        ? null
        : formInputs.vetting_img,
      id_proof_doc: formInputs.id_proof_doc?.includes(
        process.env.REACT_APP_ASSETS_URL
      )
        ? null
        : formInputs.id_proof_doc,
    });
    setIsCompanyFormClick(false);
    dispatch(updateLoading(false));
    if (requestMetadata) {
      navigate("/guards");
    }
  }

  function validateCompanyForm(wiz) {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors[wiz]);
    const requiredArr = ["bank_details", "locations"];
    errorKeys.forEach((item, index) => {
      if (requiredArr.includes(wiz)) {
        let newArr = [];
        formInputsErrors[wiz].forEach((childLoop, childIndex) => {
          let newObj = {};
          const childErrorsArr = Object.keys(childLoop);
          childErrorsArr.forEach((grandChildLoop) => {
            if (
              formInputs[wiz][childIndex] &&
              formInputs[wiz][childIndex][grandChildLoop]?.trim() === ""
            ) {
              newObj[grandChildLoop] = validation_msg[grandChildLoop];
              isValid = false;
            } else {
              newObj[grandChildLoop] = "";
            }
          });
          newArr.push(newObj);
        });
        errors = newArr;
      } else {
        if (
          formInputs[item] === null ||
          formInputs[item]?.toString()?.trim() === ""
        ) {
          errors[item] = validation_msg[item];
          isValid = false;
        } else {
          if (item === "guard_email" && !pattern.test(formInputs.guard_email)) {
            errors[item] = validation_msg.invalid_email;
            isValid = false;
          } else {
            errors[item] = "";
          }
        }
      }
    });

    setInputErrors((prevState) => ({ ...prevState, [wiz]: errors }));
    return isValid;
  }

  const getDetails = async () => {
    const res = await APICall(ApiRoutes.GuardEditDetails, {
      id: routeParams.id,
    });
    if (res) {
      setInput({
        ...res.data,
        date_of_join: new Date(res.data.date_of_join),
        profile_img: res.data.profile_img
          ? process.env.REACT_APP_ASSETS_URL + res.data.profile_img
          : null,
        vetting_img: res.data.vetting_img
          ? process.env.REACT_APP_ASSETS_URL + res.data.vetting_img
          : null,
        id_proof_doc: res.data.id_proof_doc
          ? process.env.REACT_APP_ASSETS_URL + res.data.id_proof_doc
          : null,
        bank_details: [
          {
            bank_name: res.data.bank_details.length
              ? res.data.bank_details[0].bank_name
              : "",
            account_no: res.data.bank_details.length
              ? res.data.bank_details[0].account_no
              : "",
          },
        ],
        locations: [
          {
            address1: res.data.locations.length
              ? res.data.locations[0].address1
              : "",
            city: res.data.locations.length ? res.data.locations[0].city : "",
            state: res.data.locations.length ? res.data.locations[0].state : "",
            postal_code: res.data.locations.length
              ? res.data.locations[0].postal_code
              : "",
            country: res.data.locations.length
              ? res.data.locations[0].country
              : "",
          },
        ],
        emergency_contacts: [
          {
            emr_contact_name: res.data.emergency_contacts.length
              ? res.data.emergency_contacts[0].emr_contact_name
              : "",
            emr_contact_phone: res.data.emergency_contacts.length
              ? res.data.emergency_contacts[0].emr_contact_phone
              : "",
            emr_contact_relation: res.data.emergency_contacts.length
              ? res.data.emergency_contacts[0].emr_contact_relation
              : "",
          },
        ],
      });
      getRequiredFields(res.data.bank_details, res.data.locations);
    }
  };

  const getBranchData = async () => {
    const response = await APICall(ApiRoutes.BranchDropDownList);
    if (response) {
      setBranchData(response.data.list);
    }
  };

  const renderWizardScreen = () => {
    switch (activeWizard) {
      case 1:
        return (
          <EditWizardOne
            formInputs={formInputs}
            formInputsErrors={formInputsErrors.wizOne}
            genderDropDownData={genderDropDownData}
            branchData={branchData}
            isCompany={
              authUserDtl.association_type_term == Enums.UserRole.company
            }
            setInput={(value) => setInput(value)}
            onNextPress={() => {
              if (validateCompanyForm("wizOne")) {
                setActiveWizard(2);
              }
            }}
          />
        );
      case 2:
        return (
          <EditWizardTwo
            formInputs={formInputs}
            formInputsErrors={formInputsErrors}
            genderDropDownData={genderDropDownData}
            setInput={(value) => setInput(value)}
            onNextPress={() => {
              if (validateCompanyForm("bank_details")) {
                setActiveWizard(3);
              }
            }}
            onPreviousPress={() => setActiveWizard(1)}
          />
        );
      case 3:
        return (
          <EditWizardThree
            formInputs={formInputs}
            formInputsErrors={formInputsErrors}
            genderDropDownData={genderDropDownData}
            setInput={(value) => setInput(value)}
            onNextPress={() => {
              if (validateCompanyForm("locations")) {
                setActiveWizard(4);
              }
            }}
            onPreviousPress={() => setActiveWizard(2)}
          />
        );
      case 4:
        return (
          <EditWizardFour
            formInputs={formInputs}
            formInputsErrors={formInputsErrors}
            genderDropDownData={genderDropDownData}
            setInput={(value) => setInput(value)}
            onNextPress={(event) => submitGuardsForm(event)}
            onPreviousPress={() => setActiveWizard(3)}
            isCompanyFormClick={isCompanyFormClick}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Guard Edit
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/guards">Setup</Breadcrumb.Item>
                <Breadcrumb.Item href="/guards">Guards</Breadcrumb.Item>
                <Breadcrumb.Item active>Edit</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="horizontal-wizard">
            <div className="bs-stepper linear">
              <div className="bs-stepper-header">
                <div className={`step ${activeWizard === 1 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">1</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Guard Details</span>
                      <span className="bs-stepper-subtitle">
                        Enter Guard Details.
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className={`step ${activeWizard === 2 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">2</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Bank Details</span>
                      <span className="bs-stepper-subtitle">
                        Add Bank Details
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className={`step ${activeWizard === 3 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">3</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Location Details</span>
                      <span className="bs-stepper-subtitle">
                        Add Location Details
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className={`step ${activeWizard === 4 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">4</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">
                        Emergency details
                      </span>
                      <span className="bs-stepper-subtitle">
                        Add Emergency details
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              {renderWizardScreen()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
