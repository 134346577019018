import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiRoutes } from "../../constants";
import { Breadcrumb, Button, InputGroup } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../services/alert.service";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import DataTable from "react-data-table-component";
import InputText from "../../components/form/inputText";
import Export from "../../components/common/Export";
import { Enums } from "../../constants/enums";
import "./Index.css";
import NewLoader from "../../components/loader/NewLoader";

export default function Index() {
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [CommonLoaderFlag, setCommonLoaderFlag] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(
    Enums.DefaultPerPageLength
  );
  const [visitorFrom, setVisitorFrom] = useState(null);
  const [visitorTo, setVisitorTo] = useState(null);
  const [visitorTotalRows, setVisitorTotalRows] = useState(0);
  const [visitorList, setVisitorList] = useState({
    isData: false,
    data: [],
  });
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [txtSearch, setTxtSearch] = useState("");
  const [propertyList, setPropertyList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedClientList, setSelectedClientList] = useState([]);
  const [selectedPropertyList, setSelectedPropertyList] = useState([]);

  // React.useEffect(() => {
  //   getVisitorList();
  // }, [pageNo, perPageRecords]);

  useEffect(() => {
    getVisitorList("", "", 1);
  }, [txtSearch, pageNo, perPageRecords]);

  const fetchClients = async () => {
    const res = await APICall(ApiRoutes.getVisitorsClients);
    if (res.status == 1) {
      setClientList(
        res.data.map((mapitem) => ({
          value: mapitem.client_name,
          label: mapitem.client_name,
        }))
      );
    }
  };
  const fetchProperties = async () => {
    const res = await APICall(ApiRoutes.getVisitorsProperties);
    if (res.status === 1) {
      setPropertyList(
        res.data.map((mapitem) => ({
          value: mapitem.property_name,
          label: mapitem.property_name,
        }))
      );
    }
  };
  useEffect(() => {
    fetchClients();
    fetchProperties();
  }, []);
  async function getVisitorList(column = "", sortDirection = "", isFilter = 0) {
    setDataTableLoading(true);
    setVisitorList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.getVisitors, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          visitorFrom != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(visitorFrom) + " 00:00:00"
              )
            : null,
        end_date:
          visitorTo != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(visitorTo) + " 23:59:59"
              )
            : null,
        client_list: selectedClientList,
        property_list: selectedPropertyList,
      },
    });

    if (requestMetadata) {
      setVisitorList({ isData: true, data: requestMetadata.data.list });

      setVisitorTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
    // setDataTableLoading(false);
  }

  const handleSort = async (column, sortDirection) => {
    getVisitorList(column.sortField, sortDirection, 1);
  };

  const searchDateWise = (e) => {
    e.preventDefault();
    let isValidate = true;
    if (visitorTo || visitorFrom) {
      if (visitorTo && visitorFrom) {
        // const dateFrom = new Date(visitorTo);
        // const dateTo = new Date(visitorFrom);

        const dateFrom = new Date(visitorFrom);
        const dateTo = new Date(visitorTo);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getVisitorList("", "", 1);
      setVisitorTotalRows(1);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const resetFilter = () => {
    setSelectedClientList([]);
    setSelectedPropertyList([]);
    setVisitorTo(null);
    setVisitorFrom(null);
    setTxtSearch("");
    getVisitorList("", "", 0);
  };

  const visitorTblColumns = [
    {
      name: "Visitor Name",
      selector: (row) => row.visitor_name,
      sortable: true,
      sortField: "visitor_name",
    },
    {
      name: "Contract/Property",
      selector: (row) => (
        <span
          className="cursor-pointer text-primary"
          onClick={() =>
            navigate(`/contracts/view`, {
              state: {
                id: row.contract_id,
              },
            })
          }
        >
          {row.property_name}
        </span>
      ),
      sortable: true,
      sortField: "property_name",
    },
    {
      name: "Visited in Date",
      sortable: false,
      sortField: "remark",
      cell: (row) =>
        commonService.convertUTCToLocal(row.created_at, "Do MMM, YYYY"),
    },

    {
      name: "Visited Out Date",
      sortable: false,
      sortField: "remark",
      cell: (row) =>
        commonService.convertUTCToLocal(row.visit_time_out, "Do MMM, YYYY"),
    },
    {
      name: "Time In",
      selector: (row) => row.visit_time_in,
      sortable: true,
      sortField: "visit_time_in",
      cell: (row) =>
        commonService.convertUTCToLocal(row.visit_time_in, "HH:mm"),
    },
    {
      name: "Time Out",
      selector: (row) => row.visit_time_out,
      sortable: true,
      sortField: "visit_time_out",
      cell: (row) =>
        commonService.convertUTCToLocal(row.visit_time_out, "HH:mm"),
    },
    // {
    //   name: "Property Manager",
    //   selector: (row) => <span className="cursor-pointer text-primary" onClick={() => navigate(`/clients/view/${row.client_id}`)}>{row.client_name}</span>,
    //   sortable: true,
    //   sortField: "client_name",
    // },
    {
      name: "Visitor Id",
      selector: (row) => row.visitor_ref_id,
      sortable: true,
      sortField: "visitor_ref_id",
    },
    {
      name: "Vehicle Number",
      sortable: false,
      selector: (row) => row.visitor_vehicle_number,
      sortField: "visitor_vehicle_number",
    },
    {
      name: "Contact Number",
      sortable: false,
      selector: (row) => row.visitor_phone,
      sortField: "visitor_phone",
    },
    {
      name: "Address",
      sortable: false,
      selector: (row) => (
        <span className="cursor-pointer" title={row.visiting_address}>
          {row.visiting_address}
        </span>
      ),
      sortField: "visiting_address",
    },
    {
      name: "Submitted By",
      sortable: true,
      sortField: "association_name",

      cell: (row) => row.association_name,
    },
    {
      name: "Remark",
      sortable: false,
      selector: (row) => row.remark,
      sortField: "remark",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="justify-content-center align-items-center d-flex">
          <Button
            style={{ fontSize: "0.7rem", padding: "0.3rem 1rem" }}
            variant="primary"
            type="button"
            onClick={() => {
              navigate(`/visitors/view/${row.visitor_id}`);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];
  let key_headers = {
    visitor_name: {
      text: "Visitor Name",
    },
    property_name: {
      text: "Contract/Property",
    },
    created_at: {
      text: "Visited Date",
      is_date: true,
      date_format: "Do MMMM YYYY",
    },
    visit_time_in: {
      text: "Time In",
      is_date: true,
      date_format: "HH:mm",
    },
    visit_time_out: {
      text: "Time Out",
      is_date: true,
      date_format: "HH:mm",
    },
    // "client_name": {
    //   'text': "Property Manager",
    //   'is_date': false
    // },
    visitor_ref_id: {
      text: "Visitor Id",
      is_date: false,
    },
    visitor_vehicle_number: {
      text: "Vehicle Number",
      is_date: false,
    },
    visitor_phone: {
      text: "Contact Number",
      is_date: false,
    },
    visiting_address: {
      text: "Address",
      is_date: false,
    },
    association_name: {
      text: "Submitted By",
      is_date: false,
    },

    remark: {
      text: "Remark",
      is_date: false,
    },
  };
  // const TableAction = () => {
  //   return ()
  // }
  
  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Visitor List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/visitors">Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Visitors</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
        <div>
          <Card>
            <Card.Body className="p-3">
              <div className="row">
                <div className="col-3">
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={visitorFrom ? visitorFrom : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Start Date"
                      onChange={(date) => {
                        if (date) {
                          setVisitorFrom(date);
                        } else {
                          setVisitorFrom(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
                <div className="col-3">
                  <div>
                    <InputGroup>
                      <DatePicker
                        className="datepicker form-control"
                        selected={visitorTo ? visitorTo : ""}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="End Date"
                        onChange={(date) => {
                          if (date) {
                            setVisitorTo(date);
                          } else {
                            setVisitorTo(null);
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
                {/* <div className="col-3">
                <DropDown
                  data={clientList}
                  value={selectedClientList}
                  style={{ div: "col-12" }}
                  isMulti={true}
                  onChange={(value) =>
                    setSelectedClientList(value)
                  }
                  placeholder="Select Property Manager"
                  required
                // validationText={formInputsErrors.duration_term}
                />

              </div> */}
                <div className="col-3">
                  <DropDown
                    value={selectedPropertyList}
                    data={propertyList}
                    style={{ div: "col-12" }}
                    isMulti={true}
                    onChange={(value) => setSelectedPropertyList(value)}
                    placeholder="Select Contract/Property"
                    required
                    // validationText={formInputsErrors.duration_term}
                  />
                </div>
                <div className="col-3">
                  <div className="d-flex">
                    <div className="ms-2 me-2">
                      <Button
                        onClick={searchDateWise}
                        variant="outline-primary"
                        type="button"
                      >
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                    <div className="ms-">
                      <Button
                        onClick={resetFilter}
                        variant="outline-primary"
                        type="button"
                      >
                        <i className="fa fa-refresh"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="z-adjust-for-dropdown custom-th-gp-width hideDiv  my-special-class">
        <DataTable
        paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
          columns={visitorTblColumns}
          data={visitorList.data}
          progressPending={dataTableLoading}
          progressComponent={<NewLoader />}
          pagination
          paginationServer
          paginationTotalRows={visitorTotalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSort={handleSort}
          paginationPerPage={perPageRecords}
          actions={
            <>
              <div className="col-4">
                <InputText
                  formGroupClass={"mb-0"}
                  size={"12"}
                  type={"text"}
                  Label={false}
                  placeholder={"Search..."}
                  value={txtSearch}
                  onChange={(value) => {
                    if (value) {
                      setTxtSearch(value);
                    } else {
                      setTxtSearch(value);
                    }
                  }}
                />
              </div>
              <Export
                maincommonloaderflag={setCommonLoaderFlag}
                passcommonloaderflag={CommonLoaderFlag}
                data={visitorList.data}
                key_headers={key_headers}
                name={"visitors"}
              />
            </>
          }
        />
      </div>
    </>
  );
}
