import Lottie from "lottie-react";
import React from "react";
import LoaderJson from "../../assets/Icons/animation/Loader.json";
import "../loader/loader.css"

function Loader({width,height}) {
    return (
        <Lottie
            animationData={LoaderJson}
            loop
            style={{ width: width, height:height , padding: 0 }}
        />
    );
}

export default Loader;
