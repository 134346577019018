import React, { useState } from 'react'
import { Button, Modal, Spinner, InputGroup, Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../../services/alert.service";
import { ApiRoutes } from "../../../constants/apiroutes";
import { commonService } from '../../../services/common.service';
import { APICall } from "../../../services/axiosService";
import { contractScheduleStatusEnum, Enums } from "../../../constants";
import { useEffect } from 'react';

export default function GuardReplaceModal({   fetchGuardSchedules , guardRepModal, setGuardRepModal, contractId }) {

    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [scheduleFrom, setScheduleFrom] = useState(null);
    const [scheduleTo, setScheduleTo] = useState(null);
    const [txtSearch, setTxtSearch] = useState("");
    const [formHidden, setFormHidden] = useState(true);
    const [schedulesData, setSchedulesData] = useState([]);
    const [guards, setGuards] = useState([]);
    const [selectedRepGuard, setSelectedRepGuard] = useState({});

    const handleCloseModal = () => {
        setGuardRepModal(false)
        setFormHidden(true)
        setScheduleFrom(null)
        setScheduleTo(null)
        setSchedulesData([])
        setSelectedRepGuard({})
    }

    const handleReplaceGuard = () => {
        if (!formHidden) {
            if(Object.keys(selectedRepGuard).length){
                let arrayToSend = []
                Object.keys(selectedRepGuard).forEach((sc_id) => {
                    let newJson = {
                        ...selectedRepGuard[sc_id],
                        'contract_schedule_id': sc_id
                    }
                    arrayToSend = [...arrayToSend , newJson]
                })
                handleCloseModal()
                fetchGuardSchedules("", "", 1);
            }else{
                alertService.alert({
                    type: AlertType.Error,
                    message: "No guard selected for the replacement",
                });
            }
           
        }
    }

    async function fetchContractSchedules() {
        setDataLoading(true)
        let res = await APICall(ApiRoutes.guardContraSchedules, {
            contract_id: contractId,
            no_table: false,
            is_filter: 1,
            filter: {
                search_text: txtSearch,
                start_date: scheduleFrom != null
                    ? commonService.convertLocalToUTC(
                        commonService.formatDate(scheduleFrom) + " 00:00:00"
                    )
                    : null,
                end_date: scheduleTo != null
                    ? commonService.convertLocalToUTC(
                        commonService.formatDate(scheduleTo) + " 23:59:59"
                    )
                    : null
            },
        });

        if (res.status == 1) {
            setSchedulesData(res.data.list)
            setFormHidden(false)
            setDataLoading(false)

        } else {
            setDataLoading(false)
        }
    }

    const fetchCompanyGuardList = async () => {
        let res = await APICall(ApiRoutes.guardOptions ,{is_all : false , remaining_guards : true , is_fixed_association : 1});
        if (res.status == 1) {
            setGuards(res.data);
        }
    }

    const handleSubmitDuration = () => {
        let isValidate = true;
        if (scheduleFrom || scheduleTo) {
            if (scheduleFrom && scheduleTo) {
                const dateFrom = new Date(scheduleFrom);
                const dateTo = new Date(scheduleTo);
                if (dateFrom > dateTo) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: "End date must be after start date",
                    });
                    isValidate = false;
                }
            }
        } else {
            isValidate = false;
            alertService.alert({
                type: AlertType.Error,
                message: "Start time or End time is required",
            });
        }
        if (isValidate) {
            fetchContractSchedules();
        }
    }

    const selectGuard = (e, item) => {
        if(e.target.value){
            setSelectedRepGuard({
                ...selectedRepGuard,
                [item.contract_schedule_id]: {
                    'contract_id': item.contract_id,
                    'replace_guard_id': e.target.value
                }
            })
        }else{
            let newJson = selectedRepGuard;
            delete newJson[item.contract_schedule_id];
            setSelectedRepGuard(newJson)
        }
        
    }

    useEffect(() => {
        fetchCompanyGuardList()
    }, []);

    return (<Modal dialogClassName="modal-dialog modal-xl modal-dialog-centered" className="modal-popup" show={guardRepModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
            <Modal.Title>Replace Guard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
                <div className="col-3 ps-3">
                    <InputGroup>
                        <DatePicker
                            className="datepicker form-control"
                            selected={scheduleFrom ? scheduleFrom : ""}
                            dateFormat="MM-dd-yyyy"
                            placeholderText="Start Date"
                            onChange={(date) => {
                                if (date) {
                                    setScheduleFrom(date);
                                } else {
                                    setScheduleFrom(null);
                                }
                            }}
                        />

                    </InputGroup>
                </div>
                <div className="col-3">
                    <InputGroup>
                        <DatePicker
                            className="datepicker form-control"
                            selected={scheduleTo ? scheduleTo : ""}
                            dateFormat="MM-dd-yyyy"
                            placeholderText="End Date"
                            onChange={(date) => {
                                if (date) {
                                    setScheduleTo(date);
                                } else {
                                    setScheduleTo(null);
                                }
                            }}
                        />

                    </InputGroup>
                </div>
                <div className="col-3">
                    {!dataLoading ?
                        <Button variant='primary' onClick={handleSubmitDuration}>Go</Button>
                        :
                        <Button variant='primary'><Spinner animation="border" size="sm" /></Button>
                    }
                </div>
            </div>
            {!formHidden && <div className='row my-3 px-3 ps-3'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Current Guard</th>
                            <th>Schedule Date</th>
                            <th>Day</th>
                            <th>Status</th>
                            <th>Replace with Guard</th>
                        </tr>
                    </thead>
                    <tbody>
                        {schedulesData.map((item, index) => {
                            return (<tr key={index}>
                                <td>{item.association_name}</td>
                                <td>{commonService.convertUTCToLocal(item.schedule_date)}</td>
                                <td>{commonService.convertUTCToLocal(item.schedule_date, 'dddd')}</td>
                                <td>{contractScheduleStatusEnum[item.schedule_status]}</td>
                                <td>
                                    <Form.Select onChange={(e) => { selectGuard(e, item) }}>
                                        <option value="">
                                            Select Guard
                                        </option>

                                        {guards.map((guard, i) => {
                                            return <option key={i} value={guard.value}>
                                                {guard.label}
                                            </option>

                                        })}
                                    </Form.Select>
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
            </div>}
        </Modal.Body>
        <Modal.Footer>
            {!loading ? (
                <>
                    <Button variant="primary" disabled={formHidden}
                        onClick={handleReplaceGuard}
                    >
                        Save
                    </Button>
                </>
            ) : (
                <Button variant="primary">
                    <Spinner animation="border" size="sm" /> Loading...
                </Button>
            )}
        </Modal.Footer>
    </Modal >)
}
