import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Form, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import DropDown from "../../components/common/DropDown";
import ImgInput from "../../components/common/ImgInput";
import InputText from "../../components/form/inputText";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { updateLoading } from "../../store/actions";

function Create() {
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [dropDownData, setDropDownData] = useState();

  useEffect(() => {
    getDropDownData();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const getDropDownData = async () => {
    const requestMetadata = await APICall(ApiRoutes.PropertyDropdownList);
    if (requestMetadata) {
      setDropDownData(requestMetadata.data.list);
    }
  };

  const [isUserFormClick, setIsUserFormClick] = useState(false);

  const dispatch = useDispatch();

  const [formInputs, setInput] = useState({
    profile_img: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    position: "",
  });
  const [formInputsErrors, setInputErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    position: "",
  });

  const validation_msg = {
    first_name: "This field is required",
    last_name: "This field is required",
    email: "This field is required",
    phone: "This field is required",
    position: "This field is required",
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  async function submitGuardsForm(event) {
    event.preventDefault();
    if (validateCompanyForm()) {
      dispatch(updateLoading(true));
      setIsUserFormClick(true);
      const requestMetadata = await APICall(ApiRoutes.UserSave, formInputs);
      setIsUserFormClick(false);
      dispatch(updateLoading(false));
      if (requestMetadata) {
        navigate("/user");
      }
    }
  }

  function validateCompanyForm() {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors);
    errorKeys.forEach((item) => {
      if (
        formInputs[item] === null ||
        formInputs[item]?.toString().trim() === ""
      ) {
        errors[item] = validation_msg[item];
        isValid = false;
      } else {
        if (item === "guard_email" && !pattern.test(formInputs.email)) {
          errors[item] = validation_msg.invalid_email;
          isValid = false;
        } else {
          errors[item] = "";
        }
      }
    });

    setInputErrors(errors);
    return isValid;
  }

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">Add User</h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/user">Setup</Breadcrumb.Item>
              <Breadcrumb.Item href="/user">Users</Breadcrumb.Item>
              <Breadcrumb.Item active>Create</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Card className="pt-4">
        <Card.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row>
              <InputText
                type={"text"}
                title={"First Name"}
                value={formInputs.first_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    first_name: value,
                  })
                }
                required
                validationText={formInputsErrors.first_name}
              />

              <InputText
                type={"text"}
                title={"Last Name"}
                value={formInputs.last_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    last_name: value,
                  })
                }
                required
                validationText={formInputsErrors.last_name}
              />
            </Row>
            <Row>
              <InputText
                type={"email"}
                title={"Corporate Email"}
                value={formInputs.email}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    email: value,
                  })
                }
                required
                validationText={formInputsErrors.email}
              />
              <InputText
                type={"number"}
                title={"Phone"}
                value={formInputs.phone}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    phone: value,
                  })
                }
                required
                validationText={formInputsErrors.phone}
              />
            </Row>
            <Row>
              <InputText
                type={"text"}
                title={"Position"}
                value={formInputs.position}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    position: value,
                  })
                }
                required
                validationText={formInputsErrors.position}
              />
              {/* <DropDown
                data={dropDownData.client_contacts}
                title={"Users"}
                value={formInputs.contacts_list}
                isMulti
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    contacts_list: value,
                  })
                }
                required
                validationText={formInputsErrors.contacts_list}
              /> */}
            </Row>
            <Row>
              <ImgInput
                title={"Profile Picture"}
                img_src={formInputs.profile_img}
                input_ref_id="profile_img"
                onChange={(value) => {
                  setInput({
                    ...formInputs,
                    profile_img: value,
                  });
                }}
              />
            </Row>
            <div className="col-12 d-flex justify-content-end">
              <Button
                variant="outline-primary"
                className="btn-15"
                // type="button"
                onClick={() => {
                  navigate("/user");
                }}
              >
                Cancel
              </Button>

              {isUserFormClick === false ? (
                <>
                  <Button
                    variant="primary"
                    className="btn-15"
                    onClick={submitGuardsForm}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button variant="primary" className="btn-15">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default Create;
