import React, { useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Alert from "./components/common/Alert";
import Sidebar from "./Layout/DefaultNavigation/Sidebar/Sidebar";
import Header from "./Layout/Header/Header";
import ButtonPage from "./components/buttons/Button";
import FormPage from "./components/form/Index";
import SwitchPage from "./components/switch/Switch";
import TextareaPage from "./components/textarea/Textarea";
import RadioPage from "./components/radio/Radio";
import FormWizardPage from "./components/wizard/FormWizard";
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs";
import TabsComponent from "./components/tabs/Tab";
import Toaster from "./components/toaster/toaster";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import CompanyList from "./pages/Company";
import CompanyCreate from "./pages/Company/Create";
import CompanyEdit from "./pages/Company/Edit";
import BranchList from "./pages/Branch";
import BranchCreate from "./pages/Branch/Create";
import BranchEdit from "./pages/Branch/Edit";
import GuardsList from "./pages/Guards/Index";
import GuardsCreate from "./pages/Guards/Create";
import GuardsEdit from "./pages/Guards/Edit";
import InchargeList from "./pages/Incharge";
import InchargeCreate from "./pages/Incharge/Create";
import InchargeEdit from "./pages/Incharge/Edit";
import PropertyList from "./pages/Properties";
import PropertyCreate from "./pages/Properties/Create";
import PropertyEdit from "./pages/Properties/Edit";
import UserList from "./pages/Users";
import UserCreate from "./pages/Users/Create";
import UserEdit from "./pages/Users/Edit";
import QuotationList from "./pages/Quotation";
import QuotationCreate from "./pages/Quotation/Create";
import QuotationView from "./pages/Quotation/View";
import QuotationReqList from "./pages/QuotationReq";
import QuotationReqCreate from "./pages/QuotationReq/Create";
import ContractList from "./pages/Contracts";
import ContractView from "./pages/Contracts/View";
import ContractCreate from "./pages/Contracts/Create";
import MyContractList from "./pages/MyContracts";
import MyContractView from "./pages/MyContracts/View";
import QuotationReqView from "./pages/QuotationReq/View";
import Notifications from "./pages/Notifications/Notifications";
import Profile from "./pages/Profiles/Profile";
import ChangePassword from "./pages/Auth/ChangePassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import { commonService } from "./services/common.service";
import ResetPassword from "./pages/Auth/ResetPassword";
import CompanyProfile from "./pages/Quotation/Company/CompanyProfile";
import BranchProfile from "./pages/Quotation/Branch/BranchProfile";
import ClientList from "./pages/Client";
import ClientView from "./pages/Client/View";
import LeaveView from "./pages/Leaves/view";
import LeaveList from "./pages/Leaves/index";
import PaymentRequestView from "./pages/PaymentRequests/View";
import PaymentReqList from "./pages/PaymentRequests/index";
import UnderDev from "./components/underdev/UnderDev";
import WebSetting from "./pages/Settings/WebSetting";
import AppSetting from "./pages/Settings/AppSetting";
import ComplaintList from "./pages/Complaint/index";
import ComplainView from "./pages/Complaint/View";
import VisitorsView from "./pages/Visitors/index";
import InvoiceList from "./pages/Invoice/index";
import IncidentList from "./pages/Incident/index";
import IncidentView from "./pages/Incident/View";
import GeneralSetting from "./pages/Settings/GeneralSetting";
import GuardPatrolling from "./pages/Report/GuardPatrolling";
import ReviewAll from "./pages/Review/ReviewAll";
import CompanyView from "./pages/Company/View";
import BranchView from "./pages/Branch/View";
import GuardView from "./pages/Guards/View";
import InchargeView from "./pages/Incharge/View";
import UserView from "./pages/Users/View";
import Attendance from "./pages/Report/Attendance";
import ViewVisitors from "./pages/Visitors/View";
import ClientLogin from "./pages/Auth/client/ClientLogin";
import VerfiyOtp from "./pages/Auth/client/VerfiyOtp";
import PrivacyPolicy from "./pages/Auth/PrivacyPolicy";
import Guidelines from "./pages/Guidelines/Guidelines";


function AppRoutes() {
  const [isHeaderBtnClick, setIsHeaderBtnClick] = useState(false);
  const location = useLocation();
  const authToken = commonService.getItem("auth_token");

  const authRoute = () => (
    <>
      <Route path="/signup" element={<Signup />} />
      <Route exact strict path="/portal/login" element={<Login />} />
      <Route exact strict path="/" element={<ClientLogin />} />
      <Route exact strict path="/verify-otp" element={<VerfiyOtp/>} />
      <Route path="/portal/forgot/password" element={<ForgotPassword />} />
      <Route path="/reset/password/:token" element={<ResetPassword />} />
      <Route path="/privacy" element={<PrivacyPolicy/>} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  );  
  const appRoute = () => (
    <>
      {/* <Route path="/signup" element={<Signup />} /> */}
      <Route path="*" element={<Navigate to="/" replace />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="/buttons" element={<ButtonPage />} />
      <Route path="/input" element={<FormPage />} />
      <Route path="/switch" element={<SwitchPage />} />
      <Route path="/textarea" element={<TextareaPage />} />
      <Route path="/radio" element={<RadioPage />} />
      <Route path="/wizard" element={<FormWizardPage />} />
      <Route path="/breadcrumbs" element={<Breadcrumbs />} />
      <Route path="/tab" element={<TabsComponent />} />
      <Route path="/toaster" element={<Toaster />} />
      <Route path="/company" element={<CompanyList />} />
      <Route path="/company/create" element={<CompanyCreate />} />
      <Route path="/company/edit" element={<CompanyEdit />} />
      <Route path="/branch" element={<BranchList />} />
      <Route path="/branch/create" element={<BranchCreate />} />
      <Route path="/branch/edit" element={<BranchEdit />} />
      <Route path="/guards" element={<GuardsList />} />
      <Route path="/guards/create" element={<GuardsCreate />} />
      <Route path="/guards/edit" element={<GuardsEdit />} />
      <Route path="/incharge" element={<InchargeList />} />
      <Route path="/incharge/create" element={<InchargeCreate />} />
      <Route path="/incharge/edit" element={<InchargeEdit />} />
      <Route path="/property" element={<PropertyList />} />
      <Route path="/property/create" element={<PropertyCreate />} />
      <Route path="/property/edit" element={<PropertyEdit />} />
      <Route path="/user" element={<UserList />} />
      <Route path="/user/create" element={<UserCreate />} />
      <Route path="/user/edit" element={<UserEdit />} />
      <Route path="/myquotation" element={<QuotationList />} />
      <Route path="/myquotation/create" element={<QuotationCreate />} />
      <Route path="/myquotation/view" element={<QuotationView />} />

      <Route path="/quotationreq" element={<QuotationReqList />} />   {/* Company & branch manager Quotation requests */}


      <Route path="/contracts" element={<ContractList />} />
      <Route path="/contracts/create" element={<ContractCreate />} />
      <Route path="/contracts/view" element={<ContractView />} />̉
      <Route path="/client_contracts" element={<MyContractList />} />
      <Route path="/client_contracts/view" element={<MyContractView />} />
      {/* <Route
                    path="/quotationreq/create"
                    element={<QuotationReqCreate />}
                  /> */}
      <Route path="/quotationreq/view" element={<QuotationReqView />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/profile/edit" element={<Profile />} />
      <Route path="/change/password" element={<ChangePassword />} />
      <Route path="/contract/company/profile" element={<CompanyProfile />} />
      <Route path="/contract/branch/profile" element={<BranchProfile />} />
      <Route path="/leaves/view/:leave_id" element={<LeaveView />} />
      {/* LeaveList */}
      <Route path="/leaves" element={<LeaveList />} />
      <Route path="/clients" element={<ClientList />} />   
      <Route path="/clients/view/:client_id" element={<ClientView />} />  
      <Route path="/payment_requests/view/:payment_request_id" element={<PaymentRequestView />} />
      <Route path="/payment_requests" element={<PaymentReqList />} />
      <Route path="/web_setting" element={<WebSetting/>} />
      <Route path="/app_setting" element={<AppSetting/>} />
      <Route path="/complaint" element={<ComplaintList />} />
      <Route path="/complaint" element={<UnderDev />} />
      <Route path="/complaint/view/:ticket_request_id" element={<ComplainView/>} />
      <Route path="/invoices" element={<InvoiceList/>} />
      <Route path="/visitors" element={<VisitorsView/>} />
      <Route path="/incidents" element={<IncidentList />} />
      <Route path="/incidents/view/:incident_id" element={<IncidentView />} />
      <Route path="/guard_patrolling" element={<GuardPatrolling />} />
      <Route path="/general_setting" element={<GeneralSetting />} />
      <Route path="/reviews_ratings" element={<ReviewAll />} />      
      <Route path="/payment_transaction" element={<UnderDev />} />
      {/* View Pages */}
      <Route path="/company/view/:company_id" element={<CompanyView />} />

      <Route path="/branch/view/:branch_id" element={<BranchView />} />
      <Route path="/guard/view/:guard_id" element={<GuardView />} />
      <Route path="/incharge/view/:incharge_id" element={<InchargeView />} />
      <Route path="/user/view/:user_id" element={<UserView />} />
      <Route path="/visitors/view/:visitor_id" element={<ViewVisitors />} />
      <Route path="/attendance_report" element={<Attendance/>} />
      
      <Route path="/user_guideline" element={<Guidelines/>} />
      

      
      
      {/*Under Development, Coming Soon*/}
      {/* <Route path="/setup" element={<UnderDev />} />
      <Route path="/clientquotationreq" element={<UnderDev />} /> */}
    </>
  );
  return (
    <>
      <div className="alert-container">
        <Alert />
      </div>
      <div className={authToken && "App"}>
        <div className={authToken && "Wrapper"}>
          {authToken && (
            <Sidebar
              isHeaderBtnClick={isHeaderBtnClick}
              onCloseClicked={() => setIsHeaderBtnClick(false)}
            />
          )}
          <div className={authToken && "app-content content"}>
            {authToken ? (
              <Header
                onHeaderButtonClick={() => {
                  setIsHeaderBtnClick((prevState) => !prevState);
                }}
              />
            ) : (
              ""
            )}
            <div className={authToken && "content-wrapper"}>
              <Routes>
                {/* <Suspense fallback={<Spinner />}> */}
                {/* <Route path="/" element={<Dashboard />} /> */}
                {authToken ? appRoute() : authRoute()}

                {/* <NotFoundRoute handler={<div>dfgdfg</div>} /> */}
              </Routes>
            </div>
          </div>
        </div>

        {isHeaderBtnClick && (
          <div
            className="sidenav-overlay"
            onClick={() => setIsHeaderBtnClick(false)}
          ></div>
        )}
      </div>
    </>
  );
}

export default AppRoutes;
