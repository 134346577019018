import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ApiRoutes } from "../../../constants/apiroutes";
import { APICall } from "../../../services/axiosService";
import { updateLoading } from "../../../store/actions";
import EditWizardOne from "./EditWizardOne";
import EditWizardTwo from "./EditWizardTwo";

const genderDropDownData = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

export default function Edit() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isCompanyFormClick, setIsCompanyFormClick] = useState(false);
  const [dropDownData, setDropDownData] = useState();

  const routeParams = location.state;

  const [activeWizard, setActiveWizard] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    getDropDownData();
    getDetails();
  }, []);

  const getDropDownData = async () => {
    const requestMetadata = await APICall(ApiRoutes.PropertyDropdownList);
    if (requestMetadata) {
      setDropDownData(requestMetadata.data.list);
    }
  };

  const [formInputs, setInput] = useState({
    property_name: "",
    register_no: "",
    email: "",
    phone: "",
    property_category_type: "",
    // property_development_type: "",
    profile_img: null,
    contacts_list: [],
    location: {
      address1: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      latitude: "",
      longitude: "",
    },
  });
  const [formInputsErrors, setInputErrors] = useState({
    wizOne: {
      property_name: "",
      register_no: "",
      email: "",
      phone: "",
      contacts_list: [],
      property_category_type: "",
      // property_development_type: "",
    },
    location: {
      address1: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
    },
  });
  const validation_msg = {
    property_name: "This field is required",
    register_no: "This field is required",
    email: "This field is required",
    phone: "This field is required",
    property_category_type: "This field is required",
    // property_development_type: "This field is required",
    address1: "This field is required",
    city: "This field is required",
    state: "This field is required",
    postal_code: "This field is required",
    country: "This field is required",
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  async function submitPropertyForm(event) {
    event.preventDefault();
    dispatch(updateLoading(true));
    const requestMetadata = await APICall(ApiRoutes.PropertySave, {
      ...formInputs,
      profile_img: formInputs.profile_img?.includes(
        process.env.REACT_APP_ASSETS_URL
      )
        ? null
        : formInputs.profile_img,
    });
    setIsCompanyFormClick(false);
    dispatch(updateLoading(false));
    if (requestMetadata) {
      navigate("/property");
    }
  }

  function validatePropertiesForm(wiz) {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors[wiz]);
    const requiredArr = ["location"];
    errorKeys.forEach((item, index) => {
      if (requiredArr.includes(wiz)) {
      
        let newObj = {};
        const childErrorsArr = Object.keys(formInputsErrors[wiz]);
        childErrorsArr.forEach((childLoop) => {
          if(childLoop !== "contacts_list"){
            if (formInputs[wiz][childLoop]?.trim() === "") {
              newObj[childLoop] = validation_msg[childLoop];
              isValid = false;
            } else {
              newObj[childLoop] = "";
            }
          }
        });
        errors = newObj;
      } else {
        if(item !== "contacts_list"){
          if (
            formInputs[item] === null ||
            formInputs[item]?.toString()?.trim() === ""
          ) {
            errors[item] = validation_msg[item];
            isValid = false;
          } else {
            if (item === "email" && !pattern.test(formInputs.email)) {
              errors[item] = validation_msg.invalid_email;
              isValid = false;
            } else {
              errors[item] = "";
            }
          }
        }
     
      }
    });

    setInputErrors((prevState) => ({ ...prevState, [wiz]: errors }));
    return isValid;
  }

  const getDetails = async () => {
    const res = await APICall(ApiRoutes.PropertyEditDetails, {
      id: routeParams.id,
    });
    if (res) {
      setInput({
        ...res.data,
        profile_img: res.data.profile_img
          ? process.env.REACT_APP_ASSETS_URL + res.data.profile_img
          : null,
        location: res.data.location ? res.data.location : {
          address1: "",
          city: "",
          state: "",
          postal_code: "",
          country: "",
          latitude: "",
          longitude: "",
        }
      });
    }
  };

  const renderWizardScreen = () => {
    switch (activeWizard) {
      case 1:
        return (
          <EditWizardOne
            formInputs={formInputs}
            formInputsErrors={formInputsErrors.wizOne}
            dropDownData={dropDownData}
            setInputErrors={setInputErrors}
            setInput={(value) => setInput(value)}
            onNextPress={() => {
              if (validatePropertiesForm("wizOne")) {
                setActiveWizard(2);
              }
            }}
          />
        );
      case 2:
        return (
          <EditWizardTwo
            formInputs={formInputs}
            formInputsErrors={formInputsErrors.location}
            genderDropDownData={genderDropDownData}
            setInput={(value) => setInput(value)}
            onNextPress={(event) => {
              if (validatePropertiesForm("location")) {
                submitPropertyForm(event);
              }
            }}
            onPreviousPress={() => setActiveWizard(1)}
            isCompanyFormClick={isCompanyFormClick}
          />
        );

      default:
        break;
    }
  };

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              Property Edit
            </h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/property">Setup</Breadcrumb.Item>
              <Breadcrumb.Item href="/property">Property</Breadcrumb.Item>
              <Breadcrumb.Item active>Edit</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="horizontal-wizard">
            <div className="bs-stepper linear">
              <div className="bs-stepper-header">
                <div className={`step ${activeWizard === 1 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">1</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Property Details</span>
                      <span className="bs-stepper-subtitle">
                        Enter Property Details.
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className={`step ${activeWizard === 2 ? "active" : ""}`}>
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">2</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Location Details</span>
                      <span className="bs-stepper-subtitle">
                        Add Location Details
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              {renderWizardScreen()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
