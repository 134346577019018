import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Router from "./Router";
// import { getTokenfun } from "./firebaseinit.js"
import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   let data;
  //   async function tokenFun() {
  //     data = await getTokenfun()
  //     if (data) {
  //     }
  //   }
  //   tokenFun()
  // }, []);

  return <Router />;
}

export default App;
