import { Badge } from "react-bootstrap";

export const badgesEnum = {
  pending: <Badge bg="warning">New Request</Badge>,
  // quote_rec: <Badge bg="info">Quotation Received</Badge>,
  in_progress: <Badge bg="info">In Progress</Badge>,
  rejected: <Badge bg="danger">Rejected</Badge>,
  accepted: <Badge bg="success">Accepted</Badge>,
  confirmed: <Badge bg="success">Confirmed</Badge>,
  completed: <Badge bg="primary">Completed</Badge>,
  expired: <Badge bg="danger">Expired</Badge>,
};

export const contractStatusEnum = {
  active: <Badge bg="light-primary">Active</Badge>,
  expired: <Badge bg="light-danger">Expired</Badge>,
};

export const contractScheduleStatusEnum = {
  pending: <Badge bg="light-warning">Pending</Badge>,
  completed: <Badge bg="light-success">Completed</Badge>,
  in_progress: <Badge bg="light-info">In Progress</Badge>,
  incomplete: <Badge bg="light-danger">InCompleted</Badge>
};
export const leaveStatusEnum = {
  approved: <Badge bg="light-success">Approved</Badge>,
  rejected: <Badge bg="light-danger">Declined</Badge>,
  pending: <Badge bg="light-warning">Leave Request</Badge>
}
export const PaymentReqStatusEnum = {
  approved: <Badge bg="light-success">Approved</Badge>,
  rejected: <Badge bg="light-danger">Declined</Badge>,
  pending: <Badge bg="light-warning">Requested</Badge>
}
export const PatrollingSchedulesEnum = {
  pending: <Badge bg="light-warning">Pending</Badge>,
  in_progress: <Badge bg="light-info">In progress</Badge>,
  completed: <Badge bg="light-primary">Visited</Badge>,
  incomplete: <Badge bg="light-danger">InCompleted</Badge>,
  visited: <Badge bg="light-primary">Visited</Badge>
}
export const ComplaintEnum = {
  pending: <Badge bg="light-warning">Pending</Badge>,
  rejected: <Badge bg="light-danger">Rejected</Badge>,
  accepted: <Badge bg="light-success">Accepted</Badge>,
  completed: <Badge bg="light-primary">Completed</Badge>,
}
export const GuardStatusEnum = {
  pending: <Badge bg="light-warning">Pending</Badge>,
  active: <Badge bg="light-success">Active</Badge>,
  rejected: <Badge bg="light-danger">Rejected</Badge>,
}
export const InchargeStatusEnum = {
  pending: <Badge bg="light-warning">Pending</Badge>,
  active: <Badge bg="light-success">Active</Badge>,
  rejected: <Badge bg="light-danger">Rejected</Badge>,
}
export const CheckpointActiveStatusEnum = {
  pending: <Badge bg="light-warning">Pending</Badge>,
  active: <Badge bg="light-success">Active </Badge>,
  inactive: <Badge bg="light-danger">Inactive</Badge>

}
export const BranchStatusEnum = {
  pending: <Badge bg="light-warning">Pending</Badge>,
  active: <Badge bg="light-success">Active </Badge>,
  rejected: <Badge bg="light-danger">Rejected</Badge>

}

