import React from 'react'
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner, Form } from "react-bootstrap";
import PrivacyPolicy from '../PrivacyPolicy';
import TermsAndCondition from '../TermsAndCondition';
import InputText from "../../../components/form/inputText";
import { AppConfigData, commonService } from "../../../services/common.service";
import { alertService, AlertType } from "../../../services/alert.service";
import logo from "../../../assets/Images/logo/logo.svg";
import { APICall } from '../../../services/axiosService';
import { ApiRoutes } from '../../../constants';
import { ReactComponent as PageLoading } from "../../../assets/Icons/page-loading.svg";
import axios from "axios";
const CryptoJS = require("crypto-js");

export default function ClientLogin() {
    const cryptoKey = "VeriFy_OTP@USER_NAme@123546789";
    const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [input, setInput] = useState({
        username: "",
        errors: {
            username: "",
        },
    });

    const [policy, setPolicy] = useState(null);
    const [term, setTerm] = useState(null);
    const [agreement, setAgreement] = useState(false);
    const [agreementDiv, setAgreementDiv] = useState(false);
    const [agreementEr, setAgreementEr] = useState("");
    const [showTermsCon, setShowTermsCon] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const openTerms = () => {
        setShowTermsCon(true)
    }
    const openPrivacy = () => {
        history('/privacy')
    }

    function validateLogin() {
        let isValid = true;
        let errors = {};
        if (agreementDiv) {
            if (!agreement) {
                isValid = false;
                setAgreementEr("You must accept terms and conditions");
            } else {
                setAgreementEr("")
            }
        }
        if (!input.username) {
            errors.username = "Email is required";
            isValid = false;
        } else {
            if (!pattern.test(input.username)) {
                errors.username = "Enter valid email address";
                isValid = false;
            } else {
                errors.username = "";
            }
        }

        setInput({
            ...input,
            ["errors"]: errors,
        });

        return isValid;
    }


    async function loginClick(event) {
        event.preventDefault();

        // let agreementEr = false 
        // if(agreementDiv){
        //   agreementEr  = true
        // }
        // if(agreementDiv){

        // }
        // if(agreement){
        if (validateLogin()) {
            setIsLoading(true);
            // let device_token = await getTokenfun();
            // let newData = { ...input, ...{device_token : device_token}};

            let newData = { ...input };
            const requestMetadata = {
                url: AppConfigData.APIBasePath + "send_email_otp",
                method: "POST",
                headers: commonService.getHeaders(),
                data: newData,
            };
            const response = await axios(
                AppConfigData.APIBasePath + "send_email_otp",
                requestMetadata
            );

            if (response.data.status === 1) {
                alertService.alert({
                    type: AlertType.Success,
                    message: response.data.message,
                });
                setIsLoading(false)
                let encryptedUser = CryptoJS.AES.encrypt(JSON.stringify(input.username), cryptoKey).toString();
                history(`/verify-otp/?username=${encryptedUser}`);
            } else {
                alertService.alert({
                    type: AlertType.Error,
                    message: response.data.message,
                });
                setIsLoading(false);
            }
        }
        // }else{
        //   setAgreementEr ("You must accept terms and conditions")
        // }

        setIsLoading(false);
    }

    const fetchPolicy = async () => {
        let res = await APICall(ApiRoutes.fetchPolicy)
        if (res.status == 0) {
            setAgreementDiv(false)
        } else if (res.status == 1) {

            setAgreementDiv(true)
            setPolicy(res.data.policy)
            setTerm(res.data.client_term_and_condition ? res.data.client_term_and_condition :"")
        }
        setPageLoading(false)
    }
    useEffect(() => {
        fetchPolicy()

    }, []);

    return (!pageLoading ? <>
        <div className="blank-page">
            <div className="content-body">
                <div className="auth-wrapper auth-basic px-4">
                    <div className="auth-inner my-4">
                        <div className="mb-0 card">
                            <div className="card-body p-4">
                                <Link to="/" className="login-logo">
                                    <img src={logo} alt="logo" width="160" height="48" />
                                </Link>
                                <h4 className="mb-4 card-title text-center text-dark">
                                    Welcome to Monitor & Manage
                                </h4>
                                <Form onSubmit={loginClick}>
                                    <InputText
                                        type={"text"}
                                        size={12}
                                        title={"Email Address"}
                                        value={input.username}
                                        onChange={(value) =>
                                            setInput({
                                                ...input,
                                                username: value,
                                            })
                                        }
                                        validationText={input.errors.username}
                                    />
                                    {agreementDiv && <><div className="mt-3 mb-3">
                                        <span>
                                            <input checked={agreement} onChange={() => {
                                                setAgreement(!agreement)
                                            }} className="cursor-pointer" type="checkbox"></input>  I agree to the <span className="cursor-pointer text-primary" onClick={() => openTerms()}>Terms and Conditions</span> & <a href='/privacy' className="cursor-pointer text-primary" target="_blank" >Privacy Policy</a>
                                        </span>
                                    </div>
                                        <div className="my-2">
                                            {agreementEr != "" ? <span className="text-danger">
                                                {agreementEr}
                                            </span> : ""}
                                        </div></>}
                                    {/* <Link to="/"> */}
                                    {isLoading ? (
                                        <Button variant="primary w-100">
                                            <Spinner animation="border" size="sm" /> Loading...
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="primary w-100"
                                            type="submit"
                                        // disabled={agreementDiv ? !agreement : false}
                                        // onClick={loginClick}
                                        >
                                            Sign in
                                        </Button>
                                    )}
                                    {/* </Link> */}
                                </Form>
                                <p className="create-login-account-bottom text-center mt-3">
                                    <span>New on our platform?</span>
                                    <Link to="/signup" className="text-primary h6">
                                        <span>Create an Account</span>
                                    </Link>
                                </p>
                                {/* <p className="create-login-account-bottom text-center mt-3">

                                    <Link to="/portal/forgot/password" className="text-primary h6">
                                        <span>Forgot Password?</span>
                                    </Link>
                                </p> */}

                                {/* <div onClick={() => history('/forgot/password')} className="mb-2 text-end cursor-pointer"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <PrivacyPolicy policy={policy} showPrivacyPolicy={showPrivacyPolicy} setShowPrivacyPolicy={setShowPrivacyPolicy} /> */}
        <TermsAndCondition term={term} showTermsCon={showTermsCon} setShowTermsCon={setShowTermsCon} />
    </> : <div className="page-loading">
        <PageLoading />
    </div>)
}
