import React, { useState } from "react";
// import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import DataTable from "react-data-table-component";
// import FilterComponent from "../../components/common/FilterComponent";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
// import NoDataFound from "../../components/common/NoDataFound";
import getUserAccess from "../../helpers/userAccess";
import { Enums } from "../../constants/enums";
import { Breadcrumb, Button, Card, InputGroup, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import InputText from "../../components/form/inputText";
import { alertService, AlertType } from "../../services/alert.service";
import Swal from "sweetalert2";
import NewLoader from "../../components/loader/NewLoader";

function Index() {
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [companyTotalRows, setCompanyTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [companiesList, setCompaniesJson] = useState({
    isData: false,
    data: [],
  });
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);

  const RoleRights = getUserAccess(Enums.UserRights.company);

  const companyTblColumns = [
    {
      name: "Company Name",
      selector: (row) => <div className="cursor-pointer text-primary" onClick={()=>{
        navigate(`/company/view/${row.company_id}`)
      }}> {row.company_name}</div>,
      sortable: true,
      sortField: "company_name",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      sortField: "email",
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      sortField: "phone",
    },
    // {
    //   name: "Registration No",
    //   selector: (row) => row.register_no,
    //   sortable: true,
    //   sortField: "register_no",
    // },
    {
      name: "Payment Duration",
      selector: (row) => row.payment_duration_title,
      sortable: true,
      sortField: "payment_duration_title",
    },
    {
      name: "Total Guards",
      selector: (row) => row.total_guards_count,
      sortable: true,
      sortField: "total_guards_count",
    },
    {
      name: "Total Incharge",
      selector: (row) => row.total_incharges_count,
      sortable: true,
      sortField: "total_incharges_count",
    },

    // {
    //   name: "Created On",
    //   selector: (row) => row.created_at,
    //   sortable: true,
    //   sortField: "created_at",
    //   cell: (row, index, column, id) =>
    //     commonService.convertUTCToLocal(row.created_at),
    // },
    {
      name: "Total Contracts",
      selector: (row) => row.contract_counts,
      sortable: true,
      sortField: "contract_counts",
    },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      sortField: "start_date",
      cell: (row, index, column, id) =>
        row.start_date ? commonService.convertUTCToLocal(row.start_date) : "",
    },
    {
      name: "Expiry Date",
      selector: (row) => row.end_date,
      sortable: true,
      sortField: "end_date",
      cell: (row, index, column, id) =>
        row.end_date ? commonService.convertUTCToLocal(row.end_date) : "",
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      sortField: "is_active",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            <Switch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={(value) => onSwitchToggle(value, row, index)}
              checked={row.is_active === "1" ? true : false}
              height={20}
              width={40}
              disabled={!RoleRights.is_update}
              onColor="#263A96"
            />
          </span>
        );
      },
    },
    {
      name: "Action",
      selector: (row) => row.year,
      cell: (row, index, column, id) =>

          <div className="justify-content-center align-items-center d-flex">
                 {  RoleRights.is_update === 1 && (
            <div className="me-2 cursor-pointer"
              onClick={() => {
                navigate("edit", {
                  state: {
                    id: row.company_id,
                  },
                });
              }}
            >
              <i className="fa fa-edit text-primary"></i>
            </div>)}
            <div className="me-2 cursor-pointer" onClick={() => {
              navigate(`/company/view/${row.company_id}`)
            }}
            >
              <i className="fa fa-eye text-primary"></i>
            </div>
            <div className="cursor-pointer"
                            onClick={(e) => {
                               handleDelete(e , row.company_id)
                            }}
                        >
                            <i className="fa fa-trash text-danger"></i>
                        </div>
          </div>
        ,
    },
  
  ];

  const navigate = useNavigate();

  React.useEffect(() => {
    getCompaniesList("", "", 1);
  }, [txtSearch,pageNo, perPageRecords]);

  // React.useEffect(() => {
  //   getCompaniesList("", "", 1);
  // }, [txtSearch]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };


  const resetFilter = () => {
    setFromDate(null)
    setToDate(null)
    setTxtSearch("")
    setFilterStatus(null)
    getCompaniesList("", "", 0);
  };

  const searchData = (e) => {
    e.preventDefault();

    let isValidate = true;
    if (toDate || fromDate) {
      if (toDate && fromDate) {
        const dateFrom = new Date(fromDate);
        const dateTo = new Date(toDate);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getCompaniesList("", "", 1);
      setCompanyTotalRows(1)
    }
  };


  async function getCompaniesList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setDataTableLoading(true);
    setCompaniesJson({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.CompanyList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        status: filterStatus,
        start_date:
          fromDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(fromDate) + " 00:00:00"
            )
            : null,
        end_date:
          toDate != null
            ? commonService.convertLocalToUTC(
              commonService.formatDate(toDate) + " 23:59:59"
            )
            : null,
      },
    });

    if (requestMetadata) {
      setCompaniesJson({ isData: true, data: requestMetadata.data.list });

      setCompanyTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
  }

  const handleSort = async (column, sortDirection) => {
    getCompaniesList(column.sortField, sortDirection, 1);
  };

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.CompanyStatusUpdate, {
      id: item.company_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setCompaniesJson((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };

  const handleFilterStatus = (value) => {
    if (value) {
      setFilterStatus(parseInt(value));
    } else {
      setFilterStatus(null);
    }
  }


  const handleDelete = async (e, company_id) => {

    e.preventDefault();

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      let res = await APICall(ApiRoutes.CompanyDelete, {
        company_id: company_id
      })
      if (res.status === 1) {
        
        alertService.alert({
          type: AlertType.Success,
          message: res.message,
        });
        getCompaniesList("", "", 1);
      }
    }
  }
  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Company List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/company">Setup</Breadcrumb.Item>
                <Breadcrumb.Item active>Companies</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>

        <div className="col-12 d-flex align-items-center justify-content-end mb-3 mt-2">
          {RoleRights.is_update === 1 && (
            <Link to="/company/create">
              <Button className="btn-15" variant="primary">
                <i className="fa-regular fa-plus"></i> Add Company
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div>
        <Card>
          <Card.Body className="p-3">
            <div className="row">

              <div className="col-3">
                <InputGroup>
                  <DatePicker
                    className="datepicker form-control"
                    selected={fromDate ? fromDate : ""}
                    dateFormat="MM-dd-yyyy"
                    placeholderText="Start Date"
                    onChange={(date) => {
                      if (date) {
                        setFromDate(date);
                      } else {
                        setFromDate(null);
                      }
                    }}
                  />
                </InputGroup>
              </div>
              <div className="col-3">
                <div>
                  <InputGroup>
                    <DatePicker
                      className="datepicker form-control"
                      selected={toDate ? toDate : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="End Date"
                      onChange={(date) => {
                        if (date) {
                          setToDate(date);
                        } else {
                          setToDate(null);
                        }
                      }}
                    />
                  </InputGroup>
                </div>

              </div>
              <div className="col-3">
                <Form.Select onChange={(e) => { handleFilterStatus(e.target.value) }}>
                  <option value="" selected={filterStatus == null && true}>
                    Company Status
                  </option>
                  <option value="1" selected={filterStatus == 1 && true}>
                    Active
                  </option>
                  <option value="0" selected={filterStatus == 0 && true}>
                    Inactive
                  </option>

                </Form.Select>
              </div>
              <div className="col-3">
                <div className="d-flex">
                  <div className="me-2">
                    <Button
                      onClick={searchData}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </Card.Body>
        </Card>
      </div>

      {/* <FilterComponent
        searchText={txtSearch}
        PHSearchText={DisplayText.SearchText}
        PHDatepickerFrom={DisplayText.DatepickerFrom}
        PHDatepickerTo={DisplayText.DatepickerTo}
        startDate={filterStartDate}
        endDate={filterEndDate}
        onSearchTextChange={(value) => setTxtSearch(value)}
        onStartDateChange={(value) => setFilterStartDate(value)}
        onEndDateChange={(value) => setFilterEndDate(value)}
        onSearchClick={() => filterCompaniesList()}
      /> */}

      {/* {companiesList.data.length === 0 ? (
        <div className="event-details">
          <NoDataFound fontsize={"150px"} />
        </div>
      ) : ( */}
      <DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
        columns={companyTblColumns}
        data={companiesList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={companyTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
          <div className="col-4">
            <InputText
              formGroupClass={"mb-0"}
              size={'12'}
              type={"text"}
              Label={false}
              placeholder={"Search..."}
              value={txtSearch}
              onChange={(value) => {
                if (value) {
                  setTxtSearch(value)
                } else {
                  setTxtSearch(value)
                }
              }}
            />
          </div>
        </>}
      />
      {/* )} */}
    </>
  );
}

export default Index;
