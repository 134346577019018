import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import DatePickerInput from "../../../components/common/DatePickerInput";
import DropDown from "../../../components/common/DropDown";
import ImgInput from "../../../components/common/ImgInput";
import InputText from "../../../components/form/inputText";

export default function CreateWizardOne({
  formInputs,
  setInput,
  formInputsErrors,
  genderDropDownData,
  onNextPress,
  branchData,
  isCompany,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Row>
            <InputText
              type={"text"}
              title={"Name"}
              value={formInputs.guard_name}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  guard_name: value,
                })
              }
              validationText={formInputsErrors.guard_name}
              required
            />
            <InputText
              type={"email"}
              title={"Email"}
              value={formInputs.guard_email}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  guard_email: value,
                })
              }
              validationText={formInputsErrors.guard_email}
              required
            />
          </Row>
          <Row>
            <InputText
              type={"number"}
              title={"Phone"}
              value={formInputs.guard_phone}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  guard_phone: value,
                })
              }
              validationText={formInputsErrors.guard_phone}
              required
            />

            <DropDown
              data={genderDropDownData}
              title={"Gender"}
              value={formInputs.gender}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  gender: value,
                })
              }
              validationText={formInputsErrors.gender}
              required
            />
          </Row>
          <Row>
            <InputText
              type={"text"}
              title={"ID Number"}
              value={formInputs.id_number}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  id_number: value,
                })
              }
              validationText={formInputsErrors.id_number}
              required
            />
            <InputText
              type={"text"}
              title={"Passport Number"}
              value={formInputs.passport_number}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  passport_number: value,
                })
              }
            />
          </Row>
          <Row>
            <InputText
              type={"text"}
              title={"Qualification"}
              value={formInputs.qualification}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  qualification: value,
                })
              }
            />
            <InputText
              type={"number"}
              title={"Year of experience"}
              value={formInputs.year_of_exp}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  year_of_exp: value,
                })
              }
            />
          </Row>
          <Row>
            <InputText
              type={"number"}
              title={"EPF"}
              value={formInputs.epf}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  epf: value,
                })
              }
            />
            <InputText
              type={"number"}
              title={"Sosco"}
              value={formInputs.sosco}
              onChange={(value) =>
                setInput({
                  ...formInputs,
                  sosco: value,
                })
              }
            />
          </Row>
          <Row>
            <DatePickerInput
              title={"Joined Date"}
              selectedDate={formInputs.date_of_join}
              onChange={(value) => {
                setInput({
                  ...formInputs,
                  date_of_join: value,
                });
              }}
              validationText={formInputsErrors.date_of_join}
              required
            />
            {/* {isCompany && (
              <DropDown
                data={branchData}
                title={"Branch"}
                value={formInputs.branch_list}
                isMulti
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    branch_list: value,
                  })
                }
                // required
                // validationText={formInputsErrors.branch_list}
              />
            )} */}
          </Row>
          <br />
          <Row>
            <ImgInput
              title={"Profile Picture"}
              img_src={formInputs.profile_img}
              input_ref_id="profile_img"
              onChange={(value) => {
                setInput({
                  ...formInputs,
                  profile_img: value,
                });
              }}
            />

            <ImgInput
              title={"Vetting Image"}
              img_src={formInputs.vetting_img}
              input_ref_id="vetting_img"
              onChange={(value) => {
                setInput({
                  ...formInputs,
                  vetting_img: value,
                });
              }}
            />

            <ImgInput
              title={"ID / Passport Image"}
              img_src={formInputs.id_proof_doc}
              input_ref_id="id_proof_doc"
              onChange={(value) => {
                setInput({
                  ...formInputs,
                  id_proof_doc: value,
                });
              }}
            />
          </Row>
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="disabled btn-15"
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15"
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
