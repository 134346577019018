import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { alertService } from "../../services/alert.service";
const propTypes = {
  id: PropTypes.string,
  fade: PropTypes.bool,
};

const defaultProps = {
  id: "default-alert",
  fade: true,
};

const popupOptions = {
  position: "top-right",
  autoClose: 5000,
  theme: "colored",
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

function Alert({ id, fade }) {
  const [alerts, setAlerts] = useState([]);

  const callAlert = (type, message) => {
    switch (type) {
      case "Success":
        toast.success(message, popupOptions);
        break;

      case "Info":
        toast.info(message, popupOptions);
        break;

      case "Error":
        toast.error(message, popupOptions);
        break;

      case "Warning":
        toast.warn(message, popupOptions);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    // subscribe to new alert notifications
    const subscription = alertService.onAlert(id).subscribe((alert) => {
      // clear alerts when an empty alert is received
      if (!alert.message) {
        setAlerts((alerts) => {
          // filter out alerts without 'keepAfterRouteChange' flag
          const filteredAlerts = alerts.filter((x) => x.keepAfterRouteChange);

          // remove 'keepAfterRouteChange' flag on the rest
          filteredAlerts.forEach((x) => delete x.keepAfterRouteChange);
          return filteredAlerts;
        });
      } else {
        // add alert to array
        setAlerts((alerts) => [...alerts, alert]);
        callAlert(alert.type, alert.message);

        // auto close alert if required
        // if (alert.autoClose) {
        //   setTimeout(() => removeAlert(alert), 3000);
        // }
      }
    });

    // clear alerts on location change
    // const historyUnlisten = history.listen(() => {
    //   alertService.clear(id);
    // });

    // clean up function that runs when the component unmounts
    return () => {
      // unsubscribe & unlisten to avoid memory leaks
      subscription.unsubscribe();
      // historyUnlisten();
    };
  }, []);

  if (!alerts.length) return null;

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export default Alert;
