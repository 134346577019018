import React, { useRef, useState } from 'react'
import {Form } from "react-bootstrap";

export default function InputSearchLocation({size , placeholder  , onPlaceChange}) {
  const [locationInput, setLocationInput] = useState("");
    const autoCompleteRef = useRef(null)
    if(typeof window.google !== 'undefined'){
      let autocomplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
   

      autocomplete.addListener('place_changed', function (event) {
        let place = autocomplete.getPlace();
        setLocationInput(place.formatted_address)
        let street_address = '';
        let city = '';
        let state = '';
        let country = '';
        let postal_code = '';
    
        for (var comp in place.address_components) {
    
          var type = place.address_components[comp].types;
    
          if (type[0] == "street_number") {
            street_address += place.address_components[comp].long_name;
          } else if (type[0] == "neighborhood") {
            street_address += street_address != '' ? ', ' + place.address_components[comp].long_name : place.address_components[comp].long_name;
          } else if (type[0] == "route") {
            street_address += street_address != '' ? ', ' + place.address_components[comp].long_name : place.address_components[comp].long_name;
          } else if (type[0] == "sublocality") {
            street_address += street_address != '' ? ', ' + place.address_components[comp].long_name : place.address_components[comp].long_name;
          } else if (type[0] == "locality") {
            city = place.address_components[comp].long_name
          } else if (type[0] == "administrative_area_level_1") {
            state = place.address_components[comp].long_name;
          } else if (type[0] == "country") {
            country = place.address_components[comp].long_name
          } else if (type[0] == "postal_code") {
            postal_code = place.address_components[comp].long_name;
          }
        }
        let addressInfoArray = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          street_address: street_address,
          city: city,
          state: state,
          country: country,
          postal_code: postal_code
        }
    
        onPlaceChange(addressInfoArray)
      })
    }

    
  

    return (<Form.Group md={size ? size : "6"} className="mb-3">
        <Form.Control
            ref={autoCompleteRef}
            type="text"
            placeholder={placeholder}
            onChange={(e) =>{setLocationInput(e.target.value)}}
            value={locationInput}
        />
    </Form.Group>)
}
