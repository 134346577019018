import { Button, Col, Form, Row } from "react-bootstrap";

function FormWizardPage() {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="horizontal-wizard">
            <div className="bs-stepper linear">
              <div className="bs-stepper-header">
                <div className="step active">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">1</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Account Details</span>
                      <span className="bs-stepper-subtitle">
                        Enter Your Account Details.
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className="step">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">2</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Personal Info</span>
                      <span className="bs-stepper-subtitle">
                        Add Personal Info
                      </span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className="step">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">3</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Address</span>
                      <span className="bs-stepper-subtitle">Add Address</span>
                    </span>
                  </button>
                </div>
                <div className="line">
                  <i className="fa-solid fa-angle-right"></i>
                </div>
                <div className="step">
                  <button type="button" className="step-trigger">
                    <span className="bs-stepper-box">4</span>
                    <span className="bs-stepper-label">
                      <span className="bs-stepper-title">Social Links</span>
                      <span className="bs-stepper-subtitle">
                        Add Social Links
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="bs-stepper-content">
                <div className="content active dstepper-block">
                  <div className="content-header">
                    <h5 className="content-title">Account Details</h5>
                    <small className="text-muted">
                      Enter Your Account Details.
                    </small>
                  </div>
                  <Form>
                    <Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        className="mb-3"
                        controlId="username"
                      >
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="Username" />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        className="mb-3"
                        controlId="Email"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" />
                      </Form.Group>

                      <div className="d-flex justify-content-between">
                        <Button
                          type="submit"
                          className="btn-prev btn btn-outline-secondary disabled"
                        >
                          <i className="fa-regular fa-arrow-left"></i>{" "}
                          <span className="align-middle d-sm-inline-block d-none">
                            Previous
                          </span>
                        </Button>
                        <Button
                          type="submit"
                          className="btn-next btn btn-primary"
                        >
                          <span className="align-middle d-sm-inline-block d-none">
                            Next
                          </span>{" "}
                          <i className="fa-regular fa-arrow-right"></i>
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormWizardPage;
