import React from "react";

const NewLoader = () => {
  return (
    <>
      <h4>
        <b>Loading data...</b>
      </h4>
    </>
  );
};

export default NewLoader;
