import React, { useState } from "react";
import { Breadcrumb, Button, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import { badgesEnum } from "../../constants";

// import FilterComponent from "../../components/common/FilterComponent";
import { ApiRoutes } from "../../constants/apiroutes";
// import NoDataFound from "../../components/common/NoDataFound";
import { Enums } from "../../constants/enums";
import getUserAccess from "../../helpers/userAccess";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { Form, Card } from "react-bootstrap";
import { alertService, AlertType } from "../../services/alert.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputText from "../../components/form/inputText";
import NewLoader from "../../components/loader/NewLoader";

function Index() {
  const [txtSearch, setTxtSearch] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [companyTotalRows, setGuardTotalRows] = useState(0);
  const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
  const [quotationList, setQuotationList] = useState({
    isData: false,
    data: [],
  });
  const [quotationStatus, setQuotationStatus] = useState(null);
  const [quotaionFrom, setQuotaionFrom] = useState(null);
  const [quotaionTo, setQuotaionTo] = useState(null);

  const companyTblColumns = [
    {
      name: "Property Manager",
      selector: (row) => row.client_name,
      sortable: true,
      width : '170px',
      
      sortField: "client_name",
    },
    {
      name: "Property Name",
      selector: (row) => row.property_name,
      sortable: true,
      sortField: "property_name",
    },
    {
      name: "Duration",
      selector: (row) => row.duration_title,
      sortable: true,
      sortField: "duration_title",
    },
    {
      name: "Service Hours",
      selector: (row) => row.service_hours_title,
      sortable: true,
      sortField: "service_hours_title",
    },
    {
      name: "Number of Guards",
      selector: (row) => row.no_of_guards,
      sortable: true,
      sortField: "no_of_guards",
    },
    {
      name: "Number of Checkpoints",
      selector: (row) => row.no_of_checkpoints,
      sortable: true,
      sortField: "no_of_checkpoints",
    },
    {
      name: "Status",
      // selector: (row) => row.quotation_status,
      sortable: false,
      sortField: "custom_quote_status",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            {badgesEnum[row.custom_quote_status]}
          </span>
        );
      },
    },
    {
      name: "Submission Date",
      selector: (row) => row.created_at,
      sortable: true,
      sortField: "created_at",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.created_at),
    },
    {
      name: "Action",
      selector: (row) => row.year,
      cell: (row, index, column, id) => (
        <div className="justify-content-center align-items-center d-flex">
          <Button
            style={{ fontSize : "0.7rem", padding: "0.3rem 1rem" }}
            variant="primary"
            type="button"
            onClick={() => {
              navigate("view", {
                state: {
                  id: row.quotation_id,
                },
              });
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();

  React.useEffect(() => {
      getQuotationList('' ,'' ,1);
  }, [pageNo, perPageRecords]);

  const handlePageChange = (page) => {
    setPageNo(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPageRecords(newPerPage);
  };

  function filterCompaniesList() {
    getQuotationList("", "", 1);
  }
  const filterQuotation = (value) => {
    // quotation_status
    if (value) {
      setQuotationStatus(value);
    } else {
      setQuotationStatus(null);
    }
  };
  
  const resetFilter = () => {
    setQuotaionTo(null);
    setQuotaionFrom(null);
    setQuotationStatus(null);
    setTxtSearch("")
    getQuotationList("", "", 0);
  };

  const searchDateWise = () => {
    let isValidate = true;
    if (quotaionFrom || quotaionTo) {
      if (quotaionFrom && quotaionTo) {
        const dateFrom = new Date(quotaionFrom);
        const dateTo = new Date(quotaionTo);
        if (dateFrom > dateTo) {
          alertService.alert({
            type: AlertType.Error,
            message: "End date must be after start date",
          });
          isValidate = false;
        }
      }
    }
    if (isValidate) {
      getQuotationList("", "", 1);
    }
  };

  async function getQuotationList(
    column = "",
    sortDirection = "",
    isFilter = 0
  ) {
    setDataTableLoading(true);
    setQuotationList({ isData: false, data: [] });
    const requestMetadata = await APICall(ApiRoutes.QuotationList, {
      page: pageNo,
      limit: perPageRecords,
      order: { column: column, dir: sortDirection },
      is_filter: isFilter,
      filter: {
        search_text: txtSearch,
        start_date:
          quotaionFrom != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(quotaionFrom) + " 00:00:00"
              )
            : null,
        end_date:
          quotaionTo != null
            ? commonService.convertLocalToUTC(
                commonService.formatDate(quotaionTo) + " 23:59:59"
              )
            : null,
        quotation_status: quotationStatus,
      },
    });

    if (requestMetadata) {
      setQuotationList({ isData: true, data: requestMetadata.data.list });

      setGuardTotalRows(requestMetadata.data.total);
      setDataTableLoading(false);
    }
    
  }

  const handleSort = async (column, sortDirection) => {
    getQuotationList(column.sortField, sortDirection , 1);
  };

  const onSwitchToggle = async (value, item, index) => {
    const data = await APICall(ApiRoutes.GuardStatusUpdate, {
      id: item.guard_id,
      is_active: value ? "1" : "0",
    });
    if (data) {
      setQuotationList((prevState) => ({
        ...prevState,
        data: prevState.data.map((MapItem, MapIndex) => ({
          ...MapItem,
          is_active:
            MapIndex === index ? (value ? "1" : "0") : MapItem.is_active,
        })),
      }));
    }
  };
  React.useEffect(() => {
    getQuotationList("", "", 1);
  }, [txtSearch]);

  return (
    <>
      <div className="row">
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2">
            <div className="breadcrumbs-top d-flex align-items-center">
              <h2 className="content-header-title float-start mb-0">
                Quotation List
              </h2>
              <Breadcrumb className="breadcrumb-chevron">
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                {/* <Breadcrumb.Item href="/quotationreq">Setup</Breadcrumb.Item> */}
                <Breadcrumb.Item active>Quotations</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>

        <div>
          <Card>
            <Card.Body className="p-3">
              <div className="col-12 row mb-3 mt-2">
              
                <div className="col-3">
                  <InputGroup>

                    <DatePicker
                      className="datepicker form-control"
                      selected={quotaionFrom ? quotaionFrom : ""}
                      dateFormat="MM-dd-yyyy"
                      placeholderText="Start Date"
                      onChange={(date) => {
                        if (date) {
                          setQuotaionFrom(date);
                        } else {
                          setQuotaionFrom(null);
                        }
                      }}
                    />
                  
                  </InputGroup>
                </div>
                <div className="col-3">
                    <div>
                      <InputGroup>
                      <DatePicker
                        className="datepicker form-control"
                        selected={quotaionTo ? quotaionTo : ""}
                        dateFormat="MM-dd-yyyy"
                        placeholderText="End Date"
                        onChange={(date) => {
                          if (date) {
                            setQuotaionTo(date);
                          } else {
                            setQuotaionTo(null);
                          }
                        }}
                      />
                      </InputGroup>
                     
                    </div>
                  </div>
                  <div className="col-3">
                  <Form.Select onChange={(e) => { filterQuotation(e.target.value) }}>
                    <option value="" selected={!quotationStatus && true}>
                      Quotation Status
                    </option>
                        {Object.keys(Enums.QuotationRequests).map((item , i)=>{
                            return <option key={i} selected={item === quotationStatus ? true : false} value={item}>
                            {Enums.QuotationRequests[item]}
                          </option>
                        })}
                  </Form.Select>
                </div>
                <div className="col-3 d-flex">
                  
                  <div className="ms-2 me-2">
                    <Button
                      onClick={searchDateWise}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </Button>
                  </div>
                  <div className="ms-2 me-2">
                    <Button
                      onClick={resetFilter}
                      variant="outline-primary"
                      type="button"
                    >
                      <i className="fa fa-refresh"></i>
                    </Button>
                  </div>
                </div>
                
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* <FilterComponent
        searchText={txtSearch}
        PHSearchText={DisplayText.SearchText}
        PHDatepickerFrom={DisplayText.DatepickerFrom}
        PHDatepickerTo={DisplayText.DatepickerTo}
        startDate={filterStartDate}
        endDate={filterEndDate}
        onSearchTextChange={(value) => setTxtSearch(value)}
        onStartDateChange={(value) => setFilterStartDate(value)}
        onEndDateChange={(value) => setFilterEndDate(value)}
        onSearchClick={() => filterCompaniesList()}
      /> */}

      {/* {quotationList.data.length === 0 ? (
        <div className="event-details">
          <NoDataFound fontsize={"150px"} />
        </div>
      ) : ( */}
      <DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
        columns={companyTblColumns}
        data={quotationList.data}
        progressPending={dataTableLoading}
        progressComponent={<NewLoader />}
        pagination
        paginationServer
        paginationTotalRows={companyTotalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onSort={handleSort}
        paginationPerPage={perPageRecords}
        actions={<>
          <div className="col-4">
            <InputText
              formGroupClass={"mb-0"}
              size={'12'}
              type={"text"}
              Label={false}
              placeholder={"Search..."}
              value={txtSearch}
              onChange={(value) => {
                if (value) {
                  setTxtSearch(value)
                } else {
                  setTxtSearch(value)
                }
              }}
            />
          </div>
        </>}
      />
      {/* )} */}
    </>
  );
}

export default Index;
