import React from "react";
import DayScheduleCom from "./DayScheduleCom";

export default function SecondStep({
  validatePatrollingSchedule,
  daysTime,
  checkPoinSchedules,
  setCheckPointSchedules,
  patrollingSchedulesErrors,
}) {
  if (Object.keys(checkPoinSchedules)) {
    return Object.keys(checkPoinSchedules).map((term_schedule, index) => {
      return (
        <div key={index}>
          <DayScheduleCom
            daysTime={daysTime}
            validatePatrollingSchedule={validatePatrollingSchedule}
            checkPoinSchedules={checkPoinSchedules}
            term_schedule={term_schedule}
            setCheckPointSchedules={setCheckPointSchedules}
            patrollingSchedulesErrors={patrollingSchedulesErrors}
          />
        </div>
      );
    });
  } else {
    return "";
  }
}
