import { Nav, Tab, Tabs } from "react-bootstrap";

function TabsComponent() {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Start</h4>
            </div>
            <div className="card-body">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="home" title="Home">
                  <div className="tab-content">
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy. Jelly-o lollipop
                      oat cake marshmallow fruitcake candy canes toffee. Jelly
                      oat cake pudding jelly beans brownie lemon drops ice cream
                      halvah muffin. Brownie candy tiramisu macaroon tootsie
                      roll danish.
                    </p>
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy. Jelly-o lollipop
                      oat cake marshmallow fruitcake candy canes toffee. Jelly
                      oat cake pudding jelly beans brownie lemon drops ice cream
                      halvah muffin. Brownie candy tiramisu macaroon tootsie
                      roll danish.
                    </p>
                  </div>
                </Tab>
                <Tab eventKey="Disabled" title="Disabled" disabled>
                  <div className="tab-content">
                    <p>
                      Jelly oat cake pudding jelly beans brownie lemon drops ice
                      cream halvah muffin. Brownie candy tiramisu macaroon
                      tootsie roll danish.
                    </p>
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy.
                    </p>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Profile">
                  <div className="tab-content">
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy. Jelly-o lollipop
                      oat cake marshmallow fruitcake candy canes toffee. Jelly
                      oat cake pudding jelly beans brownie lemon drops ice cream
                      halvah muffin. Brownie candy tiramisu macaroon tootsie
                      roll danish.
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">End</h4>
            </div>
            <div className="card-body">
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3 justify-content-end"
              >
                <Tab eventKey="home" title="Home">
                  <div className="tab-content">
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy. Jelly-o lollipop
                      oat cake marshmallow fruitcake candy canes toffee. Jelly
                      oat cake pudding jelly beans brownie lemon drops ice cream
                      halvah muffin. Brownie candy tiramisu macaroon tootsie
                      roll danish.
                    </p>
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy. Jelly-o lollipop
                      oat cake marshmallow fruitcake candy canes toffee. Jelly
                      oat cake pudding jelly beans brownie lemon drops ice cream
                      halvah muffin. Brownie candy tiramisu macaroon tootsie
                      roll danish.
                    </p>
                  </div>
                </Tab>
                <Tab eventKey="Disabled" title="Disabled" disabled>
                  <div className="tab-content">
                    <p>
                      Jelly oat cake pudding jelly beans brownie lemon drops ice
                      cream halvah muffin. Brownie candy tiramisu macaroon
                      tootsie roll danish.
                    </p>
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy.
                    </p>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Profile">
                  <div className="tab-content">
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy. Jelly-o lollipop
                      oat cake marshmallow fruitcake candy canes toffee. Jelly
                      oat cake pudding jelly beans brownie lemon drops ice cream
                      halvah muffin. Brownie candy tiramisu macaroon tootsie
                      roll danish.
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card-snippet card">
            <div className="card-header">
              <h4 className="card-title">Tabs with icons</h4>
            </div>
            <div className="card-body">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className="mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <i className="fa-regular fa-house"></i>
                      <span className="pills-tab-text">Home</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <i className="fa-regular fa-gear"></i>
                      <span className="pills-tab-text">Service</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="three" disabled>
                      <i className="fa-regular fa-eye-slash"></i>
                      <span className="pills-tab-text">Disabled</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="tab-content">
                      <p>
                        Dragée jujubes caramels tootsie roll gummies gummies
                        icing bonbon. Candy jujubes cake cotton candy. Jelly-o
                        lollipop oat cake marshmallow fruitcake candy canes
                        toffee. Jelly oat cake pudding jelly beans brownie lemon
                        drops ice cream halvah muffin. Brownie candy tiramisu
                        macaroon tootsie roll danish.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="tab-content">
                      <p>
                        Dragée jujubes caramels tootsie roll gummies gummies
                        icing bonbon. Candy jujubes cake cotton candy. Jelly-o
                        lollipop oat cake marshmallow fruitcake candy canes
                        toffee. Jelly oat cake pudding jelly beans brownie lemon
                        drops ice cream halvah muffin. Brownie candy tiramisu
                        macaroon tootsie roll danish.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="three">
                    <div className="tab-content">
                      <p>
                        Dragée jujubes caramels tootsie roll gummies gummies
                        icing bonbon. Candy jujubes cake cotton candy. Jelly-o
                        lollipop oat cake marshmallow fruitcake candy canes
                        toffee. Jelly oat cake pudding jelly beans brownie lemon
                        drops ice cream halvah muffin. Brownie candy tiramisu
                        macaroon tootsie roll danish.
                      </p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default TabsComponent;
