import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Nav, Tab, Breadcrumb, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DefaultImg from "../../assets/Images/Icon/no_image.png";
import DocImg from "../../assets/Icons/doc.png";
import XlsImg from "../../assets/Icons/xls.png";
import PdfImg from "../../assets/Icons/pdf.png";
import JpgImg from "../../assets/Icons/jpg.png";
import DropDown from "../../components/common/DropDown";
import { badgesEnum, Enums } from "../../constants";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import MasterIcon from "../../assets/Icons/doc.png";
import axios from "axios";
import { legacy_createStore } from "redux";
import { contractStatusEnum } from "../../constants";
import DataTable from "react-data-table-component";
import ClientUsers from "../../components/clientusers/ClientUsers";
import NewLoader from "../../components/loader/NewLoader";

function View() {
  const userType = JSON.parse(commonService.getItem("user")).association_type_term;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const [clientDetails, setClientDetails] = useState(null)


  const [contractList, setContractList] = useState({
    isData: false,
    data: [],
  });
  const [contractTotalRows, setContractTotalRows] = useState(0);

  const location = useLocation();
  const params = useParams();

  const getClientDetails = async () => {

    setIsLoading(true)
    let res = await APICall(ApiRoutes.ClientDetails, { client_id: params.client_id })
    if (res.status == 1) {
      setIsLoading(false);

      let client_info = res.data;

      setClientDetails(client_info);
      setContractList({ isData: true, data: client_info.contracts });
      setContractTotalRows(client_info.contracts.length);
    }

  }

  useEffect(() => {
    getClientDetails()
  }, [])

  const renderLocation = () => {
    if (clientDetails.locations.length) {
      const locationString = clientDetails.locations[0];
      let str = (locationString.address1
      ? locationString.address1 + ", "
      : "" )+ (locationString.address2
      ? locationString.address2 + ", "
      : "" )+
        (locationString.city
          ? locationString.city + ", "
          : "") +
        (locationString.state
          ? locationString.state + ", "
          : "") +
        (locationString.country
          ? locationString.country + ", "
          : "") +
        (locationString.postal_code
          ? locationString.postal_code + ", "
          : "");
        str = str.trim();
        str = str.slice(0, -1);
        if (str.length && str && str !== "null") {
          return str;
        } else {
          return "-";
        }
    } else {
      return "";
    }
  }

  const contractTblColumns = [
    {
      name: "#",
      selector: (row) => row.full_name,
      sortable: false,
      cell: (row, index, column, id) => {
        return (
          <img
            src={
              row.property_profile_img
                ? process.env.REACT_APP_ASSETS_URL + row.property_profile_img
                : DefaultImg
            }
            className="round"
            alt="user"
            width="40"
            style={{ objectFit: "cover" }}
            height="40"
          />
        );
      },
    },
    {
      name: "Company",
      selector: (row) => row.company_name,
      sortable: true,
      sortField: "company_name",
      omit: userType === Enums.UserRole.admin ? false : true
    },
    {
      name: "Property",
      // selector: (row) => row.property_name,
      sortable: true,
      sortField: "property_name",
      // omit : userType === Enums.UserRole.admin ? true : false ,
      cell: (row, index, column, id) => (
        <a className="text-primary cursor-pointer"
          onClick={() => {
            navigate("/client_contracts/view", {
              state: {
                id: row.contract_id,
              },
            });
          }}
        >
          {row.property_name}
        </a>
      ),
    },
    // {
    //   name: "Property",
    //   selector: (row) => row.property_name,
    //   sortable: true,
    //   sortField: "property_name",
    //   omit : userType === Enums.UserRole.admin ? false : true ,
    // },
    {
      name: "Branch Manager",
      selector: (row) => row.branch_name,
      sortable: true,
      sortField: "branch_name",
    },
    {
      name: "Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      sortField: "start_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.start_date),
    },
    {
      name: "Expiry Date",
      selector: (row) => row.end_date,
      sortable: true,
      sortField: "end_date",
      cell: (row, index, column, id) =>
        commonService.convertUTCToLocal(row.end_date),
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      sortField: "is_active",
      cell: (row, index, column, id) => {
        return (
          <span className="justify-content-center align-items-center d-flex">
            {contractStatusEnum[row.contract_status]}
          </span>
        );
      },
    },

  ];
  const ContractsList = () => {
    return (<><DataTable
      paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
      columns={contractTblColumns}
      data={contractList.data}
      pagination
      paginationTotalRows={contractTotalRows}
    />
    </>)
  }

  const renderClientDetails = () => (
    <Col className="order-1 col-12 order-lg-0 col-lg-5 col-xl-4 contract-company-view">
      <Card>
        <Card.Body>
          <div className="company-profile-avatar my-3">
            <div className="text-dark h6">Profile Details</div>
            {/* <div className="d-flex align-items-center flex-column m-auto avatar rounded mt-3 mb-3 bg-light-primary">
              <span className="avatar-content">
                <img className="w-100 h-100 obj-fit-cover" src={clientDetails.profile_img ? process.env.REACT_APP_ASSETS_URL + clientDetails.profile_img : DefaultImg}></img>
              </span>
            </div> */}
            {/* <div className="d-flex flex-column align-items-center text-center user-info">
              <span className="mb-2 text-primary h4">{clientDetails.client_name}</span>
              <span className="text-capitalize badge bg-light-primary">
                #Property Manager
              </span>
            </div> */}
          </div>
         

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-user text-primary me-2"></i>
              {clientDetails.client_name ?
                clientDetails.client_name : "-"}
            </div>
          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-envelope text-primary me-2"></i>
              {clientDetails.email ?
                clientDetails.email : "-"}
            </div>
          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-phone text-primary me-2"></i>
              {clientDetails.phone ?
                clientDetails.phone : "-"}
            </div>
          </div>

          <div className="company-details mt-1">
            <div className="left-field"><i className="fa-solid fa-user text-primary me-2"></i>
              {clientDetails.position ?
                clientDetails.position : "-"}
            </div>
          </div>

          {clientDetails.locations.length ? <div className="company-details mt-1">
            <div className="left-field">{company?.locations[0] ? <><i className="fa-solid fa-location-dot text-primary me-2"></i> </> : <i className="fa-solid fa-location-dot text-primary me-2"></i>}
              {renderLocation()}
            </div>
          </div> : ""}

          <div className="company-figures d-flex justify-content-between my-3">
            <div className="d-flex align-items-start me-2">
              <span className="rounded p-75 badge bg-light-primary">
                <i className=" fa-solid fa-code-branch"></i>
              </span>
              <div className="complete-texts">
                <h4 className="mb-0">{contractList.data.length} </h4>
                <small className="h4 text-dark">Total Contracts</small>
              </div>
            </div>
            {userType == Enums.UserRole.admin || userType == Enums.UserRole.client && <div className="d-flex align-items-start me-2">
              <span className="rounded p-75 badge bg-light-primary">
                <i className=" fa-solid fa-code-branch"></i>
              </span>
              <div className="complete-texts">
                <h4 className="mb-0">{clientDetails?.users_count} </h4>
                <small className="h4 text-dark">Total Users</small>
              </div>
            </div>}
          </div>

        </Card.Body>
      </Card>

    </Col>
  );

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">
              Property Manager
            </h2>
            {userType == Enums.UserRole.client ? <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Profile</Breadcrumb.Item>
            </Breadcrumb> :
             <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/clients">Property Manager</Breadcrumb.Item>
              <Breadcrumb.Item active>View</Breadcrumb.Item>
            </Breadcrumb>  }
          </div>
        </div>
      </div>

      {!isLoading ? (<div className="app-user-view">
        <Row>

          {renderClientDetails()}

          <Col className="order-0 col-12 order-lg-1 col-lg-8 col-xl-8 col">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="mb-3">
                <Nav.Item className=" cursor-pointer">
                  <Nav.Link eventKey="first">
                    <i className="fa-regular fa-th-large"></i>
                    <span className="pills-tab-text">Contracts</span>
                  </Nav.Link>
                </Nav.Item>
                {userType == Enums.UserRole.admin || userType == Enums.UserRole.client && <Nav.Item className=" cursor-pointer">
                  <Nav.Link eventKey="sec">
                    <i className="fa-regular fa-list"></i>
                    <span className="pills-tab-text">Users</span>
                  </Nav.Link>
                </Nav.Item>}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <ContractsList />
                </Tab.Pane>

                {userType == Enums.UserRole.admin || userType == Enums.UserRole.client && <Tab.Pane eventKey="sec">
                  <ClientUsers client_id={params.client_id} />
                </Tab.Pane>}
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </div>) : <NewLoader/>}
    </>
  );





}


export default View;