import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import InputText from "../../../components/form/inputText";
import InputSearchLocation from "../../../components/form/InputSearchLocation";

export default function EditWizardThree({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const handleLocation = (addressInfoArray) => {
    setInput({
      ...formInputs,
      locations: [{
        ...formInputs.locations[0],
        address1: addressInfoArray.street_address,
        city: addressInfoArray.city,
        state: addressInfoArray.state,
        postal_code: addressInfoArray.postal_code,
        country: addressInfoArray.country,
        longitude: addressInfoArray.lng,
        latitude: addressInfoArray.lat,
      }]
    })
  }
  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header">
          <InputSearchLocation size={"12"} placeholder="Search location" onPlaceChange={handleLocation} />
        </div>
        <Form validated={validated} onSubmit={handleSubmit}>
          {formInputs?.locations?.map((mapItem, mapIndex) => (
            <>
              <Row>
                <InputText
                  type={"text"}
                  title={"Address"}
                  value={mapItem.address1}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      locations: formInputs.locations.map(
                        (innerItem, innerIndex) => {
                          if (innerIndex === mapIndex) {
                            return {
                              ...innerItem,
                              address1: value,
                            };
                          }
                        }
                      ),
                    })
                  }
                  required
                  validationText={
                    formInputsErrors?.locations[mapIndex]?.address1
                  }
                />
                <InputText
                  type={"text"}
                  title={"City"}
                  value={mapItem.city}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      locations: formInputs.locations.map(
                        (innerItem, innerIndex) => {
                          if (innerIndex === mapIndex) {
                            return {
                              ...innerItem,
                              city: value,
                            };
                          }
                        }
                      ),
                    })
                  }
                  required
                  validationText={formInputsErrors?.locations[mapIndex]?.city}
                />
              </Row>
              <Row>
                <InputText
                  type={"text"}
                  title={"State"}
                  value={mapItem.state}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      locations: formInputs.locations.map(
                        (innerItem, innerIndex) => {
                          if (innerIndex === mapIndex) {
                            return {
                              ...innerItem,
                              state: value,
                            };
                          }
                        }
                      ),
                    })
                  }
                  required
                  validationText={formInputsErrors?.locations[mapIndex]?.state}
                />
                <InputText
                  type={"text"}
                  title={"Postal code"}
                  value={mapItem.postal_code}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      locations: formInputs.locations.map(
                        (innerItem, innerIndex) => {
                          if (innerIndex === mapIndex) {
                            return {
                              ...innerItem,
                              postal_code: value,
                            };
                          }
                        }
                      ),
                    })
                  }
                  required
                  validationText={
                    formInputsErrors?.locations[mapIndex]?.postal_code
                  }
                />
              </Row>
              <Row>
                <InputText
                  type={"text"}
                  title={"Country"}
                  value={mapItem.country}
                  onChange={(value) =>
                    setInput({
                      ...formInputs,
                      locations: formInputs.locations.map(
                        (innerItem, innerIndex) => {
                          if (innerIndex === mapIndex) {
                            return {
                              ...innerItem,
                              country: value,
                            };
                          }
                        }
                      ),
                    })
                  }
                  required
                  validationText={
                    formInputsErrors?.locations[mapIndex]?.country
                  }
                />
              </Row>
            </>
          ))}
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              <Button
                className="btn-15 "
                variant="primary"
                onClick={onNextPress}
              >
                <span className="align-middle d-sm-inline-block d-none">
                  Next
                </span>{" "}
                <i className="fa-regular fa-arrow-right"></i>
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
