import React, { useState } from 'react'
import { Button, Modal, Spinner } from "react-bootstrap";
import DropDown from "../../components/common/DropDown";
import { ApiRoutes, Enums } from '../../constants';
import { APICall } from '../../services/axiosService';

export default function GuardStatusPopup({getGuardsList , guardStatusPopup , setGuardStatusPopup , guardStatusId , setGuardStatusId}) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [statusError, setStatusError] = useState("");


    const handleSubmit = async(e) => {

        if(selectedStatus){
            setIsLoading(true)
            e.preventDefault()
            const res = await APICall(ApiRoutes.acceptGuardStatus, { 
                guard_id : guardStatusId,
                status : selectedStatus
            })
            if(res){
                getGuardsList("", "", 1);
                closePopUp()
                setIsLoading(false)
            }else{
                setIsLoading(false)
            }

            
        }else{
            setStatusError("Please select guard status.")
        }
    }

    const closePopUp = () => {

        setGuardStatusPopup(false)
        setGuardStatusId(null)
        setStatusError("")
    }

  return (<Modal className="modal-popup" show={guardStatusPopup} onHide={closePopUp}>
  <Modal.Header closeButton>
      <Modal.Title>Select Guard Status</Modal.Title>
  </Modal.Header>
  <Modal.Body>
      <DropDown
          style={{ div: "col-md-12 col-12" }}
          data={Enums.GuardInchargeDropDown}
          title={"Guard Status"}
          value={selectedStatus}
          isMulti={false}
          onChange={(value) => setSelectedStatus(value)}
          required
          validationText={statusError}
      />
  </Modal.Body>
  <Modal.Footer>
      <Button variant="outline-primary" onClick={closePopUp}>
          Close
      </Button>
      {isLoading === false ? (
          <>
              <Button variant="primary"
                  onClick={(e) => { handleSubmit(e) }}
              >
                  Save
              </Button>
          </>
      ) : (
          <Button variant="primary">
              <Spinner animation="border" size="sm" /> Loading...
          </Button>
      )}
  </Modal.Footer>
</Modal>)
}
