import React, { useState } from 'react'
import { useEffect } from 'react';
import { Breadcrumb, Spinner, Modal, Button, Form } from "react-bootstrap";
import TextInput from "../../components/form/inputText";
import { ApiRoutes, Enums } from '../../constants';
import { commonHelper } from '../../helpers/commonHelper';
import { alertService, AlertType } from '../../services/alert.service';
import { APICall } from '../../services/axiosService';
import { commonService } from '../../services/common.service';

export default function SendReviewGuard({ guardList, isAddEdit, fetchApiAfter, setIsEdit, reviewModal, setReviewModal, contract_id = null, is_edit = false, reviewId = null, setReviewId = null }) {

    const user = JSON.parse(commonService.getItem("user"));
    const [loading, setLoading] = useState(false);
    const [formInputs, setFormInputs] = useState({
        is_edit: is_edit,
        contract_id: contract_id,
        from_association_id: user.association_id,
        from_association_type_term: user.association_type_term,
        to_association_id: "",
        to_association_type_term: Enums.UserRole.guard,
        review: "",
        rating: 0
    });

    const [formInputsErrors, setFormInputsErrors] = useState({
        to_association_id: "",
        // review: "",
        rating: 0
    });

    const [fetchLoading, setFetchLoading] = useState(false);

    const validationError = {
        to_association_id: "Select guard for review.",
        rating: "Rating field is required."
    }
    const closePopup = () => {

        setReviewModal(false);
        setFormInputs({
            is_edit: false,
            contract_id: contract_id,
            from_association_id: user.association_id,
            from_association_type_term: user.association_type_term,
            to_association_id: "",
            to_association_type_term:  Enums.UserRole.guard,
            review: "",
            rating: 0
        })
        setFormInputsErrors({
            to_association_id: "",
            // review: "",
            rating: 0
        })

        setIsEdit(false)
        if (setReviewId) {
            setReviewId(null)
        }
        setReviewId()
    }

    const validateForm = () => {
        let errorJson = {}
        let isValidate = true;
        Object.keys(formInputsErrors).forEach((key_name) => {
            if (formInputs[key_name] === "" || !formInputs[key_name]) {
                errorJson = {
                    ...errorJson,
                    [key_name]: validationError[key_name]
                }
                isValidate = false;
            }
        })
        setFormInputsErrors(errorJson)
        // if (!reviewId) {
        //     if (!isAddEdit) {
        //         alertService.alert({
        //             type: AlertType.Error,
        //             message: "You have already submitted review for this month.",
        //         });
        //         isValidate = false;
        //     }
        // }
        return isValidate;
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {

            let data = formInputs;
            if (is_edit) {
                data = {
                    ...formInputs,
                    review_id: reviewId
                }
            }

            setLoading(true)
            let res = await APICall(ApiRoutes.addUpdateReview, data)
            if (res.status === 1) {
                closePopup()
                fetchApiAfter("", "", 1)
            }
            setLoading(false)

        }
    }

    const handleRat = (e) => {
        setFormInputs({
            ...formInputs,
            rating: parseInt(e.target.value)
        })
    }
    let arrayRating = [5, 4, 3, 2, 1];

    const fetchReview = async () => {
        setFetchLoading(true);
        let res = await APICall(ApiRoutes.fetchRating, { review_id: reviewId })
        if (res.status == 1) {
            setFormInputs({
                is_edit: true,
                contract_id: res.data.contract_id,
                from_association_id: res.data.from_association_id,
                from_association_type_term: res.data.from_association_type_term,
                to_association_id: res.data.to_association_id,
                to_association_type_term: res.data.to_association_type_term,
                review: res.data.review,
                rating: res.data.rating
            })
        }
        setFetchLoading(false)
    }

    useEffect(() => {
        if (reviewId) {
            fetchReview(reviewId)
        }
    }, [reviewId]);

    return (<>
        <Modal className="modal-popup" size="lg" show={reviewModal} onHide={closePopup}>
            <Modal.Header closeButton>
                <Modal.Title>{is_edit ? "Edit Review" : "Send Review"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className='mb-3'>
                    <Form.Label>Guard</Form.Label>
                    <Form.Select
                    disabled={is_edit ? true :false}
                    onChange={(e) => {
                        if(!is_edit){
                            setFormInputs({
                                ...formInputs,
                                to_association_id: e.target.value ? e.target.value : "",
                            })
                        }
                    }} aria-label="Default select example">
                        <option value="">Select Guard</option>
                        {guardList.map((guard_item, index) => {
                            return (
                                <option key={index} selected={guard_item.guard_id === formInputs.to_association_id ? true : false} value={guard_item.guard_id}>{guard_item.guard_name}</option>
                            )
                        })}
                    </Form.Select>
                    {formInputsErrors.to_association_id !== "" && <span className="error">{formInputsErrors.to_association_id}</span>}
                </Form.Group>
                <label>Rating</label>
                <div className="form-body">

                    <div className="star-rating">

                        {arrayRating.map((item, index) => {
                            return (<>
                                <input checked={formInputs.rating == item ? true : false} id={`star-${item}`} type="radio" onChange={(e) => handleRat(e)} name="rating" value={item} />
                                <label htmlFor={`star-${item}`} title={`${item} stars`}>
                                    <i className="active fa fa-star" aria-hidden="true"></i>
                                </label>
                            </>)
                        })}

                    </div>
                    <div className='mb-1'>{formInputsErrors.rating ? <span className="error">{formInputsErrors.rating}</span> : ""}</div>

                    <div className="row">
                        <TextInput
                            multiLine={true}
                            size={"12"}
                            type={"text"}
                            title={"Review"}
                            value={formInputs.review}
                            onChange={(value) => {
                                if (value) {
                                    setFormInputs({
                                        ...formInputs,
                                        review: value,
                                    })
                                } else {
                                    setFormInputs({
                                        ...formInputs,
                                        review: "",
                                    })
                                }

                            }}
                            validationText={formInputsErrors.review}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={closePopup}>
                    Close
                </Button>
                {!loading ? (
                    <>
                        <Button type="button" variant="primary"
                            onClick={(e) => handleFormSubmit(e)}
                        >
                            {is_edit ? "Update" : "Send"}
                        </Button>
                    </>
                ) : (
                    <Button variant="primary">
                        <Spinner animation="border" size="sm" /> Loading...
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    </>)
}
