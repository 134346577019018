import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Form, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import ImgInput from "../../components/common/ImgInput";
import InputText from "../../components/form/inputText";
import { ApiRoutes } from "../../constants/apiroutes";
import { APICall } from "../../services/axiosService";
import { commonService } from "../../services/common.service";
import { updateLoading } from "../../store/actions";

function Edit(props) {
  const navigate = useNavigate();
  let auth_user = commonService.getLoginUserData();

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const location = useLocation();

  const userData = JSON.parse(commonService.getItem("user"));

  const routeParams = location.state;
  useEffect(() => {
    getDetails();
  }, []);

  const [isCompanyFormClick, setIsCompanyFormClick] = useState(false);
  const dispatch = useDispatch();

  const [formInputs, setInput] = useState({
    profile_img: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    position: "",
  });
  const [formInputsErrors, setInputErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    position: "",
  });

  const validation_msg = {
    first_name: "This field is required",
    last_name: "This field is required",
    email: "This field is required",
    phone: "This field is required",
    position: "This field is required",
  };
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  async function submitUsersForm(event) {
    event.preventDefault();
    if (validateCompanyForm()) {
      dispatch(updateLoading(true));
      setIsCompanyFormClick(true);
      const requestMetadata = await APICall(ApiRoutes.UserSave, {
        ...formInputs,
        profile_img: formInputs.profile_img?.includes(
          process.env.REACT_APP_ASSETS_URL
        )
          ? null
          : formInputs.profile_img,
      });
      setIsCompanyFormClick(false);
      dispatch(updateLoading(false));
      if (requestMetadata) {
        navigate("/user");
      }
    }
  }

  function validateCompanyForm() {
    let isValid = true;
    let errors = {};
    const errorKeys = Object.keys(formInputsErrors);
    errorKeys.forEach((item) => {
      if (
        formInputs[item] === null ||
        formInputs[item]?.toString().trim() === ""
      ) {
        errors[item] = validation_msg[item];
        isValid = false;
      } else {
        if (item === "guard_email" && !pattern.test(formInputs.email)) {
          errors[item] = validation_msg.invalid_email;
          isValid = false;
        } else {
          errors[item] = "";
        }
      }
    });

    setInputErrors(errors);
    return isValid;
  }

  const getDetails = async () => {
    const res = await APICall(ApiRoutes.UserEditDetails, {
      id: routeParams.id,
    });
    if (res) {
      setInput({
        ...res.data,
        profile_img: res.data.profile_img
          ? process.env.REACT_APP_ASSETS_URL + res.data.profile_img
          : null,
      });
    }
  };

  return (
    <>
      <div className="content-header row">
        <div className="content-header-left col-md-9 col-12 mb-2">
          <div className="breadcrumbs-top d-flex align-items-center">
            <h2 className="content-header-title float-start mb-0">Edit user</h2>
            <Breadcrumb className="breadcrumb-chevron">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item href="/user">Setup</Breadcrumb.Item>
              <Breadcrumb.Item href="/user">Users</Breadcrumb.Item>
              <Breadcrumb.Item active>Edit</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <Card className="pt-4">
        <Card.Body>
          <Form validated={validated} onSubmit={handleSubmit}>
            <Row>
              <InputText
                type={"text"}
                title={"First Name"}
                value={formInputs.first_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    first_name: value,
                  })
                }
                required
                validationText={formInputsErrors.first_name}
              />

              <InputText
                type={"text"}
                title={"Last Name"}
                value={formInputs.last_name}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    last_name: value,
                  })
                }
                required
                validationText={formInputsErrors.last_name}
              />
            </Row>
            <Row>
              <InputText
                type={"email"}
                title={"Corporate Email"}
                value={formInputs.email}
                disabled={true}
                required
                validationText={formInputsErrors.email}
              />
              <InputText
                type={"number"}
                title={"Phone"}
                value={formInputs.phone}
                disabled={true}
                required
                validationText={formInputsErrors.phone}
              />
            </Row>
            <Row>
              <InputText
                type={"text"}
                title={"Position"}
                value={formInputs.position}
                onChange={(value) =>
                  setInput({
                    ...formInputs,
                    position: value,
                  })
                }
                required
                validationText={formInputsErrors.position}
              />
            </Row>
            <Row>
              <ImgInput
                title={"Profile Picture"}
                img_src={formInputs.profile_img}
                input_ref_id="profile_img"
                onChange={(value) => {
                  setInput({
                    ...formInputs,
                    profile_img: value,
                  });
                }}
              />
            </Row>
            <div className="col-12 d-flex justify-content-end">
              <Button
                className="btn-15"
                variant="outline-primary"
                onClick={() => {
                  navigate("/user");
                }}
              >
                Cancel
              </Button>

              {isCompanyFormClick === false ? (
                <>
                  <Button
                    variant="primary"
                    className="btn-15"
                    onClick={submitUsersForm}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button variant="primary" className="btn-15">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default Edit;
