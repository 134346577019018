import React, { useState } from "react";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import InputText from "../../../components/form/inputText";

export default function CreateWizardFour({
  formInputs,
  setInput,
  formInputsErrors,
  onNextPress,
  onPreviousPress,
  isCompanyFormClick,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const renderCheckPoint = () => {
    const renderarr = Object.keys(formInputs.checkpoint_list);
    return renderarr.map((item, index) => (
      <InputText
        key={index}
        type={"text"}
        title={"Title of checkpoint " + (index + 1)}
        value={formInputs.checkpoint_list[item]}
        onChange={(value) =>
          setInput({
            ...formInputs,
            checkpoint_list: {
              ...formInputs.checkpoint_list,
              [item]: value,
            },
          })
        }
        required
        validationText={formInputsErrors[item]}
      />
    ));
  };

  return (
    <div className="bs-stepper-content p-4">
      <div className="content active dstepper-block">
        <div className="content-header"></div>
        <Form validated={validated} onSubmit={handleSubmit}>
          <Row>{renderCheckPoint()}</Row>
          <Row>
            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="outline-primary"
                className="btn-15"
                onClick={onPreviousPress}
              >
                <i className="fa-regular fa-arrow-left"></i>{" "}
                <span className="align-middle d-sm-inline-block d-none">
                  Previous
                </span>
              </Button>
              {isCompanyFormClick === false ? (
                <>
                  <Button
                    className="btn-15"
                    variant="primary"
                    onClick={onNextPress}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button className="btn-15" variant="primary">
                  <Spinner animation="border" size="sm" /> Loading...
                </Button>
              )}
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
}
