import React from "react";
import "../../../assets/styles/Component.css";

function DrawerButton({
  isParentOpen,
  onExpandClick,
  item_name,
  noChild,
  icon,
}) {
  return (
    <div
      className={`ButtonLink ${
        isParentOpen ? (noChild ? "link active" : "open") : ""
      }`}
      onClick={() => onExpandClick()}
    >
      <div className="DrawerInnerButton">
        <span className="ButtonIcon">
          <i className={icon}></i>
        </span>
        <span className="ButtonTitle">{item_name}</span>
      </div>
      {!noChild && (
        <div className="menu-toggle-icon">
          <i className="fa-regular fa-angle-down"></i>
        </div>
      )}
    </div>
  );
}
export default DrawerButton;
