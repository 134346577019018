import React, { useEffect, useState } from 'react'
import { ApiRoutes, Enums } from '../../../constants';
import { APICall } from '../../../services/axiosService';
import { commonService } from '../../../services/common.service'
import { useNavigate, Link } from "react-router-dom";
import { Breadcrumb, Button, InputGroup } from "react-bootstrap";
import { Form, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { alertService, AlertType } from "../../../services/alert.service";
import DataTable from "react-data-table-component";
import InputText from "../../../components/form/inputText";
import Switch from "react-switch";
import SendReviewGuard from '../../Review/SendReviewGuard';
import NewLoader from '../../../components/loader/NewLoader';

export default function Index({ action_right, contractId, guardList }) {
    const userType = JSON.parse(commonService.getItem("user")).association_type_term;
    const navigate = useNavigate();
    const [txtSearch, setTxtSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [perPageRecords, setPerPageRecords] = useState(Enums.DefaultPerPageLength);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [totalRows, setTotalRows] = useState(0);
    const [reviewList, setReviewList] = useState({
        isData: false,
        data: [],
    });
    const [dataTableLoading, setDataTableLoading] = useState(false);


    const [reviewModal, setReviewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [reviewId, setReviewId] = useState(null);
    const [isAddEdit, setIsAddEdit] = useState(false);

    React.useEffect(() => {
        fetchReviews("", "", 1);
    }, [txtSearch,pageNo, perPageRecords]);


    // const fetchReviews = async () => {
    //   let res = await APICall(ApiRoutes.fetchReviews, { contract_id: contractId })
    // }
    // useEffect(() => {
    //   fetchReviews()
    // }, []);

    async function fetchReviews(
        column = "",
        sortDirection = "",
        isFilter = 0
    ) {
        setDataTableLoading(true);
        const requestMetadata = await APICall(ApiRoutes.fetchReviews, {
            is_all: false,
            contract_id: contractId,
            page: pageNo,
            limit: perPageRecords,
            order: { column: column, dir: sortDirection },
            is_filter: isFilter,
            filter: {
                search_text: txtSearch,
                start_date:
                    from != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(from) + " 00:00:00"
                        )
                        : null,
                end_date:
                    to != null
                        ? commonService.convertLocalToUTC(
                            commonService.formatDate(to) + " 23:59:59"
                        )
                        : null,
            },
        });

        if (requestMetadata) {
            setReviewList({ isData: true, data: requestMetadata.data.list });

            setTotalRows(requestMetadata.data.total);
            setIsAddEdit(requestMetadata.data.is_add_edit)
        }
        setDataTableLoading(false);
    }

    const handleSort = async (column, sortDirection) => {
        fetchReviews(column.sortField, sortDirection, 1);
    };

    const searchDateWise = (e) => {
        e.preventDefault();
        let isValidate = true;
        if (from || to) {
            if (from && to) {
                const dateFrom = new Date(from);
                const dateTo = new Date(to);
                if (dateFrom > dateTo) {
                    alertService.alert({
                        type: AlertType.Error,
                        message: "End date must be after start date",
                    });
                    isValidate = false;
                }
            }
        }
        if (isValidate) {
            fetchReviews("", "", 1);
            setTotalRows(1)
        }
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPageRecords(newPerPage);
    };

    const handlePageChange = (page) => {
        setPageNo(page);
    };

    const resetFilter = () => {
        setTo(null);
        setFrom(null);
        setTxtSearch("")
        fetchReviews("", "", 0);
    };

    // React.useEffect(() => {
    //     fetchReviews("", "", 1);
    // }, [txtSearch]);

    const columns = [
        {
            name: "Property Manager",
            selector: (row) => row.client_name,
            sortable: true,
            sortField: "client_name",
            width: "180px",
        },
        {
            name: "From User",
            selector: (row) => <span onClick={() => {

                // navigate(`/clients/view/${row.from_association_id}`)
            }} className="p-0">{Enums.UserRoleDetail[row.from_association_type_term]}</span>,
            sortable: true,
            sortField: "from_association_name",
            width: "180px",
        },
        {
            name: "From User Title",
            selector: (row) => <span onClick={() => {
                // if (row.from_association_type_term === Enums.UserRole.client) {
                //     navigate(`/clients/view/${row.from_association_id}`)
                // }
                // 
            }} className={`p-0`}>{row.from_association_name}</span>,
            sortable: true,
            sortField: "from_association_name",
            width: "180px",
        },
        {
            name: "To User",
            selector: (row) => <span onClick={() => {
                if (row.to_association_type_term == Enums.UserRole.company) {
                    // navigate('/contract/company/profile', { state: { comapny_id: row.to_association_id } })
                } else if (row.to_association_type_term == Enums.UserRole.branch_manager) {
                    // navigate('/contract/branch/profile', { state: { branch_id: row.to_association_id } })
                }
            }} className="p-0">{Enums.UserRoleDetail[row.to_association_type_term]}</span>,
            sortable: true,
            sortField: "to_association_name",
            width: "180px",
        },
        {
            name: "To User Title",
            selector: (row) => <span onClick={() => {
                // if (row.to_association_type_term == Enums.UserRole.company) {
                //     navigate('/contract/company/profile', { state: { comapny_id: row.to_association_id } })
                // } else if (row.to_association_type_term == Enums.UserRole.branch_manager) {
                //     navigate('/contract/branch/profile', { state: { branch_id: row.to_association_id } })
                // }
            }} className={`p-0`}>{row.to_association_name}</span>,
            sortable: true,
            sortField: "to_association_name",
            width: "180px",
        },
        {
            name: "Review",
            selector: (row) => <span className="cursor-pointer" title={row.review}>{row.review}</span>,
            sortable: true,
            sortField: "review",
            width: "180px",
        },
        {
            name: "Rating",
            selector: (row) => row.rating,
            sortable: true,
            sortField: "rating",
            width: "100px",
        },
        {
            name: "Reviewed At",
            sortable: false,
            sortField: "created_at",
            width: "130px",
            cell: (row, index, column, id) =>
                commonService.convertUTCToLocal(row.created_at),
        },
        {
            name: "Action",

            selector: (row) => row.year,
            width: "100px",
            omit: userType === Enums.UserRole.admin ? true : false,
            cell: (row, index, column, id) =>

                <div className="cursor-pointer justify-content-center align-items-center d-flex">
                    {row.from_association_type_term === Enums.UserRole.company && <div
                        onClick={() => {
                            setIsEdit(true)
                            setReviewModal(true)
                            setReviewId(row.review_id)
                        }}
                    >
                        <i className="fa fa-edit text-primary"></i>
                    </div>}
                </div>
            ,
        }

        // 
    ];

    return (<>
        <div className="row">
            <div>
                <Card>
                    <Card.Body className="p-3">
                        <div className="col-12 row mb-3 mt-2">
                            <div className="col-3">
                                <InputGroup>
                                    <DatePicker
                                        className="datepicker form-control"
                                        selected={from ? from : ""}
                                        dateFormat="MM-dd-yyyy"
                                        placeholderText="MM-DD-YYYY"
                                        onChange={(date) => {
                                            if (date) {
                                                setFrom(date);
                                            } else {
                                                setFrom(null);
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </div>
                            <div className="col-3">
                                <div>
                                    <InputGroup>
                                        <DatePicker
                                            className="datepicker form-control"
                                            selected={to ? to : ""}
                                            dateFormat="MM-dd-yyyy"
                                            placeholderText="MM-DD-YYYY"
                                            onChange={(date) => {
                                                if (date) {
                                                    setTo(date);
                                                } else {
                                                    setTo(null);
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </div>

                            <div className="col-3 d-flex">
                                <div className="ms-2 me-2">
                                    <Button
                                        onClick={searchDateWise}
                                        variant="outline-primary"
                                        type="button"
                                    >
                                        <i className="fa fa-search"></i>
                                    </Button>
                                </div>
                                <div className="ms-2 me-2">
                                    <Button
                                        onClick={resetFilter}
                                        variant="outline-primary"
                                        type="button"
                                    >
                                        <i className="fa fa-refresh"></i>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>

        <SendReviewGuard guardList={guardList} isAddEdit={isAddEdit} fetchApiAfter={fetchReviews} reviewId={reviewId} setReviewId={setReviewId} contract_id={contractId} setIsEdit={setIsEdit} is_edit={isEdit} reviewModal={reviewModal} setReviewModal={setReviewModal} />


        {userType != Enums.UserRole.admin && action_right ? <div className="col-12 d-flex align-items-center justify-content-end mb-3 mt-2">
            {/* <Link to="/contract/send/review"> */}
            <Button onClick={() => { setReviewModal(true) }} className="btn-15" variant="primary" type="button">
                <i className="fa-regular fa-plus"></i> Send Review
            </Button>
            {/* </Link> */}
        </div> : ""}

        <DataTable
        paginationRowsPerPageOptions={Enums.DefaultRowsPerPageOptions}
            columns={columns}
            data={reviewList.data}
            progressPending={dataTableLoading}
            progressComponent={<NewLoader />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
            paginationPerPage={perPageRecords}
            actions={<>
                <div className="col-4">
                    <InputText
                        formGroupClass={"mb-0"}
                        size={'12'}
                        type={"text"}
                        Label={false}
                        placeholder={"Search..."}
                        value={txtSearch}
                        onChange={(value) => {
                            if (value) {
                                setTxtSearch(value)
                            } else {
                                setTxtSearch(value)
                            }
                        }}
                    />
                </div>
            </>}
        />
    </>)
}
